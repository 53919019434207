import { useState, ChangeEvent } from 'react';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';

import Button from 'components/common/Button';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import { TextField } from '@mui/material';
import colors from 'constants/colors';

export default function CompanyLLMOutputsDoubleCheck({
  llmSourceId,
  pg,
  refetchLLMOutputs,
}: {
  llmSourceId: string;
  pg: number | null;
  refetchLLMOutputs: () => void;
}) {
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [pageValue, setPageValue] = useState<number | null>(pg);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPageValue(Number(event.target.value));
  };

  const { mutate, isError, isLoading } = useMutation({
    onSuccess: () => {
      refetchLLMOutputs();
    },
    onError: (err) => {
      console.error('Error during double check:', err);
      setErrorMessage(JSON.stringify((err as any).response.data));
    },
    mutationFn: async () => {
      const res = await axios.post(
        `${api.url}/etl/services/image_based_llm_retriever/`,
        {
          llmoutput_source_id: llmSourceId,
          page_number: pageValue,
        },
        auth.requestConfig
      );
      return res;
    },
  });

  return (
    <Stack
      spacing={1}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      {pg ? (
        <Stack direction="row">
          <TextField
            sx={{ width: 70, alignSelf: 'center' }}
            size="small"
            id="outlined-basic"
            label="pg"
            variant="outlined"
            type="number"
            value={pageValue}
            onChange={handleChange}
          />
          <Button
            size="small"
            variant="contained"
            color="secondary"
            sx={{ alignSelf: 'center' }}
            onClick={() => mutate()}
            disabled={isLoading}
          >
            double check
          </Button>
        </Stack>
      ) : null}
      {isError && (
        <Typography color={colors.warning500}>
          An error occurred: {errorMessage}
        </Typography>
      )}
    </Stack>
  );
}

import React from 'react';

import CPASurveyQuestion from './CPASurveyQuestion';
import useCPASurvey from '../store/useCPASurvey';

const CPASurveyQuestions = ({ identifier, final }: any) => {
  const { flagQuestion, surveyQuestions } = useCPASurvey();
  const questions = surveyQuestions.slice();
  return (
    <div>
      {questions.map((question, i) => {
        return (
          <div key={question.id}>
            <CPASurveyQuestion
              id={question.id}
              title={question.title}
              mediaid={question.mediaid}
              values={question.results ? Object.values(question.results) : []}
              answers={question.answers ? Object.values(question.answers) : []}
              cpasurveyId={identifier}
              index={i}
              onFlagClick={() => {
                flagQuestion(question.id);
              }}
              final={final}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CPASurveyQuestions;

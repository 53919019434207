import React from 'react';
import { observer } from 'mobx-react-lite';
import useAuth from 'hooks/useAuth';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { getCountryName } from 'utils/getCountryName';

import Box from 'components/common/Box';
import Card from '@mui/material/Card';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LocationIcon from 'components/icons/Location';

import RatingLabel from 'components/common/CompanyRating/RatingLabel/RatingLabel';
import CompanyLogo from './CompanyLogo';
import TccPublishChip from 'components/common/TccPublishChip';

import Grid from 'components/common/Grid';

import colors from 'constants/colors';
import Typography from 'components/common/Typography';
import SaveFavoriteButton from 'components/common/SaveFavoriteButton';
import { useNavigate } from 'react-router-dom';
import useCompanies from 'hooks/useCompanies';

const CompaniesListDataItem = ({ row }: { row: any }) => {
  const auth = useAuth();
  let navigate = useNavigate();
  const { t } = useTranslation('common');
  const [isHover, setIsHover] = React.useState(false);

  const hasEmployeeRange = Object.keys(row).includes('employee_range');
  const hasTurnover = Object.keys(row).includes('turnover');
  const { companyFilterCategories } = useCompanies();

  return (
    <Card
      elevation={isHover ? 4 : 0}
      sx={{
        mb: 3,
        cursor: 'pointer',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        '&:hover': {
          boxShadow: 4,
        },
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => {
        navigate(`/company/${row.slug}`);
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={9} md={8} xs={12}>
          <Box display="flex">
            <Box sx={{ flex: '0 0 auto', p: 1.5 }}>
              <CompanyLogo title={row.name} logoSrc={row.logo} />
            </Box>
            <Box sx={{ flex: '1 1 auto', p: 1.5 }}>
              <Box display="flex" alignItems="center" color={colors.neutral600}>
                <Box
                  sx={{
                    flex: '0 0 auto',
                    maxHeight: '35px',
                    mr: 1,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <LocationIcon />
                </Box>
                <Box
                  sx={{
                    flex: '1 1 auto',
                    textTransform: 'uppercase',
                    fontSize: '.85em',
                    fontWeight: 'bold',
                    ml: -0.5,
                  }}
                >
                  {row.city && row.country
                    ? [row.city, getCountryName(row.country)].join(',   ')
                    : getCountryName(row.country)}
                </Box>
                <TccPublishChip
                  isAdmin={auth.hasAdminPermission}
                  published={row.published}
                  visibleIcon={false}
                />
              </Box>
              <Typography variant={'h5'} color={'black'} sx={{ mt: 1, mb: 1 }}>
                {row.name}
              </Typography>
              {row.tcc_product_tags && (
                <Box>
                  <Typography
                    variant="caption"
                    sx={{ mr: 1, fontWeight: 'bold', color: 'text.secondary' }}
                  >
                    {t('Product & Services') + ':'}
                  </Typography>
                  <Typography variant="caption" color="primary.main">
                    {Object.values(row.tcc_product_tags).length === 0 ? (
                      <Typography variant="caption" color={colors.neutral400}>
                        {t('not available')}
                      </Typography>
                    ) : (
                      Object.values(row.tcc_product_tags).join(',  ')
                    )}
                  </Typography>
                </Box>
              )}
              {companyFilterCategories?.sectors && (
                <Box>
                  <Typography
                    variant="caption"
                    sx={{ mr: 1, fontWeight: 'bold', color: 'text.secondary' }}
                  >
                    {t('Sector') + ':'}
                  </Typography>
                  <Typography variant="caption" color="primary.main">
                    {row.sector ? (
                      companyFilterCategories?.sectors[row.sector]
                    ) : (
                      <Typography variant="caption" color={colors.neutral400}>
                        {t('not available')}
                      </Typography>
                    )}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Box
            sx={{
              p: 1.5,
              backgroundColor: colors.neutral100,
              height: '100%',
            }}
          >
            <Box sx={{ mb: 1 }}>
              <SaveFavoriteButton rowId={row.id} />
            </Box>
            {row.survey && (
              <Box display="flex" alignItems="center" sx={{ mb: 0.75 }}>
                <Box
                  sx={{
                    flex: '0 0 auto',
                    maxHeight: '35px',
                    mr: 1,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <RatingLabel iconSrc={row.survey.badge} width="35px" />
                </Box>
                <Box
                  sx={{
                    flex: '1 1 auto',
                    textTransform: 'uppercase',
                    fontSize: '.75em',
                  }}
                >
                  {t('Climate Choice')} {row.survey.label} {t('Rating')}
                </Box>
              </Box>
            )}
            {hasEmployeeRange && (
              <Box display="flex" alignItems="center">
                <Box
                  sx={{
                    flex: '0 0 auto',
                    padding: '0 4px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <PeopleIcon />
                </Box>
                <Box
                  sx={{
                    flex: '1 1 auto',
                    fontSize: '.75em',
                  }}
                >
                  {row.employee_range || row.employee_range === 0
                    ? `${row.employee_range} ${t('Employees')}`
                    : t('N/A')}
                </Box>
              </Box>
            )}
            {hasTurnover && (
              <Box display="flex" alignItems="center">
                <Box
                  sx={{
                    flex: '0 0 auto',
                    padding: '0 4px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <BarChartIcon />
                </Box>
                <Box
                  sx={{
                    flex: '1 1 auto',
                    fontSize: '.75em',
                  }}
                >
                  {row.turnover || row.turnover === 0
                    ? `${row.turnover} ${t('Eur')}`
                    : t('N/A')}
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default observer(CompaniesListDataItem);

import { observer } from 'mobx-react-lite';

import Paper from 'components/common/Paper';

// import Employees from './Employees/Employees';
// import Revenue from './Revenue/Revenue';
import SocialMedia from './SocilaMedia/SocialMedia';
import * as schema from 'types/backendSchema';

export type Props = {
  companyData: schema.definitions['PublicCompany'];
};

function ContactCard({ companyData }: Props) {
  const hasSocial =
    companyData.facebook_handle ||
    companyData.instagram_handle ||
    companyData.twitter_handle ||
    companyData.linkedin_handle;
  const hasEmployeeRange = Object.keys(companyData).includes('employee_range');
  const hasTurnover = Object.keys(companyData).includes('turnover');
  // {hasEmployeeRange && <Employees employees={companyData.employee_range} />}
  // {hasTurnover && <Revenue revenue={companyData.turnover} />}
  return hasSocial || hasEmployeeRange || hasTurnover ? (
    <Paper
      elevation={0}
      sx={{
        pt: '24px',
        pl: '16px',
        pr: '16px',
        pb: '16px',
        minWidth: '160px',
        backgroundColor: 'neutral.main',
      }}
    >
      {hasSocial && (
        <SocialMedia
          facebook={companyData.facebook_handle}
          instagram={companyData.instagram_handle}
          twitter={companyData.twitter_handle}
          linkedin={companyData.linkedin_handle}
        />
      )}
    </Paper>
  ) : null;
}

export default observer(ContactCard);

import useStore from 'components/App/Routes/Admin/Screening/store/useStore';
import useSearchStore from 'components/App/Routes/Admin/Search/store/useStore';
import { observer } from 'mobx-react-lite';
import Dialog from 'components/common/Dialog/Dialog';

import DialogHeader from './DialogHeader';
import DialogBody from './DialogBody';
import { toJS } from 'mobx';

const Component = () => {
  const {
    consolidateCompanyId,
    setConsolidateCompanyId,
    shortlistScreeningCompany,
    shortlistGLEIFCompany,
    shortlistTCCCompany,
  } = useStore();

  const { getCompanyForm, selectedCompany } = useSearchStore();

  const tccForm = getCompanyForm(toJS(selectedCompany));
  const gleifForm = getCompanyForm();
  const screeningForm = getCompanyForm();
  const consolidatedForm = getCompanyForm(
    shortlistTCCCompany.id ? toJS(selectedCompany) : null
  );

  tccForm.resetValues({
    ...shortlistTCCCompany,
  });
  gleifForm.resetValues({
    ...shortlistGLEIFCompany,
  });
  screeningForm.resetValues({
    ...shortlistScreeningCompany,
  });

  const keys = shortlistScreeningCompany.id
    ? (Object.keys(shortlistScreeningCompany) as Array<
        keyof typeof shortlistScreeningCompany
      >)
    : shortlistGLEIFCompany.id
    ? (Object.keys(shortlistGLEIFCompany) as Array<
        keyof typeof shortlistGLEIFCompany
      >)
    : shortlistTCCCompany.id
    ? (Object.keys(shortlistTCCCompany) as Array<
        keyof typeof shortlistTCCCompany
      >)
    : [];
  keys.forEach((key) => {
    consolidatedForm.setValue(
      key,
      shortlistTCCCompany[key] ||
        shortlistGLEIFCompany[key] ||
        shortlistScreeningCompany[key] ||
        ''
    );
  });

  consolidatedForm.setValue(
    'published',
    shortlistTCCCompany.id ? selectedCompany.published : true
  );
  consolidatedForm.setValue('id', consolidateCompanyId);

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      open={!!consolidateCompanyId}
      onClose={() => setConsolidateCompanyId('')}
    >
      <DialogHeader />
      <DialogBody
        consolidatedForm={consolidatedForm}
        gleifForm={gleifForm}
        tccForm={tccForm}
        screeningForm={screeningForm}
      />
    </Dialog>
  );
};

export default observer(Component);

import Box from '@mui/material/Box';
import Collapse from 'components/common/Collapse';
import Address from 'components/common/Address';

const AddressCell = ({
  tableParams,
  isExpanded,
}: {
  tableParams: any;
  isExpanded: any;
}) => {
  const reportingCompanyData = tableParams.row;
  return (
    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
      <Box>
        <Address
          address={reportingCompanyData.address}
          address2={reportingCompanyData.address2}
          country={reportingCompanyData.country}
          city={reportingCompanyData.city}
          zip_code={reportingCompanyData.zip_code}
          locationIcon={false}
        />
      </Box>
    </Collapse>
  );
};

export default AddressCell;

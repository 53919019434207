import React from 'react';

import Stack from 'components/common/Stack';
import InputBase from 'components/common/InputBase/InputBase';
import InputLabel from 'components/common/InputLabel/InputLabel';
import Typography from 'components/common/Typography';

type Props = {
  id?: string;
  title?: string | null;
  subTitle?: string | null;
  error?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  required?: boolean;
  type?: string;
  multiline?: boolean;
  rows?: number;
  value: string;
  placeholder?: string | undefined;
  characterLimit?: number;
  isDirty?: boolean;
  helpText?: string;
  endAdornment?: any;
  startAdornment?: any;
  disabled?: boolean;
};
const inputStyle = {
  backgroundColor: 'neutral.main',
  outline: 'none',
  '.css-1r5iues-MuiInputBase-root-MuiInput-root:before': {
    borderBottom: 'none',
  },
  '.css-1r5iues-MuiInputBase-root-MuiInput-root:after': {
    borderBottom: 'none',
  },
  '.css-1r5iues-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before':
    {
      borderBottom: 'none',
    },
  color: 'neutral100',
  p: '9px',
  borderRadius: '3px',
};

const dirtyStyle = {
  color: 'neutral100',
};

export default function TccInputTextField({
  id,
  title,
  subTitle,
  error,
  onChange,
  onBlur,
  required = false,
  type = 'text',
  multiline = false,
  rows = 2,
  value,
  placeholder = '',
  characterLimit,
  isDirty = false,
  helpText = '',
  endAdornment,
  startAdornment,
  disabled,
}: Props) {
  return (
    <Stack>
      {title && (
        <Typography
          pb={subTitle ? 0 : 1}
          variant={'body1'}
          color={error ? 'error.main' : 'neutral.contrastText'}
        >
          {`${title}${required ? ' *' : ''}`}
        </Typography>
      )}
      {subTitle && (
        <Typography
          variant={'caption2'}
          color={error ? 'error.main' : 'neutral.contrastText'}
          mb={'10px'}
        >
          {`${subTitle}${required && !title ? ' *' : ''}`}
        </Typography>
      )}
      <InputBase
        required={required}
        fullWidth
        margin="dense"
        type={type}
        multiline={multiline}
        disabled={disabled}
        rows={rows}
        value={value}
        id={id}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={{
          maxLength: characterLimit,
          style: { padding: '7.5px 4px 7.5px 5px' },
        }}
        endAdornment={endAdornment}
        startAdornment={startAdornment}
        placeholder={placeholder}
        sx={Object.assign({}, inputStyle, isDirty ? dirtyStyle : {})}
      />
      {error && helpText && (
        <Typography variant={'caption2'} color={'error'} mb={'10px'}>
          {helpText}
        </Typography>
      )}
    </Stack>
  );
}

import { decorate, observable, action, runInAction } from 'mobx';
import api from 'constants/api';
import get from 'axios';
class UserDashboardSuppliersStore {
  // OBSERVABLES................................................................
  isLoadingDashboardDataStatistics = false;
  hasLoadedDashboardDataStatistics = false;
  isLoadingDashboardData = false;
  hasLoadedDashboardData = false;
  user_company_suppliers_count_on_search = null;
  user_company_suppliers_results = [];
  user_company_suppliers_with_valid_crc = 0;
  supplier_invite_link = '';

  // COMPUTEDS..................................................................
  // ACTIONS....................................................................

  loadDashboardData = async ({ auth, page, search, rowsPerPage }) => {
    let params = new URLSearchParams();
    runInAction(() => (this.isLoadingDashboardData = true));

    params.append('page', page);
    params.append('search', search);
    params.append('page-size', rowsPerPage);
    try {
      let user_company_suppliers_call = get(
        `${api.url}/user/company/suppliers/`,
        Object.assign(auth.requestConfig, { params })
      );
      const results = await user_company_suppliers_call;
      runInAction(
        () => (this.supplier_invite_link = results.data.supplier_invite_link)
      );
      runInAction(
        () => (this.user_company_suppliers_count_on_search = results.data.count)
      );
      runInAction(
        () => (this.user_company_suppliers_results = results.data.results)
      );
    } catch (err) {
      this.loadDashboardDataFailure(err);
    }

    runInAction(() => (this.hasLoadedDashboardData = true));
    runInAction(() => (this.isLoadingDashboardData = false));
  };

  loadUserCompanyStatistics = async ({ auth }) => {
    runInAction(() => (this.isLoadingDashboardDataStatistics = true));

    try {
      let user_company_statistics = get(
        `${api.url}/user/company/suppliers/crc/statistics/valid/`,
        Object.assign(auth.requestConfig)
      );
      const results = await user_company_statistics;
      runInAction(
        () =>
          (this.user_company_suppliers_with_valid_crc =
            results.data.companies_with_valid_crc)
      );
    } catch (err) {
      this.loadDashboardDataFailure(err);
    }

    runInAction(() => (this.hasLoadedDashboardDataStatistics = true));
    runInAction(() => (this.isLoadingDashboardDataStatistics = false));
  };

  loadUserCompanySuppliersCRCValid = async ({ auth, companies = [] }) => {
    let params = new URLSearchParams();
    let results;
    for (let company of companies) {
      params.append('companies', company);
    }

    try {
      let user_company_valid_crc = get(
        `${api.url}/user/company/suppliers/crc/assets/valid/`,
        Object.assign(auth.requestConfig, { params })
      );
      results = await user_company_valid_crc;

      return results.data.supplier_valid_crc_export;
    } catch (err) {
      this.loadDashboardDataFailure(err);
    }

    runInAction(() => (this.hasLoadedDashboardDataStatistics = true));
    runInAction(() => (this.isLoadingDashboardDataStatistics = false));
  };

  loadDashboardDataFailure = action((err) => {
    this.isLoadingDashboardDataStatistics = false;
    this.hasLoadedDashboardDataStatistics = true;
  });
}

decorate(UserDashboardSuppliersStore, {
  isLoadingDashboardDataStatistics: observable,
  hasLoadedDashboardDataStatistics: observable,
  isLoadingDashboardData: observable,
  hasLoadedDashboardData: observable,
  user_company_suppliers_count: observable,
  user_company_suppliers_results: observable,
  user_company_suppliers_with_valid_crc: observable,
  supplier_invite_link: observable,
  loadDashboardDataFailure: action,
  loadUserCompanySuppliersCRCValid: action,
  setSupplierValidExportNull: action,
  setSupplierResponsesExportNull: action,
});

export default UserDashboardSuppliersStore;

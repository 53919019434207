import React from 'react';
import Chip from 'components/common/Chip';

type ChipProps = {
  selectedTagKey: string;
  label: string;
  onDelete: (selectedTagKey: string) => void;
};

export default function FilterChip({
  selectedTagKey,
  label,
  onDelete,
}: ChipProps) {
  return (
    <Chip
      size={'small'}
      key={selectedTagKey}
      label={label}
      onDelete={() => onDelete(selectedTagKey)}
      sx={{
        display: 'flex',
        justifyContent: 'left',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        margin: '6px',
      }}
    />
  );
}

import { useState, useEffect } from 'react';

import Routes from 'components/App/Routes';
import PosthogInitializer from './PosthogInitializer';
import AppLoading from 'components/common/AppLoading';

import useAuth from 'hooks/useAuth';

function AppInitializer() {
  const auth = useAuth();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function initializeApp() {
      try {
        // we need to wait for this async call to get userDetails
        await auth.checkAuth();
      } catch (err) {
        console.log(err);
      }
      setLoaded(true);
    }
    if (!loaded) {
      initializeApp();
    }
  }, [auth, loaded]);

  return (
    <>
      <PosthogInitializer />
      {loaded ? <Routes /> : <AppLoading />}
    </>
  );
}

export default AppInitializer;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Typography from 'components/common/Typography';
import colors from 'constants/colors';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function CPASortingMenu() {
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  const handleChange = (event: SelectChangeEvent) => {
    navigateSearch({
      pathname: location.pathname,
      params: {
        page: 1,
        status: event.target.value,
      },
      replace: false,
    });
  };

  return (
    <Select
      id={'select'}
      name={'select'}
      variant="filled"
      disableUnderline
      color="primary"
      value={searchParams.get('status') || 'NEW'}
      onChange={handleChange}
      IconComponent={() => (
        <KeyboardArrowDownIcon
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            display: 'inline-block',
            color: colors.neutral500,
            marginTop: '8px',
            marginRight: '6px',
            pointerEvents: 'none',
          }}
        />
      )}
      sx={{
        paddingBottom: '14px',
        borderRadius: '8px',
        height: '40px',
        // width: '140px',
        backgroundColor: colors.neutral100,
        '& .MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <MenuItem key={'NEW'} value={'NEW'}>
        <Typography variant="body1"> {t('NEW')}</Typography>
      </MenuItem>
      <MenuItem key={'IN_REVIEW'} value={'IN_REVIEW'}>
        <Typography variant="body1"> {t('IN REVIEW')}</Typography>
      </MenuItem>
      <MenuItem key={'FINALIZED'} value={'FINALIZED'}>
        <Typography variant="body1"> {t('FINALIZED')}</Typography>
      </MenuItem>
    </Select>
  );
}
export default observer(CPASortingMenu);

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import useCPASurvey from './store/useCPASurvey';
import { useParams } from 'react-router-dom';

import Container from 'components/common/Container';

import CPASurveyInfo from './CPASurveyInfo';

const CPASurvey = () => {
  const { surveyId } = useParams();
  const { clearCpasurveyData } = useCPASurvey();

  const id = surveyId;
  useEffect(() => {
    return () => {
      clearCpasurveyData();
    };
  }, [clearCpasurveyData]);

  return (
    <Container pageContainer maxWidth="lg" disableGutters={false}>
      <CPASurveyInfo identifier={id} />
    </Container>
  );
};

export default observer(CPASurvey);

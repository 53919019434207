import api from 'constants/api';
import Button from 'components/common/Button';
import Link from 'components/common/Link';

import { DownloadIcon } from 'components/icons/mui_icons';
import { useTranslation } from 'react-i18next';

function Component() {
  const { t } = useTranslation('common');

  return (
    <Link
      href={`${api.url}/user/company/suppliers/data_export`}
      rel="noopener"
      underline="none"
      target="_blank"
    >
      <Button
        type="button"
        variant="contained"
        color="primary"
        startIcon={<DownloadIcon />}
      >
        {t('Download Supplier Data')}
      </Button>
    </Link>
  );
}

export default Component;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import DemoMockedData from '../../DemoMockedData/store/DemoMockedData';

import Grid from 'components/common/Grid/Grid';
import Card from 'components/common/Card';
import Typography from 'components/common/Typography/Typography';

import UserDashboardSuppliersPieChart from '../../UserDashboardSuppliersPieChart';
import UserDashboardSuppliersColumn from '../../UserDashboardSuppliersColumn';

import colors from 'constants/colors';

function Component() {
  const { t } = useTranslation('common');

  const {
    suppliersWithScopeEmissionsData,
    suppliersWithoutScopeEmissionsData,
    targetCoverage,
    addCategoryEmissionsKilotonne,
    scopePerYearCount,
    emissionsCountPerCountry,
  } = DemoMockedData();

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Typography variant="h8" color={colors.neutral600}>
          {t('Scope 1')}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Card>
          <UserDashboardSuppliersPieChart
            title={t('Scope 1 - Corporate Carbon Footprint Emissions')}
            labels={[t('Yes'), t('No')]}
            colors={[colors.secondary300, colors.tertiary500]}
            data={[
              suppliersWithScopeEmissionsData('scope1'),
              suppliersWithoutScopeEmissionsData('scope1'),
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={8}>
        <Card>
          <UserDashboardSuppliersPieChart
            title={t('Scope 1 Target Coverage')}
            labels={['Keine', '1.5˚C', '2°C', 'Unsure']}
            colors={[
              colors.secondary300,
              colors.tertiary500,
              colors.primary300,
              colors.warning100,
              colors.secondary100,
            ]}
            data={[
              targetCoverage('scope1').Keine,
              targetCoverage('scope1')[`1.5°C `],
              targetCoverage('scope1')[`2°C `],
              targetCoverage('scope1').Unsure,
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Reported Scope 1 Category Emissions')}
            titleY={t('GHG Emissions(metrics kilotonne CO2)')}
            formater={''}
            groups={[{ title: 'Scope 1 Category', cols: 4 }]}
            color={colors.primary400}
            data={[
              {
                x: '1:Direkte Emissionen aus Verbrennungsprozessen stationärer Anlagen',
                y: addCategoryEmissionsKilotonne(
                  'Scope1_1_Stationary_Combustion'
                ),
              },
              {
                x: '2:Direkte Emissionen aus Verbrennungsprozessen mobiler Anlagen',
                y: addCategoryEmissionsKilotonne('Scope1_2_Mobile_Combustion'),
              },
              {
                x: '3:Direkte Emissionen flüchtiger Gase',
                y: addCategoryEmissionsKilotonne('Scope1_3_Fugitive_Emissions'),
              },
              {
                x: '4:Direkte Emissionen aus Prozessen',
                y: addCategoryEmissionsKilotonne('Scope1_4_Process_Emissions'),
              },
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Reporting Years for Scope 1')}
            titleY={t('Companies reporting for Scope 1')}
            formater={''}
            groups={[{ title: t('Year'), cols: 2 }]}
            color={colors.warning500}
            data={scopePerYearCount('scope1_reporting_year')}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Scope 1 Emissions per Country')}
            titleY={t('GHG Emissions(metrics kiltonne CO2)')}
            formater={''}
            groups={[{ title: t('Country'), cols: 4 }]}
            color={colors.secondary300}
            data={emissionsCountPerCountry('scope1')}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

export default observer(Component);

import React from 'react';
import { Link } from 'react-router-dom';

import Stack from 'components/common/Stack';
import Avatar from 'components/common/Avatar';
import TccTooltipText from 'components/common/TccTooltipText';

import colors from 'constants/colors';

type Props = {
  params: any;
};

export default function CompanyTitleCell({ params }: Props) {
  function stringAvatar(name: string) {
    if (name?.includes(' ')) {
      return {
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    } else return { children: name?.slice(0, 2) };
  }

  return (
    <Link
      to={`/company/${params.row.customerSlug}`}
      style={{ textDecoration: 'none' }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {' '}
        <Avatar
          src={(params as any).logo}
          alt=""
          {...stringAvatar((params as any).value)}
          sx={{
            width: 20,
            height: 20,
            fontSize: '8px',
            fontWeight: 900,
            p: '8px',
            color: 'neutral.contrastText',
            backgroundColor: colors.neutral200,
            border: '0.1px solid onSurface.100',
          }}
        />
        <TccTooltipText text={params.value} />
      </Stack>
    </Link>
  );
}

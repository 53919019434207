import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useSignUp from 'hooks/useSignUp';

import Typography from 'components/common/Typography';
import Grid from 'components/common/Grid';
import TccInputTextField from 'components/common/TccInputTextField';
import CountrySelect from 'components/common/CountrySelect';
import Button from 'components/common/Button/Button';
import SelectMenu from 'components/common/SelectMenu';
import Form from 'components/common/Form';
import Container from 'components/common/Container';
import LinearStepper from 'components/common/LinearStepper';

import SignUpHeader from './SignUpHeader';

import useAuth from 'hooks/useAuth';

const SignUpForm1 = () => {
  const { t, i18n } = useTranslation('common');
  const { signUpForm1, submitForm1 } = useSignUp();
  const auth = useAuth();

  // TODO: this is here in the render so we can regen the language names on a language selection with the correct translations - we can move it later to something functional, but maybe we end up getting it from tehe backend so it is just easier to stick it here
  const languages: { [key: string]: string } = {
    en: t('English'),
    de: t('German'),
  };

  return (
    <Container pageContainer maxWidth="md">
      <LinearStepper
        steps={[t('Registration Sign up'), t('Climate Readiness Check')]}
        activeStep={0}
        maxWidth={400}
      />

      <Form onSubmit={() => submitForm1({ auth })}>
        <Grid container p={4} spacing={3}>
          <Grid item xs={12}>
            <SignUpHeader />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectMenu
              required
              items={Object.values(languages)}
              title={t('Language')}
              handleChange={(e) => {
                const language = Object.keys(languages).find(
                  (key) => languages[key] === e.target.value
                );
                signUpForm1.setValue('language', language);
                i18n.changeLanguage(language);
              }}
              value={
                Object.values(languages).find(
                  (value) =>
                    signUpForm1.values.has('language') &&
                    value === languages[signUpForm1.values.get('language')]
                ) || ''
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              title={t(`Title`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('title', e.target.value);
              }}
              value={signUpForm1.values.get('title')}
              onBlur={() => signUpForm1.checkFieldError('title')}
              placeholder={`${t('e.g., Ms, Dr, etc')}`}
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              required
              title={t(`First Name`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('first_name', e.target.value);
              }}
              error={signUpForm1.errors.has('first_name')}
              onBlur={() => signUpForm1.checkFieldError('first_name')}
              value={signUpForm1.values.get('first_name')}
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              required
              title={t(`Last Name`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('last_name', e.target.value);
              }}
              error={signUpForm1.errors.has('last_name')}
              onBlur={() => signUpForm1.checkFieldError('last_name')}
              value={signUpForm1.values.get('last_name')}
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              title={t(`Company Email`)}
              subTitle={t(`This should not be a personal email.`)}
              type={'email'}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('email', e.target.value);
              }}
              error={signUpForm1.errors.has('email')}
              onBlur={() => {
                signUpForm1.checkFieldError('email');
              }}
              value={signUpForm1.values.get('email')}
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              title={t(`Contact Telephone`)}
              subTitle={t('Please include country code.')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('contact_number', e.target.value);
              }}
              error={signUpForm1.errors.has('contact_number')}
              onBlur={() => signUpForm1.checkFieldError('contact_number')}
              value={signUpForm1.values.get('contact_number')}
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              required
              title={t(`Job Title`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('job_title', e.target.value);
              }}
              onBlur={() => signUpForm1.checkFieldError('job_title')}
              value={signUpForm1.values.get('job_title')}
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              required
              title={t(`Company Name`)}
              subTitle={t(
                'This must be the official legal name of the company, as formally registered with relevant authorities.'
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('company_name', e.target.value);
              }}
              error={signUpForm1.errors.has('company_name')}
              onBlur={() => signUpForm1.checkFieldError('company_name')}
              value={signUpForm1.values.get('company_name')}
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              title={t(`Company website`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('website', e.target.value);
              }}
              error={signUpForm1.errors.has('website')}
              onBlur={() => signUpForm1.checkFieldError('website')}
              value={signUpForm1.values.get('website')}
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              required
              title={t(`Address Line 1`)}
              subTitle={t('This should be the legal address of your company.')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('address', e.target.value);
              }}
              error={signUpForm1.errors.has('address')}
              onBlur={() => signUpForm1.checkFieldError('address')}
              value={signUpForm1.values.get('address')}
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              title={t(`Address Line 2`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('address2', e.target.value);
              }}
              error={signUpForm1.errors.has('address2')}
              onBlur={() => signUpForm1.checkFieldError('address2')}
              value={signUpForm1.values.get('address2')}
            />
          </Grid>
          <Grid item xs={6}>
            <TccInputTextField
              title={t(`Zip code`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('zip_code', e.target.value);
              }}
              error={signUpForm1.errors.has('zip_code')}
              onBlur={() => signUpForm1.checkFieldError('zip_code')}
              value={signUpForm1.values.get('zip_code')}
            />
          </Grid>
          <Grid item xs={6}>
            <TccInputTextField
              required
              title={t(`City`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('city', e.target.value);
              }}
              error={signUpForm1.errors.has('city')}
              onBlur={() => signUpForm1.checkFieldError('city')}
              value={signUpForm1.values.get('city')}
            />
          </Grid>
          <Grid item xs={12}>
            <TccInputTextField
              title={t(`Region`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                signUpForm1.setValue('region', e.target.value);
              }}
              error={signUpForm1.errors.has('region')}
              onBlur={() => signUpForm1.checkFieldError('region')}
              value={signUpForm1.values.get('region')}
            />
          </Grid>
          <Grid item xs={12}>
            <CountrySelect
              required
              title={t('Company Country')}
              subTitle={t(
                "This should be the country of your company's legal address."
              )}
              onChange={(country) => {
                signUpForm1.setValue('country', country);
              }}
              value={signUpForm1.values.get('country')}
            />
          </Grid>
          {signUpForm1.hasErrors && (
            <Grid item mt={2}>
              <Typography color="error">
                {Array.from(signUpForm1.errors.values()).join(' ')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} mt={4}>
            <Button fullWidth variant="contained" type="submit">
              {t('Next')}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default observer(SignUpForm1);

// import React from 'react'
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import api from 'constants/api';

import Container from 'components/common/Container';

import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import AppLoading from 'components/common/AppLoading/AppLoading';
import Stack from 'components/common/Stack/Stack';
import { useState } from 'react';
import CompanySelect from 'components/common/CompanySelect';
import CompanyDetails from './CompanyDetails';
import CompanyDataServices from './CompanyDataServices';
import CompanyDataServicesToolbar from './CompanyDataServicesToolbar';
import CompanyCollections from './CompanyCollections';
import useAdminCompany from 'hooks/useAdminCompany';
import CompanyCannedSearches from './CompanyCannedSearches';

function Component() {
  const auth = useAuth();
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [codeError, setCodeError] = useState(false);
  const { t } = useTranslation('common');

  const { isLoading, isError, refetch, data } = useAdminCompany(companyId);
  return !companyId ? (
    <Container pageContainer maxWidth="xs" sx={{ pt: 3 }}>
      <Stack spacing={2}>
        <CompanySelect
          value={companyId}
          onChange={(value) => {
            navigate(value);
          }}
          placeholder={t('Select company')}
        />
        {codeError ? (
          <Alert severity="error">{t('Something happened.')}</Alert>
        ) : null}
      </Stack>
    </Container>
  ) : isLoading ? (
    <AppLoading />
  ) : data ? (
    <Container pageContainer maxWidth="xl" sx={{ pt: 3 }}>
      <Stack spacing={5}>
        <CompanyDetails />
        <CompanyCannedSearches />
        <CompanyDataServicesToolbar company={data} />
        <CompanyDataServices />
        <CompanyCollections companyId={data.id} />
      </Stack>
    </Container>
  ) : (
    <div>no company state</div>
  );
}

export default observer(Component);

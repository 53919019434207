import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';
import SourceChannelChip from '../../SourceChannelChip';

import { useTranslation } from 'react-i18next';
import EmptyTextField from 'components/common/EmptyTextField';
import { getLanguagePreference } from 'utils/browser';

import { BaseYearDataPoint } from '../Component';

export default function EmissionsDataTable({
  data,
}: {
  data: BaseYearDataPoint[];
}) {
  const { t } = useTranslation('common');
  const rows = data;
  const columns: GridColDef[] = [
    {
      field: 'reporting_year',
      headerName: t('Year'),
      flex: 1,
      sortable: true,
    },
    {
      field: 'value',
      headerName: t('Base Year'),
      flex: 2,
      sortable: false,
    },
    {
      field: 'source_channel',
      headerName: t('Source'),
      sortable: false,
      flex: 0,
      renderCell: (params) => {
        return <SourceChannelChip channel={params.row.source_channel} />;
      },
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('Data unavailable'),
  };

  return !data ? (
    <EmptyTextField text={t('Data unavailable') + ''} />
  ) : (
    <div style={{ width: '100%' }}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'reporting_year', sort: 'desc' }],
          },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50]}
        pagination
        autoHeight
        localeText={localeText}
        sx={{ width: '100%' }}
        rows={rows}
        columns={columns}
        hideFooter={rows.length <= 10}
      />
    </div>
  );
}

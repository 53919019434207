import React from 'react';
import { useTranslation } from 'react-i18next';

import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import colors from 'constants/colors';

type Props = {
  text?: string;
};

function EmptyTextField({ text }: Props) {
  const { t } = useTranslation('common');
  return (
    <>
      {text ? (
        <Stack direction={'row'}>
          <Typography variant="body1" color={colors.neutral400} mt={'18px'}>
            {text}
          </Typography>
        </Stack>
      ) : (
        <Typography variant="body1" color={colors.neutral400} mt={'18px'}>
          {t('This company has not provided any information')}
        </Typography>
      )}
    </>
  );
}

export default EmptyTextField;

// import React from 'react'
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';

import EmissionsIntensityDataTable from './EmissionsIntensityDataTable';

function Component({ emissionIntensities }: any) {
  const { t } = useTranslation('common');

  return emissionIntensities ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" color="onSurface.100">
          {t('Emission Intensity')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <EmissionsIntensityDataTable data={emissionIntensities} />
      </Grid>
    </Grid>
  ) : null;
}

export default observer(Component);

import * as React from 'react';

function SvgClimateImpactModel(props: any) {
  return (
    <svg
      width={30}
      height={30}
      fill={props.color}
      viewBox="0 0 30 30"
      {...props}
    >
      <g clipPath="url(#ClimateImpactModel_svg__clip0_706:110770)">
        <path d="M1.111 15A13.889 13.889 0 0123.09 3.709l-1.515.003a.556.556 0 00.004 1.111l3.092-.007a.558.558 0 00.556-.556l.006-3.105a.555.555 0 10-1.111 0l-.004 1.936A15 15 0 002.561 23.388a.557.557 0 001.006-.207.558.558 0 00-.085-.416A13.815 13.815 0 011.112 15zM27.438 6.612a.555.555 0 10-.92.624A13.888 13.888 0 016.633 26.082l1.61-.002a.556.556 0 000-1.112l-3.093.006a.558.558 0 00-.555.555l-.008 3.106a.556.556 0 001.112 0l.003-1.866A15 15 0 0027.438 6.612z" />
        <path d="M18.737 6.47l-.037-.018a9.238 9.238 0 00-2.215-.646 9.44 9.44 0 00-10.59 11.185 9.19 9.19 0 00.561 1.723 9.3 9.3 0 0016.593.903l.031-.056a9.29 9.29 0 00-4.343-13.09zM16.088 23.1a6.946 6.946 0 00-.588-2.333c-.035-.1-.841-2.457.206-3.794 1.035-1.32 2.303-.949 2.36-.932a.624.624 0 00.086.02c.096.014 2.338.36 3.77 3.268a8.261 8.261 0 01-5.834 3.773V23.1zm-1.256-6.81c-1.464 1.868-.407 4.77-.357 4.905.28.627.45 1.298.504 1.983a8.186 8.186 0 01-7.125-4.153c4.655-.277 4.828-3.971 4.887-5.202.069-1.457.24-1.778 1.407-3.352a2.111 2.111 0 00.413-1.626 3.823 3.823 0 00-1.055-1.926 8.136 8.136 0 013.71.178c-.868.635-1.91 1.75-1.344 3.195.55 1.402 1.445 1.501 2.099 1.574.365-.003.72.12 1.003.35a3.736 3.736 0 002.55 1 4.202 4.202 0 001.4-.246 8.17 8.17 0 01-.383 5.17c-1.63-2.683-3.934-3.13-4.18-3.17a3.36 3.36 0 00-3.529 1.32zm-2.581-9.035c.519.442 1.116 1.142 1.21 1.759a1.007 1.007 0 01-.206.796c-1.266 1.705-1.545 2.234-1.627 3.963-.09 1.926-.662 4.132-4.296 4.155a8.187 8.187 0 014.919-10.673zm7.473 4.142a2.643 2.643 0 00-1.63-.635c-.588-.065-.883-.098-1.187-.876-.42-1.071 1.03-1.984 1.608-2.296a8.306 8.306 0 014.064 4.326 2.67 2.67 0 01-2.855-.52z" />
      </g>
      <defs>
        <clipPath id="ClimateImpactModel_svg__clip0_706:110770">
          <path d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgClimateImpactModel;

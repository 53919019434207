import React from 'react';

type Props = {
  iconSrc: string | undefined;
  width?: string;
  marginRight?: string;
};

function RatingLabel({ iconSrc, width, marginRight }: Props) {
  return (
    <img
      src={iconSrc}
      style={{ width: width, marginRight: marginRight }}
      alt=""
    />
  );
}

export default RatingLabel;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { phCapture } from 'utils/posthog';

import Grid from 'components/common/Grid';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';

import Button from '../Button/Button';
import Card from 'components/common/Card';

import colors from 'constants/colors';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props = {
  title: string;
  suppliersInvitationLink: string | undefined;
};

const SuppliersInviteCard = ({ title, suppliersInvitationLink }: Props) => {
  const { t } = useTranslation('common');
  const [copySuccess, setCopySuccess] = useState<string>(
    `${t('Copy link and invite your suppliers')}`
  );

  return (
    <Card backgroundColor={colors.secondary500}>
      <Grid
        container
        spacing={4}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid item xs={12} lg={8}>
          <Stack spacing={1}>
            <Typography color={colors.white} variant="h5">
              {title}
            </Typography>
            <Typography color={colors.white} variant="body1">
              {t(
                'Copy the invite link below and email it to your suppliers. Once they have followed the instructions and completed the onboarding process, you will be able to see an assessment of their climate maturity and can begin to deepen your analysis.'
              )}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button
            variant="whiteBackground"
            startIcon={<ContentCopyIcon />}
            onClick={() => {
              if (suppliersInvitationLink) {
                navigator.clipboard.writeText(suppliersInvitationLink);
                setCopySuccess(`${t('Copied!')}`);
                phCapture('copy supplier invite link');
              }
            }}
          >
            {copySuccess}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SuppliersInviteCard;

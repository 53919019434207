import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Collapse from 'components/common/Collapse';
import Tooltip from 'components/common/Tooltip';
import colors from 'constants/colors';
import { LinkIcon } from 'components/icons/mui_icons';
import LinkMui from 'components/common/Link';
import { getUniqueTargets } from './utils';

const ReportingCompanyCell = ({
  tableParams,
  isExpanded,
  sectorData,
}: {
  tableParams: any;
  isExpanded: any;
  sectorData: any;
}) => {
  const reportingCompanyData = tableParams.row.reporting_company_data;
  return (
    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
      <Box>
        name
        <Link
          to={`/company/${reportingCompanyData?.slug}`}
          target="_blank"
          style={{ textDecoration: 'none', color: colors.neutral500 }}
        >
          <Tooltip title={reportingCompanyData?.slug}>
            <div>{reportingCompanyData?.name}</div>
          </Tooltip>
        </Link>
        Data Services
        <div>
          <Link
            to={`/admin/company/${reportingCompanyData?.id}`}
            target="_blank"
            style={{ textDecoration: 'none', color: colors.neutral500 }}
          >
            <Tooltip title={reportingCompanyData?.name}>
              <LinkIcon />
            </Tooltip>
          </Link>
        </div>
        Country
        <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
          {reportingCompanyData?.country}
        </div>
        Address
        <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
          {reportingCompanyData?.address}, {reportingCompanyData?.address2},{' '}
          {reportingCompanyData?.zip_code} {reportingCompanyData?.city}
        </div>
        Website
        <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
          <LinkMui
            href={
              reportingCompanyData?.website?.startsWith('http')
                ? `${reportingCompanyData?.website}`
                : `https://${reportingCompanyData?.website}`
            }
            target="_blank"
            rel="noopener"
            underline="none"
            style={{ textDecoration: 'none', color: colors.neutral500 }}
          >
            {reportingCompanyData?.website}
          </LinkMui>
        </div>
        Lei
        <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
          {reportingCompanyData?.lei_code}
        </div>
        Isin
        <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
          {reportingCompanyData?.isin}
        </div>
        Reporting company
        <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
          {reportingCompanyData?.reporting_company}
        </div>
        Sector
        <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
          {sectorData ? sectorData[reportingCompanyData?.sector] : null}
        </div>
        Last creened
        <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
          {reportingCompanyData?.last_screened}
        </div>
        Latest CDP
        <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
          {reportingCompanyData?.cdp_summary
            ? reportingCompanyData.cdp_summary[0].year
            : null}
        </div>
        Targets
        <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
          {reportingCompanyData?.company_targets
            ? getUniqueTargets(reportingCompanyData?.company_targets)
            : null}
        </div>
      </Box>
    </Collapse>
  );
};

export default ReportingCompanyCell;

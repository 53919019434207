import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { runInAction } from 'mobx';
import { useMutation } from '@tanstack/react-query';
import api from 'constants/api';
import axios from 'axios';
import countriesI18n from 'i18n-iso-countries';

import CompanySelect from 'components/common/CompanySelect';
import ScreeningListEntrySelect from 'components/common/ScreeningListEntrySelect';
import SelectScreeningList from 'components/common/ScreeningListSelect';
import Grid from 'components/common/Grid';
import Box from 'components/common/Box';
import ProgressIndicator from 'components/common/ProgressIndicator';

import useAuth from 'hooks/useAuth';
import useStore from 'components/App/Routes/Admin/Screening/store/useStore';
import useSearchStore from 'components/App/Routes/Admin/Search/store/useStore';
import useNavigateSearch from 'hooks/useNavigateSearchParams';
import Stack from 'components/common/Stack/Stack';
import CompanyShortlist from '../CompanyShortlist';
import AdminSearch from 'components/App/Routes/Admin/Search';
import Button from 'components/common/Button/Button';
import { CloseIcon } from 'components/icons/mui_icons';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import { observer } from 'mobx-react-lite';
import useScreeningListEntries from 'hooks/useScreeningListEntries';

const Component = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { companyId, screeningListId } = useParams();
  const {
    setShortlistScreeningCompany,
    setShortlistGLEIFCompany,
    setShortlistTCCCompany,
    screeningCompanyListEntryId,
    setScreeningCompanyListEntryId,
    setIsReportingCompany,
    toggleReportingCompanyMode,
    reportingCompanyMode,
    toggleMatchCompanyMode,
    matchCompanyMode,
  } = useStore();
  const { setFiltersOpen, setSelectedCompany, setSelectedGLEIFCompany } =
    useSearchStore();
  const auth = useAuth();
  const navigateSearch = useNavigateSearch();

  const { data, refetch } = useScreeningListEntries(screeningListId || '');
  const handleChange = React.useCallback(
    (entryId: string) => {
      const selectedListEntry =
        data.find((item: any) => item.id === entryId) || {};
      setScreeningCompanyListEntryId(selectedListEntry.id);
      const shortlistCompany = {
        id: selectedListEntry.id,
        name: selectedListEntry.name,
        address: selectedListEntry.address1,
        address2: selectedListEntry.address2,
        city: selectedListEntry.city,
        zip_code: selectedListEntry.zip_code,
        country: selectedListEntry.country
          ? countriesI18n.getAlpha2Code(selectedListEntry.country, 'en')
          : '',
        lei_code: selectedListEntry.lei,
        isin: selectedListEntry.isin || '',
        website: selectedListEntry.website || '',
        description: selectedListEntry.description || '',
        short_description: selectedListEntry.short_description || '',
        sector: selectedListEntry.sector || '',
      };

      navigateSearch({
        pathname: `/admin/screening/${companyId}/${screeningListId}`,
        params: {
          search: shortlistCompany.name || '',
          address: shortlistCompany.address || '',
          address2: shortlistCompany.address2 || '',
          zip_code: shortlistCompany.zip_code || '',
          country: shortlistCompany.country || '',
          isin: shortlistCompany.isin || '',
          lei_code: shortlistCompany.lei_code || '',
        },
        replace: true,
      });
      runInAction(() => {
        setShortlistScreeningCompany(shortlistCompany);
        setShortlistTCCCompany({});
        setShortlistGLEIFCompany({});
        setFiltersOpen(true);
        setIsReportingCompany(false);
        setSelectedCompany({});
        setSelectedGLEIFCompany({});
      });
    },
    [
      data,
      navigateSearch,
      setFiltersOpen,
      setScreeningCompanyListEntryId,
      setShortlistGLEIFCompany,
      setShortlistScreeningCompany,
      setShortlistTCCCompany,
      setIsReportingCompany,
      setSelectedCompany,
      setSelectedGLEIFCompany,
      companyId,
      screeningListId,
    ]
  );
  const { mutate: mutateReportingCompany } = useMutation({
    onError: (err: any) => {
      console.log(err);
    },
    onSuccess: () => {
      runInAction(() => {
        refetch();
        setShortlistScreeningCompany({});
        setShortlistTCCCompany({});
        setShortlistGLEIFCompany({});
        setSelectedCompany({});
        setSelectedGLEIFCompany({});
      });
    },
    mutationFn: async ({
      targetCompany,
      value,
    }: {
      targetCompany: string;
      value: string;
    }) => {
      if (screeningCompanyListEntryId) {
        const formData = new FormData();
        formData.set('reporting_company', value);
        const res = await axios.patch(
          `${api.url}/admin/companies/${targetCompany}/`,
          formData,
          auth.requestConfig
        );
        return res;
      }
    },
  });

  const { mutate: mutateMatchedCompany } = useMutation({
    onError: (err: any) => {
      console.log(err);
    },
    onSuccess: () => {
      runInAction(() => {
        refetch();
        setShortlistScreeningCompany({});
        setShortlistTCCCompany({});
        setShortlistGLEIFCompany({});
        setSelectedCompany({});
        setSelectedGLEIFCompany({});
      });
    },
    mutationFn: async (value: string) => {
      if (screeningCompanyListEntryId) {
        const formData = new FormData();
        formData.set('matched_company', value);
        const res = await axios.patch(
          `${api.url}/admin/screening_list_entries/${screeningCompanyListEntryId}/`,
          formData,
          auth.requestConfig
        );
        return res;
      }
    },
  });

  const countTotal = data && data.length > 0 ? data.length : 0;

  const DPC = data
    ? data.filter((entry: { [key: string]: any }) => entry.reporting_company)
    : 0;
  const IC = data
    ? data.filter((entry: { [key: string]: any }) => entry.matched_company)
    : 0;
  const countDPC = DPC && DPC.length > 0 ? DPC.length : 0;
  const countIC = IC && IC.length > 0 ? IC.length : 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <CompanySelect
          value={companyId || ''}
          onChange={(value) => {
            setScreeningCompanyListEntryId('');
            navigate(`/admin/screening/${value}`);
          }}
          placeholder={t('Select company')}
        />
      </Grid>
      <Grid item xs={12} sm={8}></Grid>
      <Grid item xs={12} sm={4}>
        <SelectScreeningList
          companyId={companyId}
          value={screeningListId || ''}
          onChange={(value) => {
            setShortlistScreeningCompany({});
            navigateSearch({
              pathname: `/admin/screening/${companyId}/${value}`,
              params: {},
              replace: true,
            });
          }}
        />
      </Grid>
      <Grid item xs={4}>
        {countIC > 0 ? (
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Box>Match company progress</Box>
            <ProgressIndicator progress={(countIC / countTotal) * 100} />
          </Stack>
        ) : null}
      </Grid>
      <Grid item xs={4}>
        {countDPC > 0 ? (
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Box>Reporting company progress</Box>
            <ProgressIndicator progress={(countDPC / countTotal) * 100} />
          </Stack>
        ) : null}
      </Grid>

      <Grid item xs={12} sm={4}>
        <ScreeningListEntrySelect
          title={t('Screening List Entry')}
          listId={screeningListId || ''}
          value={screeningCompanyListEntryId}
          onChange={handleChange}
        />
      </Grid>
      {data && screeningCompanyListEntryId ? (
        <>
          <Grid item xs={12} sm={4}>
            <Stack spacing={2}>
              <CompanySelect
                title={t('Matched Company')}
                value={
                  data.find(
                    (entry: any) => entry.id === screeningCompanyListEntryId
                  )?.matched_company || ''
                }
                onChange={(value) => {
                  mutateMatchedCompany(value);
                }}
                placeholder={t('Select Matched Company')}
              />
              <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                {data.find(
                  (entry: any) => entry.id === screeningCompanyListEntryId
                )?.matched_company ? (
                  <Button
                    startIcon={<CloseIcon />}
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      mutateMatchedCompany('');
                    }}
                  >
                    {t('Remove')}
                  </Button>
                ) : (
                  <Button
                    startIcon={<CloseIcon />}
                    disabled
                    sx={{ opacity: 0 }}
                    variant="outlined"
                  >
                    {t('Remove')}
                  </Button>
                )}
                <Button
                  startIcon={<JoinFullIcon />}
                  color={'secondary'}
                  variant={matchCompanyMode ? 'contained' : 'outlined'}
                  onClick={toggleMatchCompanyMode}
                >
                  {t('Consolidate')}
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack spacing={2}>
              <CompanySelect
                title={t('Reporting Company')}
                value={
                  data.find(
                    (entry: any) => entry.id === screeningCompanyListEntryId
                  )?.reporting_company || ''
                }
                onChange={(value) => {
                  mutateReportingCompany({
                    targetCompany: data.find(
                      (entry: any) => entry.id === screeningCompanyListEntryId
                    )?.matched_company,
                    value,
                  });
                }}
                placeholder={t('Select Reporting Company')}
              />
              <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                {data.find(
                  (entry: any) => entry.id === screeningCompanyListEntryId
                )?.reporting_company ? (
                  <Button
                    startIcon={<CloseIcon />}
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      mutateReportingCompany({
                        targetCompany: data.find(
                          (entry: any) =>
                            entry.id === screeningCompanyListEntryId
                        )?.matched_company,
                        value: '',
                      });
                    }}
                  >
                    {t('Remove')}
                  </Button>
                ) : (
                  <Button
                    startIcon={<CloseIcon />}
                    disabled
                    sx={{ opacity: 0 }}
                    variant="outlined"
                  >
                    {t('Remove')}
                  </Button>
                )}
                <Button
                  startIcon={<JoinFullIcon />}
                  color="secondary"
                  variant={reportingCompanyMode ? 'contained' : 'outlined'}
                  onClick={toggleReportingCompanyMode}
                >
                  {t('Consolidate')}
                </Button>
              </Stack>
            </Stack>
          </Grid>
          {matchCompanyMode || reportingCompanyMode ? (
            <>
              <Grid item xs={12}>
                <CompanyShortlist />
              </Grid>
              <Grid item xs={12}>
                <AdminSearch isNested />
              </Grid>
            </>
          ) : null}
        </>
      ) : null}
    </Grid>
  );
};

export default observer(Component);

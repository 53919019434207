import React from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import Stack from 'components/common/Stack';
import ReactApexChart from 'react-apexcharts';

import colors from 'constants/colors';

function Component() {
  const { t } = useTranslation();
  const auth = useAuth();
  const { isLoading, data, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['supplier-dashboard-scorecards'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/scorecards/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const distinctCats: Set<string> = new Set();
  if (data) {
    data.forEach((company: any) => {
      company.scorecard.categories.forEach((category: any) => {
        distinctCats.add(category.name);
      });
    });
  }

  const categories = Array.from(distinctCats).sort();

  const companySlugs = isLoading ? [] : data.map((c: any) => c.company_slug);
  const companyNames = isLoading ? [] : data.map((c: any) => c.company_name);
  const companyScores = isLoading
    ? []
    : data.map((c: any) => c.scorecard.overall);

  const config = !isLoading
    ? {
        series: categories.map((category: string) => {
          return {
            name: category,
            data: companySlugs.map((slug: string) =>
              Number(
                data
                  .find((c: any) => c.company_slug === slug)
                  ?.scorecard.categories.find((ce: any) => ce.name === category)
                  ?.score || 0
              ).toFixed(2)
            ),
          };
        }),
        options: {
          colors: [
            colors.primary500,
            colors.secondary500,
            colors.tertiary500,
            colors.neutral500,
            colors.neutral400,
          ],
          dataLabels: {
            enabled: false,
          },
          chart: {
            stacked: true,
            dataLabels: {
              show: false,
            },
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              distributed: false,
              horizontal: true,
              borderRadius: 0,
              dataLabels: {
                total: {
                  enabled: false,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900,
                  },
                  formatter: function (
                    value: any,
                    index: { dataPointIndex: number }
                  ) {
                    Number(value);
                    return companyScores[index.dataPointIndex];
                  },
                },
              },
            },
          },
          xaxis: {
            labels: {
              show: true,
            },
            categories: companyNames,
          },
          legend: {
            show: true,
            position: 'top' as 'top',
          },
          yaxis: {
            axisTicks: {
              show: false,
            },
            tickAmount: 10,
            labels: {
              show: true,
            },
          },
        },
      }
    : undefined;

  return isLoading ? (
    <Loading color="primary" />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <Stack spacing={2}>
      <ReactApexChart
        options={config?.options}
        series={config?.series}
        type="bar"
        height={400}
      />
    </Stack>
  );
}

export default observer(Component);

import { isDevEnv } from 'utils/browser';

export const phCapture = (event?: string, params?: { [key: string]: any }) => {
  if (isDevEnv() || !event || !window.posthog || !window.posthog.capture)
    return;
  else {
    window.posthog.capture(event, params);
  }
};

export const phReset = () => {
  if (isDevEnv() || !window.posthog || !window.posthog.reset) return;
  else {
    window.posthog.reset();
  }
};

export const phIdentify = (id?: string, params?: { [key: string]: any }) => {
  if (isDevEnv() || !id || !window.posthog || !window.posthog.identify) return;
  else {
    window.posthog.identify(id, params);
  }
};

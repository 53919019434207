import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import useSnackbarMessage from 'hooks/useSnackbarMessage';
import useAuth from 'hooks/useAuth';

import Container from 'components/common/Container';
import Grid from 'components/common/Grid';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Form from 'components/common/Form';

import useChangePasswordStore from 'components/App/Routes/ChangePassword/store/useChangePasswordStore';
import Typography from 'components/common/Typography';
import FormHelperText from '@mui/material/FormHelperText';

const DashboardPreferences = () => {
  const {
    onChangePasswordSubmit,
    isSubmitting,
    form,
    invalid,
    passwordErrorMessage,
  } = useChangePasswordStore();

  const auth = useAuth();

  const {
    userDataForm,
    hasLoadedUserData,
    isLoadingUserData,
    isPatchingUserData,
    loadUserData,
    onUserSubmit,
  } = auth;
  const { t } = useTranslation('common');
  const snackbarMessage = useSnackbarMessage();
  const onSubmit = () => onChangePasswordSubmit({ auth, snackbarMessage });

  React.useEffect(() => {
    if (!hasLoadedUserData && !isLoadingUserData) {
      loadUserData();
    }
  });

  return (
    <Container maxWidth="xs">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {t('User preferences')}
          <Form onSubmit={() => onUserSubmit({ snackbarMessage })}>
            <Grid container spacing={2}>
              <Grid item xs={12} />
              <Grid item xs={12}>
                <Input
                  required
                  fullWidth
                  id="dasboardPreferences-email"
                  label={t('Email')}
                  name="email"
                  value={userDataForm.values.get('email')}
                  onChange={(e: any) =>
                    userDataForm.setValue('email', e.target.value)
                  }
                  error={userDataForm.errors.has('email')}
                  helperText={userDataForm.errors.get('email')}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  required
                  fullWidth
                  id="dasboardPreferences-first_name"
                  label={t('First name')}
                  name="first_name"
                  value={userDataForm.values.get('first_name')}
                  onChange={(e: any) =>
                    userDataForm.setValue('first_name', e.target.value)
                  }
                  error={userDataForm.errors.has('first_name')}
                  helperText={userDataForm.errors.get('first_name')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  required
                  fullWidth
                  id="dasboardPreferences-last_name"
                  label={t('Last name')}
                  name="last_name"
                  value={userDataForm.values.get('last_name')}
                  onChange={(e: any) =>
                    userDataForm.setValue('last_name', e.target.value)
                  }
                  error={userDataForm.errors.has('last_name')}
                  helperText={userDataForm.errors.get('last_name')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  fullWidth
                  id="dasboardPreferences-job_title"
                  label={t('Job title')}
                  name="job_title"
                  value={userDataForm.values.get('job_title')}
                  onChange={(e: any) =>
                    userDataForm.setValue('job_title', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={
                    isLoadingUserData ||
                    isPatchingUserData ||
                    userDataForm.hasErrors ||
                    !userDataForm.hasDirtyValues
                  }
                >
                  {t('Update')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12}>
          {t('Change password')}
          <Form onSubmit={() => onSubmit()}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={'caption2'} color={'neutral.contrastText'}>
                  {t(
                    `Your password must contain at least 10 characters, consisting of both letters and numbers. It should also not contain your name, email or other identifiable details.`
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Input
                  required
                  fullWidth
                  id="changePassword-password"
                  label={t('New password')}
                  type="password"
                  name="password"
                  value={form.values.get('password')}
                  onChange={(e: any) =>
                    form.setValue('password', e.target.value)
                  }
                  error={form.errors.has('password')}
                  helperText={form.errors.get('password')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  required
                  fullWidth
                  id="changePassword-password_match"
                  label={t('Repeat new password')}
                  type="password"
                  name="password_match"
                  value={form.values.get('password_match')}
                  error={form.errors.has('password_match')}
                  helperText={form.errors.get('password_match')}
                  onChange={(e: any) =>
                    form.setValue('password_match', e.target.value)
                  }
                  onBlur={(e: any) => form.checkFieldError('password_match')}
                />
              </Grid>
              {invalid && (
                <Grid item xs={12}>
                  <FormHelperText error>
                    {passwordErrorMessage || t('Something went wrong.')}
                  </FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={
                    isSubmitting || form.hasErrors || !form.hasDirtyValues
                  }
                >
                  {t('Submit')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default observer(DashboardPreferences);

import React from 'react';

import Typography from 'components/common/Typography';
import Stack from 'components/common/Stack';
import Tooltip from 'components/common/Tooltip/Tooltip';

import colors from 'constants/colors';
import { InfoOutlinedIcon } from 'components/icons/mui_icons';

type AlertToggleProps = {
  text?: string | null;
  tooltipText: string;
};

function AlertTooltip({ text, tooltipText }: AlertToggleProps) {
  return (
    <Tooltip
      title={tooltipText}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            color: colors.neutral600,
            fontSize: '14px',
            padding: '22px',
            boxShadow:
              '0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.1)',
            borderRadius: '22px',
            bgcolor: 'white',
            '& .MuiTooltip-arrow': {
              color: 'white',
            },
            marginBottom: '20px',
          },
        },
      }}
    >
      <div style={{ display: 'inline-block' }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <InfoOutlinedIcon sx={{ color: colors.primary500 }} />
          <Typography
            variant={'h6'}
            color={colors.neutral500}
            sx={{ cursor: 'default' }}
          >
            {text}
          </Typography>
        </Stack>
      </div>
    </Tooltip>
  );
}

export default AlertTooltip;

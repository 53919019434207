import React from 'react';
import { observer } from 'mobx-react-lite';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import StyledTagButton from 'components/common/StyledTagButton';
import Stack from 'components/common/Stack';

type Props = {
  companyImpactCategories: {};
};

function ImpactCategoriesTags({ companyImpactCategories }: Props) {
  const navigateSearch = useNavigateSearch();

  const filterItemsDefault = {
    property1: '',
    property2: '',
  };

  const handleCompanyFilterChange = (
    category: string,
    companyImpactCategories = filterItemsDefault
  ) => {
    if (Object.keys(companyImpactCategories).length !== 0) {
      navigateSearch({
        pathname: `/companies/search`,
        params: {
          tcc_impact_categories: [category],
        },
        replace: true,
      });
    }
  };

  return (
    <Stack direction={'row'} spacing={1}>
      {Object.entries(companyImpactCategories)
        .slice(0, 3)
        .map((filterObjArray, index) => {
          //we limit to only 3 categories
          let readableText: string = `${filterObjArray[1]}`;

          return (
            <StyledTagButton
              key={index}
              title={readableText}
              onClick={() =>
                handleCompanyFilterChange(
                  filterObjArray[0],
                  companyImpactCategories as any
                )
              }
            />
          );
        })}
    </Stack>
  );
}

export default observer(ImpactCategoriesTags);

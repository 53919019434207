import React from 'react';

const Form = ({ onSubmit, disableEnterSubmit = true, children, ...rest }) => {
  return (
    <form
      style={{ width: '100%' }}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      onKeyPress={(e) => {
        if (e.shiftKey && e.key === 'Enter') {
          return;
        } else if (e.key === 'Enter' && disableEnterSubmit) {
          e.preventDefault();
        }
      }}
      {...rest}
    >
      {children}
    </form>
  );
};

export default Form;

import React from 'react';
import Typography from 'components/common/Typography';
import colors from 'constants/colors';

type Props = {
  title: string;
};

function TagTitle({ title }: Props) {
  return (
    <Typography
      variant="caption"
      color={colors.neutral600}
      marginTop={'6px'}
      marginRight="0px"
      noWrap
    >
      {title}
    </Typography>
  );
}

export default TagTitle;

import { observer } from 'mobx-react-lite';
import ReactApexChart from 'react-apexcharts';

import Typography from 'components/common/Typography/Typography';
import Grid from '@mui/material/Grid';

import colors from 'constants/colors';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';

type FunnelProps = {
  textTitle: string;
  valueTitle: string;
  data: any;
};

function Component({ textTitle, valueTitle, data }: FunnelProps) {
  const { t } = useTranslation();
  const FirstColumnData = {
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        height: 50,
        toolbar: {
          show: false,
        },
      },
      colors: [colors.primary400],
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        position: 'left',
        style: {
          colors: [colors.neutral600],
        },
        formatter: function (val: string) {
          return val + '%';
        },
      },
      yaxis: {
        categories: ['1', '2', '3'],
        axisBorder: {
          show: false,
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
        categories: data.map((element: any) => element.text),
      },
      stroke: {
        width: 0,
      },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false,
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
    },
  };

  const getOptions = (color = '', header = '', dataPostfix = '') => ({
    chart: {
      height: 50,
      toolbar: {
        show: false,
      },
      position: 'center',
      colors: ['transparent'],
    },
    colors: [color || colors.neutral200],
    plotOptions: {
      bar: {
        horizontal: true,
        position: 'center',
        align: 'center',
      },
    },
    dataLabels: {
      enabled: true,
      position: 'center',
      align: 'center',
      style: {
        colors: [colors.neutral600],
      },
      formatter: function (val: string) {
        return val + dataPostfix;
      },
    },
    yaxis: {
      show: false,
      categories: header,
      axisBorder: {
        show: false,
      },
    },
    xaxis: {
      show: false,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
      categories: [header],
    },
    stroke: {
      width: 0,
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} md={3} lg={2}>
        <Stack>
          <Typography noWrap variant="caption1">
            {textTitle}
          </Typography>

          <ReactApexChart
            options={FirstColumnData.options}
            series={FirstColumnData.series}
            width={'100%'}
            type="bar"
            height={200}
          />
        </Stack>
      </Grid>
      <Grid item xs={5} md={6} lg={7}>
        <Stack>
          <Typography
            noWrap
            variant="caption1"
            sx={{
              paddingLeft: (theme: any) => theme.spacing(3),
            }}
          >
            {valueTitle}
          </Typography>
          <ReactApexChart
            options={getOptions(colors.primary300, valueTitle)}
            series={[
              {
                name: valueTitle,
                data: data.map((element: any) => element.value),
              },
            ]}
            width={'100%'}
            type="bar"
            height={200}
          />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack>
          <Typography
            noWrap
            variant="caption1"
            sx={{
              paddingLeft: (theme: any) => theme.spacing(3),
            }}
          >
            {t('Percentage of Total')}
          </Typography>
          <ReactApexChart
            options={getOptions(
              colors.neutral200,
              t('Percentage of Total'),
              '%'
            )}
            series={[
              {
                name: t('Percentage of Total'),
                data: data.map(
                  (element: any) =>
                    Math.round((element.value / data[0].value) * 100) + ''
                ),
              },
            ]}
            width={'100%'}
            type="bar"
            height={200}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default observer(Component);

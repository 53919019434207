import i18n from 'i18n';

const moduleActions = () => ({
  level1: {
    emissions_calculation: {
      title: i18n.t('Quantify your Scope 1 and 2 emissions'),
      subtitle: i18n.t(
        `The most important step now for your organization is to quantify your Scope 1 and 2 emissions according to GHG protocol standards. Depending on your industry and activities, you may need external expertise to complete that.\n
      The GHG Protocol considers organizational emissions to be within three categories: Scopes 1, 2 and 3. At the beginning of your journey, you can focus on just Scopes 1 and 2. However, you should aim to base your calculations on primary data, that is specific data reflecting your company's activities, rather than non-specific data such as industry averages.`
      ),
      recommendationText: i18n.t('Find Solution Providers'),
      recommendationLink:
        '/companies/search?tcc_product_tags=Corporate+carbon+footprint&page=1',
    },
    decarbonization_measures: {
      title: i18n.t('Switch to 100% renewable energy'),
      recommendationText: i18n.t('Find Solution Providers'),
      subtitle: i18n.t(
        `From here, you can take the next steps of setting a climate transition plan to decarbonize your company. A good starting point is to procure 100% renewable energy and implement energy efficiency measures.`
      ),
      recommendationLink:
        '/companies/search?page=1&tcc_impact_categories=ENERGY',
    },
    targets: {
      title: i18n.t('Set Climate Targets'),
      subtitle: i18n.t(
        `From here you can take the next steps of setting targets and an action plan to achieve them.`
      ),
      recommendationText: i18n.t('Find Solution Providers'),
      recommendationLink:
        '/companies/search?page=1&tcc_product_tags=Climate+targets+setting',
    },
  },
  level2: {
    targets: {
      title: i18n.t('Set Climate Targets'),
      subtitle: i18n.t(
        `As your organization has quantified its Scope 1 and 2 emissions, the most important next step is to set meaningful reduction targets. Depending on your industry and activities, you may need external expertise to undertake this.\n
        Targets should be 'science-based', in that they are in line with what the latest climate science deems necessary to meet the goals of the Paris Agreement and pursue limiting the effects of climate to 1.5C above pre-industrial levels. The Science Based Targets initiative (SBTi) is the leading framework for setting such targets, and begins with a simple letter of commitment. From here, you can work to develop an emissions reduction target in line with their criteria, and then have it validated by them for you to work towards.`
      ),
      recommendationText: i18n.t('Find Solution Providers'),
      recommendationLink:
        '/companies/search?page=1&tcc_product_tags=Climate+targets+setting',
    },
  },
  level3: {
    decarbonization_measures: {
      title: i18n.t('Start Engaging Your Suppliers'),
      subtitle: i18n.t(
        `Your organization has quantified its emissions, set targets and aligned its business strategy with a climate transition plan. The next step is to meaningfully decarbonize, which requires working collaboratively with value chain partners to obtain primary data, as well as driving impactful change through data-driven decisions. A good starting point is to screen the climate maturity of your suppliers.`
      ),
      recommendationText: i18n.t('Engage suppliers'),
      recommendationLink: '/dashboard/my-suppliers/screening',
    },
    strategy_and_control: {
      title: i18n.t('Position As A Climate Preferred Supplier'),
      subtitle: i18n.t(
        `Our Climate Performance Assessment can support this by enabling transparency and standardization between organizations, as well as granular primary data for decision-making. It is also integral to our Supplier Climate Performance solution.`
      ),
      recommendationText: i18n.t('Climate Performance Assessment'),
      recommendationLink: '/dashboard/my-company/analytics',
    },
  },
});

export default moduleActions;

import React from 'react';
// import SvgIcon from '@mui/material/SvgIcon';
import GetAppIcon from '@mui/icons-material/GetApp';

// can swap later
const DownloadIcon = (props: any) => (
  // <SvgIcon {...props}>
  //
  // </SvgIcon>
  <GetAppIcon {...props} />
);

export default DownloadIcon;

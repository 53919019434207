import { useTranslation } from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';

import colors from 'constants/colors';
import Typography from 'components/common/Typography';
import Box from 'components/common/Box';

import Avatar from 'components/common/Avatar';

import Stack from 'components/common/Stack/Stack';

import useUserCompany from 'hooks/useUserCompany';

import { getCountryName } from 'utils/getCountryName';
import Chip from 'components/common/Chip';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';
import Card from 'components/common/Card';
import { useState } from 'react';
import Dialog from 'components/common/Dialog';
import CRCOverviewYourAnswers from './CRCOverviewYourAnswers';
import Button from 'components/common/Button';
import Divider from 'components/common/Divider';

import { DownloadIcon, LinkIcon } from 'components/icons/mui_icons';

import Link from 'components/common/Link';
import LaunchOutlined from '@mui/icons-material/LaunchOutlined';

const Component = () => {
  const { t } = useTranslation('common');

  const auth = useAuth();
  const { userCompanyCRC } = useUserCompany();
  const [crcDialogOpen, setCrcDialogOpen] = useState(false);

  const {
    isLoading,
    isFetching,
    data: crcReport,
    isError,
  } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['user-company-crc-report'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/crc/report/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  // const {
  //   isLoading,
  //   isFetching,
  //   data: crcReport,
  //   isError,
  // } = useQuery({
  //   refetchOnReconnect: false,
  //   refetchOnWindowFocus: false,
  //   queryKey: ['user-company-crc-report'],
  //   queryFn: async () => {
  //     const res = await axios.get(
  //       `${api.url}/user/company/crc/report/`,
  //       auth.requestConfig
  //     );
  //     return res.data;
  //   },
  // });

  const questions = crcReport && crcReport.questions ? crcReport.questions : [];

  return (
    <Card>
      <Stack spacing={2}>
        <Typography variant="h5">
          {t('What was used for your score?')}
        </Typography>
        <Divider />
        <Stack direction="row" spacing={2} alignItems={'center'}>
          <Box flex={1}>
            <Typography variant="body1">
              {t('Climate Readiness Check')}
            </Typography>
          </Box>
          <Box flex={0}>
            <Button
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => setCrcDialogOpen(true)}
            >
              {t('View Answers')}
            </Button>
          </Box>
        </Stack>
        <Divider />
        {userCompanyCRC.status !== undefined ? (
          <>
            <Stack direction="row" spacing={2} alignItems={'center'}>
              <Box flex={1}>
                <Typography variant="body1">
                  {t('Climate Performance Assessment')}
                </Typography>
              </Box>
              <Box>
                <Link
                  href={`${api.url}/user/company/crc/assets/answers_export/`}
                  underline="none"
                >
                  <Button variant="text" startIcon={<DownloadIcon />}>
                    {t('Export assessment')}
                  </Button>
                </Link>
              </Box>
            </Stack>
            <Divider />
          </>
        ) : null}
        <Stack direction="row" spacing={2} alignItems={'center'}>
          <Box flex={1}>
            <Typography variant="body1">
              {t(
                'Possible other sources include: Publically available documents, CDP, SBTi'
              )}
            </Typography>
          </Box>
          <Box flex={0}>
            <Link
              href={'https://eu1.hubs.ly/H0c4TNq0'}
              underline="none"
              target={'_blank'}
            >
              <Button
                sx={{ whiteSpace: 'nowrap' }}
                startIcon={<LaunchOutlined />}
              >
                {t('View Methodology')}
              </Button>
            </Link>
          </Box>
        </Stack>
      </Stack>

      <Dialog open={crcDialogOpen} onClose={() => setCrcDialogOpen(false)}>
        <CRCOverviewYourAnswers questions={questions} />
      </Dialog>
    </Card>
  );
};

export default Component;

import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import useAdminCompanies from 'hooks/useAdminCompanies';
import useAdminCompany from 'hooks/useAdminCompany';
import { useParams } from 'react-router-dom';
import { getCountryName } from 'utils/getCountryName';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    sortable: false,
    editable: true,
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 100,
    sortable: false,
    editable: true,
    valueFormatter: (value: any) => getCountryName(value.value),
  },
  {
    field: 'website',
    headerName: 'Website',
    sortable: false,
    width: 160,
  },
  {
    field: 'initial_source',
    headerName: 'Source',
    sortable: false,
    width: 160,
  },
];

export default function DataGridDemo() {
  const { companyId } = useParams();
  const { data: company } = useAdminCompany(companyId);
  const { data, isLoading } = useAdminCompanies({ search: company?.name });
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={data?.filter((c: any) => c.id !== company?.id) || []}
        loading={isLoading}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}

// import React from 'react'
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from 'components/common/Card/Card';
import { observer } from 'mobx-react-lite';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

// import useAuth from 'hooks/useAuth'

import DashboardHeader from 'components/common/DashboardHeader';
import DashboardLegend from 'components/common/DashboardLegend';

import ScreeningFunnel from './ScreeningFunnel';
import SuppliersNotCompletedSurveyTable from './SuppliersNotCompletedSurveyTable';
import SurveyResultsPie from './SurveyResultsPie';
import CCFQuantified from './CCFQuantified';
import CCFQuantifiedScopes from './CCFQuantifiedScopes';
import CCFQuantifiedPCF from './CCFQuantifiedPCF';
import TargetsGHG from './TargetsGHG';
import TargetsRoadmap from './TargetsRoadmap';
import SuppliersWithCCFAndPCF from './SuppliersWithCCFAndPCF';
import CCFPCFDistribution from './CCFPCFDistribution';

// import { DemoPie } from './demos';

function Component() {
  // const auth = useAuth()
  // const navigate = useNavigate()
  const { t } = useTranslation('common');
  return (
    <Grid container spacing={{ xs: 2, sm: 3 }}>
      <Grid item xs={12}>
        <DashboardHeader text={t('Screening Progress Overview')} />
      </Grid>
      <Grid item xs={12}>
        <Card title={t('Supplier Screening')}>
          <ScreeningFunnel />
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card title={t('Stages of Supplier Screening')}>
          <DashboardLegend
            items={[
              {
                title: t(`Identified Suppliers`),
                description: t(`A company that is listed as your supplier`),
              },
              {
                title: t(`User signed up`),
                description: t(
                  `A user has signed up for a supplier and has provided basic company information`
                ),
              },
              {
                title: t(`Email verified`),
                description: t(`The user verified their email`),
              },
              {
                title: t(`Screening started`),
                description: t(
                  `The user has answered at least one question of the screening`
                ),
              },
              {
                title: t(`Screening completed`),
                description: t(
                  `The user completed the Climate Readiness Check that gives a preliminary assessment of their climate maturity`
                ),
              },
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card
          title={t(
            'Number of suppliers that have signed up but not completed the Climate Readiness Check'
          )}
        >
          <SuppliersNotCompletedSurveyTable countOnly />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card
          title={t(
            'Suppliers that have signed up but not completed the Climate Readiness Check'
          )}
        >
          <SuppliersNotCompletedSurveyTable />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <DashboardHeader text={t('Screening Results')} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          <Grid item xs={12} lg={4}>
            <Stack
              spacing={{ xs: 2, sm: 3 }}
              direction={{ xs: 'column', sm: 'row', lg: 'column' }}
            >
              <Card
                title={t(
                  'Number of suppliers that completed the Climate Readiness Check'
                )}
              >
                <SurveyResultsPie countOnly />
              </Card>
              <Card title={t('What do these results mean?')}>
                <DashboardLegend
                  items={[
                    {
                      title: t(`Level 1`),
                      description: t(`Does not have Scope 1 & 2 CCF data`),
                    },
                    {
                      title: t(`Level 2`),
                      description: t(
                        `Has Scope 1 & 2 data but does not have set targets`
                      ),
                    },
                    {
                      title: t(`Level 3`),
                      description: t(
                        `Has Scope 1 & 2 data and has set targets`
                      ),
                    },
                  ]}
                />
              </Card>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card title={t(`Suppliers' Climate Readiness Check results`)}>
              <SurveyResultsPie />
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <DashboardHeader
          text={t(`Corporate Carbon Footprint (CCF) Insights`)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card title={t(`Have suppliers quantified their carbon footprints?`)}>
          <CCFQuantified />
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card title={t(`Which of the Scopes were included in CCFs?`)}>
          <CCFQuantifiedScopes />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <DashboardHeader text={t(`Product Carbon Footprint (PCF) Insights`)} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card
          title={t(
            `Have suppliers quantified the product carbon footprint of any products or services?`
          )}
        >
          <CCFQuantifiedPCF />
        </Card>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Card title={t(`Distribution of suppliers as per their CCF and PCF`)}>
          <CCFPCFDistribution />
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card
          title={t(
            `Percentage of suppliers that have a PCF and CCF covering all three scopes`
          )}
        >
          <SuppliersWithCCFAndPCF countOnly />
        </Card>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Card
          title={t(
            `Suppliers that have a PCF and CCF covering all three scopes`
          )}
        >
          <SuppliersWithCCFAndPCF />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <DashboardHeader text={t(`Climate Targets Insights`)} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card
          title={t(`Are suppliers setting targets for reducing GHG emissions?`)}
        >
          <TargetsGHG />
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card
          title={t(`Are suppliers following a roadmap for achieving targets?`)}
        >
          <TargetsRoadmap />
        </Card>
      </Grid>
    </Grid>
  );
}

export default observer(Component);

//<Card title={t(``)}></Card>

import React from 'react';

import Stack from 'components/common/Stack';
import AlertTooltip from 'components/common/AlertTooltip';
import Typography from 'components/common/Typography';

type ColHeaderProps = {
  title: string | undefined;
  text: string;
};

const ColHeader = ({ title, text }: ColHeaderProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ textOverflow: 'clip', whiteSpace: 'break-spaces', lineHeight: 1 }}
    >
      <AlertTooltip tooltipText={text} />
      <Typography variant={'action_small'}>{title}</Typography>
    </Stack>
  );
};

export default ColHeader;

import * as React from 'react';

function SvgClimateAction(props: any) {
  return (
    <svg
      width={30}
      height={30}
      fill={props.color}
      viewBox="0 0 30 30"
      {...props}
    >
      <g clipPath="url(#ClimateAction_svg__clip0_713:107664)">
        <path d="M29.884 14.878c-.27-.31-6.742-7.576-14.884-7.576S.388 14.567.117 14.878a.467.467 0 000 .617c.27.31 6.74 7.576 14.883 7.576 8.142 0 14.613-7.266 14.884-7.576a.467.467 0 000-.617zM15 22.134c-6.787 0-12.56-5.541-13.89-6.947C2.441 13.78 8.213 8.24 15 8.24c6.788 0 12.56 5.54 13.89 6.947-1.331 1.407-7.102 6.947-13.89 6.947zM6.516 7.146l.571 1.046a.47.47 0 00.412.244.446.446 0 00.226-.08.469.469 0 00.188-.632l-.577-1.046a.473.473 0 10-.82.47v-.002zM9.615 5.745l.35 1.14a.467.467 0 00.445.332.444.444 0 00.141-.023.468.468 0 00.31-.586L10.51 5.47a.469.469 0 00-.895.276h.001zM12.984 6.17a.47.47 0 00.47.423l.042-.004a.47.47 0 00.42-.512L13.8 4.892a.469.469 0 10-.933.093l.117 1.186zM16.501 6.594h.046a.469.469 0 00.465-.441l.116-1.186a.469.469 0 10-.932-.093l-.117 1.21a.468.468 0 00.422.51zM19.449 7.193a.443.443 0 00.14.024.467.467 0 00.447-.332l.35-1.14a.469.469 0 10-.896-.275l-.35 1.139a.468.468 0 00.309.584zM22.276 8.376c.068.04.146.06.225.06a.47.47 0 00.412-.225l.572-1.045a.473.473 0 00-.82-.467l-.577 1.045a.469.469 0 00.188.632zM7.748 22.003a.475.475 0 00-.647.178l-.572 1.044a.47.47 0 00.644.637.47.47 0 00.176-.168l.576-1.046a.474.474 0 00-.177-.645zM10.556 23.192a.47.47 0 00-.59.324l-.35 1.14a.468.468 0 00.31.585c.044.007.09.007.135 0a.467.467 0 00.45-.309l.35-1.14a.473.473 0 00-.305-.6zM13.505 23.78a.47.47 0 00-.516.422l-.117 1.185a.47.47 0 00.418.515h.047a.47.47 0 00.468-.403l.122-1.204a.472.472 0 00-.422-.516zM17.016 24.202a.47.47 0 00-.515-.422.471.471 0 00-.423.515l.117 1.185a.469.469 0 00.469.422h.052a.468.468 0 00.417-.515l-.117-1.185zM20.367 24.638l-.35-1.14a.469.469 0 10-.896.277l.35 1.14a.468.468 0 00.896-.277zM23.471 23.208l-.571-1.046a.474.474 0 10-.825.469l.576 1.045a.47.47 0 00.412.245.508.508 0 00.225-.077.47.47 0 00.182-.637l.001.001z" />
        <path d="M15 9.259a5.917 5.917 0 105.917 5.917A5.923 5.923 0 0015 9.259zm5.214 6.262a5.183 5.183 0 01-.46 1.83H17.92c.12-.603.188-1.215.204-1.83h2.089zm0-.69h-2.09a10.938 10.938 0 00-.203-1.83h1.833c.264.576.42 1.196.46 1.83zm-4.87 2.52v-1.83h2.092c-.017.615-.09 1.228-.218 1.83h-1.873zm0-5.041v-2.304c.494.155.963.642 1.33 1.383.144.297.265.605.362.921h-1.691zm1.435-2.048a5.215 5.215 0 012.595 2.048h-1.619a7.218 7.218 0 00-.466-1.229 4.733 4.733 0 00-.51-.82zm-1.434 4.569V13h1.87c.129.601.202 1.214.22 1.83h-2.09zm1.432 5.263c.198-.258.37-.536.512-.829.192-.393.348-.803.466-1.224h1.619a5.224 5.224 0 01-2.597 2.053zm-1.432.255v-2.308h1.69a6.269 6.269 0 01-.361.922c-.369.743-.837 1.231-1.33 1.386zm-.69-2.308v2.308c-.492-.155-.96-.643-1.329-1.386a6.243 6.243 0 01-.362-.922h1.69zm-4.029-5.731a5.22 5.22 0 012.595-2.048 4.73 4.73 0 00-.51.82 7.226 7.226 0 00-.466 1.228h-1.619zm1.619 5.731c.117.422.273.831.466 1.224.142.293.314.57.512.83a5.222 5.222 0 01-2.597-2.054h1.619zm2.41-.69h-1.873a10.166 10.166 0 01-.218-1.83h2.09l.001 1.83zm-2.09-2.52c.018-.616.09-1.229.219-1.83h1.87v1.83h-2.089zm2.09-4.825v2.304h-1.69c.097-.316.218-.624.363-.921.365-.741.834-1.228 1.327-1.383zm-2.576 7.345h-1.833a5.19 5.19 0 01-.46-1.83h2.09c.015.614.083 1.227.203 1.83zm-2.293-2.52c.04-.634.196-1.254.46-1.83h1.833c-.12.602-.188 1.215-.204 1.83h-2.09z" />
      </g>
      <defs>
        <clipPath id="ClimateAction_svg__clip0_713:107664">
          <path d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgClimateAction;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

import CompanyShortlistTable from './CompanyShortlistTable';
import useStore from 'components/App/Routes/Admin/Screening/store/useStore';
import useSearchStore from 'components/App/Routes/Admin/Search/store/useStore';

const companyKeys = {
  id: true,
  name: true,
  slug: true,
  address: true,
  address2: true,
  city: true,
  zip_code: true,
  country: true,
  lei_code: true,
  isin: true,
  description: true,
  short_description: true,
  website: true,
  sector: true,
  published: true,
};

const Component = () => {
  const { selectedGLEIFCompany, selectedCompany } = useSearchStore();
  const { shortlist, setShortlistGLEIFCompany, setShortlistTCCCompany } =
    useStore();

  React.useEffect(() => {
    if (selectedGLEIFCompany.id) {
      setShortlistGLEIFCompany(selectedGLEIFCompany);
    } else {
      setShortlistGLEIFCompany({});
    }
    if (selectedCompany.id) {
      const selectedCompanyObject = toJS(selectedCompany);
      const selectedCompanyAsShortlistCompany: any = {};
      Object.keys(companyKeys).forEach((key) => {
        if (selectedCompanyObject[key]) {
          selectedCompanyAsShortlistCompany[key] = selectedCompanyObject[key];
        }
      });
      setShortlistTCCCompany(selectedCompanyAsShortlistCompany);
    } else {
      setShortlistTCCCompany({});
    }
  }, [
    selectedGLEIFCompany,
    selectedCompany,
    setShortlistTCCCompany,
    setShortlistGLEIFCompany,
  ]);
  return <CompanyShortlistTable data={shortlist.slice()} />;
};

export default observer(Component);

// import React from 'react'
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import DashboardLineChart from 'components/common/DashboardLineChart';
import SelectMenu from 'components/common/SelectMenu';
import { useEffect, useState } from 'react';
import Stack from 'components/common/Stack';
import Box from 'components/common/Box';

function Component() {
  const { t } = useTranslation();
  const [type, setType] = useState<string>(t('Absolute'));
  const [reductionScopes, setReductionScopes] = useState<string | undefined>(
    undefined
  );
  const auth = useAuth();
  const {
    isLoading: isLoadingReductionScopes,
    data: reductionScopesData,
    isError: isErrorReductionScopes,
  } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['supplier-screening-reduction_targets-', { type }],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/reduction_targets/${
          type === t('Absolute') ? 'Absolute' : 'Intensity'
        }/scopes/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const { isLoading, data, isError } = useQuery({
    enabled: !!reductionScopes,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: [
      'supplier-screening-reduction_targets-other-',
      { type, reductionScopes },
    ],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/reduction_targets/${
          type === t('Absolute') ? 'Absolute' : 'Intensity'
        }/scopes/${reductionScopes}/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  useEffect(() => {
    if (reductionScopesData && reductionScopesData.length > 0) {
      setReductionScopes(reductionScopesData[0]);
    }
  }, [setReductionScopes, reductionScopesData]);

  const distinctYears: Set<number> = new Set();
  if (data) {
    data.forEach((company: any) => {
      company.targets.forEach((target: any) => {
        distinctYears.add(target.target_year);
      });
    });
  }

  const years = Array.from(distinctYears).sort();

  const series = data
    ? data.map((c: any) => ({
        name: c.company_name,
        data: years.map((year) => {
          return {
            x: [year],
            y:
              c.targets.find((ce: any) => ce.target_year === year)?.reduction ||
              null,
          };
        }),
      }))
    : [];

  return isLoadingReductionScopes ? (
    <Loading color="primary" />
  ) : isErrorReductionScopes || isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <Stack spacing={2}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <Box width={180}>
          <SelectMenu
            items={[t('Absolute'), t('Intensity')]}
            value={type}
            handleChange={(e) =>
              setType(
                e.target.value === t('Absolute')
                  ? t('Absolute')
                  : t('Intensity')
              )
            }
          />
        </Box>
        <Box flexGrow={1} />
        <Box width={180}>
          <SelectMenu
            items={reductionScopesData}
            value={reductionScopes}
            handleChange={(e) => setReductionScopes(e.target.value)}
          />
        </Box>
      </Stack>

      {data ? (
        <DashboardLineChart
          yaxisTitle={t('Amount (t CO₂e)')}
          categories={years}
          series={isLoading ? [] : series}
        />
      ) : null}
    </Stack>
  );
}

export default observer(Component);

import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from 'components/common/Grid';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import CardMedia from 'components/common/CardMedia';
import EmptyTextField from 'components/common/EmptyTextField';

import SDGsInfoButton from './SDGsInfoButton';
import { imageSrcMap } from 'media/sdgs_empty_state/index';

function SdgEmptyState() {
  const { t } = useTranslation('common');
  return (
    <Grid item>
      <EmptyTextField />
      <Stack
        direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
        spacing={2}
        sx={{ backgroundColor: 'neutral.main', p: '22px', mt: '28px' }}
      >
        <Grid item xs={6}>
          <Grid item xs={9}>
            <Typography variant="body1" color="black" mt={'12px'}>
              {t(
                'The Division for Sustainable Development Goals (DSDG) in the United Nations Department of Economic and Social Affairs (UNDESA) acts as the Secretariat for the SDGs, providing substantive support and capacity-building for the goals and their related thematic issues.'
              )}
            </Typography>
          </Grid>
          <SDGsInfoButton />
        </Grid>
        <Grid item container xs={6} justifyContent={'space-around'}>
          <CardMedia
            component="img"
            image={imageSrcMap.sdgsImage}
            sx={{ p: '8px', objectFit: 'contain' }}
          ></CardMedia>
        </Grid>
      </Stack>
    </Grid>
  );
}

export default SdgEmptyState;

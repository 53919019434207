import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useAdminCompanyLLMOutputs({
  jobId,
}: {
  jobId?: string;
}) {
  const auth = useAuth();
  return useQuery({
    enabled: !!jobId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    retry: false,
    queryKey: ['company-llm-outputs', { jobId }],
    queryFn: async () => {
      const params = new URLSearchParams();
      // if (companyId) {
      //   params.set('company', companyId);
      // }
      if (jobId) {
        params.set('job', jobId);
      }
      const res = await axios.get(
        `${api.url}/etl/llm-outputs/`,
        Object.assign(auth.requestConfig, { params })
      );
      return res.data;
    },
  });
}

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@mui/material/FormHelperText';

import useLogin from './store/useLogin';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import Form from 'components/common/Form';
import UnauthenticatedContainer from 'components/common/UnauthenticatedContainer';
import Grid from 'components/common/Grid';
import Input from 'components/common/Input';
import Checkbox from 'components/common/Checkbox';
import Typography from 'components/common/Typography/Typography';
import Link from 'components/common/Link';

const Login = () => {
  const { login, isLoggingIn, form, isInvalid, error_message } = useLogin();
  let navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  let auth = useAuth();
  const { t } = useTranslation('common');

  return (
    <UnauthenticatedContainer
      title=""
      subtitle={t('Login to your account.')}
      data-testid="login"
      maxWidth="xs"
    >
      <Form
        disableEnterSubmit={false}
        onSubmit={() => {
          login({ auth, snackbarMessage });
        }}
      >
        <Grid container spacing={2} alignItems="center">
          {auth.useToken ? (
            <Grid item xs={12}>
              <Input
                required
                fullWidth
                id="user-token"
                label={'user-token'}
                name="user-token"
                autoFocus
                value={form.values.get('userToken')}
                error={form.errors.has('userToken')}
                helperText={form.errors.get('userToken')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setValue('userToken', e.target.value)
                }
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Input
                  required
                  fullWidth
                  id="login-email"
                  label={t('Email Address')}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={form.values.get('email')}
                  error={form.errors.has('email')}
                  helperText={form.errors.get('email')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    form.setValue('email', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  required
                  fullWidth
                  name="password"
                  label={t('Password')}
                  type="password"
                  id="login-password"
                  autoComplete="current-password"
                  value={form.values.get('password')}
                  error={form.errors.has('password')}
                  helperText={form.errors.get('password')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    form.setValue('password', e.target.value)
                  }
                  onBlur={() => form.checkFieldError('password')}
                />
              </Grid>
            </>
          )}
          {isInvalid && (
            <Grid item xs={12}>
              <FormHelperText error>
                {error_message || t('Login credentials invalid.')}
              </FormHelperText>
            </Grid>
          )}
          <Grid item xs={6}>
            <Checkbox
              checked={form.values.get('remember')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                form.setValue('remember', e.target.checked)
              }
              color="primary"
              label={`${t('Remember me')}`}
              error={false}
              helperText={''}
            />
          </Grid>
          <Grid item xs={6} textAlign={'right'}>
            <Button
              variant="text"
              color="primary"
              onClick={() => navigate(`/forget`)}
            >
              {t('Forgot password?')}
            </Button>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoggingIn || form.hasErrors}
            >
              {t('Sign In')}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'center', gap: 1, pt: 2 }}
          >
            <Typography>{t(`Don't have an account?`)}</Typography>
            <Link href={t('https://theclimatechoice.com/en/plans-pricing/')}>
              <Typography>{t(`Create an account`)}</Typography>
            </Link>
          </Grid>
        </Grid>
      </Form>
    </UnauthenticatedContainer>
  );
};

export default observer(Login);

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import useAuth from 'hooks/useAuth';
import useSignUp from 'hooks/useSignUp';
import { useParams, useNavigate } from 'react-router-dom';

import Loading from 'components/common/Loading';
import UnauthenticatedContainer from 'components/common/UnauthenticatedContainer';
import Button from 'components/common/Button';
import Stack from 'components/common/Stack';
import { isValidEmail } from 'utils/validators';
import TccInputTextField from 'components/common/TccInputTextField';

const VerifyEmail = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const {
    emailVerified,
    isVerifyingEmail,
    verifyEmail,
    hasVerifiedEmail,
    tokenInvalid,
    resendEmail,
    hasResentEmail,
  } = useSignUp();
  const { t } = useTranslation('common');
  const { uid, token } = useParams();
  const [email, setEmail] = useState(auth.userDetails.email || '');

  useEffect(() => {
    if (!hasVerifiedEmail && !isVerifyingEmail) {
      verifyEmail({ auth, uid, token });
    }
  }, [verifyEmail, isVerifyingEmail, hasVerifiedEmail, auth, uid, token]);

  return (
    <UnauthenticatedContainer
      title={t('Email Verified')}
      maxWidth="xs"
      subtitle={
        !hasVerifiedEmail || isVerifyingEmail
          ? undefined
          : emailVerified
          ? t(
              'Your email has now been verified. Please follow the link below to login and continue.'
            )
          : tokenInvalid
          ? t('Email verification link is no longer valid')
          : t('There was a problem verifiying your email')
      }
    >
      <Box display="flex" justifyContent="center">
        {!hasVerifiedEmail || isVerifyingEmail ? (
          <Loading color="primary" />
        ) : emailVerified ? (
          <Button
            onClick={() =>
              navigate({
                pathname: '/',
              })
            }
            color="primary"
          >
            {t('Login')}
          </Button>
        ) : hasResentEmail ? (
          <Button fullWidth disabled variant="contained" type="button">
            {t('Email sent.')}
          </Button>
        ) : tokenInvalid ? (
          <Stack spacing={2} width={'100%'}>
            <TccInputTextField
              title={t(`Email`)}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              fullWidth
              disabled={!isValidEmail(email) || isVerifyingEmail}
              variant="contained"
              type="button"
              onClick={() => resendEmail({ auth: auth, email: email })}
            >
              {t('Resend Verification Email')}
            </Button>
          </Stack>
        ) : (
          <Button
            onClick={() => verifyEmail({ auth, uid, token })}
            color="secondary"
          >
            {t('Try again')}
          </Button>
        )}
      </Box>
    </UnauthenticatedContainer>
  );
};

export default observer(VerifyEmail);

import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import LinearProgress from '@mui/material/LinearProgress';

import api from 'constants/api';
import useAuth from 'hooks/useAuth';

import SearchCompanyResults from './SearchCompanyResults';
import { useSearchParams } from 'react-router-dom';
import Button from 'components/common/Button';

import useSearchStore from 'components/App/Routes/Admin/Search/store/useStore';
import { observer } from 'mobx-react-lite';

const Component = () => {
  const auth = useAuth();
  const { ref, inView } = useInView();
  let [searchParams] = useSearchParams();
  const { invalid, setInvalid } = useSearchStore();

  const {
    status,
    data,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    queryKey: [`companies`, searchParams.toString()],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await axios.get(
        `${api.url}/admin/companies/?page=${pageParam}${
          searchParams.size > 0 ? '&' + searchParams.toString() : ''
        }`,
        auth.requestConfig
      );

      return res.data;
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.next) return undefined;
      const url = new URL(lastPage.next);
      return url.searchParams.get('page') ?? undefined;
    },
  });

  React.useEffect(() => {
    if (invalid && !isFetching && !isRefetching) {
      refetch();
      setInvalid(false);
    }
  }, [invalid, isFetching, isRefetching, refetch, setInvalid]);

  React.useEffect(() => {
    if (inView) {
      // fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  return (
    <>
      {isRefetching ? <LinearProgress color="primary" /> : null}
      {status === 'error' ? (
        <span>Error</span>
      ) : data ? (
        <SearchCompanyResults pages={data.pages} />
      ) : null}
      {isFetching && !isRefetching ? <LinearProgress color="primary" /> : null}
      <div ref={ref}>
        <Button
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? 'Loading more...'
            : hasNextPage
            ? 'Show more'
            : 'Nothing more to load'}
        </Button>
      </div>
    </>
  );
};

export default observer(Component);

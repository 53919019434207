import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import IconButton from 'components/common/IconButton';
import Typography from 'components/common/Typography';

import Menu from 'components/common/Menu';
import MenuItem from 'components/common/MenuItem';
import { MenuIcon } from 'components/icons/mui_icons';

import Box from 'components/common/Box';

import { listItemClasses } from '@mui/material/ListItem';
import colors from 'constants/colors';

import useAuth from 'hooks/useAuth';

function BurgerLinksMobile() {
  const { t } = useTranslation('common');
  const auth = useAuth();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const MyNavLink = React.forwardRef((props: any, ref) => {
    return <NavLink ref={ref} {...props} activeclassname="active" />;
  });

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="primary"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        defaultValue={'search'}
        PaperProps={{ style: { borderRadius: 4 } }}
        sx={{
          display: { xs: 'block', md: 'none' },
          [`& .${listItemClasses.root}`]: {
            color: colors.secondary500,
            // textDecoration: 'underline'
          },
          [`& .active`]: {
            color: colors.primary500,
            fontWeight: 900,
            backgroundColor: colors.primary100,
          },
        }}
      >
        {auth.hasDashboardPermission ? (
          <MenuItem
            component={MyNavLink}
            key={1}
            to="/dashboard"
            onClick={() => {
              handleCloseNavMenu();
            }}
          >
            <Typography textAlign="center">{t('Dashboard')}</Typography>
          </MenuItem>
        ) : null}
        {auth.hasUserPermission ? (
          <MenuItem
            component={MyNavLink}
            key={3}
            to="/user/company"
            onClick={() => {
              handleCloseNavMenu();
            }}
          >
            <Typography textAlign="center">{t('Company Profile')}</Typography>
          </MenuItem>
        ) : null}
        {auth.hasCompanyPermission ? (
          <MenuItem
            component={MyNavLink}
            key={2}
            to="/companies/search"
            onClick={() => {
              handleCloseNavMenu();
            }}
          >
            <Typography textAlign="center">{t('Search')}</Typography>
          </MenuItem>
        ) : null}
        {auth.hasCompanyPermission ? (
          <MenuItem
            component={MyNavLink}
            key={4}
            to="/companies/watchlist"
            onClick={() => {
              handleCloseNavMenu();
            }}
          >
            <Typography textAlign="center">{t('Watchlist')}</Typography>
          </MenuItem>
        ) : null}
      </Menu>
    </Box>
  );
}

export default observer(BurgerLinksMobile);

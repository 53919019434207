import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import AppLoading from 'components/common/AppLoading';
import AppInitializer from './AppInitializer';
import Theme from './Theme';
import SnackbarMessages from './SnackbarMessages';

const queryClient = new QueryClient();

function App() {
  return (
    <BrowserRouter>
      <Theme>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<AppLoading />}>
            <AppInitializer />
          </Suspense>
          <SnackbarMessages />
        </QueryClientProvider>
      </Theme>
    </BrowserRouter>
  );
}

export default App;

import { useTranslation } from 'react-i18next';

import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';

import CDPTable from './CDPTable';

const CompanyCertifications = ({ data = [] }: any) => {
  const { t } = useTranslation('common');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" color="onSurface.100">
          {t('CDP')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CDPTable data={data} />
      </Grid>
    </Grid>
  );
};

export default CompanyCertifications;

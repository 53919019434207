import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';

function NoMatch() {
  const { t } = useTranslation('common');
  return (
    <Container>
      <h2>{t("It looks like you're lost...")}</h2>
      <p>
        <Link to="/">{t('Go to the home page')}</Link>
      </p>
    </Container>
  );
}
export default NoMatch;

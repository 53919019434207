import { observer } from 'mobx-react-lite';
import LanguageIcon from '@mui/icons-material/Language';

import Box from '@mui/material/Box';

import colors from 'constants/colors';
import Stack from 'components/common/Stack';
import { useState } from 'react';

import i18n from 'i18n';
import axios from 'axios';

const Component = () => {
  const [lang, setLang] = useState(i18n.language);
  return (
    <Stack
      direction={'row'}
      spacing={1}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <LanguageIcon fontSize="small" sx={{ color: colors.neutral400 }} />
      <Stack
        direction={'row'}
        spacing={0.5}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box
          sx={{
            cursor: 'pointer',
            fontWeight: lang === 'de' ? 'bold' : 'normal',
            color: lang === 'de' ? colors.primary500 : colors.neutral400,
            fontSize: '12px',
          }}
          onClick={() => {
            setLang('de');
            i18n.changeLanguage('de');
            axios.defaults.headers.common['Accept-Language'] = 'de';
          }}
        >
          DE
        </Box>
        <Box sx={{ color: colors.neutral400 }}>/</Box>
        <Box
          sx={{
            cursor: 'pointer',
            fontWeight: lang === 'en' ? 'bold' : 'normal',
            color: lang === 'en' ? colors.primary500 : colors.neutral600,
            fontSize: '12px',
          }}
          onClick={() => {
            setLang('en');
            i18n.changeLanguage('en');
            axios.defaults.headers.common['Accept-Language'] = 'en';
          }}
        >
          EN
        </Box>
      </Stack>
    </Stack>
  );
};
export default observer(Component);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'components/common/Button/Button';
import colors from 'constants/colors';

function UserDashboardCustomersSearch() {
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  const searchCompanies = (string: string) => {
    navigateSearch({
      pathname: location.pathname,
      params: {
        page: 1,
        search: string === '' ? [] : string,
      },
      replace: false,
    });
  };

  return (
    <TextField
      fullWidth
      placeholder={`${t('Search your customers')}`}
      variant="standard"
      size="small"
      value={searchParams.get('search') ? searchParams.get('search') : ''}
      onChange={(e) => searchCompanies(e.target.value)}
      InputProps={{
        disableUnderline: true,
        style: {
          paddingLeft: 10,
          borderRadius: '8px',
          backgroundColor: colors.neutral100,
        },
        endAdornment: (
          <Button
            color="primary"
            variant="contained"
            onClick={
              !!searchParams.get('search')
                ? () => searchCompanies('')
                : () => null
            }
            startIcon={
              !!searchParams.get('search') ? <CloseIcon /> : <SearchIcon />
            }
            sx={{ borderRadius: '0px 8px 8px 0px' }}
          >
            {t('Search')}
          </Button>
        ),
      }}
      sx={{
        maxWidth: '540px',
      }}
    />
  );
}

export default UserDashboardCustomersSearch;

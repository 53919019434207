import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Grid from 'components/common/Grid';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import colors from 'constants/colors';

import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedInIcon,
} from 'components/icons/mui_icons/index';
import SocialMediaIcon from './SocilaMediaIcon/SocialMediaIcon';

type Props = {
  facebook: string | undefined;
  instagram: string | undefined;
  twitter: string | undefined;
  linkedin: string | undefined;
};
function SocialMedia({ facebook, instagram, twitter, linkedin }: Props) {
  const [noSocialData, setnoSocialData] = useState({
    socialData: false,
    marginB: '40px',
  });

  useEffect(() => {
    if (!facebook && !instagram && !twitter && !linkedin) {
      setnoSocialData({ socialData: true, marginB: '0px' });
    }
  }, [facebook, instagram, linkedin, twitter]);

  return (
    <Grid>
      {!facebook && !instagram && !twitter && !linkedin ? null : (
        <Typography variant="caption" color={colors.neutral700} mb={'10px'}>
          {'Social'}
        </Typography>
      )}
      <Stack
        direction={'row'}
        spacing={1}
        mb={noSocialData.marginB}
        mt={'10px'}
      >
        <SocialMediaIcon
          icon={<FacebookIcon sx={{ color: colors.neutral600 }} />}
          link={facebook}
        />
        <SocialMediaIcon
          icon={<InstagramIcon sx={{ color: colors.neutral600 }} />}
          link={instagram}
        />
        <SocialMediaIcon
          icon={<TwitterIcon sx={{ color: colors.neutral600 }} />}
          link={twitter}
        />
        <SocialMediaIcon
          icon={<LinkedInIcon sx={{ color: colors.neutral600 }} />}
          link={linkedin}
        />
      </Stack>
    </Grid>
  );
}

export default observer(SocialMedia);

const fontSize = {
  h3: '48px',
  h4: '34px',
  h5: '20px',
  h6: '13px',
  h7: '28px',
  h8: '24px',
  body1: '15px',
  subTitle1: '16px',
  subTitle2: '14px',
  caption: '12px',
  overline: '12px',
};

const fontWeight = {
  h3: 900,
  h4: 900,
  h5: 900,
  h6: 700,
  subTitle1: 700,
  subTitle2: 500,
  caption: 700,
  overline: 900,
};

const letterSpacing = {
  overline: '1.5px',
};

export { fontSize, fontWeight, letterSpacing };

const api = {
  url: `${
    process.env.REACT_APP_API_URL || 'https://test-api.theclimatechoice.tech'
  }/api/v1`,
  mediaUrl: `${
    process.env.REACT_APP_MEDIA_URL || 'https://test-api.theclimatechoice.tech'
  }/media`,
  privateMediaUrl: `${
    process.env.REACT_APP_MEDIA_URL || 'https://test-api.theclimatechoice.tech'
  }/smedia`,
};

export default api;

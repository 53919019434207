import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import Grid from 'components/common/Grid';
import Link from 'components/common/Link';

type Props = {
  value: string;
  iconSrc: string;
};

const CertificationsCard = ({ value = '', iconSrc = '' }: Props) => {
  const navigateSearch = useNavigateSearch();
  const handleCompanyFilterChange = useCallback(
    (value: string) => {
      navigateSearch({
        pathname: `/companies/search`,
        params: { page: 1, certifications: value },
      });
    },
    [navigateSearch]
  );

  return (
    <>
      {value && typeof value === 'string' ? (
        <Grid item>
          <Link
            component="button"
            variant="body2"
            onClick={() => handleCompanyFilterChange(value)}
          >
            {
              <img
                alt=""
                src={iconSrc}
                style={{ height: 80, marginRight: 20 }}
              />
            }
          </Link>
        </Grid>
      ) : null}
    </>
  );
};

export default observer(CertificationsCard);

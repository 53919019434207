// import React from 'react'
import { observer } from 'mobx-react-lite';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/common/Checkbox';

// import useAuth from 'hooks/useAuth'
import useNavigateSearch from 'hooks/useNavigateSearchParams';

function Component() {
  // const auth = useAuth()
  // const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();
  const { t } = useTranslation('common');
  return (
    <Checkbox
      checked={searchParams.get('emas_data') === 'True'}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        navigateSearch({
          pathname: location.pathname,
          params: {
            page: 1,
            ...(e.target.checked && { emas_data: 'True' }),
          },
          removeParams: e.target.checked ? [] : ['emas_data'],
          replace: false,
        });
      }}
      label={t('EMAS certification') + ''}
    />
  );
}

export default observer(Component);

import { decorate, observable, action } from 'mobx';
import axios from 'axios';

// import i18n from 'i18n';
import api from 'constants/api';

import AuthStore from 'state/AuthStore';
import SnackbarMessageStore from 'state/SnackbarMessageStore';

class LogoutStore {
  // OBSERVABLES................................................................
  isLoggingOut = false;

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  logout = async ({
    auth,
    snackbarMessage,
  }: {
    auth: AuthStore;
    snackbarMessage: SnackbarMessageStore;
  }) => {
    this.isLoggingOut = true;
    try {
      await axios.post(`${api.url}/auth/logout/`, {}, auth.requestConfig);
      this.logoutSuccess();
    } catch (err) {
      console.error(err);
      window.Sentry?.captureException(err);
      // snackbarMessage.addMessage({
      //   message: i18n.t('common:::Something went wrong.'),
      // });
      this.logoutFailure();
    }
    auth.removeAuthenticated();
  };

  logoutSuccess = () => {
    this.isLoggingOut = false;
  };

  logoutFailure = () => {
    this.isLoggingOut = false;
  };
}

decorate(LogoutStore, {
  isLoggingOut: observable,
  logout: action,
  logoutSuccess: action,
  logoutFailure: action,
});

export default LogoutStore;

import { observer } from 'mobx-react-lite';
import ReactApexChart from 'react-apexcharts';

import colors from 'constants/colors';

type dataObjProps = {
  x: string;
  y: number;
};

type Props = {
  horizontal?: boolean;
  seriesLabel: string;
  data: dataObjProps[];
  barColors?: string[];
  labelColors?: string[];
  height?: number;
  width?: string;
  yaxisTitle?: string;
  xaxisTitle?: string;
  xaxisShow?: boolean;
  yaxisShow?: boolean;
  yaxisMax?: number;
  yaxisTickAmount?: number;
  yaxisFormatter?: (value: number) => string;
  showDataLabels?: boolean;
  labelFontSize?: string;
  offsetX?: number;
  percent?: boolean;
};

function Component({
  horizontal = false,
  seriesLabel,
  data,
  barColors,
  labelColors = [colors.white],
  height,
  width,
  yaxisTitle,
  xaxisTitle,
  xaxisShow = true,
  yaxisShow = true,
  yaxisMax,
  yaxisTickAmount,
  yaxisFormatter = (value) => value + '',
  showDataLabels = true,
  labelFontSize = '14px',
  offsetX,
  percent,
}: Props) {
  const config = {
    series: [
      {
        name: seriesLabel,
        data: data.map((item: any) => item.y),
      },
    ],
    options: {
      grid: {
        show: yaxisShow || xaxisShow,
      },
      xaxis: {
        axisBorder: {
          show: xaxisShow,
        },
        axisTicks: {
          show: xaxisShow,
        },
        labels: {
          show: xaxisShow,
        },
        title: { text: xaxisTitle },
        categories: data.map((item: any) => {
          const words: string[] = item.x?.split(' ') || [];
          return words.length > 10
            ? [
                words.slice(0, 5).join(' '),
                words.slice(5, 10).join(' '),
                words.slice(10, words.length).join(' '),
              ]
            : words.length > 6
            ? [
                words.slice(0, 6).join(' '),
                words.slice(6, words.length).join(' '),
              ]
            : item.x;
        }),
      },
      yaxis: {
        show: yaxisShow,
        max: yaxisMax,
        tickAmount: yaxisTickAmount,
        labels: {
          formatter: yaxisFormatter,
        },
        title: { text: yaxisTitle },
      },
      chart: {
        height: height ? height : 380,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: horizontal,
          colors: {
            // backgroundBarColors: [colors.neutral100],
          },
        },
      },
      colors: barColors ? barColors : [colors.secondary500],
      dataLabels: {
        enabled: showDataLabels,
        style: {
          colors: labelColors ? labelColors : [colors.neutral800],
          fontSize: labelFontSize ? labelFontSize : '14px',
          fontFamily: ['Lato', 'sans-serif'].toString(),
        },
        offsetY: labelFontSize ? -3 : 0,
        offsetX: offsetX ? offsetX : 0,
        formatter: (value: any) => {
          return percent ? value + '%' : value;
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={config.options}
      series={config.series}
      width={width ? width : '100%'}
      type="bar"
      height={height ? height : 450}
    />
  );
}

export default observer(Component);

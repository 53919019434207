// import React from 'react'
import axios from 'axios';
import { observer } from 'mobx-react-lite';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import useAuth from 'hooks/useAuth';
import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';
import api from 'constants/api';
import Loading from 'components/common/Loading/Loading';

import ClimateTargetsDataTable from './ClimateTargetsDataTable';

function Component({ slug }: { slug: string | undefined }) {
  const auth = useAuth();
  // const navigate = useNavigate()
  const { t } = useTranslation('common');
  const { isLoading, data, isError } = useQuery({
    queryKey: [`company-targets-${slug}`], // company-targets-${slug}
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/targets/${slug}`, // /companies/targets/{company_slug}
        auth.requestConfig
      );
      return res.data as { [key: string]: string };
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" color="onSurface.100">
          {t('Climate Targets')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <Alert severity="error">{t('There was a problem')}</Alert>
        ) : (
          <ClimateTargetsDataTable data={data.results} />
        )}
      </Grid>
      {/* <Grid item xs={10}>
        <Typography variant="caption" color="inherit" mt={'12px'}>
          {t('*Further data available')}
        </Typography>
        <Typography variant="h6" color="inherit" mt={'12px'}>
          {t('Description')}
        </Typography>
        <Typography variant="body1" color="inherit" mt={'12px'}>
          {t(
            'Swiss multinational pharmaceutical company Novartis commits to reduce absolute scope 1, 2 and 3 GHG emissions 35% by 2030 from a 2016 base year.'
          )}
        </Typography>
        <Typography
          variant="body1"
          color="inherit"
          mt={'12px'}
          sx={{ fontStyle: 'italic' }}
        >
          {t('Net Zero Committed')}
        </Typography>
      </Grid> */}
    </Grid>
  );
}

export default observer(Component);

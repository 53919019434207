import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import FilterSectionSearch from './FilterCommon/FilterSectionSearch';

type tagsProps = {
  filterId: string;
  tags: Array<string>;
};

type tagsTableProps = [
  {
    key: string;
    value: string;
  }
];

const FilterSectionSearchTags = ({ filterId, tags }: tagsProps) => {
  const [tagsTable, setTagsTable] = React.useState<tagsTableProps>([
    { key: '', value: '' },
  ]);
  //FIX-QA
  useEffect(() => {
    const tagsObj: tagsTableProps = [{ key: '', value: '' }];
    if (tags) {
      Object.entries(tags).forEach((tag) => {
        const tagObj = {
          key: tag[0],
          value: tag[1],
        };
        tagsObj.push(tagObj);
      });
    }
    setTagsTable(tagsObj);
  }, [tags]);

  return (
    <>
      <FilterSectionSearch
        filterId={filterId}
        tags={tags}
        tagsTable={tagsTable}
      />
    </>
  );
};

export default observer(FilterSectionSearchTags);

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';

import { useTranslation } from 'react-i18next';
import { getLanguagePreference } from 'utils/browser';
import SourceChannelChip from 'components/App/Routes/Company/CompanyDetailsPage/SourceChannelChip';
import useAdminCompanyTargets from 'hooks/useAdminCompanyTargets';

export default function EmissionsDataTable({ jobId }: { jobId?: string }) {
  const { t } = useTranslation('common');

  const { isLoading, data, page, setPage } = useAdminCompanyTargets({
    jobId,
  });

  const columns: GridColDef[] = [
    { field: 'scope', headerName: t('Scope'), flex: 0, sortable: false },
    {
      field: 'framework',
      headerName: t('Framework'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'ambition',
      headerName: t('Ambition'),
      flex: 0,
      sortable: false,
    },
    {
      field: 'target_year',
      headerName: t('Target Year'),
      sortable: false,
      flex: 0,
    },
    {
      field: 'target_type',
      headerName: t('Target Type'),
      sortable: false,
      flex: 0,
    },
    {
      field: 'base_year',
      headerName: t('Base Year'),
      sortable: false,
      flex: 0,
    },
    {
      field: 'reduction',
      headerName: t('Reduction'),
      sortable: false,
      flex: 0,
      renderCell: (params) => (
        <div>
          {params.row &&
            typeof params.row.reduction === 'number' &&
            `${params.row.reduction}%`}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: t('Status'),
      sortable: false,
      flex: 0,
    },
    {
      field: 'publish_year',
      headerName: t('Publish Year'),
      flex: 0,
      sortable: false,
    },
    {
      field: 'source',
      headerName: t('Source'),
      sortable: false,
      flex: 0,
      renderCell: (params) => {
        return <SourceChannelChip channel={params.row.source_channel} />;
      },
    },
  ];

  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    // noRowsLabel: t('Data unavailable'),
  };

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        loading={isLoading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'reporting_year', sort: 'desc' }],
          },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        autoHeight
        localeText={localeText}
        sx={{ width: '100%' }}
        rows={data?.results || []}
        columns={columns}
        pagination
        hideFooter={!data || data?.count <= 10}
        rowCount={data?.count || 0}
        paginationMode="server"
        pageSizeOptions={[10]}
        paginationModel={{ page: page - 1, pageSize: 10 }}
        onPaginationModelChange={(model) => {
          setPage(model.page + 1);
        }}
      />
    </div>
  );
}

import cdp from './cdp.png';
import bnw from './bnw.png';
import baum from './baum.png';
import lfca from './lfca.png';

// ** Use for matching api call **
//Save img into the folder
//Import with short name
//Export the img under the relevant query value

export const iconSrcMap: any = {
  'CDP ACCREDITED SOLUTION PROVIDER': cdp,
  BNW: bnw,
  BAUM: baum,
  LFCA: lfca,
};

// ** Direct use with group export **
// import * as name from ''

// export { default as cdp } from './cdp.png';
// export { default as bnw } from './bnw.png';
// export { default as baum } from './baum.png';
// export { default as bcorp } from './b_corp.png';

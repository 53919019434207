import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useUserCompanyCustomers(params?: {
  page?: string;
  search?: string;
  rowsPerPage?: string;
}) {
  const auth = useAuth();
  return useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    queryKey: ['user-company-customers', params],
    queryFn: async () => {
      let searchParams = new URLSearchParams();

      searchParams.append('page', params?.page || '1');
      searchParams.append('search', params?.search || '');
      searchParams.append('page-size', params?.rowsPerPage || '100');
      const res = await axios.get(
        `${api.url}/user/company/customers/`,
        Object.assign(auth.requestConfig, { params: searchParams })
      );
      return res.data.results;
      // return [
      //   {
      //     name: 'joe company customer',
      //     id: '123',
      //     slug: 'joe-company-customer',
      //     data_access_permissions: false
      //   },
      // ];
    },
  });
}

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Box from 'components/common/Box';
import List from '@mui/material/List';
import ListItem, { listItemClasses } from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';
import colors from 'constants/colors';

import useAuth from 'hooks/useAuth';

function LinksTabWeb() {
  const { t } = useTranslation('common');
  const auth = useAuth();

  const MyNavLink = React.forwardRef((props: any, ref) => {
    return <NavLink ref={ref} {...props} activeclassname="active" />;
  });

  return (
    <Box sx={{ ml: 8 }}>
      <List
        sx={(theme) => ({
          ...theme.mixins.toolbar,
          padding: 0,
          display: 'flex',
          [`& .${listItemClasses.root}`]: {
            color:
              theme.palette.mode === 'dark'
                ? colors.white
                : colors.secondary500,
          },
          [`& .active`]: {
            color: colors.primary500,
            paddingTop: '10px',
            fontWeight: 900,
            borderBottom: `2px solid ${colors.primary500}`,
          },
        })}
      >
        {auth.hasDashboardPermission ? (
          <ListItem component={MyNavLink} key={1} to="/dashboard">
            <ListItemText primary={`${t('Dashboard')}`} />
          </ListItem>
        ) : null}
        {auth.hasUserPermission ? (
          <ListItem component={MyNavLink} key={3} to="/user/company/profile">
            <ListItemText
              primary={`${t('Company Profile')}`}
              sx={{ whiteSpace: 'nowrap' }}
            />
          </ListItem>
        ) : null}
        {auth.hasCompanyPermission ? (
          <ListItem component={MyNavLink} key={2} to="/companies/search">
            <ListItemText primary={`${t('Search')}`} />
          </ListItem>
        ) : null}
        {auth.hasCompanyPermission ? (
          <ListItem component={MyNavLink} key={4} to="/companies/watchlist">
            <ListItemText primary={`${t('Watchlist')}`} />
          </ListItem>
        ) : null}
      </List>
    </Box>
  );
}

export default observer(LinksTabWeb);

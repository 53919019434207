import colors from 'constants/colors';
import { PaletteOptions } from '@mui/material';

const tccPalette = {
  primary: {
    main: colors.primary500,
    hover: colors.primary300,
    hoverContained: colors.primary400,
    contrastText: colors.neutral100,
  },
  secondary: {
    main: colors.secondary500,
    hover: colors.secondary400,
  },
  neutral: {
    main: colors.neutral100,
    hover: colors.neutral200,
    contrastText: colors.neutral500,
  },
  onSurface: {
    100: colors.onSurface100,
  },
  info: {
    main: colors.neutral600,
  },
} as PaletteOptions;

export default tccPalette;

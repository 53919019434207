import React from 'react';

import Box from 'components/common/Box';
import Typography from 'components/common/Typography';

type Props = {
  title?: string | null;
  subtitle?: string | null;
};

function UserDashboardCRCHeader({ title, subtitle }: Props) {
  return (
    <Box>
      <Typography variant="h5" color="inherit">
        {title}
      </Typography>
      <Typography variant="body1" color="inherit" mt={'12px'}>
        {subtitle}
      </Typography>
    </Box>
  );
}

export default UserDashboardCRCHeader;

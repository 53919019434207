import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import DemoMockedData from '../../DemoMockedData/store/DemoMockedData';

import Grid from 'components/common/Grid/Grid';
import Card from 'components/common/Card';
import Typography from 'components/common/Typography/Typography';

import UserDashboardSuppliersColumn from '../../UserDashboardSuppliersColumn';

import colors from 'constants/colors';

function Component() {
  const { t } = useTranslation('common');

  const { targetCoverage, scopePerYearCount } = DemoMockedData();

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Typography variant="h8" color={colors.neutral600}>
          {t('Scope 1 Targets')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Scope 1 Targets Reported')}
            titleY={t('Companies Reporting Targets')}
            formater={''}
            groups={[{ title: t('Target Ambition'), cols: 5 }]}
            color={colors.warning100}
            data={[
              { x: 'Keine', y: targetCoverage('scope1').Keine },
              { x: '1.5˚C', y: targetCoverage('scope1')[`1.5°C `] },
              {
                x: 'Deutlich unter 2°C ("Well below 2°C")',
                y: targetCoverage('scope1')[
                  `Deutlich unter 2°C ("Well below 2°C")    `
                ],
              },
              { x: '2°C', y: targetCoverage('scope1')[`2°C `] },
              { x: 'Unsure', y: targetCoverage('scope1').Unsure },
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Near-term Target Years Reported for Scope 1')}
            titleY={t('Companies reporting Targets')}
            formater={''}
            groups={[{ title: t('Year'), cols: 4 }]}
            color={colors.warning100}
            data={scopePerYearCount('scope1_near_term_target_year')}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

export default observer(Component);

import React from 'react';

import Grid from 'components/common/Grid';

import CompanyName from './CompanyName/CompanyName';
import CompanyTags from './CompanyTags';
import CompanyShortDescription from './CompanyShortDescription/CompanyShortDescription';
import CompanyDetails from './CompanyDetails/CompanyDetails';
import ContactCard from './ContactCard/ContactCard';
import * as schema from 'types/backendSchema';

type Props = {
  companyData: schema.definitions['PublicCompany'];
};

function CompanyInfoBlock({ companyData }: Props) {
  return (
    <Grid
      item
      container
      mt={'20px'}
      mb={4}
      alignItems="center"
      justifyContent={'space-between'}
      wrap="wrap"
    >
      <Grid item container spacing={2} justifyContent="space-between">
        <Grid item xs>
          <CompanyName companyName={companyData.name} />
          <CompanyTags companyData={companyData} />
          <CompanyShortDescription
            shortDescription={companyData.short_description}
          />
          <CompanyDetails companyData={companyData} />
        </Grid>
        <Grid item xs={'auto'}>
          <ContactCard companyData={companyData} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CompanyInfoBlock;

import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';

import api from 'constants/api';
import useAuth from 'hooks/useAuth';
import Button from 'components/common/Button';
import { DownloadIcon } from 'components/icons/mui_icons';
import colors from 'constants/colors';
import { useTranslation } from 'react-i18next';
import Link from 'components/common/Link';

function Component({ id }: { id: string }) {
  const auth = useAuth();
  const { t } = useTranslation();
  return (
    <Link
      href={`${api.url}/admin/crc_surveys/${id}/answers_export/`}
      underline="none"
      sx={{
        width: '100%',
      }}
    >
      <Button
        variant="outlined"
        endIcon={<DownloadIcon />}
        sx={{
          color: colors.neutral600,
          height: '32px',
          width: '100%',
          textTransform: 'uppercase',
          borderColor: colors.neutral200,
          boxShadow: ' -12px 12px 16px -16px rgba(230, 235, 239, 1.5)',
        }}
      >
        {t('Export answers')}
      </Button>
    </Link>
  );
}

export default observer(Component);

import React from 'react';
import { observer } from 'mobx-react-lite';

import Grid from 'components/common/Grid';
import { DoneIcon } from 'components/icons/mui_icons/index';
import KeyFactsCard from './KeyFactsCard/KeyFactsCard';

type Props = {
  keyFacts: object;
};

function CompanyKeyFacts({ keyFacts }: Props) {
  return (
    <>
      {keyFacts && Object.keys(keyFacts).length !== 0 ? (
        <Grid item>
          <Grid item xs={11} container justifyContent="space-between">
            {Object.values(keyFacts).map((fact, key) => {
              return (
                <KeyFactsCard
                  key={key}
                  text={fact as string}
                  icon={<DoneIcon />}
                />
              );
            })}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

export default observer(CompanyKeyFacts);

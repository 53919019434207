import { decorate, action, observable, computed } from 'mobx';

export type ShortlistCompany = {
  id?: string;
  name?: string;
  slug?: string;
  address?: string;
  address2?: string;
  city?: string;
  zip_code?: string;
  country?: string;
  lei_code?: string;
  isin?: string;
  description?: string;
  short_description?: string;
  website?: string;
  sector?: string;
};

class Store {
  // OBSERVABLES................................................................
  consolidateCompanyId = '';
  screeningCompanyListEntryId = '';
  screeningCompanyListEntriesInvalid = false;
  shortlistScreeningCompany: ShortlistCompany = {};
  shortlistGLEIFCompany: ShortlistCompany = {};
  shortlistTCCCompany: ShortlistCompany = {};
  isReportingCompany = false;
  matchCompanyMode = false;
  reportingCompanyMode = false;

  // COMPUTEDS..................................................................
  get shortlist() {
    return [
      ...(this.shortlistScreeningCompany.id
        ? [
            {
              ...this.shortlistScreeningCompany,
              source: 'SCREENING',
            },
          ]
        : []),
      ...(this.shortlistTCCCompany.id
        ? [
            {
              ...this.shortlistTCCCompany,
              source: 'TCC',
            },
          ]
        : []),
      ...(this.shortlistGLEIFCompany.id
        ? [
            {
              ...this.shortlistGLEIFCompany,
              source: 'GLEIF',
            },
          ]
        : []),
    ];
  }
  // ACTIONS....................................................................
  setConsolidateCompanyId = (id: string) => {
    this.consolidateCompanyId = id;
  };

  setScreeningCompanyListEntryId = (id: string) => {
    this.screeningCompanyListEntryId = id;
  };

  setScreeningCompanyListEntriesInvalid = (bool: boolean) => {
    this.screeningCompanyListEntriesInvalid = bool;
  };

  setShortlistScreeningCompany = (company: ShortlistCompany) => {
    this.shortlistScreeningCompany = company;
  };
  setShortlistGLEIFCompany = (company: ShortlistCompany) => {
    this.shortlistGLEIFCompany = company;
  };
  setShortlistTCCCompany = (company: ShortlistCompany) => {
    this.shortlistTCCCompany = company;
  };

  setIsReportingCompany = (bool: boolean) => {
    this.isReportingCompany = bool;
  };

  toggleReportingCompanyMode = () => {
    this.reportingCompanyMode = !this.reportingCompanyMode;
    if (this.reportingCompanyMode) {
      this.matchCompanyMode = false;
    }
  };

  toggleMatchCompanyMode = () => {
    this.matchCompanyMode = !this.matchCompanyMode;
    if (this.matchCompanyMode) {
      this.reportingCompanyMode = false;
    }
  };
}

decorate(Store, {
  consolidateCompanyId: observable,
  screeningCompanyListEntryId: observable,
  screeningCompanyListEntriesInvalid: observable,
  shortlistScreeningCompany: observable,
  shortlistGLEIFCompany: observable,
  shortlistTCCCompany: observable,
  isReportingCompany: observable,
  matchCompanyMode: observable,
  reportingCompanyMode: observable,
  shortlist: computed,
  setConsolidateCompanyId: action,
  setScreeningCompanyListEntryId: action,
  setScreeningCompanyListEntriesInvalid: action,
  setShortlistScreeningCompany: action,
  setShortlistGLEIFCompany: action,
  setShortlistTCCCompany: action,
  setIsReportingCompany: action,
  toggleMatchCompanyMode: action,
  toggleReportingCompanyMode: action,
});

export default Store;

import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function useAssignConfirmationDialog() {
  const [open, setOpen] = useState(false);
  const [resolveCallback, setResolveCallback] =
    useState<(value: boolean) => void>();
  const [dialogText, setDialogText] = useState({ title: '', content: '' });

  const confirm = (title: string, content: string) => {
    setDialogText({ title, content });
    setOpen(true);
    return new Promise<boolean>((resolve) => {
      setResolveCallback(() => resolve);
    });
  };

  const handleClose = (value: boolean) => {
    if (resolveCallback) {
      resolveCallback(value);
    }
    setOpen(false);
  };

  const DialogComponent = (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>{dialogText.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClose(true)} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { confirm, DialogComponent };
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import api from 'constants/api';
import Loading from 'components/common/Loading';
// import Alert from '@mui/material/Alert';

import TagBlock from 'components/common/TagBlock/TagBlock';
import * as schema from 'types/backendSchema';
import useAuth from 'hooks/useAuth';

type Props = {
  companyData: schema.definitions['PublicCompany'];
};

function CompanyTags({ companyData }: Props) {
  const { t } = useTranslation('common');
  const auth = useAuth();

  const { isLoading, data, isError } = useQuery({
    queryKey: ['companies-sectors'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/sectors`,
        auth.requestConfig
      );
      return res.data;
    },
  });
  const sectors =
    companyData.sector && data
      ? {
          [companyData.sector]: data[companyData.sector],
        }
      : {};

  const companyDataPlusSectors = {
    ...companyData,
    sectors,
  };

  return (
    <Box mt={3}>
      {isLoading ? (
        <Loading color="secondary" />
      ) : isError ? // we can just show nothing if this fails to load // <Alert severity="error">{t('Something went wrong')}</Alert>
      undefined : (
        <>
          <TagBlock
            title={t('Products and Services:')}
            filterTag={'tcc_product_tags'}
            companyData={companyData}
          />
          <TagBlock
            title={t('Climate Focus:')}
            filterTag={'tcc_impact_tags'}
            companyData={companyData}
          />
          <TagBlock
            title={t('Sector') + ':'}
            filterTag={'sectors'}
            companyData={companyDataPlusSectors}
          />
        </>
      )}
    </Box>
  );
}

export default CompanyTags;

import React from 'react';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';
import Chip from 'components/common/Chip';
import Container from 'components/common/Container';

import Stack from 'components/common/Stack';
import ViewIcon from '@mui/icons-material/ArticleOutlined';
import useAdminCompanyJobs from 'hooks/useAdminCompanyJobs';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { getLanguagePreference } from 'utils/browser';
import { getDurationBetweenDates, getLengthOfTime } from 'utils/date';
import Box from 'components/common/Box';
import colors from 'constants/colors';

function JobSelect({
  value,
  onChange,
  multiselect,
  filterJobTypes,
}: {
  value: string[];
  onChange: (values: string[]) => void;
  multiselect?: boolean;
  filterJobTypes?: string[];
}) {
  const { t } = useTranslation();
  const [_, setSearchParams] = useSearchParams();
  const { companyId } = useParams();
  const [page, setPage] = React.useState(0);
  const { data, isLoading } = useAdminCompanyJobs({
    companyId,
    page: page + 1,
  });
  const rowCount = data?.count || 0;

  const rows =
    data && data.results
      ? data.results
          .filter((service: any) =>
            filterJobTypes ? filterJobTypes.includes(service.type) : true
          )
          .map((service: any) => ({
            id: service.id,
            type: service.type,
            file_name: service.inputs[0]?.message.file_name?.split('/')[2],
            status: service.status,
            created: service.created,
            modified: service.modified,
            inputs: service.inputs || [],
            outputs: service.outputs || [],
          }))
      : [];

  const columns: GridColDef[] = [
    {
      field: 'type',
      headerName: t('Type'),
      flex: 0.5,
      minWidth: 100,
      sortable: false,
    },
    {
      field: 'file_name',
      headerName: t('File name'),
      flex: 0.5,
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={params.row?.status}
          style={{
            borderRadius: 4,
            fontSize: '12px',
            color: colors.neutral600,
            height: '24px',
            backgroundColor:
              params.row?.status === 'PENDING'
                ? colors.warning100
                : params.row?.status === 'COMPLETED'
                ? colors.primary100
                : colors.neutral200,
          }}
        />
      ),
    },
    {
      field: 'modified',
      headerName: t('Last updated'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return getDurationBetweenDates(
          params.row?.modified,
          new Date().toUTCString()
        );
      },
    },
    {
      field: 'duration',
      headerName: t('Duration'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return getLengthOfTime(params.row?.created, params.row?.modified);
      },
    },
    {
      field: 'inputs',
      headerName: t('Input'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box>
            {params.row?.inputs.map((input: any) => (
              <Box key={input.id}>{JSON.stringify(input)}</Box>
            ))}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('Summary'),
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ViewIcon />}
          label={t('View')}
          onClick={() => setSearchParams({ job: String(params.id) })}
        />,
      ],
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('No data services.'),
  };

  return (
    <Container disableGutters maxWidth="xl">
      <Stack spacing={2}>
        <DataGrid
          loading={isLoading}
          checkboxSelection
          disableRowSelectionOnClick
          rowSelectionModel={value}
          onRowSelectionModelChange={(rowModel) => {
            if (multiselect) {
              onChange(rowModel as string[]);
            } else if (rowModel.length > 0) {
              onChange([rowModel[rowModel.length - 1] as string]);
            } else {
              onChange([]);
            }
          }}
          autoHeight
          rows={rows}
          columns={columns}
          rowCount={rowCount}
          pageSizeOptions={[100]}
          initialState={{
            sorting: {
              sortModel: [{ field: 'modified', sort: 'desc' }],
            },
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox': multiselect
              ? {}
              : {
                  opacity: 0,
                  pointerEvents: 'none',
                },
            '& .MuiDataGrid-cell': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .actions': {
              maxWidth: 'unset',
            },
          }}
          pagination
          paginationMode="server"
          onPaginationModelChange={(params: any) => setPage(params.page)}
        />
      </Stack>
    </Container>
  );
}

export default JobSelect;

import {
  format,
  formatDistance,
  intervalToDuration,
  formatDuration,
} from 'date-fns';
import i18n from 'i18n';

import { dateLocales } from 'i18n';

export const getLocalFormattedDate = (date: any) => {
  return date ? format(new Date(date), i18n.t('MM/dd/yyyy')) : '';
};

export const dateReadable = (date: string | undefined) => {
  if (date) {
    const event = new Date(date);
    return event.toLocaleDateString(i18n.language, {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  } else {
    return '';
  }
};

export const dateTimeReadable = (date: string | undefined) => {
  if (date) {
    const event = new Date(date);
    return event.toLocaleDateString(i18n.language, {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  } else {
    return '';
  }
};

export const getDurationBetweenDates = (
  startDate: string | number | Date,
  endDate: string | number | Date
) => {
  if (startDate && endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return formatDistance(start, end, {
      addSuffix: true,
      locale: dateLocales[i18n.language],
    });
  } else {
    return '';
  }
};

export const getLengthOfTime = (
  startDate: string | number | Date,
  endDate: string | number | Date
) => {
  if (startDate && endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const duration = intervalToDuration({ start, end });

    return formatDuration(duration, {
      delimiter: ', ',
      locale: dateLocales[i18n.language],
    });
  } else {
    return '';
  }
};

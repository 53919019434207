import { decorate, observable, action } from 'mobx';
import axios from 'axios';
import api from 'constants/api';

class CPASurveysStore {
  // OBSERVABLES................................................................
  count = 0;
  selectedCPASurvey = '';
  isLoadingCPASurveys = false;
  hasLoadedCPASurveys = false;
  cpasurveys = [];
  status = ['NEW'];

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  loadCPASurveys = async ({
    auth,
    page = 1,
    ids = [],
    status = ['NEW'],
    companyName = '',
  }) => {
    let params = new URLSearchParams();
    this.isLoadingCPASurveys = true;
    params.append('page', page);
    params.append('company_name', companyName);
    params.append('status', status);
    // for (let stat of status) {
    //   params.append('status', status);
    // }
    for (let id of ids) {
      params.append('id', id);
    }
    let defaultUrl = `${api.url}/admin/crc_surveys/`;
    try {
      const res = await axios.get(
        defaultUrl,
        Object.assign(auth.requestConfig, { params })
      );
      this.loadCPASurveysSuccess(res.data, auth);
    } catch (err) {
      this.loadCPASurveysFailure(err);
    }
  };

  setStatus = (status = ['NEW']) => {
    this.status = status;
  };

  loadCPASurveysSuccess = (data) => {
    this.count = data.count;
    this.cpasurveys.replace(data.results);
    this.isLoadingCPASurveys = false;
    this.hasLoadedCPASurveys = true;
  };

  loadCPASurveysFailure = (err) => {
    console.log(err);
    this.isLoadingCPASurveys = false;
    this.hasLoadedCPASurveys = true;
  };
}

decorate(CPASurveysStore, {
  isLoadingCPASurveys: observable,
  hasLoadedCPASurveys: observable,
  cpasurveys: observable.shallow,
  count: observable,
  status: observable,
  setStatus: action,
  loadCPASurveys: action,
  loadCPASurveysSuccess: action,
  loadCPASurveysFailure: action,
});

export default CPASurveysStore;

import React from 'react';
import MuiFab, { FabProps } from '@mui/material/Fab';

const Fab = React.forwardRef<HTMLButtonElement, FabProps>((props, ref) => {
  return (
    <MuiFab
      ref={ref}
      color={props.color}
      size={props.size}
      sx={props.sx}
      onClick={props.onClick}
    >
      {props.children}
    </MuiFab>
  );
});

export default Fab;

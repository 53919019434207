import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Tabs from 'components/common/Tabs';
import Tab from 'components/common/Tab';
import Container from 'components/common/Container';
import Divider from 'components/common/Divider';
import Box from 'components/common/Box';

import ClimateActionScore from './ClimateActionScore/ClimateActionScore';
import ClimateReadinessCheck from './ClimateReadinessCheck/ClimateReadinessCheck';
// import SeeMyMethodologyButton from './MethodologyButton/MethodologyButton';
import colors from 'constants/colors';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import api from 'constants/api';
import useAuth from 'hooks/useAuth';
import { getClimateScoreCategoryScores } from 'utils/tools';
import Typography from '../Typography';
import Stack from '../Stack';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export type CompanyCRCScoreProps = {
  id?: string;
  crcDate?: boolean;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CompanyCRCScore({ id, crcDate = true }: CompanyCRCScoreProps) {
  const { t } = useTranslation('common');
  const [value, setValue] = React.useState(0);
  const auth = useAuth();

  const { isLoading, data, isError } = useQuery({
    enabled: !!id,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['scorecard', id],
    retry: false,
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/scorecard/${id}`,
        auth.requestConfig
      );
      return res.data as {
        scorecard: { overall: number; categories: { score: number }[] };
      };
    },
  });

  const { decarbonizationMeasures, ...rest } = getClimateScoreCategoryScores(
    data?.scorecard
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container disableGutters maxWidth={'lg'}>
      <Stack spacing={2}>
        <Typography variant={'h5'}>{t('ClimateChoice Score')}</Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          indicatorColor="primary"
        >
          <Tab
            label={t('Overall')}
            {...a11yProps(0)}
            value={0}
            color={colors.neutral800}
            sx={{
              pl: { xs: 2, sm: 4, md: 6 },
              pr: { xs: 2, sm: 4, md: 6 },
              fontSize: '16px',
              fontWeight: '700',
              textTransform: 'none',
            }}
          />
          <Tab
            label={t('Decarbonization Measures')}
            value={1}
            {...a11yProps(1)}
            color={colors.neutral800}
            sx={{
              pl: { xs: 2, sm: 4, md: 6 },
              pr: { xs: 2, sm: 4, md: 6 },
              fontSize: '16px',
              fontWeight: '700',
              textTransform: 'none',
            }}
          />
        </Tabs>
      </Stack>
      <Divider />
      <Box p={3}>
        <TabPanel value={value} index={0}>
          <ClimateReadinessCheck
            overallScore={data?.scorecard.overall}
            {...rest}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ClimateActionScore climateActionsScore={decarbonizationMeasures} />
        </TabPanel>
      </Box>
    </Container>
  );
}

export default observer(CompanyCRCScore);

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useSignUp from 'hooks/useSignUp';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import api from 'constants/api';
import Alert from '@mui/material/Alert';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from 'components/common/Typography';
import Container from 'components/common/Container';
import Button from 'components/common/Button/Button';
import TextWithLinks from 'components/common/TextWithLinks';
import CardMedia from 'components/common/CardMedia';

import useAuth from 'hooks/useAuth';
import AppLoading from 'components/common/AppLoading/AppLoading';
import colors from 'constants/colors';

import { imageSrcMap } from 'media/crc_start_icon/index';

const ReferralStartPage = ({ buyerId }: { buyerId: string }) => {
  const { t } = useTranslation('common');
  const { setFormPage } = useSignUp();
  const auth = useAuth();

  const {
    isLoading,
    data: buyerCompany,
    isError,
  } = useQuery({
    queryKey: [`company-name-${buyerId}`],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/name/${buyerId}`,
        auth.requestConfig
      );
      return res.data as string;
    },
  });

  return (
    <Container maxWidth={'md'} pageContainer>
      {isLoading ? (
        <AppLoading />
      ) : isError ? (
        <Alert severity="error">{t('Something went wrong')}</Alert>
      ) : (
        <>
          <Container maxWidth={'sm'} disableGutters>
            <Typography
              mt={2}
              variant={'h4'}
              color={'black'}
              mb={4}
              textAlign="center"
            >
              {t('{{buyerCompany}} has invited you to connect.', {
                buyerCompany,
              })}
            </Typography>
          </Container>
          <Stack spacing={2}>
            <Typography
              variant="h5"
              sx={{ color: colors.neutral700 }}
              textAlign="center"
            >
              {t(
                `You have been requested to provide company data and answer some questions by your trading partner, {{buyerCompany}}. This process should take less than 10 minutes.`,
                {
                  buyerCompany,
                }
              )}
            </Typography>
            <Box textAlign="center">
              <TextWithLinks
                blankPage
                color={colors.neutral700}
                initialText={t(
                  `To begin, please click on the "Start" button below. You will be guided through a short onboarding process and some brief climate-related questions. Please provide accurate and up-to-date information. Please find {{link_start}}here the Supplier FAQ{{link_end}} for further information.`
                )}
                links={[t('https://hubs.ly/Q025564h0')]}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{ color: colors.neutral700 }}
              textAlign="center"
            >
              {t(
                `This procedure will create your company account and give you user-access to our Climate Intelligence Platform. This will enable you to change your answers and edit your data at any time in the future. Please note you will therefore be asked to verify your email address. We treat your data with the utmost confidentiality and security, and your climate-related information will only be shared with your trading partner.`
              )}
            </Typography>
            <Alert severity="info">
              <TextWithLinks
                color={colors.neutral700}
                initialText={t(
                  `If you already have an account on our platform, please contact our support team by sending an email to {{link_start}}support@theclimatechoice.com{{link_end}}. We are happy to help with any questions you may have.`
                )}
                links={[t('mailto:support@theclimatechoice.com')]}
              />
            </Alert>
            <Box pt={4}>
              <Container maxWidth={'xs'} disableGutters>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    setFormPage(1);
                  }}
                >
                  {t('Start')}
                </Button>
              </Container>
            </Box>
            <Box textAlign="center">
              <Container maxWidth={'xs'} disableGutters>
                <TextWithLinks
                  blankPage
                  color={colors.neutral700}
                  initialText={t(
                    `To learn more about our Global Supplier Intelligence solutions, please visit the link {{link_start}}here{{link_end}}.`
                  )}
                  links={[t('https://theclimatechoice.com/')]}
                />
              </Container>
            </Box>
            <Box textAlign="center" mt={2}>
              <CardMedia
                image={imageSrcMap.crcStartIcon}
                component="img"
                sx={{
                  // marginTop: '20px',
                  objectFit: 'contain',
                  height: '250px',
                  width: '250px',
                  m: 'auto',
                  mt: 4,
                  mb: 4,
                }}
              />
            </Box>
          </Stack>
        </>
      )}
    </Container>
  );
};

export default observer(ReferralStartPage);

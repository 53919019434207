import React, { useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';

import * as schema from 'types/backendSchema';

import Typography from 'components/common/Typography';

import { styled } from '@mui/system';
import colors from 'constants/colors';
import Box from 'components/common/Box';

const StyledDataGrid = styled(DataGrid)(`
.MuiDataGrid-row:nth-of-type(odd){
    background: white;
  }
  .MuiDataGrid-row:hover{
    background: ${colors.primary100};
  }
.MuiDataGrid-columnHeaders{
  background: ${colors.neutral200};
}
.MuiDataGrid-columnHeaderTitle{
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.MuiDataGrid-rowSeparator{
  display: 'none',
},
`);

type Props = {
  suppliers: schema.operations['user_company_suppliers_list']['responses'][200]['schema']['results'];
  suppliersCount: number | null;
  isLoading: boolean;
};

export default function UserDashboardSuppliersTable({
  suppliers,
  suppliersCount,
  isLoading = false,
}: Props) {
  const { t } = useTranslation('common');

  const [height, setHeight] = useState(340);

  useLayoutEffect(() => {
    setHeight(suppliers.length * 52 + 56);
  }, [suppliers.length]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: `${t('Supplier name')}`,
      width: 220,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const rows = suppliers.map((supplier, index) => {
    return {
      id: index,
      name: supplier.name,
    };
  });

  return (
    <Box>
      {suppliersCount !== null && suppliersCount !== 0 ? (
        <div style={{ height: `${height}px`, position: 'relative' }}>
          {isLoading ? (
            <LinearProgress
              color="primary"
              sx={{
                position: 'absolute',
                zIndex: 1000,
                width: '100%',
                top: '55px',
              }}
            />
          ) : null}
          <StyledDataGrid
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            hideFooter
            style={{ borderStyle: 'none' }}
          />
        </div>
      ) : (
        <Typography variant="subtitle1" color={colors.neutral600}>
          {t('0 results found')}
        </Typography>
      )}
    </Box>
  );
}

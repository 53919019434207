import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import useAuth from 'hooks/useAuth';
import useCompanies from 'hooks/useCompanies';

import CompaniesListData from '../CompanyListData/CompaniesListData';

const Component = () => {
  const auth = useAuth();

  const { companies } = useCompanies();

  const favorites = toJS(auth.favorites.slice());
  const rows =
    toJS(companies.slice().filter((c) => c.id && favorites.includes(c.id))) ||
    [];

  return <CompaniesListData rows={rows} />;
};

export default observer(Component);

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import DemoMockedData from './DemoMockedData/store/DemoMockedData';

import Grid from 'components/common/Grid/Grid';
import Card from 'components/common/Card';
import Typography from 'components/common/Typography/Typography';

import UserDashboardSuppliersColumn from './UserDashboardSuppliersColumn';

import Scope1 from './Scopes/Scope1';
import Scope2 from './Scopes/Scope2';
import Scope3 from './Scopes/Scope3';
import CarbonEfficiency from './CarbonEfficiency';
import ScopesTargets from './ScopesTargets';

import colors from 'constants/colors';

function Component() {
  const { t } = useTranslation('common');

  const { suppliersWithScopeEmissionsData } = DemoMockedData();

  return (
    <Grid container gap={2}>
      <Typography variant="h4" color="onSureface.100">
        {t('Supplier Analytics')}
      </Typography>
      <Grid item xs={12}>
        <Typography variant="h7" color="onSureface.100">
          {t('GHG Emissions')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Companies with GHG Protocol Data')}
            titleY={t('Companies with Data')}
            formater={''}
            groups={[{ title: t('Scope'), cols: 3 }]}
            color={colors.neutral400}
            data={[
              {
                x: t('Scope 1 - Emissions'),
                y: suppliersWithScopeEmissionsData('scope1'),
              },
              {
                x: t('Scope 2 - Emissions'),
                y: suppliersWithScopeEmissionsData('scope2'),
              },
              {
                x: t('Scope 3 - Emissions'),
                y: suppliersWithScopeEmissionsData('scope3'),
              },
            ]}
          />
        </Card>
      </Grid>
      <Scope1 />
      <Scope2 />
      <Scope3 />
      <CarbonEfficiency />
      <ScopesTargets />
    </Grid>
  );
}

export default observer(Component);

// import React from 'react'
import axios from 'axios';
import { observer } from 'mobx-react-lite';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import useAuth from 'hooks/useAuth';
import Grid from 'components/common/Grid';
import api from 'constants/api';
import Loading from 'components/common/Loading/Loading';

import EmissionsDataTable from './EmissionsDataTable';
import EmissionsDataGraph from './EmissionsDataGraph';
import i18n from 'i18n';
import Box from 'components/common/Box';
import Stack from 'components/common/Stack';

export type EmissionDataPoint = {
  id: number | string;
  scope: string;
  amount: string;
  reporting_year: number;
};

type Datapoint = {
  source: string;
  value: string;
  verified: null | boolean;
  reporting_year: number;
};

export type Variable = {
  variable: {
    caption: string;
    id: string;
    type: string;
    unit: string;
  };
  datapoints: Datapoint[];
};

const getPoints = (data: any): [EmissionDataPoint[], string] => {
  if (!data) return [[], ''];
  let unit = '';
  const points = data.map(
    ({ variable, datapoints }: { variable: any; datapoints: any }) => {
      const scope = variable.caption;
      unit = variable.unit;
      const valueMap = datapoints.map((point: any) => {
        return {
          id: Math.random(),
          scope,
          amount: Number(point.value)
            ? Number(point.value).toLocaleString(i18n.language)
            : point.value,
          reporting_year: point.reporting_year,
          source_channel: point.source_channel,
        };
      });
      return valueMap;
    }
  );
  let formattedData = [] as EmissionDataPoint[];
  points.forEach((datapoints: any) => {
    formattedData = formattedData.concat(datapoints);
  });
  return [formattedData, unit];
};

function Component({ slug }: { slug: string | undefined }) {
  const auth = useAuth();
  // const navigate = useNavigate()
  const { t } = useTranslation('common');
  const { isLoading, data, isError } = useQuery({
    queryKey: [`company-variables-${slug}`],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/variables/${slug}?starts_with=SCOPE_`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const [formattedData, unit] = getPoints(data);

  return (
    <Stack spacing={3}>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Alert severity="error">{t('There was a problem')}</Alert>
      ) : (
        <>
          <EmissionsDataGraph data={data} unit={unit} />
          <EmissionsDataTable data={formattedData} unit={unit} />
        </>
      )}
    </Stack>
  );
}

export default observer(Component);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Tabs from 'components/common/Tabs/';
import Tab from 'components/common/Tab';
import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';
import Divider from 'components/common/Divider';
import Box from 'components/common/Box';

import EmptyTextField from 'components/common/EmptyTextField';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={'div'} variant="subtitle1" color="plain">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type Props = {
  description: string;
};

function CompanyDescription({ description }: Props) {
  const { t } = useTranslation('common');
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid item xs={10}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          indicatorColor="primary"
        >
          <Tab
            label={t('Description')}
            {...a11yProps(0)}
            color="black"
            sx={{
              fontSize: '16px',
              fontWeight: '700',
              textTransform: 'none',
              width: '160px',
            }}
          />
          {/* <Tab
            label="Details"
            {...a11yProps(1)}
            color="text.primary"
            sx={{ fontWeight: '700', textTransform: 'none', width: '160px' }}
            disabled
          /> */}
        </Tabs>
        <Divider />
      </Box>

      <Box
        sx={{
          minHeight: '60px',
          overflowY: 'auto',
          // width: { xs: '380px', sm: '500px', md: '700px', lg: },
        }}
      >
        <TabPanel value={value} index={0}>
          {description ? (
            <Typography variant="body1" color="onSurface.100" mt={'22px'}>
              {description}
            </Typography>
          ) : (
            <EmptyTextField />
          )}
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          Consectetur mattis accumsan ut pretium mattis. Vitae commodo quis et
          vitae mollis suspendisse in. Id tortor sodales cras adipiscing ut duis
          faucibus. Tempor cras enim, interdum vitae. Urna ultrices fames eu
          amet, suscipit vitae netus risus. Tincidunt ac est vulputate mattis.
          Sed nisl amet, turpis quis. Nisl urna et ligula tellus. Venenatis diam
          elit gravida sed tortor in sit nibh. Ipsum consectetur iaculis odio
          lobortis a.
        </TabPanel> */}
      </Box>
    </Grid>
  );
}
export default observer(CompanyDescription);

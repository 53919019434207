import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import { useTranslation } from 'react-i18next';
import FilterIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import TccInputTextField from 'components/common/TccInputTextField/TccInputTextField';
import Stack from 'components/common/Stack';
import IconButton from 'components/common/IconButton/IconButton';

import useStore from 'components/App/Routes/Admin/Search/store/useStore';

import SearchFilters from './SearchFilters';

let debounceId: ReturnType<typeof setTimeout>;
const DEBOUNCE_INTERVAL = 300; // ms

const Search = () => {
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();
  const [searchText, setSearchText] = React.useState(
    searchParams.get('search') || ''
  );

  React.useEffect(() => {
    const param = searchParams.get('search');
    if (param) {
      setSearchText(param);
    }
  }, [setSearchText, searchParams]);

  const { filtersOpen, setFiltersOpen } = useStore();

  const searchCompanies = (string = '') => {
    setSearchText(string);
    clearTimeout(debounceId);
    debounceId = setTimeout(
      () =>
        navigateSearch({
          pathname: location.pathname,
          params: {
            search: string === '' ? [] : string,
          },
          replace: false,
        }),
      DEBOUNCE_INTERVAL
    );
  };

  return (
    <>
      <div>
        <TccInputTextField
          placeholder={t('Search companies')}
          value={searchText}
          onChange={(e) => searchCompanies(e.target.value)}
          startAdornment={
            <IconButton size="small">
              <SearchIcon />
            </IconButton>
          }
          endAdornment={
            <Stack direction="row">
              {searchText ? (
                <IconButton size="small" onClick={() => searchCompanies('')}>
                  <CloseIcon />
                </IconButton>
              ) : null}
              <IconButton
                size="small"
                onClick={() => setFiltersOpen(!filtersOpen)}
              >
                <FilterIcon />
              </IconButton>
            </Stack>
          }
        />
      </div>

      {filtersOpen ? <SearchFilters /> : null}
    </>
  );
};

export default observer(Search);

import React from 'react';
import Autocomplete from 'components/common/Autocomplete';
import TextField from 'components/common/TextField';
import colors from 'constants/colors';

type AutocompleteProps = {
  options: string[];
  onChange: (options: any) => void;
  value: string[];
  textField: string;
};

export default function FilterAutocomplete({
  options,
  onChange,
  value,
  textField,
}: AutocompleteProps) {
  return (
    <Autocomplete
      freeSolo
      size="small"
      multiple
      id="free-solo-demo"
      options={options}
      onChange={(e: any, options: any) => onChange(options)}
      value={value}
      renderTags={() => null}
      clearIcon={null}
      renderInput={(params: any) => (
        <TextField
          {...params}
          placeholder={textField}
          sx={{
            '& input::placeholder': { color: colors.neutral400 },
            backgroundColor: colors.neutral100,
          }}
        />
      )}
    />
  );
}

import { IconButton, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

import useStore from 'components/App/Routes/Admin/Screening/store/useStore';
import { observer } from 'mobx-react-lite';
import DialogTitle from 'components/common/DialogTitle';

import Checkbox from 'components/common/Checkbox/Checkbox';
import Typography from 'components/common/Typography/Typography';
import Stack from 'components/common/Stack/Stack';
import colors from 'constants/colors';

const Component = () => {
  const { t } = useTranslation('common');
  const {
    setConsolidateCompanyId,
    setIsReportingCompany,
    isReportingCompany,
    reportingCompanyMode,
  } = useStore();
  return (
    <DialogTitle variant="h4">
      <Toolbar disableGutters>
        <Stack>
          {t('Consolidate Company Data')}
          <Typography color={colors.neutral500} variant={'body1'}>
            {reportingCompanyMode
              ? t('Identify Reporting Company')
              : t('Identify Company')}
          </Typography>
        </Stack>
        <div style={{ flex: 1 }} />
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => setConsolidateCompanyId('')}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      {reportingCompanyMode ? null : (
        <Checkbox
          checked={isReportingCompany}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setIsReportingCompany(e.target.checked)
          }
          color="primary"
          label={`${t('Set as reporting company')}`}
          error={false}
          helperText={''}
        />
      )}
    </DialogTitle>
  );
};

export default observer(Component);

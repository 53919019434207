import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import DemoMockedData from '../../DemoMockedData/store/DemoMockedData';

import Grid from 'components/common/Grid/Grid';
import Card from 'components/common/Card';
import Typography from 'components/common/Typography/Typography';

import UserDashboardSuppliersPieChart from '../../UserDashboardSuppliersPieChart';
import UserDashboardSuppliersColumn from '../../UserDashboardSuppliersColumn';

import colors from 'constants/colors';

function Component() {
  const { t } = useTranslation('common');

  const {
    suppliersWithScopeEmissionsData,
    suppliersWithoutScopeEmissionsData,
    targetCoverage,
    addCategoryEmissionsKilotonne,
    scopePerYearCount,
    emissionsCountPerCountry,
  } = DemoMockedData();

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Typography variant="h8" color={colors.neutral600}>
          {t('Scope 3')}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Card>
          <UserDashboardSuppliersPieChart
            title={t('Scope 3 - Corporate Carbon Footprint Emissions')}
            labels={[t('Yes'), t('No')]}
            colors={[colors.secondary300, colors.tertiary500]}
            data={[
              suppliersWithScopeEmissionsData('scope3'),
              suppliersWithoutScopeEmissionsData('scope3'),
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={8}>
        <Card>
          <UserDashboardSuppliersPieChart
            title={t('Scope 3 Target Coverage')}
            labels={['Keine', '1.5˚C', 'Unsure']}
            colors={[
              colors.secondary300,
              colors.tertiary500,
              colors.primary300,
              colors.secondary100,
            ]}
            data={[
              targetCoverage('scope3').Keine,
              targetCoverage('scope3')[`1.5°C `],
              targetCoverage('scope3').Unsure,
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Reported Scope 3 Upstream Category Emissions')}
            titleY={t('GHG Emissions(metrics kilotonne CO2)')}
            formater={''}
            groups={[{ title: 'Scope 3 Category', cols: 8 }]}
            color={colors.primary400}
            data={[
              {
                x: '1: Eingekaufte Güter und Dienstleistungen',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_1_Upstream_Goods_and_Services'
                ),
              },
              {
                x: '2: Kapitalgüter',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_2_Upstream_Capital_Goods'
                ),
              },
              {
                x: '3: Brennstoff- und energiebezogene Emissionen',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_3_Upstream_Fuel_and_Energy'
                ),
              },
              {
                x: '4: Transport und Logistik (vorgelagert)',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_4_Upstream_Transportation'
                ),
              },
              {
                x: '5: Abfall (am Standort)',
                y: addCategoryEmissionsKilotonne('Scope3_5_Upstream_Waste'),
              },
              {
                x: '6: Geschäftsreisen',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_6_Upstream_Business_Travel'
                ),
              },
              {
                x: '7: Pendeln der Arbeitnehmer:innen',
                y: addCategoryEmissionsKilotonne('Scope3_7_Upstream_Commuting'),
              },
              {
                x: '8: Angemietete oder geleaste Sachanlagen',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_8_Upstream_Leased_Assets'
                ),
              },
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Reported Scope 3 Downstream Category Emissions')}
            titleY={t('GHG Emissions(metrics kilotonne CO2)')}
            formater={''}
            groups={[{ title: t('Scope 3 Category'), cols: 8 }]}
            color={colors.primary400}
            data={[
              {
                x: '09: Transport und Logistik (nachgelagert)',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_9_Downstream_Transportation'
                ),
              },
              {
                x: '10: Verarbeitung der verkauften Güter',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_10_Downstream_Sold_Products_Processing'
                ),
              },
              {
                x: '11: Nutzung der verkauften Güter',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_11_Downstream_Sold_Products_Use'
                ),
              },
              {
                x: '12: Umgang mit verkauften Gütern an deren Lebenszyklusende',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_12_Downstream_Sold_Products_EndofLife'
                ),
              },
              {
                x: '13: Vermietete oder verleaste Sachanlagen',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_13_Downstream_Leased_Assets'
                ),
              },
              {
                x: '14: Franchise',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_14_Downstream_Franchises'
                ),
              },
              {
                x: '15: Investitionen',
                y: addCategoryEmissionsKilotonne(
                  'Scope3_15_Downstream_Investments'
                ),
              },
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Reporting Years for Scope 3')}
            titleY={t('Companies reporting for Scope 3')}
            formater={''}
            groups={[{ title: t('Year'), cols: 2 }]}
            color={colors.warning500}
            data={scopePerYearCount('scope3_reporting_year')}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Scope 3 Emissions per Country')}
            titleY={t('GHG Emissions(metrics kilotonne CO2)')}
            formater={''}
            groups={[{ title: t('Country'), cols: 4 }]}
            color={colors.secondary300}
            data={emissionsCountPerCountry('scope3')}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

export default observer(Component);

import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';

import CertificationsCard from './CertificationsCard/CertificationsCard';
import { iconSrcMap } from 'media/certifications_logos/index';
import EmptyTextField from 'components/common/EmptyTextField';

const isDataValid = (filterObj: any) => {
  if (
    filterObj &&
    filterObj !== undefined &&
    Object.entries(filterObj).length !== 0 &&
    !Object.values(filterObj).includes(undefined) &&
    !Object.values(filterObj).includes('')
  ) {
    return true;
  } else {
    return false;
  }
};

const CompanyCertifications = ({ data }: any) => {
  const { t } = useTranslation('common');

  return (
    <Grid item>
      <Typography variant="h6" color="onSurface.100">
        {t('Other')}
      </Typography>
      {isDataValid(data) ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '30px' }}>
          {Object.entries(data).map((arrayObj, key) => {
            const queryValue = arrayObj[0];
            const iconSrc = iconSrcMap[queryValue];

            return (
              <CertificationsCard
                key={key}
                value={queryValue}
                iconSrc={iconSrc}
              />
            );
          })}
        </div>
      ) : (
        <EmptyTextField />
      )}
    </Grid>
  );
};

export default CompanyCertifications;

// import React from 'react'
import DashboardFunnel from 'components/common/DashboardFunnel';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';

function Component() {
  const { t } = useTranslation();
  const auth = useAuth();
  const { isLoading, data, isError } = useQuery({
    queryKey: ['supplier-screening-onboarding-funnel'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/funnel/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <DashboardFunnel
      textTitle={t('Screening Stage')}
      valueTitle={t('Number of Suppliers')}
      data={[
        { text: t('Identified Suppliers'), value: data.companies_signed_up },
        { text: t('User signed up'), value: data.users_signed_up },
        { text: t('Email verified'), value: data.email_verified },
        { text: t('Survey started'), value: data.survey_started },
        { text: t('Survey completed'), value: data.survey_completed },
      ]}
    />
  );
}

export default observer(Component);

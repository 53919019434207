import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';

export default function useAdminCompanyCollection(companyId?: string) {
  const auth = useAuth();
  return useQuery({
    enabled: !!companyId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // refetchInterval: 5000,
    // cacheTime: 0,
    staleTime: 5000,
    queryKey: ['admin-company-collection', { companyId }],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/admin/company/collections/?company=${companyId}`,
        auth.requestConfig
      );
      return res.data;
    },
  });
}

export function useAdminCompanyCollectionRecordComplete(recordId?: string) {
  const auth = useAuth();
  return useMutation({
    mutationKey: ['mutate-complete', { recordId }],
    mutationFn: async ({ onSuccess }: { onSuccess?: () => void }) => {
      if (!recordId) return;
      const res = await axios.post(
        `${api.url}/admin/company/collections/collection_record/${recordId}/completed/`,
        {},
        auth.requestConfig
      );
      if (onSuccess) {
        onSuccess();
      }
      return res.data;
    },
  });
}

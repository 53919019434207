import React from 'react';
import { observer } from 'mobx-react-lite';

import { useParams } from 'react-router-dom';
import useSignUp from 'hooks/useSignUp';
import useAuth from 'hooks/useAuth';

import SignUpForm0 from './SignUpForm0';
import SignUpForm1 from './SignUpForm1';
import SignUpForm2 from './SignUpForm2';
import SignupSuccess from './SignupSuccess';
import ReferralStartPage from './ReferralStartPage';

const SignUp = () => {
  const auth = useAuth();

  const { buyerId } = useParams();

  const { setBuyerID, hasSubmitted, setFormPage, formPage } = useSignUp();

  React.useEffect(() => {
    if (buyerId) {
      setBuyerID(buyerId);
    }
  }, [auth, hasSubmitted, buyerId, setBuyerID, setFormPage]);

  return formPage === 0 && buyerId ? (
    <ReferralStartPage buyerId={buyerId} />
  ) : formPage === 0 ? (
    <SignUpForm0 />
  ) : formPage === 1 ? (
    <SignUpForm1 />
  ) : formPage === 2 ? (
    <SignUpForm2 />
  ) : formPage === 3 ? (
    <SignupSuccess />
  ) : null;
};

export default observer(SignUp);

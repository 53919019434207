import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Card from 'components/common/Card';
import Button from 'components/common/Button';
// import Stack from 'components/App/Company/CompanyDetailsPage/layout/Stack';
import AlertTooltip from 'components/common/AlertTooltip';
// import CardMedia from 'components/App/Company/CompanyDetailsPage/common/CardMedia';
// import { dashboardImages } from 'media/user_dashboard';
import { BubbleChartSharpIcon } from 'components/icons/mui_icons';
import colors from 'constants/colors';

import RFIDialog from './RFIDialog';

type Props = {
  id?: string;
  companyName?: string;
  userDetails: object;
  rowDirection?: boolean;
};

function UserDashboardCustomersES({
  id,
  companyName,
  userDetails,
  rowDirection = false,
}: Props) {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const setOpenRFI = useCallback(() => {
    setOpen(!open);
    return true;
  }, [open]);

  return (
    <Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: rowDirection ? 'row' : 'column',
            gap: rowDirection ? 10 : 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <Typography variant="h5" color={'inherit'}>
              {t('Customer Relationships')}
            </Typography>
            <AlertTooltip
              text={t('Learn more about customer relationships.')}
              tooltipText={t(
                'See the status of customer Supplier Climate Performance projects your company is involved in.'
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <Button variant={'contained'} onClick={setOpenRFI}>
              {t('Invite your customers')}
            </Button>
            {/* <Stack>
              <CardMedia
                image={dashboardImages.suppliers}
                component="img"
                sx={{
                  marginTop: '20px',
                  objectFit: 'contain',
                  height: '133px',
                  width: '120px',
                }}
              />
            </Stack> */}
          </Box>
          <RFIDialog
            open={open}
            closeDialog={setOpenRFI}
            identifier={id}
            companyName={companyName}
            userDetails={userDetails}
          />
        </Box>

        {!rowDirection ? (
          <BubbleChartSharpIcon
            sx={{
              width: '34px',
              height: '34px',
              color: colors.warning500,
              backgroundColor: colors.warning100,
              p: '8px',
              borderRadius: '4px',
            }}
          />
        ) : null}
      </Box>
    </Card>
  );
}

export default observer(UserDashboardCustomersES);

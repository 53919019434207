import React from 'react';
import { observer } from 'mobx-react-lite';

import Box from 'components/common/Box';
import Avatar from 'components/common/Avatar';
import TccPublishChip from 'components/common/TccPublishChip';
import colors from 'constants/colors';
import { useNavigate } from 'react-router-dom';

type Props = {
  companyName: string;
  logo: string;
  isAdmin: boolean;
  published: boolean;
  companySlug: string;
  userCompanySlug: string;
  isUserCompany?: boolean;
  hideChip?: boolean;
};

function CompanyAvatar({
  companyName,
  logo,
  isAdmin,
  published,
  companySlug,
  userCompanySlug,
  isUserCompany,
  hideChip,
}: Props) {
  const navigate = useNavigate();
  function stringAvatar(name: string = '') {
    if (name.includes(' ')) {
      return {
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    } else return { children: name.slice(0, 2) };
  }

  return (
    <Box
      onClick={
        isUserCompany
          ? () => navigate('/user/company/settings/edit')
          : () => false
      }
      sx={{
        cursor: isUserCompany ? 'pointer' : 'default',
      }}
    >
      <Avatar
        src={logo}
        alt=""
        {...stringAvatar(companyName)}
        sx={{
          width: 140,
          height: 140,
          '& .MuiAvatar-img': {
            objectFit: 'contain',
          },
          fontSize: '48px',
          fontWeight: 900,
          color: 'neutral.contrastText',
          backgroundColor: colors.neutral100,
          border: '0.1px solid onSurface.100',
        }}
      />
      {hideChip ? null : (
        <Box sx={{ position: 'relative', marginTop: '-28px', zIndex: 1 }}>
          <TccPublishChip
            isAdmin={isAdmin}
            published={published}
            companySlug={companySlug}
            userCompanySlug={userCompanySlug}
            visibleIcon={false}
          />
        </Box>
      )}
    </Box>
  );
}

export default observer(CompanyAvatar);

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Collapse from 'components/common/Collapse';
import ListItem, { listItemClasses } from '@mui/material/ListItem';
import Chip from 'components/common/Chip';

import Divider from 'components/common/Divider';
import IconButton from 'components/common/IconButton';
import Drawer from 'components/common/Drawer';
import DrawerHeader from 'components/common/DrawerHeader';
import Avatar from 'components/common/Avatar';

import {
  ListItemIcon,
  ListItemText,
  GridViewSharpIcon,
  LeaderboardSharpIcon,
  MediationSharpIcon,
  BubbleChartSharpIcon,
} from 'components/icons/mui_icons';

import colors from 'constants/colors';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import useUserCompany from 'hooks/useUserCompany';
import { observer } from 'mobx-react-lite';

const MyNavLink = React.forwardRef((props: any, ref) => {
  return (
    <NavLink
      style={{ whiteSpace: 'normal' }}
      ref={ref}
      {...props}
      activeclassname="active"
    />
  );
});

const UserDashboardDrawer = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const [open, setOpen] = React.useState(true);
  const { userCompany } = useUserCompany();

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    if (isSm) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isSm, setOpen]);

  const myCompanyExpanded = true;
  // const [myCompanyExpanded, setMyCompanyExpanded] = React.useState(true);

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const handleLinkClick = () => {
    if (isSm) {
      setOpen(false);
    }
  };

  function stringAvatar(name: string) {
    if (name.includes(' ')) {
      return {
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    } else return { children: name.slice(0, 2) };
  }

  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        style: {
          left: 0,
          right: 'unset',
          position: 'relative',
          borderStyle: 'none',
        },
      }}
      ModalProps={{
        style: { position: 'relative' },
      }}
    >
      <DrawerHeader sx={{ mr: '4px' }}>
        <IconButton onClick={handleDrawerClose}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List
        sx={{
          p: 0,
          [`& .${listItemClasses.root}`]: {
            color: 'black',
          },
          [`& .active, & .${listItemClasses.root}:hover`]: {
            color: colors.primary500,
            fontWeight: 900,
            '& svg': {
              fill: 'white',
              backgroundColor: colors.primary500,
            },
          },
        }}
      >
        <ListItem
          component={MyNavLink}
          key={2}
          to="my-company"
          onClick={handleLinkClick}
        >
          <ListItemIcon>
            {
              <LeaderboardSharpIcon
                sx={{
                  width: '34px',
                  height: '34px',
                  color: colors.primary500,
                  backgroundColor: colors.primary100,
                  p: '8px',
                  borderRadius: '4px',
                }}
              />
            }
          </ListItemIcon>
          <ListItemText primary={t('My Company')} />
        </ListItem>

        <div>
          <Collapse in={myCompanyExpanded} timeout="auto" unmountOnExit>
            <List component="div" sx={{ ml: '56px' }}>
              <ListItem
                component={MyNavLink}
                key={1}
                to="my-company/overview"
                onClick={handleLinkClick}
              >
                <ListItemText primary={<>{t('Overview')}</>} />
              </ListItem>
              <ListItem
                component={MyNavLink}
                key={2}
                to="my-company/collections"
                onClick={handleLinkClick}
              >
                <ListItemText primary={<>{t('Collections')}</>} />
              </ListItem>
            </List>
          </Collapse>
        </div>
        <Divider />

        <ListItem
          component={MyNavLink}
          key={4}
          to="my-suppliers"
          onClick={handleLinkClick}
        >
          <ListItemIcon>
            <MediationSharpIcon
              sx={{
                width: '34px',
                height: '34px',
                color: colors.primary500,
                backgroundColor: colors.primary100,
                p: '8px',
                borderRadius: '4px',
              }}
            />
          </ListItemIcon>
          <ListItemText primary={t('My Suppliers')} />
        </ListItem>
        <div>
          <Collapse in={myCompanyExpanded} timeout="auto" unmountOnExit>
            <List component="div" sx={{ ml: '56px' }}>
              <ListItem
                component={MyNavLink}
                key={1}
                to={'my-suppliers/list'}
                onClick={handleLinkClick}
              >
                <ListItemText primary={t('Supplier List')} />
              </ListItem>

              <ListItem
                component={MyNavLink}
                key={2}
                to={'my-suppliers/screening'}
                onClick={handleLinkClick}
              >
                <ListItemText primary={t('Supplier Maturity')} />
              </ListItem>

              <ListItem
                component={MyNavLink}
                key={3}
                to={'my-suppliers/analytics'}
                onClick={handleLinkClick}
              >
                <ListItemText
                  primary={
                    <>
                      {t('Supplier Analytics')}
                      <Chip sx={{ ml: 1 }} size="small" label="Beta" />
                    </>
                  }
                />
              </ListItem>

              {auth.userDetails.is_staff ? (
                <ListItem
                  component={MyNavLink}
                  key={4}
                  to={'my-suppliers/cpa-analytics'}
                  onClick={handleLinkClick}
                >
                  <ListItemText
                    primary={
                      <>
                        CPA Analytics
                        <Chip sx={{ ml: 1 }} size="small" label="Admin" />
                      </>
                    }
                  />
                </ListItem>
              ) : null}
            </List>
          </Collapse>
        </div>
        <Divider />
        <ListItem
          component={MyNavLink}
          key={5}
          to="my-customers"
          onClick={handleLinkClick}
        >
          <ListItemIcon>
            {
              <BubbleChartSharpIcon
                sx={{
                  width: '34px',
                  height: '34px',
                  color: colors.primary500,
                  backgroundColor: colors.primary100,
                  p: '8px',
                  borderRadius: '4px',
                }}
              />
            }
          </ListItemIcon>
          <ListItemText primary={t('My Customers')} />
        </ListItem>
      </List>
      <Divider />
      <ListItem
        component={MyNavLink}
        key={6}
        to="/user/company/settings/edit"
        onClick={handleLinkClick}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          mt={4}
          onClick={handleLinkClick}
        >
          <Avatar
            sx={{
              width: '35px',
              height: '35px',
            }}
            src={userCompany.logo}
            alt={userCompany.name}
            {...(userCompany.name && !userCompany.logo
              ? stringAvatar(userCompany.name)
              : {})}
          />

          <Typography
            color={'black'}
            fontSize="16px"
            fontWeight={'normal'}
            width={'164px'}
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {userCompany.name}
          </Typography>
        </Stack>
      </ListItem>
    </Drawer>
  );
};

export default observer(UserDashboardDrawer);

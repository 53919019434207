import { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import AppLoading from 'components/common/AppLoading';

import useLogout from './store/useLogout';
import useAuth from 'hooks/useAuth';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import useUserCompany from 'hooks/useUserCompany';

const Logout = () => {
  const { logout } = useLogout();
  const snackbarMessage = useSnackbarMessage();
  const { removeUserCompany } = useUserCompany();
  const auth = useAuth();

  const logoutAsync = useCallback(async () => {
    await logout({ auth, snackbarMessage });
    // clear cached user info so it is refetched next login
    removeUserCompany();
  }, [logout, auth, snackbarMessage, removeUserCompany]);

  useEffect(() => {
    logoutAsync();
  }, [logoutAsync]);

  return <AppLoading />;
};

export default observer(Logout);

// import React from 'react'
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import DashboardLineChart from 'components/common/DashboardLineChart';

import Stack from 'components/common/Stack';

function Component() {
  const { t } = useTranslation();
  const auth = useAuth();
  const { isLoading, data, isError } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['supplier-screening-scope-1-2-emission_intensities'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/emission_intensities/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const distinctYears: Set<number> = new Set();
  if (data) {
    data.forEach((company: any) => {
      company.emission_intensities.forEach((emission: any) => {
        distinctYears.add(emission.reporting_year);
      });
    });
  }

  const years = Array.from(distinctYears).sort();

  const series = data
    ? data.map((c: any) => ({
        name: c.company_name,
        data: years.map((year) => {
          return {
            x: [year],
            y:
              c.emission_intensities.find(
                (ce: any) => ce.reporting_year === year
              )?.intensity_amount || null,
          };
        }),
      }))
    : [];

  return isLoading ? (
    <Loading color="primary" />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <Stack spacing={2}>
      <DashboardLineChart
        yaxisTitle={t('Amount (t CO₂e / million EUR)')}
        categories={years}
        series={series}
      />
    </Stack>
  );
}

export default observer(Component);

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';
import SourceChannelChip from '../../SourceChannelChip';

import { useTranslation } from 'react-i18next';
import EmptyTextField from 'components/common/EmptyTextField';
import { getLanguagePreference } from 'utils/browser';
import Typography from 'components/common/Typography';

import { EmissionDataPoint } from '../Component';
import { Box } from '@mui/material';

export default function EmissionsDataTable({
  data,
  unit,
}: {
  data: EmissionDataPoint[];
  unit?: string;
}) {
  const { t } = useTranslation('common');
  const rows = data;
  const columns: GridColDef[] = [
    { field: 'reporting_year', headerName: t('Year'), flex: 0, sortable: true },
    { field: 'scope', headerName: t('Scope'), flex: 1, sortable: false },
    {
      field: 'amount',
      headerName: t('Amount') + ` (${unit})`,
      flex: 1,
      sortable: false,
    },
    {
      field: 'source',
      headerName: t('Source'),
      sortable: false,
      flex: 0,
      renderCell: (params) => {
        return <SourceChannelChip channel={params.row.source_channel} />;
      },
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('Data unavailable'),
  };

  return !data ? (
    <EmptyTextField text={t('Data unavailable') + ''} />
  ) : (
    <Box>
      <div style={{ width: '100%' }}>
        <Typography variant="h5" color="onSurface.100">
          {t('GHG Emissions')}
        </Typography>
        <Typography variant="body1" color="inherit" mb={2} mt={'12px'}>
          {t('All Data')}
        </Typography>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'reporting_year', sort: 'desc' }],
            },
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50]}
          pagination
          autoHeight
          localeText={localeText}
          sx={{ width: '100%' }}
          rows={rows}
          columns={columns}
          hideFooter={rows.length <= 10}
        />
      </div>
    </Box>
  );
}

// import React from 'react'
import DashboardPieChart from 'components/common/DashboardPieChart';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import colors from 'constants/colors';
import CDPScores from 'constants/CDPScores';

function Component({ countOnly }: { countOnly?: boolean }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const { isLoading, data, isError } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['latest-cdp-score-distribution'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/cdp_scores/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const scoresReversed = CDPScores.slice().reverse();

  return isLoading ? (
    <Loading color={'primary'} />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : countOnly ? (
    <Typography variant="h1" color={colors.neutral600}>
      {data.level_1 + data.level_2 + data.level_3}
    </Typography>
  ) : (
    <DashboardPieChart
      labels={scoresReversed}
      data={scoresReversed.map(
        (score) => data.filter((c: any) => c.cdp_score.score === score).length
      )}
      colors={[
        colors.tertiary800,
        colors.tertiary500,
        colors.neutral800,
        colors.neutral600,
        colors.neutral400,
        colors.secondary300,
        colors.secondary500,
        colors.primary300,
        colors.primary500,
      ].reverse()}
    />
  );
}

export default observer(Component);

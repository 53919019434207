import { decorate, observable, action, computed, toJS } from 'mobx';
import axios from 'axios';
import api from 'constants/api';
import FormStore from 'state/FormStore';
import i18n from 'i18n';

class CPASurveyStore {
  constructor() {
    this.form = new FormStore({
      defaults: {
        note: '',
        review: [],
        result: {},
      },
    });
  }
  // OBSERVABLES................................................................
  hasReviewUpdates = false;
  hasEdits = false;
  flags = new Map();
  edits = new Map();
  notes = new Map();
  isLoadingSurvey = '';
  hasLoadedSurvey = false;
  isPatchingSurveyData = '';
  cpasurveyData = null;

  // COMPUTEDS..................................................................
  get surveyQuestions() {
    return this.cpasurveyData && this.cpasurveyData && this.cpasurveyData.result
      ? Object.values(this.cpasurveyData.result)
      : [];
  }

  get final() {
    return !!this.cpasurveyData && this.cpasurveyData.status === 'FINALIZED';
  }

  // ACTIONS....................................................................
  loadSurveyData = async ({ auth, cpasurveyId }) => {
    this.hasReviewUpdates = false;
    this.hasEdits = false;
    this.cpasurveyData = null;
    this.flags.clear();
    this.notes.clear();
    this.edits.clear();
    this.form.resetValues();
    this.isLoadingSurvey = cpasurveyId;
    try {
      const res = await axios.get(
        `${api.url}/admin/crc_surveys/${cpasurveyId}/`,
        auth.requestConfig
      );
      this.loadSurveyDataSuccess(res.data);
    } catch (err) {
      this.loadDataFailure(err);
    }
  };

  loadSurveyDataSuccess = (data) => {
    this.cpasurveyData = Object.assign({}, data);
    this.form.resetValues({
      note: data.note,
      review: data.review ? data.review : [],
      result: data ? data.result : {},
    });
    if (data.review) {
      for (let reviewItem of data.review) {
        this.flags.set(reviewItem.id, !reviewItem.resolved);
        this.notes.set(reviewItem.id, reviewItem.note);
      }
    }
    this.isLoadingSurvey = '';
    this.hasLoadedSurvey = true;
  };

  loadDataFailure = (err) => {
    console.log(err);
    this.isLoadingSurvey = '';
    this.hasLoadedSurvey = true;
    this.cpasurveyData = {};
  };

  patchSurvey = async ({
    auth,
    navigateSearch,
    goBack,
    snackbarMessage,
    cpasurveyId,
    status = 'IN_REVIEW',
  }) => {
    this.isPatchingSurveyData = cpasurveyId;
    try {
      const res = await axios.patch(
        `${api.url}/admin/crc_surveys/${cpasurveyId}/`,
        {
          note: this.form.values.get('note'),
          review: this.form.values.get('review'),
          result: toJS(this.form.values.get('result')),
          status,
        },
        auth.requestConfig
      );
      this.patchSurveySuccess(res.data, navigateSearch, goBack);
      if (snackbarMessage) {
        snackbarMessage.addMessage({
          message: i18n.t('Update success.'),
        });
      }
    } catch (err) {
      this.patchSurveyFailure(err);
      if (snackbarMessage) {
        snackbarMessage.addMessage({
          message: i18n.t('There was a problem'),
        });
      }
    }
  };

  patchSurveySuccess = (data, navigateSearch, goBack) => {
    this.hasReviewUpdates = false;
    this.hasEdits = false;
    this.form.resetValues({
      note: data.note,
      review: data.review,
      result: data.result,
    });

    for (let reviewItem of data.review) {
      this.flags.set(reviewItem.id, !reviewItem.resolved);
      this.notes.set(reviewItem.id, reviewItem.note);
    }
    this.isPatchingSurveyData = '';
    if (goBack) {
      goBack();
    } else {
      navigateSearch({
        pathname: '/cpa-surveys',
        params: {
          page: 1,
        },
        replace: true,
      });
    }
  };

  patchSurveyFailure = (err) => {
    console.log(err);
    this.isPatchingSurveyData = '';
  };

  flagQuestion = (id) => {
    this.flags.set(id, !this.flags.get(id));
    this.updateReview(id);
  };
  saveNote = (id, note) => {
    this.notes.set(id, note);
    this.updateReview(id);
  };
  updateReview = (id) => {
    this.hasReviewUpdates = true;
    const currentReview = this.form.values.get('review');
    const update = {
      id,
      note: this.notes.get(id),
      resolved: !this.flags.get(id),
    };
    if (currentReview.find((r) => r.id === id)) {
      this.form.values.set(
        'review',
        currentReview.map((r) => (r.id === id ? update : r))
      );
    } else {
      this.form.values.set('review', [update].concat(currentReview));
    }
  };
  saveEdit = (id, value) => {
    this.hasEdits = true;
    this.edits.set(id, value);
    const result = toJS(this.form.values.get('result'));
    const cursors = id.split('_');
    const questionIdToChange = cursors[0];
    const resultIndexToChange = cursors[1];
    const resultValues = Object.values(result);
    const resultObjectIndex = resultValues.findIndex(
      (r) => r.id === questionIdToChange
    );
    result[resultObjectIndex].results[resultIndexToChange] = value;
    this.form.setValue('result', result);
  };
  clearCpasurveyData = () => {
    this.cpasurveyData = {};
    this.isLoadingSurvey = '';
    this.hasLoadedSurvey = false;
  };
}

decorate(CPASurveyStore, {
  cpasurveyData: observable.shallow,
  hasEdits: observable,
  hasReviewUpdates: observable,
  flags: observable,
  notes: observable,
  edits: observable,
  isLoadingSurvey: observable,
  hasLoadedSurvey: observable,
  isPatchingSurveyData: observable,
  useTccFilter: observable,
  surveyQuestions: computed,
  final: computed,
  loadDataFailure: action,
  loadSurveyData: action,
  loadSurveyDataSuccess: action,
  patchSurvey: action,
  patchSurveySuccess: action,
  patchSurveyFailure: action,
  flagQuestion: action,
  saveNote: action,
  updateReview: action,
  saveEdit: action,
  clearCpasurveyData: action,
});

export default CPASurveyStore;

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material//Alert';

import DataPoint from './DataPoint';

const UserCompanyData = () => {
  const { t } = useTranslation('common');
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h5">
          {t('Emissions and Intensity Data Points')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Alert severity="info">
          {t(
            'Climate transparency is essential. Provide here your most recent company data.'
          )}
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <DataPoint
          variable="BASE_YEAR"
          title={t('Emissions Base Years')}
          unit={t('year')}
        />
      </Grid>
      <Grid item xs={12}>
        <DataPoint
          variable="SCOPE_1"
          title={t('Scope 1 Emissions')}
          unit="t CO₂e"
        />
      </Grid>
      <Grid item xs={12}>
        <DataPoint
          variable="SCOPE_2"
          title={t('Scope 2 Emissions')}
          unit="t CO₂e"
        />
      </Grid>
      <Grid item xs={12}>
        <DataPoint
          variable="SCOPE_2_LOCATION_BASED"
          title={t('Scope 2 (Location-Based) Emissions')}
          unit="t CO₂e"
        />
      </Grid>
      <Grid item xs={12}>
        <DataPoint
          variable="SCOPE_2_MARKET_BASED"
          title={t('Scope 2 (Market-Based) Emissions')}
          unit="t CO₂e"
        />
      </Grid>
      <Grid item xs={12}>
        <DataPoint
          variable="SCOPE_3"
          title={t('Scope 3 Emissions')}
          unit="t CO₂e"
        />
      </Grid>
      <Grid item xs={12}>
        <DataPoint variable="TURNOVER_AMOUNT" title={t('Net-Revenue Amount')} />
      </Grid>
      <Grid item xs={12}>
        <DataPoint
          variable="TURNOVER_CURRENCY"
          title={t('Net-Revenue Currency')}
        />
      </Grid>
      <Grid item xs={12}>
        <DataPoint variable="EMPLOYEE_COUNT" title={t('Employee Count')} />
      </Grid>
    </Grid>
  );
};

export default observer(UserCompanyData);

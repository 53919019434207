import DashboardLineChart from 'components/common/DashboardLineChart';
import DashboardBarChart from 'components/common/DashboardBarChart';
import { useTranslation } from 'react-i18next';

import { Variable } from '../Component';
import { Grid } from '@mui/material';
import React from 'react';
import SelectMenu from 'components/common/SelectMenu';
import colors from 'constants/colors';
import Typography from 'components/common/Typography';
import Box from 'components/common/Box';

const visibleVariableIds = [
  'SCOPE_1',
  'SCOPE_2',
  'SCOPE_2_LOCATION_BASED',
  'SCOPE_2_MARKET_BASED',
  'SCOPE_3',
];

function getUniqueYears(data: Variable[]): number[] {
  let years: number[] = [];
  data.forEach((item) => {
    item.datapoints.forEach((datapoint) => {
      if (!years.includes(datapoint.reporting_year)) {
        years.push(datapoint.reporting_year);
      }
    });
  });
  return years.sort();
}

export default function EmissionsDataGraph({
  data,
  unit,
}: {
  data: Variable[];
  unit?: string;
}) {
  const { t } = useTranslation('common');
  const visibleData = data.filter(
    ({ variable, datapoints }) =>
      datapoints.length > 0 && visibleVariableIds.includes(variable.id)
  );
  const uniqueVisibleYears = getUniqueYears(visibleData);
  const visibleSeries = visibleData.map((data) => ({
    name: data.variable.caption,
    data: uniqueVisibleYears.map((year) => {
      const datapoint = data.datapoints.find(
        (datapoint) => datapoint.reporting_year === year
      );
      return {
        x: [year],
        y: datapoint !== undefined ? datapoint.value : null,
      };
    }),
  }));

  const visibleEasterEggThreshold = 2;
  const hasEnoughVisibleDatapoints =
    visibleSeries.findIndex(
      (scopeSeries) =>
        scopeSeries.data.filter((d) => d.y !== null).length >=
        visibleEasterEggThreshold
    ) > -1;

  const breakdownData = data.filter(
    ({ variable }) => !visibleVariableIds.includes(variable.id)
  );
  const uniqueBreakdownYears = getUniqueYears(breakdownData);
  const [breakdownYear, setBreakdownYear] = React.useState(
    uniqueBreakdownYears[uniqueBreakdownYears.length - 1]
  );
  const breakdownSeries = breakdownData.map((data) => ({
    name: data.variable.caption,
    data: uniqueBreakdownYears.map((year) => {
      const datapoint = data.datapoints.find(
        (datapoint) => datapoint.reporting_year === year
      );
      return {
        x: year,
        y: datapoint !== undefined ? datapoint.value : null,
      };
    }),
  }));
  const breakdownEasterEggThreshold = 1;
  const hasEnoughBreakdownData =
    breakdownSeries.findIndex(
      (scopeSeries) =>
        scopeSeries.data.filter((d) => d.y !== null).length >=
        breakdownEasterEggThreshold
    ) > -1;

  const breakdownSeriesBySelectedYear = breakdownSeries
    .filter(
      (data) => data.data.findIndex((d) => d.x === Number(breakdownYear)) > -1
    )
    .map((data) => ({
      x: data.name,
      y: Number(data.data.find((d) => d.x === Number(breakdownYear))?.y),
    }));

  return hasEnoughVisibleDatapoints ? (
    <Box>
      <Grid container spacing={2}>
        {data.length !== 0 &&
        (hasEnoughVisibleDatapoints || hasEnoughBreakdownData) ? (
          <Grid item xs={12}>
            <Typography variant="h5" color="onSurface.100">
              {t('GHG Emissions')}
            </Typography>
          </Grid>
        ) : null}
        {data.length !== 0 && hasEnoughVisibleDatapoints ? (
          <Grid item xs={12}>
            <DashboardLineChart
              yaxisTitle={t('Amount ({{unit}})', { unit })}
              categories={uniqueVisibleYears}
              series={visibleSeries}
            />
          </Grid>
        ) : null}
        {data.length !== 0 && hasEnoughBreakdownData ? (
          <>
            <Grid item xs={4} mb={-3}>
              <Typography variant="body1" color="inherit" mb={1}>
                {t('Scope 3 Breakdown')}
              </Typography>
              <SelectMenu
                items={uniqueBreakdownYears}
                handleChange={(e: any) => {
                  setBreakdownYear(e.target.value);
                }}
                value={breakdownYear + ''}
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardBarChart
                horizontal
                seriesLabel={t('Amount ({{unit}})', { unit })}
                xaxisTitle={t('Amount ({{unit}})', { unit })}
                data={breakdownSeriesBySelectedYear}
                height={breakdownSeriesBySelectedYear.length * 50 + 50}
                barColors={[colors.neutral400]}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </Box>
  ) : null;
}

// import React from 'react'
import Typography from '@mui/material/Typography';
import colors from 'constants/colors';
import { observer } from 'mobx-react-lite';
// import { useNavigate } from 'react-router-dom'
// import { useTranslation } from 'react-i18next';

// import useAuth from 'hooks/useAuth'

type LegendItem = { title: string; description: string };
function Component({ items }: { items: LegendItem[] }) {
  // const auth = useAuth()
  // const navigate = useNavigate()
  // const { t } = useTranslation('common');
  return (
    <table>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <td
              width="30%"
              style={{ paddingRight: '8px', verticalAlign: 'top' }}
            >
              <Typography
                variant="subtitle2"
                sx={{ color: colors.neutral400, fontStyle: 'italic' }}
              >
                {item.title}
              </Typography>
            </td>
            <td>
              <Typography variant="subtitle2" sx={{ color: colors.neutral600 }}>
                {item.description}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default observer(Component);

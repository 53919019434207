// import React from 'react'
import { Typography } from '@mui/material';
import colors from 'constants/colors';
import { observer } from 'mobx-react-lite';
// import { useNavigate } from 'react-router-dom'
// import { useTranslation } from 'react-i18next';

// import useAuth from 'hooks/useAuth'

function Component({
  text,
  noPadding = false,
}: {
  text: string;
  noPadding?: boolean;
}) {
  // const auth = useAuth()
  // const navigate = useNavigate()
  // const { t } = useTranslation('common');
  return (
    <Typography
      pt={noPadding ? 0 : { xs: 1, sm: 2 }}
      ml={noPadding ? 0 : '22px'}
      variant="body1"
      sx={{ fontSize: '24px', color: colors.neutral400 }}
    >
      {text}
    </Typography>
  );
}

export default observer(Component);

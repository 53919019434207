import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useSignUp from 'hooks/useSignUp';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Container from 'components/common/Container';
import TccCheckbox from 'components/common/TccCheckbox';
import Typography from 'components/common/Typography';
import Button from 'components/common/Button/Button';
import LinearStepper from 'components/common/LinearStepper';

// import useAuth from 'hooks/useAuth';

import { phCapture } from 'utils/posthog';

const SignUpForm0 = () => {
  const { t } = useTranslation('common');
  const { setFormPage } = useSignUp();
  // const auth = useAuth();

  const [decarbonizeCompany, setDecarbonizeCompany] = React.useState(false);
  const [decarbonizeSupply, setDecarbonizeSupply] = React.useState(false);
  const [other, setOther] = React.useState(false);

  const options = [
    {
      text: t(`Decarbonize my company.`),
      value: 'intention-decarbonize-company',
      checked: decarbonizeCompany,
      setChecked: setDecarbonizeCompany,
    },
    {
      text: t('Decarbonize my supply chain.'),
      value: 'intention-decarbonize-supply-chain',
      checked: decarbonizeSupply,
      setChecked: setDecarbonizeSupply,
    },
    {
      text: t('I have something else in mind.'),
      value: 'intention-other',
      checked: other,
      setChecked: setOther,
    },
  ];

  return (
    <Container pageContainer maxWidth={'sm'} sx={{ textAlign: 'center' }}>
      <LinearStepper
        steps={[t('Registration Sign up'), t('Climate Readiness Check')]}
        activeStep={0}
        maxWidth={400}
      />
      <Typography
        mt={4}
        variant={'h4'}
        color={'black'}
        mb={4}
        textAlign="center"
      >
        {t(`What can we help you with?`)}
      </Typography>
      <Box sx={{ display: 'inline-flex' }} mb={4}>
        <Stack direction={'column'}>
          {options.map((option, i) => (
            <FormControlLabel
              key={i}
              control={
                <TccCheckbox
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => {
                    option.setChecked(checked);
                  }}
                  checked={option.checked}
                />
              }
              sx={{ alignItems: 'center' }}
              label={option.text}
            />
          ))}
        </Stack>
      </Box>
      <Box>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            options.forEach((option) => {
              if (option.checked) {
                phCapture(option.value);
              }
            });
            setFormPage(1);
          }}
        >
          {t('Next')}
        </Button>
      </Box>
    </Container>
  );
};

export default observer(SignUpForm0);

import { observer } from 'mobx-react-lite';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import useUserCompany from 'hooks/useUserCompany';

import Grid from 'components/common/Grid/Grid';
import Typography from 'components/common/Typography/Typography';

import UserDashboardMonSuppliersES from 'components/App/Routes/Dashboard/MySuppliers/UserDashboardMonSuppliersES';
import SuppliersInviteCard from 'components/common/SuppliersInviteCard';
import DashboardItems from './DashboardItems';
import Box from 'components/common/Box';
import Chip from 'components/common/Chip';
import Stack from 'components/common/Stack';

function Component() {
  const auth = useAuth();
  const { t } = useTranslation('common');

  const { userCompany } = useUserCompany();

  //FIXME - this is until the backend updates the /user/company/
  //@ts-ignore
  const { suppliers_count, supplier_invite_link } = userCompany;

  return (
    <Grid container spacing={{ xs: 2, sm: 3 }}>
      <Grid item xs={12}>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography variant="h4" color="onSureface.100">
            {t('Supplier Analytics')}
          </Typography>
          <Chip sx={{ ml: 1 }} size="large" label="Beta" />
        </Stack>
      </Grid>

      {suppliers_count && suppliers_count > 0 ? (
        <Grid item xs={12}>
          <Box pb={4}>
            <DashboardItems />
          </Box>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <SuppliersInviteCard
          title={t('Invite Your Suppliers')}
          suppliersInvitationLink={supplier_invite_link}
        />
      </Grid>
      {suppliers_count === 0 ? (
        <Grid item xs={12}>
          <UserDashboardMonSuppliersES
            id={(auth.userDetails as any).id}
            companyName={(auth.userDetails as any).company}
            userDetails={auth.userDetails}
            rowDirection={true}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default observer(Component);

import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button';
import Link from 'components/common/Link';
//FIXME styled comp

function SDGsInfoButton() {
  const { t } = useTranslation('common');
  return (
    <Link
      href="https://sdgs.un.org/"
      target="_blank"
      rel="noopener"
      underline="none"
    >
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{
          mt: 4,
        }}
      >
        {t('Find out more about SDGs')}
      </Button>
    </Link>
  );
}

export default SDGsInfoButton;

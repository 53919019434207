import { decorate, observable, action } from 'mobx';

class PreferencesStore {
  // OBSERVABLES................................................................
  darkMode = false;

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  setDarkMode = (bool: boolean) => {
    this.darkMode = bool;
  };
}

decorate(PreferencesStore, {
  darkMode: observable,
  setDarkMode: action,
});

export default PreferencesStore;

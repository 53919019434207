// import React from 'react'
import DashboardPieChart from 'components/common/DashboardPieChart';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import colors from 'constants/colors';

function Component({ countOnly }: { countOnly?: boolean }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const { isLoading, data, isError } = useQuery({
    queryKey: ['supplier-screening-ccf-quantified-scopes'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/survey_responses/ccf_quantified_scopes/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : countOnly ? (
    <Typography variant="h1" color={colors.neutral600}>
      {data.level_1 + data.level_2 + data.level_3}
    </Typography>
  ) : (
    <DashboardPieChart
      labels={[
        t('Scope 1 only'),
        t('Scope 2 only'),
        t('Scope 3 only'),
        t('Scope 1 & 2'),
        t('Scope 1 & 3'),
        t('Scope 2 & 3'),
        t('Scope 1 & 2 & 3'),
      ]}
      data={[
        data['Scope 1'],
        data['Scope 2'],
        data['Scope 3'],
        data['Scope 1, 2'],
        data['Scope 1, 3'],
        data['Scope 2, 3'],
        data['Scope 1, 2, 3'],
      ]}
      colors={[
        colors.warning100,
        colors.neutral400,
        colors.secondary500,
        colors.warning500,
        colors.neutral200,
        colors.primary300,
        colors.primary500,
      ]}
    />
  );
}

export default observer(Component);

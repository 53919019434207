import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Container from 'components/common/Container';

import Stack from 'components/common/Stack';
import colors from 'constants/colors';
import { useTranslation } from 'react-i18next';
import CompanyDataServicesList from './CompanyDataServicesList';

function CompanyDataServices() {
  const { t } = useTranslation();
  return (
    <Container disableGutters maxWidth="xl">
      <Stack spacing={2}>
        <Typography
          variant="body1"
          sx={{ fontSize: '24px', color: colors.neutral400 }}
        >
          {t('Data services log')}
        </Typography>
        <CompanyDataServicesList />
      </Stack>
    </Container>
  );
}

export default CompanyDataServices;

import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';

import { useTranslation } from 'react-i18next';
import { getLanguagePreference } from 'utils/browser';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

import useStore from 'components/App/Routes/Admin/Screening/store/useStore';
import useSearchStore from 'components/App/Routes/Admin/Search/store/useStore';
import CompanyShortlistToolbar from './CompanyShortlistToolbar';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Chip } from '@mui/material';
import { getCountryName } from 'utils/getCountryName';

const ShortlistCompanyListTable = ({ data }: { data: any[] }) => {
  const { t } = useTranslation('common');
  const {
    setShortlistGLEIFCompany,
    setShortlistTCCCompany,
    setShortlistScreeningCompany,
  } = useStore();
  const { setSelectedCompany, setSelectedGLEIFCompany } = useSearchStore();
  const navigate = useNavigate();

  const handleDeleteClick = (id: GridRowId) => async () => {
    const company = data.find((d) => d.id === id);
    if (company.source === 'TCC') {
      setShortlistTCCCompany({});
      setSelectedCompany({});
    }
    if (company.source === 'GLEIF') {
      setShortlistGLEIFCompany({});
      setSelectedGLEIFCompany({});
    }
    if (company.source === 'SCREENING') {
      setShortlistScreeningCompany({});
    }
  };

  const rows = data;
  const columns: GridColDef[] = [
    {
      field: 'source',
      headerName: t('Source'),
      flex: 0,
      minWidth: 120,
      sortable: false,
      renderCell: (params) => <Chip label={params.row?.source} />,
    },

    {
      field: 'name',
      headerName: t('Name'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={params.row?.name}
          onClick={
            params.row?.slug
              ? () => navigate(`/company/${params.row?.slug}`)
              : () => false
          }
        >
          {params.row?.name}
        </div>
      ),
    },
    {
      field: 'website',
      headerName: t('Website'),
      width: 180,
      sortable: false,
      renderCell: (params) => (
        <a
          style={{
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={params.row?.website}
          href={
            params.row?.website?.includes('http')
              ? params.row?.website
              : 'http://' + params.row?.website
          }
          target="_blank"
          rel="noreferrer"
        >
          {params.row?.website}
        </a>
      ),
    },
    {
      field: 'lei_code',
      headerName: t('Company LEI code'),
      flex: 0,
      minWidth: 210,
      sortable: false,
    },
    {
      field: 'country',
      headerName: t('Country'),
      flex: 0,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={getCountryName(params.row?.country)}
        >
          {getCountryName(params.row?.country)}
        </div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 40,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<PlaylistRemoveIcon />}
            label={t('Delete')}
            onClick={handleDeleteClick(id)}
          />,
        ];
      },
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('Shortlist empty'),
  };

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
        autoHeight
        localeText={localeText}
        rows={rows}
        columns={columns}
        hideFooter
        disableRowSelectionOnClick
        slots={{
          toolbar: CompanyShortlistToolbar,
        }}
      />
    </div>
  );
};

export default observer(ShortlistCompanyListTable);

import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import Loading from 'components/common/Loading';
import SelectMenu from 'components/common/SelectMenu';

import api from 'constants/api';
import useAuth from 'hooks/useAuth';

const Component = ({
  required,
  title,
  onChange,
  value,
}: {
  required?: boolean;
  title?: string;
  onChange: (currency: string) => void;
  value?: string;
}) => {
  const { t } = useTranslation('common');
  const auth = useAuth();

  const { isLoading, data, isError } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['companies-currencies'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/currencies`,
        auth.requestConfig
      );
      return res.data as string[];
    },
  });

  return isLoading ? (
    <Loading />
  ) : isError || !data ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <SelectMenu
      required={required}
      items={data}
      title={title}
      handleChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    />
  );
};

export default Component;

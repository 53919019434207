import React from 'react';
import { Link } from 'react-router-dom';

import Stack from 'components/common/Stack';
import Avatar from 'components/common/Avatar';
import TccTooltipText from 'components/common/TccTooltipText';

import colors from 'constants/colors';

type Props = {
  params: any;
};

export default function CompanyTitleCell({ params }: Props) {
  function stringAvatar(name: string) {
    if (name?.includes(' ')) {
      return {
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    } else return { children: name?.slice(0, 2) };
  }

  const stack = (
    <Stack direction="row" alignItems="center" spacing={1}>
      {' '}
      <Avatar
        src={(params as any).logo}
        alt=""
        {...stringAvatar((params as any).value)}
        sx={{
          width: 30,
          height: 30,
          fontSize: '12px',
          fontWeight: 900,
          p: '8px',
          color: 'neutral.contrastText',
          backgroundColor: colors.neutral200,
          border: '0.1px solid onSurface.100',
        }}
      />
      <TccTooltipText text={params.value} />
    </Stack>
  );

  if (!params.row.supplierSlug) {
    return stack;
  }

  return (
    <Link
      to={`/company/${params.row.supplierSlug}`}
      style={{ textDecoration: 'none', height: '100%', display: 'flex' }}
    >
      {stack}
    </Link>
  );
}

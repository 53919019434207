import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import api from 'constants/api';
import useAuth from 'hooks/useAuth';

import Form from './Form';
import useStore from 'components/App/Routes/Admin/Search/store/useStore';
import { LinearProgress } from '@mui/material';
import colors from 'constants/colors';
import useAdminCompany from 'hooks/useAdminCompany';

export type Company = {
  id: string;
  name: string;
  country: string;
  slug: string;
};

const Component = ({
  id,
  onSuccess,
  diffId,
}: {
  id: string;
  onSuccess?: () => void;
  diffId?: string;
}) => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const { getCompanyForm } = useStore();
  const form = getCompanyForm();
  const diffForm = getCompanyForm();

  const { isLoading, data, isError, isFetched, refetch, isRefetching } =
    useAdminCompany(id);

  const {
    isLoading: isLoadingDiff,
    data: dataDiff,
    isError: isErrorDiff,
    isFetched: isFetchedDiff,
    isRefetching: isRefetchingDiff,
  } = useAdminCompany(diffId);

  if (isFetched) {
    form.resetValues(data);
  }

  if (isFetchedDiff) {
    diffForm.resetValues(dataDiff);
  }

  const onFormSuccess = () => {
    refetch();
    if (onSuccess) {
      onSuccess();
    }
  };

  return isLoading ? (
    <LinearProgress color="primary" />
  ) : (
    <>
      <div style={{ display: 'block', color: colors.white }}>
        {isRefetching || isRefetchingDiff ? (
          <LinearProgress color="primary" />
        ) : (
          <LinearProgress color="inherit" variant="determinate" value={100} />
        )}
      </div>
      <Form
        form={form}
        diffForm={diffId ? diffForm : undefined}
        disabled={isLoading}
        onClose={() => form.resetValues(data)}
        onSuccess={onFormSuccess}
      />
      {isError || isErrorDiff ? (
        <Alert severity="error">{t('There was a problem')}</Alert>
      ) : null}
    </>
  );
};

export default Component;

// import React from 'react'
import DashboardBarChart from 'components/common/DashboardBarChart';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import colors from 'constants/colors';

// {
//   "has_pcf_and_less_than_3_scopes": int,
//   "has_pcf_and_no_ccf": int,
//   "has_pcf_and_3_scopes": int,
//   "no_pcf": int,
// }

function Component({ countOnly }: { countOnly?: boolean }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const { isLoading, data, isError } = useQuery({
    queryKey: ['supplier-screening-cf-distro'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/cf_distribution/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : countOnly ? (
    <Typography variant="h1" color={colors.neutral600}>
      {data.level_1 + data.level_2 + data.level_3}
    </Typography>
  ) : (
    <DashboardBarChart
      seriesLabel={t('Number of Suppliers')}
      horizontal
      data={[
        {
          x: t('Has a PCF but does not have a CCF covering all 3 Scopes'),
          y: data.has_pcf_and_less_than_3_scopes,
        },
        {
          x: t('Has a PCF but does not have a CCF'),
          y: data.has_pcf_and_no_ccf,
        },
        {
          x: t('Has a PCF and a CCF covering all 3 Scopes'),
          y: data.has_pcf_and_3_scopes,
        },
        { x: t('Does not have a PCF'), y: data.no_pcf },
      ]}
    />
  );
}

export default observer(Component);

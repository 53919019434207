import { observer } from 'mobx-react-lite';

import Typography from 'components/common/Typography/Typography';

import colors from 'constants/colors';

type Props = {
  totalNum: number;
  postFix?: string;
  textColor?: string;
};

function Component({
  totalNum,
  postFix,
  textColor = colors.neutral700,
}: Props) {
  return (
    <Typography variant="h1" color={textColor}>
      {totalNum}
      {postFix}
    </Typography>
  );
}

export default observer(Component);

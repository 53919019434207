import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';

import supportedLanguages from 'constants/supportedLanguages';
import { getLanguagePreference } from 'utils/browser';

import countriesI18n from 'i18n-iso-countries';

// this is not ideal, but I couldn't find a good way to import these locales...
// would be super awesome if we could provide them similar to the translation
// json from this i18next-xhr-backend - not sure if this is possible - Ideally
// we have one place where we add lang codes: /src/constants/supportedLanguages
import { enUS as en, de } from 'date-fns/locale';
import { Locale } from 'date-fns';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const dateFnsLocaleImports: { [key: string]: Locale } = { en, de };

export const dateLocales = supportedLanguages.reduce(
  (locales: { [key: string]: Locale }, lang) => {
    let locale = dateFnsLocaleImports[lang] as Locale;
    if (!locale) {
      locale = dateFnsLocaleImports['en'];
      console.error(`Missing ${lang} date-fns locale`);
    }
    locales[lang] = locale;
    return locales;
  },
  {}
);

supportedLanguages.forEach((lang: string) => {
  countriesI18n.registerLocale(
    require(`i18n-iso-countries/langs/${lang}.json`)
  );
});

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: getLanguagePreference(),
    fallbackLng: false,
    ns: 'common',
    defaultNS: 'common',
    fallbackNS: 'common',
    supportedLngs: supportedLanguages, // <- we only support the ones that we extract
    nonExplicitSupportedLngs: false,
    returnEmptyString: false,
    returnNull: false,
    nsSeparator: ':::',
    keySeparator: false,
    interpolation: { escapeValue: false },
    react: {
      useSuspense: true,
    },
    // debug: true,
  });

export default i18n;

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import useAuth from 'hooks/useAuth';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import useChangePasswordStore from './store/useChangePasswordStore';
import useNavigateSearch from 'hooks/useNavigateSearchParams';
import { useParams } from 'react-router-dom';

import Form from 'components/common/Form';
import UnauthenticatedContainer from 'components/common/UnauthenticatedContainer';
import Grid from 'components/common/Grid';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from 'components/common/Typography';

const ChangePassword = ({ type = 'change' }) => {
  const auth = useAuth();
  const navigateSearch = useNavigateSearch();
  const snackbarMessage = useSnackbarMessage();
  const {
    onChangePasswordSubmit,
    isSubmitting,
    form,
    passwordChanged,
    invalid,
    tokenInvalid,
    passwordErrorMessage,
  } = useChangePasswordStore();
  const { t } = useTranslation('common');
  const { uid, token } = useParams();

  const onSubmit = () =>
    onChangePasswordSubmit({ type, auth, uid, token, snackbarMessage });
  return (
    <UnauthenticatedContainer
      title={type === 'change' ? t('Change password') : t('Set password')}
      maxWidth="xs"
      subtitle={
        passwordChanged
          ? t('Your password has been set.')
          : t('Please enter your new password.')
      }
    >
      {passwordChanged ? (
        <Box display="flex" justifyContent="center">
          <Button
            onClick={() =>
              navigateSearch({
                pathname: '/',
                params: {
                  page: 1,
                },
                replace: true,
              })
            }
            color="secondary"
            disabled={isSubmitting || form.hasErrors}
          >
            {t('Login')}
          </Button>
        </Box>
      ) : (
        <Form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                required
                fullWidth
                id="changePassword-password"
                label={t('New password')}
                type="password"
                name="password"
                autoFocus
                value={form.values.get('password')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setValue('password', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required
                fullWidth
                id="changePassword-password_match"
                label={t('Repeat new password')}
                type="password"
                name="password_match"
                value={form.values.get('password_match')}
                error={form.errors.has('password_match')}
                helperText={form.errors.get('password_match')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setValue('password_match', e.target.value)
                }
                onBlur={() => form.checkFieldError('password_match')}
              />
            </Grid>
            {invalid ? (
              <Grid item xs={12}>
                <FormHelperText error>
                  {passwordErrorMessage || t('Something went wrong.')}
                </FormHelperText>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant={'caption2'} color={'neutral.contrastText'}>
                  {t(
                    `Your password must contain at least 10 characters, consisting of both letters and numbers. It should also not contain your name, email or other identifiable details.`
                  )}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} />
            <Grid item xs={12}>
              {tokenInvalid ? (
                <Button
                  fullWidth
                  type="submit"
                  variant="text"
                  onClick={() =>
                    navigateSearch({
                      pathname: '/forget',
                      params: {},
                      replace: true,
                    })
                  }
                  disabled={isSubmitting || form.hasErrors}
                >
                  {t('Request new password link')}
                </Button>
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || form.hasErrors || tokenInvalid}
                >
                  {t('Submit')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </UnauthenticatedContainer>
  );
};

export default observer(ChangePassword);

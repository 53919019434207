import supportedLanguages from 'constants/supportedLanguages';

export const scrollToTop = () => window.scrollTo(0, 0);

export const getScreenSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

const langTwoLetters = (props, lang) => {
  if (props !== undefined && props.twoLetter) {
    return lang.slice(0, 2);
  } else {
    return lang;
  }
};

export const getLanguagePreference = (props = {}) => {
  // https://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference
  const nav = window.navigator;
  const browserLanguagePropertyKeys = [
    'language',
    'browserLanguage',
    'systemLanguage',
    'userLanguage',
  ];
  let language = 'en'; // <-- default to english

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (let i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      if (language && language.length) {
        if (props.supportedLang && !supportedLanguages.includes(language)) {
          language = 'en';
        }
        return langTwoLetters(props, language);
      }
    }
  }
  // support for other well known properties in browsers
  for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      if (props.supportedLang && !supportedLanguages.includes(language)) {
        language = 'en';
      }
      return langTwoLetters(props, language);
    }
  }
  if (props.supportedLang && !supportedLanguages.includes(language)) {
    language = 'en';
  }
  return language;
};

const globMediaUrlMap = new WeakMap(); // <-- protection for memory leak with 'createObjectURL'
export const getMediaUrlSrc = (media) => {
  if (media && typeof media === 'string') {
    return media;
  } else if (media instanceof Blob || media instanceof File) {
    if (globMediaUrlMap.has(media)) {
      return globMediaUrlMap.get(media);
    } else {
      let url = URL.createObjectURL(media);
      globMediaUrlMap.set(media, url);
      return url;
    }
  } else {
    return '';
  }
};

export const setDocumentDir = (dir) => {
  if (document) {
    document.dir = dir;
  }
};

export const speechRecognitionSupported =
  typeof window !== 'undefined' &&
  !!(
    window.SpeechRecognition ||
    window.webkitSpeechRecognition ||
    window.mozSpeechRecognition ||
    window.msSpeechRecognition ||
    window.oSpeechRecognition
  );

export const getBrowserSpeechRecognition = () => {
  if (speechRecognitionSupported) {
    return (
      window.SpeechRecognition ||
      window.webkitSpeechRecognition ||
      window.mozSpeechRecognition ||
      window.msSpeechRecognition ||
      window.oSpeechRecognition
    );
  } else {
    return null;
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const getLocalStorage = (name) => {
  let storageValue = null;
  if (window.localStorage) {
    storageValue = window.localStorage.getItem(name);
  }
  return storageValue;
};

export const setLocalStorage = (name, value) => {
  if (window.localStorage) {
    window.localStorage.setItem(name, value);
  }
};

export const removeLocalStorage = (name) => {
  if (window.localStorage) {
    window.localStorage.removeItem(name);
  }
};

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const isDevEnv = (url = document.URL) => {
  return url.includes('http://localhost') || url.includes('https://dev');
};

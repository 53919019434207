import { DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

import useStore from 'components/App/Routes/Admin/Screening/store/useStore';
import useSearchStore from 'components/App/Routes/Admin/Search/store/useStore';
import { observer } from 'mobx-react-lite';
import Form from 'components/App/Routes/Admin/Company/EditCompanyDetails/Form';
import Grid from 'components/common/Grid/Grid';
import Typography from 'components/common/Typography';
import colors from 'constants/colors';
import { useMutation } from '@tanstack/react-query';
import useAuth from 'hooks/useAuth';
import axios from 'axios';

import api from 'constants/api';
import { runInAction } from 'mobx';
import FormStore from 'state/FormStore/FormStore';
import { useParams } from 'react-router-dom';
import useScreeningListEntries from 'hooks/useScreeningListEntries';

const Component = ({
  screeningForm,
  gleifForm,
  tccForm,
  consolidatedForm,
}: {
  screeningForm: FormStore;
  gleifForm: FormStore;
  tccForm: FormStore;
  consolidatedForm: FormStore;
}) => {
  const { t } = useTranslation('common');
  const snackbarMessage = useSnackbarMessage();
  const auth = useAuth();
  const { setInvalid, setSelectedCompany, setSelectedGLEIFCompany } =
    useSearchStore();
  const {
    setConsolidateCompanyId,
    shortlistGLEIFCompany,
    shortlistTCCCompany,
    shortlistScreeningCompany,
    screeningCompanyListEntryId,
    isReportingCompany,
    reportingCompanyMode,
    matchCompanyMode,
    toggleReportingCompanyMode,
    toggleMatchCompanyMode,
    setShortlistScreeningCompany,
    setShortlistTCCCompany,
    setShortlistGLEIFCompany,
  } = useStore();

  const { screeningListId } = useParams();

  const { refetch, data: listEntriesData } = useScreeningListEntries(
    screeningListId || ''
  );

  const { mutate } = useMutation({
    onError: (err) => {
      console.log(err);
    },
    onSuccess: (res) => {
      runInAction(() => {
        setConsolidateCompanyId('');
        setShortlistScreeningCompany({});
        setShortlistTCCCompany({});
        setShortlistGLEIFCompany({});
        setSelectedCompany({});
        setSelectedGLEIFCompany({});
        refetch();
        setInvalid(true);
        if (reportingCompanyMode) {
          toggleReportingCompanyMode();
        }
        if (matchCompanyMode) {
          toggleMatchCompanyMode();
        }
      });
      snackbarMessage.addMessage({
        message: reportingCompanyMode
          ? `Set reporting company: ${res?.data?.reporting_company}`
          : isReportingCompany
          ? `Matched company and reporing company: ${res?.data?.matched_company?.id}`
          : `Matched company: ${res?.data?.matched_company?.id}`,
      });
    },
    mutationFn: async (data: any) => {
      if (data.id && screeningCompanyListEntryId) {
        const formData = new FormData();
        const targetMatchedCompany = listEntriesData.find(
          (entry: any) => entry.id === screeningCompanyListEntryId
        )?.matched_company?.id;
        if (reportingCompanyMode && targetMatchedCompany) {
          formData.set('reporting_company', data.id);
          const res = await axios.patch(
            `${api.url}/admin/companies/${targetMatchedCompany}/`,
            formData,
            auth.requestConfig
          );
          return res?.data[0] || {};
        } else if (reportingCompanyMode) {
          console.log('No matched company');
          return {};
        } else {
          formData.set('matched_company', data.id);
          if (isReportingCompany) {
            const formData2 = new FormData();
            formData2.set('reporting_company', data.id);
            await axios.patch(
              `${api.url}/admin/companies/${targetMatchedCompany}/`,
              formData2,
              auth.requestConfig
            );
          }
          const res = await axios.patch(
            `${api.url}/admin/screening_list_entries/${screeningCompanyListEntryId}/`,
            formData,
            auth.requestConfig
          );
          return res;
        }
      }
    },
  });
  const gridSize =
    !shortlistTCCCompany.id && !shortlistGLEIFCompany.id
      ? 6
      : !shortlistGLEIFCompany.id ||
        !shortlistTCCCompany.id ||
        !shortlistScreeningCompany.id
      ? 4
      : 3;
  return (
    <DialogContent>
      <Grid container spacing={2} justifyContent={'flex-end'}>
        {shortlistScreeningCompany.id ? (
          <Grid item xs={gridSize}>
            <Typography
              display="flex"
              variant={'h8'}
              sx={{
                color: colors.neutral400,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              {t('Screening')}
            </Typography>
            <Form disabled form={screeningForm} />
          </Grid>
        ) : null}
        {shortlistGLEIFCompany.id ? (
          <Grid item xs={gridSize}>
            <Typography
              display="flex"
              variant={'h8'}
              sx={{
                color: colors.neutral400,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              {t('GLEIF')}
            </Typography>
            <Form disabled form={gleifForm} />
          </Grid>
        ) : null}
        {shortlistTCCCompany.id ? (
          <Grid item xs={gridSize}>
            <Typography
              display="flex"
              variant={'h8'}
              sx={{
                color: colors.neutral400,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              {t('TCC')}
            </Typography>
            <Form form={tccForm} />
          </Grid>
        ) : null}
        <Grid item xs={gridSize}>
          <Typography
            color={'primary'}
            display="flex"
            variant={'h8'}
            alignItems="center"
          >
            {t('Consolidated')}
          </Typography>
          <Form
            form={consolidatedForm}
            onClose={() => setConsolidateCompanyId('')}
            onSuccess={(consolidatedCompany) => {
              mutate(consolidatedCompany);
            }}
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default observer(Component);

import React from 'react';

import Grid from 'components/common/Grid';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import RadialBar from 'components/common/RadialBar/RadialBar';
import colors from 'constants/colors';
import Box from 'components/common/Box';

type Props = {
  title: string;
  info: string;
  score: number;
  icon: React.ReactNode;
};

function ScoreRadialSmall({ title, info, score, icon }: Props) {
  return (
    <Stack direction={'row'}>
      <RadialBar
        sizeW={120}
        sizeH={160}
        lineSize="55%"
        score={score}
        radialOffsetY={-10}
        radialOffsetX={-12}
        offsetY={5}
        fontSize={'14px'}
      />
      <Stack width={'100%'}>
        <Box flexGrow={0}>{icon}</Box>
        <Stack
          direction={'row'}
          alignItems={'center'}
          width={'100%'}
          spacing={1}
        >
          <Typography
            flexGrow={1}
            variant="body1"
            color="black"
            fontWeight={700}
          >
            {title}
          </Typography>
        </Stack>

        <Typography
          variant="caption"
          color={colors.neutral700}
          marginTop={'6px'}
        >
          {info}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default ScoreRadialSmall;

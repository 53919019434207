import { decorate, observable, action } from 'mobx';

import i18n from 'i18n';
import axios from 'axios';
import api from 'constants/api';
import * as schema from 'types/backendSchema';
import {
  combineFieldValidators,
  required,
} from 'state/FormStore/fieldValidators';

import AuthStore from 'state/AuthStore';
import SnackbarMessageStore from 'state/SnackbarMessageStore';
import FormStore from 'state/FormStore';

class ChangePasswordStore {
  constructor() {
    this.form = new FormStore({
      defaults: {
        password: '',
        password_match: '',
      },
      fieldValidators: {
        password: required,
        password_match: combineFieldValidators([
          required,
          (value, values): string => {
            return values && value !== values['password']
              ? i18n.t('Passwords must match')
              : '';
          },
        ]),
      },
    });
  }

  form: FormStore;
  // OBSERVABLES................................................................
  isSubmitting = false;
  invalid = false;
  tokenInvalid = false;
  passwordChanged = false;
  passwordErrorMessage = '';

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  onChangePasswordSubmit = ({
    type = '',
    auth,
    uid,
    token,
    snackbarMessage,
  }: {
    type?: string;
    auth: AuthStore;
    uid?: string;
    token?: string;
    snackbarMessage: SnackbarMessageStore;
  }) => {
    if (type === 'set') {
      this.submitSetPassword({ auth, uid, token, snackbarMessage });
    } else {
      this.submitChangePassword({ auth, snackbarMessage });
    }
  };

  submitChangePassword = async ({
    auth,
    snackbarMessage,
  }: {
    auth: AuthStore;
    snackbarMessage: SnackbarMessageStore;
  }) => {
    this.invalid = false;
    this.isSubmitting = true;
    const { password, password_match } = this.form.deserialize();

    try {
      await axios.post(
        `${api.url}/auth/password/change/`,
        {
          new_password1: password,
          new_password2: password_match,
        } as schema.definitions['PasswordChange'],
        auth.requestConfig
      );
      snackbarMessage.addMessage({
        message: i18n.t('common:::Password changed.'),
      });

      this.changePasswordSuccess();
    } catch (err) {
      this.changePasswordFailure(err);
    }
  };

  submitSetPassword = async ({
    auth,
    uid,
    token,
    snackbarMessage,
  }: {
    auth: AuthStore;
    uid: string | undefined;
    token: string | undefined;
    snackbarMessage: SnackbarMessageStore;
  }) => {
    this.invalid = false;
    this.tokenInvalid = false;
    this.isSubmitting = true;
    const { password, password_match } = this.form.deserialize();
    try {
      await axios.post(
        `${api.url}/auth/password/reset/confirm/`,
        {
          new_password1: password,
          new_password2: password_match,
          uid: uid,
          token: token,
        } as schema.definitions['PasswordResetConfirm'],
        auth.requestConfig
      );

      snackbarMessage.addMessage({
        message: i18n.t('common:::Password set.'),
      });
      this.changePasswordSuccess();
    } catch (err: any) {
      this.changePasswordFailure(err);
    }
  };

  changePasswordSuccess = () => {
    this.isSubmitting = false;
    this.invalid = false;
    this.tokenInvalid = false;
    this.passwordChanged = true;
    this.form.resetValues();
  };

  changePasswordFailure = (err: any) => {
    this.isSubmitting = false;
    this.invalid = true;
    const message = err?.response?.data?.new_password2
      ? err.response.data.new_password2.join(' ')
      : err?.response?.data?.token
      ? i18n.t('common:::Set password link is no longer valid.')
      : err?.response?.data?.detail;

    if (!message) {
      window.Sentry?.captureException(err);
    }
    if (err?.response?.data?.token) {
      this.tokenInvalid = true;
    }
    this.passwordErrorMessage = message;
  };
}

decorate(ChangePasswordStore, {
  isSubmitting: observable,
  invalid: observable,
  tokenInvalid: observable,
  passwordChanged: observable,
  passwordErrorMessage: observable,
  onChangePasswordSubmit: action,
  submitChangePassword: action,
  submitSetPassword: action,
  changePasswordSuccess: action,
  changePasswordFailure: action,
});

export default ChangePasswordStore;

import React from 'react';
import { observer } from 'mobx-react-lite';

import useCPASurveys from './store/useCPASurveys';
import { useSearchParams } from 'react-router-dom';

import CPASurveysData from './CPASurveysData';

const CPASurveysList = () => {
  let [searchParams] = useSearchParams();
  const { cpasurveys, isLoadingCPASurveys, count } = useCPASurveys();
  const rows = cpasurveys.slice();
  const page =
    searchParams.get('page') - 1 >= 0 ? searchParams.get('page') - 1 : 0;

  return (
    <CPASurveysData
      rows={rows}
      page={page}
      count={count}
      loading={isLoadingCPASurveys}
    />
  );
};

export default observer(CPASurveysList);

import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import SearchCompanyResultsTable from './SearchCompanyResultsTable';

function Component({ pages }: { pages: any[] }) {
  let data: any[] = [];
  pages.forEach((page) =>
    page.results.forEach((result: any) =>
      data.push({
        ...result,
        initial_source: result.initial_source[0],
      })
    )
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SearchCompanyResultsTable data={data} />
      </Grid>
    </Grid>
  );
}

export default observer(Component);

import Container from 'components/common/Container';

import Stack from 'components/common/Stack';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import axios from 'axios';
import api from 'constants/api';
import useAuth from 'hooks/useAuth';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';
import Box from 'components/common/Box';
import JobSelect from './JobSelect';

function DataServiceContentToUncheckedData() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { companyId } = useParams();
  const [jobIds, setjobIds] = useState<string[]>([]);

  const onSubmit = async () => {
    try {
      let data: any = {};
      data.type = 'CONTENT_TO_UNCHECKED_DATA';
      if (companyId) {
        data.company = companyId;
      }
      data.job_ids = jobIds;

      await axios.post(
        `${api.url}/etl/jobs/create_with_job_ids/`,
        data,
        Object.assign({}, auth.requestConfig, {
          headers: {
            ...auth.requestConfig.headers,
            'Content-Type': 'application/json',
          },
        })
      );
      navigate('../..', { relative: 'path' });
    } catch (err) {
      // enable once live on backend:
      // window.Sentry?.captureException(err);
      console.error(err);
    }
  };

  return companyId ? (
    <Container disableGutters maxWidth="md">
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography sx={{ pt: 4 }} variant="h4">
            {t('Content to unchecked data')}
          </Typography>
          <Alert severity="info">
            {t(
              'Selected jobs go in (that have text output) 👉 LLM outputs ready for review come out.'
            )}
          </Alert>
        </Stack>
        <JobSelect
          multiselect
          filterJobTypes={['HARVESTER', 'PDF_TO_TEXT', 'PDF_TO_MARKDOWN']}
          value={jobIds}
          onChange={(selectedJobIds: any) => setjobIds(selectedJobIds)}
        />
        <Button
          sx={{ maxWidth: '250px' }}
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          Run
        </Button>
      </Stack>
    </Container>
  ) : (
    <div>no company state</div>
  );
}

export default DataServiceContentToUncheckedData;

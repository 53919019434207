import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';

import { useTranslation } from 'react-i18next';
import EmptyTextField from 'components/common/EmptyTextField';
import { getLanguagePreference } from 'utils/browser';

export default function S3AssetsDataTable({ data }: { data: any }) {
  const { t } = useTranslation('common');
  const rows = data;

  const columns: GridColDef[] = [
    {
      field: 'reporting_year',
      headerName: t('Reporting Year'),
      flex: 0,
      sortable: true,
    },
    {
      field: 'disclosure_year',
      headerName: t('Disclosure Year'),
      flex: 0,
      sortable: true,
    },
    {
      field: 'file',
      headerName: t('File'),
      flex: 1,
      sortable: false,
      renderCell: (params) => <a href={params.row.file}>s3 asset link</a>,
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('Data unavailable'),
  };

  return !data ? (
    <EmptyTextField text={t('Data unavailable') + ''} />
  ) : (
    <div style={{ width: '100%' }}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'reporting_year', sort: 'desc' }],
          },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50]}
        pagination
        autoHeight
        localeText={localeText}
        sx={{ width: '100%' }}
        rows={rows}
        columns={columns}
        hideFooter={rows.length <= 10}
      />
    </div>
  );
}

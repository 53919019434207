// import React from 'react'
import DashboardPieChart from 'components/common/DashboardPieChart';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import colors from 'constants/colors';

function Component({ countOnly }: { countOnly?: boolean }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const { isLoading, data, isError } = useQuery({
    queryKey: ['supplier-screening-ccf-quantified-pcf'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/survey_responses/pcf/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : countOnly ? (
    <Typography variant="h1" color={colors.neutral600}>
      {data.Yes + data.No + data['Currently under development'] + data.Unsure}
    </Typography>
  ) : (
    <DashboardPieChart
      legendBottom
      labels={[
        t('Yes'),
        t('No'),
        t('Currently under development'),
        t('Unsure'),
      ]}
      data={[
        data.Yes,
        data.No,
        data['Currently under development'],
        data.Unsure,
      ]}
      colors={[
        colors.primary500,
        colors.secondary500,
        colors.neutral400,
        colors.neutral600,
      ]}
    />
  );
}

export default observer(Component);

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useScreeningListEntries(listId: string) {
  const auth = useAuth();
  return useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    queryKey: ['admin-screening-list-entries', { listId }],
    queryFn: async () => {
      if (listId) {
        const res = await axios.get(
          `${api.url}/admin/screening_lists/entries/${listId}/`,
          auth.requestConfig
        );
        return res.data;
      }
      return [];
    },
  });
}

import { observer } from 'mobx-react-lite';
import ReactApexChart from 'react-apexcharts';
// import { useTranslation } from 'react-i18next';

import Typography from 'components/common/Typography/Typography';
import Stack from 'components/common/Stack/Stack';
import Grid from 'components/common/Grid/Grid';

import colors from 'constants/colors';

function Component({ title, titleY, data, formater, groups, color }) {
  // const { t } = useTranslation('common');
  const config = {
    series: [
      {
        name: titleY,
        data: data,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 380,
        toolbar: {
          show: false,
        },
      },
      colors: [color],
      xaxis: {
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          trim: true,
          minHeight: '500px',
        },
        group: {
          style: {
            fontSize: '10px',
            fontWeight: 700,
          },
          groups: groups,
        },
      },
      yaxis: {
        title: {
          text: titleY,
        },
        labels: {
          formatter: function (y) {
            return y + formater;
          },
        },
      },
      dataLabels: {
        style: {
          colors: [colors.neutral800],
        },
        formatter: function (y) {
          return y + formater;
        },
      },
    },
  };

  return (
    <Grid>
      <Stack>
        <Stack direction={'row'} gap={16}>
          <Typography variant="h5">{title}</Typography>
        </Stack>
        <ReactApexChart
          options={config.options}
          series={config.series}
          width={'1000px'}
          type="bar"
          height={450}
        />
      </Stack>
    </Grid>
  );
}

export default observer(Component);

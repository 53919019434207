// import React from 'react'
import { observer } from 'mobx-react-lite';

import Container from 'components/common/Container';
import { scrollToTop } from 'utils/browser';
import ScrollToTop from 'components/common/ScrollToTop';
// import { useNavigate } from 'react-router-dom'
// import { useTranslation } from 'react-i18next';

import CompanyOverview from './Overview';
import { Outlet } from 'react-router-dom';
import Box from 'components/common/Box';

// import useAuth from 'hooks/useAuth'

function Component() {
  // const auth = useAuth()
  // const navigate = useNavigate()
  // const { t } = useTranslation('common');

  return (
    <Box
      sx={{
        width: {
          xs: 'calc(100% - 65px)',
          md: 'calc(100% - 260px)',
        },
      }}
    >
      <Container pageContainer maxWidth="lg" sx={{ pt: 3 }}>
        <Outlet />
        <ScrollToTop />
      </Container>
    </Box>
  );
}

export default observer(Component);

import React from 'react';
import { runInAction } from 'mobx';
import Store from 'state/PreferencesStore';

const store = runInAction(() => new Store());
const context = React.createContext(store);

const useStoreContext = () => React.useContext(context);

export default useStoreContext;

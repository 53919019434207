import * as React from 'react';

function SvgClimateTransparency(props: any) {
  return (
    <svg
      width={30}
      height={30}
      fill={props.color}
      viewBox="0 0 30 30"
      {...props}
    >
      <path d="M7.74 14.652h3.668v-2.201H7.739v2.201zm.733-1.467h2.201v.734H8.473v-.734zM22.519 24.144a4.033 4.033 0 10-1.729 1.513l2.967 2.967a1.145 1.145 0 101.619-1.618l-2.857-2.862zm-3.406 1.17a3.32 3.32 0 110-6.64 3.32 3.32 0 010 6.64zM16.91 8.783h-2.934v3.668h2.934V8.783zm-.733 2.934h-1.468v-2.2h1.468v2.2zM20.579 8.783h-2.935v3.668h2.935V8.783zm-.734 2.934h-1.467v-2.2h1.467v2.2zM13.976 17.22h2.934V13.55h-2.934v3.669zm.733-2.935h1.468v2.201h-1.468v-2.201zM17.644 17.22h2.935V13.55h-2.935v3.669zm.734-2.935h1.467v2.201h-1.467v-2.201zM24.247 8.783h-2.934v3.668h2.934V8.783zm-.733 2.934h-1.468v-2.2h1.468v2.2zM21.313 17.22h2.934V13.55h-2.934v3.669zm.733-2.935h1.468v2.201h-1.468v-2.201zM7.74 17.954h3.668v-2.202H7.739v2.202zm.733-1.468h2.201v.734H8.473v-.734zM8.106 19.787h2.568v.734H8.106v.734h3.302v-2.201H8.106v.733z" />
      <path d="M25.715 6.951v-.004h-.176a9.737 9.737 0 00-2.258-3.395 10.13 10.13 0 00-17.123 6.887l-.004.097-.095.013a.415.415 0 00-.357.408.424.424 0 00.203.358v6.308a1.836 1.836 0 00-2.201 1.798v3.668a1.836 1.836 0 001.467 1.798v3.338H3.704v.734h18.71v-.734h-9.172V7.682h11.74v17.975h.733V7.664c.251-.019.258-.152.258-.315v-.082c0-.163.008-.297-.258-.316zM5.171 20.521v3.605a1.102 1.102 0 01-.734-1.037v-3.63a1.124 1.124 0 01.88-1.117 1.1 1.1 0 011.321 1.08v3.667a1.098 1.098 0 01-.733 1.037v-3.604H5.17zm5.503 7.705H8.473v-4.403h2.2v4.403zm1.834 0h-1.1v-5.137H7.739v5.136H5.905v-3.338a1.836 1.836 0 001.467-1.798v-3.668a1.834 1.834 0 00-.734-1.468V11.35h5.87v16.876zm0-21.278v3.668h-2.404l.018-.234a6.182 6.182 0 019.096-4.876c.731.355 1.39.843 1.94 1.441l-8.65.001zm9.678 0c-.628-.92-1.493-1.568-2.547-2.14A7.039 7.039 0 009.26 10.443l-.008.107H8.47l.01-.125a7.822 7.822 0 0111.539-6.283c1.315.716 2.367 1.59 3.076 2.805h-.91zm1.898 0c-.765-1.556-2.01-2.705-3.619-3.592A8.663 8.663 0 007.63 10.441l-.006.109h-.616l.007-.122a9.266 9.266 0 0117.66-3.48h-.59z" />
    </svg>
  );
}

export default SvgClimateTransparency;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Grid from 'components/common/Grid/Grid';
import Typography from 'components/common/Typography/Typography';

import Scope1Targets from './Scope1Targets';
import Scope2Targets from './Scope2Targets';
import Scope3Targets from './Scope3Targets';

function Component() {
  const { t } = useTranslation('common');

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Typography variant="h4" color="onSureface.100">
          {t('Targets')}
        </Typography>
      </Grid>
      <Scope1Targets />
      <Scope2Targets />
      <Scope3Targets />
    </Grid>
  );
}

export default observer(Component);

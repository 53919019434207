// import React from 'react'
import Grid from '@mui/material/Grid';

import Card from 'components/common/Card/Card';
import { observer } from 'mobx-react-lite';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

// import useAuth from 'hooks/useAuth'

import DashboardHeader from 'components/common/DashboardHeader';

import ScopeEmissions from './ScopeEmissions';
import ScopeBreakdown from './ScopeBreakdown';
import LatestCDPScore from './LatestCDPScore';
import ScopeEmissionIntensity from './ScopeEmissionIntensity';
import CDPScores from './CDPScores';
import TargetDistribution1 from './TargetDistribution1';
import TargetDistribution3 from './TargetDistribution3';
import Reduction from './Reduction';
import MinAmbition from './MinAmbition';
import ScorecardBreakdown from './ScorecardBreakdown';
import DashboardLegend from 'components/common/DashboardLegend';
import Typography from '@mui/material/Typography';
import colors from 'constants/colors';

// import { DemoPie } from './demos';

function Component() {
  // const auth = useAuth()
  // const navigate = useNavigate()
  const { t } = useTranslation('common');
  return (
    <Grid container spacing={{ xs: 2, sm: 3 }}>
      <Grid item xs={12}>
        <DashboardHeader text={t('Scorecard')} />
      </Grid>
      <Grid item xs={12}>
        <Card title={t('Scoring Method Overview')}>
          <DashboardLegend
            items={[
              {
                title: t(`Emission Calculation`),
                description: t(
                  `Has the company calculated and disclosed their GHG emissions? (Scope 1, Scope 2, Scope 3 & Product Data Coverage)`
                ),
              },
              {
                title: t(`Climate Targets`),
                description: t(
                  `Has the company committed to or set climate targets and are they compatible with 1.5°C?`
                ),
              },
              {
                title: t(`Strategy (control)`),
                description: t(
                  `Has the company a climate transition plan with financial commitments in place?`
                ),
              },
              {
                title: t(`Decarbonization Measures`),
                description: t(
                  `Has the company taken concrete decarbonization measures?`
                ),
              },
              {
                title: t(`Emissions Performance`),
                description: t(
                  `Have emissions decreased and how does the company perform against peers?`
                ),
              },
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title={t('Scorecards (Top 30)')}>
          <ScorecardBreakdown />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <DashboardHeader text={t('Emissions')} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Typography variant="subtitle2" sx={{ color: colors.neutral600 }}>
            {t(
              `The following charts show the reported (Scope 1, 2, 3) emissions of different suppliers over the years`
            )}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title={t('Scope 1 Emissions')}>
          <ScopeEmissions variableNames={['SCOPE_1']} />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title={t('Scope 2 Emissions')}>
          <ScopeEmissions
            variableNames={[
              'SCOPE_2',
              'SCOPE_2_MARKET_BASED',
              'SCOPE_2_LOCATION_BASED',
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title={t('Scope 3 Emissions')}>
          <ScopeEmissions variableNames={['SCOPE_3']} />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <Typography variant="subtitle2" sx={{ color: colors.neutral600 }}>
            {t(
              `The following chart shows the Scope 3 sub-category breakdown for each supplier in a given year`
            )}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title={t(`Scope 3 Emissions Breakdown`)}>
          <ScopeBreakdown />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <DashboardHeader text={t('Climate Targets')} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Typography variant="subtitle2" sx={{ color: colors.neutral600 }}>
            {t(
              `The following chart shows the targetted reduction percentage and target year of your suppliers. The data can be filtered as per the type of target (Absolute / Intensity) or the scope (Scope 1/2/3)`
            )}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title={t(`Targets Reduction Percentage`)}>
          <Reduction />
        </Card>
      </Grid>
      <Grid item margin={'auto'} xs={8} md={8}>
        <Card title={t(`What percentage of my suppliers have set targets?`)}>
          <TargetDistribution1 />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Typography variant="subtitle2" sx={{ color: colors.neutral600 }}>
            {t(
              `The following charts are based on the percentage of suppliers that have set targets`
            )}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={6} md={6}>
        <Card title={t(`Are the set targets verified by SBT?`)}>
          <TargetDistribution3 />
        </Card>
      </Grid>
      <Grid item xs={6} md={6}>
        <Card title={t(`What is the distribution of target ambitions?`)}>
          <MinAmbition />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <DashboardHeader text={t('Emission Intensity')} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Typography variant="subtitle2" sx={{ color: colors.neutral600 }}>
            {t(
              `The following chart shows the emission intensity for all suppliers over the years, calculated as follows: for a given year, Scope 1 + Scope 2 emissions (in metric tons) / million revenue (in EUR)`
            )}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title={t(`Scope 1 + 2 Emission Intensity`)}>
          <ScopeEmissionIntensity />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <DashboardHeader
          text={t('Carbon Disclosure Project (CDP) Information')}
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <Card title={t(`Latest CDP Score Distribution`)}>
          <LatestCDPScore />
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card title={t(`CDP Scores`)}>
          <CDPScores />
        </Card>
      </Grid>
    </Grid>
  );
}

export default observer(Component);

//<Card title={t(``)}></Card>

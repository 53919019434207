import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import Typography from 'components/common/Typography';
import Button from 'components/common/Button';
import { CloseIcon } from 'components/icons/mui_icons';

import colors from 'constants/colors';

const getCount = (searchParams: URLSearchParams) => {
  const queryParams = ['page', 'ordering', 'search'];
  let count = 0;
  queryParams.forEach((param) => {
    if (searchParams.has(param)) {
      count = count + 1;
    }
  });
  return count;
};

function ClearAllButton() {
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  const clearFilters = () => {
    navigateSearch({
      pathname: location.pathname,
      params: {
        page: 1,
        ordering:
          searchParams.get('ordering') !== null
            ? searchParams.get('ordering')
            : [],
        search:
          searchParams.get('search') !== null ? searchParams.get('search') : [],
      },
      replace: true,
    });
  };

  const nonFilterParamsLen = getCount(searchParams);
  const hasFilters = Array.from(searchParams).length > nonFilterParamsLen;

  return hasFilters ? (
    <Button
      variant="text"
      color="primary"
      size="medium"
      onClick={() => {
        clearFilters();
      }}
      sx={{ '&:hover': { backgroundColor: colors.neutral200 } }}
      endIcon={<CloseIcon color="primary" sx={{ width: '16px' }} />}
    >
      <Typography
        variant="action_small"
        color="secondary"
        ml={'5px'}
        sx={{ textTransform: 'uppercase' }}
        noWrap
      >
        {t('Clear all')}
      </Typography>
    </Button>
  ) : null;
}

export default observer(ClearAllButton);

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import colors from 'constants/colors';

interface FilterItemProps {
  children: React.ReactNode;
  title?: string;
  divider?: boolean;
}

const FilterItem: React.FC<FilterItemProps> = ({
  children,
  title = '',
  divider = false,
}) => {
  const [open, setOpen] = React.useState(true);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.paper',
        padding: 1,
        borderRadius: 1,
        color: colors.neutral700,
        marginBottom: 1.5,
        borderBottom: divider ? `1px solid ${colors.neutral200}` : 'none',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="subtitle2"
          color={colors.neutral600}
          sx={{ flex: 1 }}
        >
          {title}
        </Typography>
        <IconButton
          size="small"
          onClick={() => setOpen(!open)}
          aria-expanded={open}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      {open && <Box>{children}</Box>}
    </Box>
  );
};

FilterItem.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  divider: PropTypes.bool,
};

export default FilterItem;

// import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import CountrySearchFilter from './CountrySearchFilter/Component';
import Container from 'components/common/Container';
import colors from 'constants/colors';
import CommonTextSearchFilter from './CommonTextSearchFilter/Component';

const SearchFilters = () => {
  const { t } = useTranslation('common');
  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        background: colors.neutral200,
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CommonTextSearchFilter
            searchParam="lei_code"
            title={t('Company LEI code')}
          />
        </Grid>

        <Grid item xs={4}>
          <CommonTextSearchFilter searchParam="isin" title={t('ISIN')} />
        </Grid>
        <Grid item xs={4}>
          <CommonTextSearchFilter searchParam="website" title={t('Website')} />
        </Grid>
        <Grid item xs={4}>
          <CommonTextSearchFilter
            searchParam="address"
            title={t('Address Line 1')}
          />
        </Grid>
        <Grid item xs={4}>
          <CommonTextSearchFilter
            searchParam="address2"
            title={t('Address Line 2')}
          />
        </Grid>
        <Grid item xs={4}>
          <CommonTextSearchFilter searchParam="city" title={t('City')} />
        </Grid>
        <Grid item xs={4}>
          <CommonTextSearchFilter
            searchParam="zip_code"
            title={t('Zip code')}
          />
        </Grid>
        <Grid item xs={4}>
          <CountrySearchFilter />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SearchFilters;

import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import Box from '@mui/material/Box';
import TextField from 'components/common/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Typography from 'components/common/Typography';
import Stack from 'components/common/Stack';
import api from 'constants/api';
import colors from 'constants/colors';
import useAuth from 'hooks/useAuth';
import CircularProgress from '@mui/material/CircularProgress';

type SectorOption = {
  value: string;
  label: string;
  description: string;
};

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: SectorOption) => option.label + option.description,
});

const Component = ({
  title,
  subtitle,
  onChange,
  required,
  value,
  error,
}: {
  title?: string;
  subtitle?: string;
  onChange: (sector: string) => void;
  required?: boolean;
  value?: string;
  error?: boolean;
}) => {
  const { t } = useTranslation('common');
  const sectorDescriptions = {
    '2_02': t('Manufacturing of aircrafts and weapons.'),
    '1_03': t(
      'Agriculture, animal husbandry, aquaculture, and fishing. Including rubber but excluding hunting and forestry.'
    ),
    '3_06': t('Passenger airlines.'),
    '2_03': t(
      'Production of road vehicles and auto parts, retail and repair of road vehicles, car rental and leasing.'
    ),
    '1_07': t(
      'Commercial banks; consumer finance; savings institutions; mortgage finance; micro-finance institutions'
    ),
    '1_09': t(
      'Asset owners and managers, investment banks, custody, and stock exchanges.'
    ),
    '2_05': t(
      'Manufacturing of chemical products, including plastics and fertilizers.'
    ),
    '1_02': t(
      'Exploration and extraction of coal; suppliers of equipment and services to coal mines; storage and transportation; refining and marketing of coal products.'
    ),
    '4_08': t(
      'Professional services, including lawyers, accountants, consultants, advertising and marketing; business process outsourcing.'
    ),
    '2_01': t(
      'Production of cement, concrete, tiles, bricks, glass and other construction materials, except steel and timber.'
    ),
    '2_04': t(
      'Construction of buildings, civil engineering and other construction activities.'
    ),
    '4_01': t('Education services at all levels, including online education.'),
    '2_08': t(
      'Manufacturing and design of electronic products, including computers, mobile phones and their components; semiconductors.'
    ),
    '1_05': t('Manufacturing of food, beverages and tobacco.'),
    '1_12': t('Forestry and logging, production of pulp and paper.'),
    '3_09': t('Management of hotels, resorts and other leisure spaces.'),
    '4_02': t(
      'Manufacturing of furniture, household appliances, toys, sporting goods and similar. Can include all light manufacturing not specified elsewhere.'
    ),
    '1_08': t('Life, non-life, and reinsurance.'),
    '2_06': t(
      'Manufacturing of machines and equipment, including ships and trains. Can include all heavy industry not specified elsewhere.'
    ),
    '4_03': t('Health care services, including veterinary.'),
    '3_01': t('Telecom operators, media companies, printing industry.'),
    '4_04': t('Manufacturing of medical supplies and equipment.'),
    '1_13': t(
      'Steel and aluminum production; smelting and processing of other metals.'
    ),
    '1_04': t(
      'Exploration and extraction of minerals, except coal; suppliers of equipment and services to mining; storage and transportation; refining and marketing of minerals.'
    ),
    '4_09': t(
      'Non-governmental organizations, foundations, professional and civic associations, charities.'
    ),
    '1_01': t(
      'Exploration and production of oil and gas; suppliers of equipment and services to oil and gas fields; storage and transportation; refining and marketing of oil and gas products.'
    ),
    '3_08': t('Containers and packaging.'),
    '2_07': t(
      'Manufacturing of pharmaceuticals products; research and development of idem, biotechnology.'
    ),
    '3_03': t('Real estate developers and services associated.'),
    '1_11': t(
      'Solar and wind project developers; biofuels producers; producers of fuel cells and industrial batteries.'
    ),
    '4_07': t('Restaurants, bars and cafes; catering services.'),
    '4_05': t('All retail services except automotive. Repair services.'),
    '4_06': t(
      'Provision of security services, management of correctional facilities.'
    ),
    '3_02': t('Software and related services.'),
    '1_06': t(
      'Manufacturing and retail of textiles, apparel, footwear, and accessories.'
    ),
    '3_07': t(
      'Freight transportation by rail or plane, postal and other logistical services, storage services, trading services.'
    ),
    '3_04': t('Operation of roads, railways, ports, airports, etc.'),
    '3_05': t('Transportation services by road.'),
    '1_10': t(
      'Electricity generation (except renewables), transmission and distribution; gas utilities and distributors; water utilities and services; waste management.'
    ),
  };
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const auth = useAuth();
  const { isFetching, data, isError } = useQuery({
    enabled: value ? true : open,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: 3600000, // 1hr
    cacheTime: 3600000, // 1hr
    queryKey: ['companies-sectors'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/sectors`,
        auth.requestConfig
      );
      return res.data as { [key: string]: string };
    },
  });

  const options = data
    ? Object.keys(data)
        .map((sectorId) => ({
          value: sectorId,
          label: data[sectorId],
          description:
            sectorDescriptions[sectorId as keyof typeof sectorDescriptions],
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];

  return (
    <Box display={'flex'} flexDirection={'column'}>
      {title ? (
        <Typography
          pb={subtitle ? 0 : 1}
          variant="body1"
          color={error ? 'error.main' : 'neutral.contrastText'}
        >
          {`${title}${required ? ' *' : ''}`}
        </Typography>
      ) : null}
      {subtitle ? (
        <Typography
          variant={'caption2'}
          color={error ? 'error.main' : 'neutral.contrastText'}
          pb={1}
        >
          {`${subtitle}${required && !title ? ' *' : ''}`}
        </Typography>
      ) : null}
      <Autocomplete
        value={options.find((option) => option.value === value) || null}
        onChange={(_, newValue: SectorOption | null) => {
          onChange(newValue ? newValue.value : '');
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        componentsProps={{}}
        filterOptions={filterOptions}
        options={options}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack spacing={0.5} width={'100%'}>
              <Typography variant="body1">{option.label}</Typography>
              <Typography
                title={option.description}
                sx={{ pb: 1, color: 'text.secondary' }}
                variant="body2"
              >
                {option.description}
              </Typography>
            </Stack>
          </li>
        )}
        sx={{ width: '100%', maxHeight: '600px' }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={isFetching}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            sx={{
              '& input::placeholder': { color: colors.neutral100 },
              backgroundColor: colors.neutral100,
              '& fieldset': { border: 'none' },
              disableUnderline: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {isError ? (
        <Alert sx={{ mt: 1 }} severity="error">
          {t('There was a problem')}
        </Alert>
      ) : null}
    </Box>
  );
};

export default Component;

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useUserCompanyCollections() {
  const auth = useAuth();
  return useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    queryKey: ['user-company-collections'],
    queryFn: async () => {
      let searchParams = new URLSearchParams();

      const res = await axios.get(
        `${api.url}/user/company/collections/`,
        Object.assign(auth.requestConfig, { params: searchParams })
      );
      return res.data;
    },
  });
}

import React from 'react';

import Typography from 'components/common/Typography';
import Box from 'components/common/Box';
import Paper from 'components/common/Paper';
import colors from 'constants/colors';

type Props = {
  icon: React.ReactNode;
  text: string;
  width?: string;
};

function KeyFactsCard({ icon, text, width }: Props) {
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: '18px',
        width: width,
        height: '80px',
        mb: '20px',
        backgroundColor: 'neutral.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
      }}
    >
      <Box sx={{ width: '30px', m: '16px', color: 'secondary.main' }}>
        {icon}
      </Box>

      <Typography variant="caption" color={colors.neutral700} pr={'16px'}>
        {text}
      </Typography>
    </Paper>
  );
}

export default KeyFactsCard;

// import React from 'react'
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import DashboardLineChart from 'components/common/DashboardLineChart';
import SelectMenu from 'components/common/SelectMenu';
import { useState } from 'react';
import Stack from 'components/common/Stack';
import Box from 'components/common/Box';

function Component({ variableNames }: { variableNames: string[] }) {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const auth = useAuth();
  const { isLoading, data, isError } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['supplier-screening-scope-', { variable: variableNames[index] }],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/emissions/${variableNames[index]}/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const distinctYears: Set<number> = new Set();
  if (data) {
    data.forEach((company: any) => {
      company.emissions.forEach((emission: any) => {
        distinctYears.add(emission.reporting_year);
      });
    });
  }

  const years = Array.from(distinctYears).sort();

  const series = data
    ? data.map((c: any) => ({
        name: c.company_name,
        data: years.map((year) => {
          return {
            x: [year],
            y:
              c.emissions.find((ce: any) => ce.reporting_year === year)
                ?.value || null,
          };
        }),
      }))
    : [];

  return isLoading ? (
    <Loading color="primary" />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <Stack spacing={2}>
      {variableNames.length > 1 ? (
        <Box maxWidth={150}>
          <SelectMenu
            items={variableNames}
            value={variableNames[index]}
            handleChange={(e) =>
              setIndex(
                variableNames.findIndex((name) => name === e.target.value)
              )
            }
          />
        </Box>
      ) : null}
      <DashboardLineChart
        yaxisTitle={t('Amount (t CO₂e)')}
        categories={years}
        series={series}
      />
    </Stack>
  );
}

export default observer(Component);

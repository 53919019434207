import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from 'components/common/Box';
import Typography from 'components/common/Typography';

import colors from 'constants/colors';

type Props = {
  totalCustomersNumber?: number;
};

function UserDashboardCustomersHeader({ totalCustomersNumber }: Props) {
  const { t } = useTranslation('common');
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h4" color="onSureface.100">
        {t('My Customers')}
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Typography variant="body1" color={colors.onSurface200}>
          {t('Total number of customers')}
          {': '}
          <strong>{totalCustomersNumber}</strong>
        </Typography>
      </Box>
    </Box>
  );
}

export default UserDashboardCustomersHeader;

import React from 'react';

import Stack from 'components/common/Stack';
import AlertTooltip from 'components/common/AlertTooltip';
import Typography from 'components/common/Typography';

type ColHeaderProps = {
  title: string | undefined;
  text?: string;
};

const ColHeader = ({ title, text }: ColHeaderProps) => {
  return (
    <div>
      <Stack direction="row" alignItems="center">
        {text ? <AlertTooltip tooltipText={text} /> : null}
        <Typography variant={'action_small'} sx={{ whiteSpace: 'normal' }}>
          {title}
        </Typography>
      </Stack>
    </div>
  );
};

export default ColHeader;

import { decorate, observable, action } from 'mobx';
import axios from 'axios';
import api from 'constants/api';

import * as schema from 'types/backendSchema';

import AuthStore from 'state/AuthStore';

class CompanyStore {
  // OBSERVABLES................................................................
  isLoadingCompany = false;
  hasLoadedCompanyData = false;
  companyData:
    | null
    | schema.operations['companies_read']['responses'][200]['schema'] = null;

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  loadDataFailure = (err: any) => {
    console.log(err);
    this.isLoadingCompany = false;
    this.hasLoadedCompanyData = true;
  };

  loadCompanyData = async ({
    auth,
    company,
  }: {
    auth: AuthStore;
    company: any;
  }) => {
    this.isLoadingCompany = true;
    try {
      const res = await axios.get(
        `${api.url}/companies/${company}/`,
        auth.requestConfig
      );
      this.loadCompanyDataSuccess(res.data);
    } catch (err) {
      this.loadDataFailure(err);
    }
  };

  loadCompanyDataSuccess = (data: schema.definitions['PublicCompany']) => {
    this.companyData = Object.assign({}, data);
    this.isLoadingCompany = false;
    this.hasLoadedCompanyData = true;
  };

  removeCompany = () => {
    this.hasLoadedCompanyData = false;
  };
}

decorate(CompanyStore, {
  isLoadingCompany: observable,
  hasLoadedCompanyData: observable,
  companyData: observable,
  loadDataFailure: action,
  loadCompanyData: action,
  loadCompanyDataSuccess: action,
  removeCompany: action,
});

export default CompanyStore;

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import useAuth from 'hooks/useAuth';

import Container from 'components/common/Container';
import Alert from '@mui/material/Alert';

import useCompany from 'hooks/useCompany';

import AppLoading from 'components/common/AppLoading';

import CompanyDetailsPage from 'components/App/Routes/Company/CompanyDetailsPage/CompanyDetailsPage';
import useUserCompany from 'hooks/useUserCompany';

import { toJS } from 'mobx';

const UserCompanyProfile = () => {
  const {
    userCompany,
    loadUserCompany,
    isLoadingUserCompany,
    hasLoadedUserCompany,
  } = useUserCompany();

  const auth = useAuth();

  useEffect(() => {
    if (!isLoadingUserCompany && !hasLoadedUserCompany) {
      loadUserCompany({ auth });
    }
  }, [auth, isLoadingUserCompany, hasLoadedUserCompany, loadUserCompany]);
  return isLoadingUserCompany || !hasLoadedUserCompany ? (
    <AppLoading />
  ) : userCompany && userCompany.id ? (
    <CompanyDetailsPage isUserCompany companyData={toJS(userCompany)} />
  ) : (
    <Container pageContainer>
      <Alert severity="error">user has no company assigned</Alert>
    </Container>
  );
};

export default observer(UserCompanyProfile);

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useAuth from 'hooks/useAuth';

import useCompany from 'hooks/useCompany';

import AppLoading from 'components/common/AppLoading';

import CompanyDetailsPage from './CompanyDetailsPage/CompanyDetailsPage';

import { toJS } from 'mobx';

const Company = () => {
  const { id } = useParams();

  const {
    hasLoadedCompanyData,
    isLoadingCompany,
    loadCompanyData,
    companyData,
  } = useCompany();
  const auth = useAuth();

  useEffect(() => {
    if (!hasLoadedCompanyData && !isLoadingCompany) {
      loadCompanyData({ auth, company: id });
    }
  }, [auth, hasLoadedCompanyData, isLoadingCompany, loadCompanyData, id]);

  return isLoadingCompany || !hasLoadedCompanyData ? (
    <AppLoading />
  ) : (
    <CompanyDetailsPage companyData={toJS(companyData)} />
  );
};

export default observer(Company);

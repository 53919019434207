import i18n from 'i18n';
import { isValidEmail, isValidUrl, isValidNumber } from 'utils/validators';

export type FieldValidator = (
  value: any,
  allFormValues?: { [key: string]: any }
) => string | boolean;

export const required: FieldValidator = (value: any) => {
  return !value && (i18n.t('common:::This is required.') as string);
};

export const string255: FieldValidator = (value: any) => {
  return (
    !!value &&
    typeof value === 'string' &&
    value.length > 255 &&
    (i18n.t('common:::Max length exceeded.') as string)
  );
};

export const string20: FieldValidator = (value: any) => {
  return (
    !!value &&
    typeof value === 'string' &&
    value.length > 20 &&
    (i18n.t('common:::Max length exceeded.') as string)
  );
};

export const integer: FieldValidator = (value: any) => {
  return (
    !isValidNumber(value) &&
    (i18n.t('common:::Please enter a valid number.') as string)
  );
};

export const integerGreaterThanZero: FieldValidator = (value: any) => {
  return (
    !isValidNumber(value) &&
    parseInt(value, 10) <= 0 &&
    (i18n.t('common:::Please enter a number greater than zero.') as string)
  );
};

export const email: FieldValidator = (value: any) => {
  return (
    !!value &&
    !isValidEmail(value) &&
    (i18n.t('common:::Please enter a valid email.') as string)
  );
};

export const url: FieldValidator = (value: any) => {
  return (
    !!value &&
    !isValidUrl(value) &&
    (i18n.t('common:::Please enter a valid url.') as string)
  );
};

export const combineFieldValidators = (validators: FieldValidator[]) => {
  const combinedValidator: FieldValidator = (value, values) => {
    const errorStrings: string[] = [];
    for (let validator of validators) {
      const error = validator(value, values);
      if (typeof error === 'string') {
        errorStrings.push(error);
      }
    }
    return errorStrings.join(' ');
  };
  return combinedValidator;
};

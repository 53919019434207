import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useAdminCompanies({
  ids,
  search,
}: {
  ids?: string[];
  search?: string;
}) {
  const auth = useAuth();
  return useQuery({
    enabled: (ids && ids.length > 0 && !ids.includes('')) || !!search,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    queryKey: ['admin-companies', { ids, search }],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (ids) {
        ids.forEach((id) => {
          params.set('id', id);
        });
      }
      if (search) {
        params.set('search', search);
      }

      const res = await axios.get(
        `${api.url}/admin/companies/`,
        Object.assign(auth.requestConfig, { params })
      );
      return res.data.results;
    },
  });
}

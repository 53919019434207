import React from 'react';

import Button from 'components/common/Button';
import { fontSize, fontWeight } from 'constants/typography';

type Props = {
  title: string;
  value?: string;
  buttonColor?: string;
  buttonColorHover?: string;
  height?: string;
  width?: string;
  onClick?: (e: any) => void;
  startIcon?: React.ReactNode;
  fontColor?: string;
  fontColorOnHover?: string;
  justifyContent?: string;
  children?: React.ReactNode;
  sx?: any;
};

function StyledTagButton({
  title,
  value,
  buttonColor = 'secondary.main',
  buttonColorHover = 'secondary.hover',
  height = '24px',
  width = '100%',
  onClick,
  startIcon,
  fontColor,
  fontColorOnHover,
  justifyContent,
  sx,
  children,
  ...rest
}: Props) {
  return (
    <Button
      variant="contained"
      value={value}
      startIcon={startIcon}
      sx={{
        ...sx,
        borderRadius: '4px',
        height: height,
        width: width,
        justifyContent: { justifyContent },
        textTransform: 'none',
        letterSpacing: '0.4px',
        backgroundColor: buttonColor,
        color: fontColor,
        fontSize: fontSize.caption,
        fontWeight: fontWeight.caption,
        '&:hover': {
          backgroundColor: buttonColorHover,
          color: fontColorOnHover,
        },
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
      {title}
    </Button>
  );
}

export default StyledTagButton;

import colors from 'constants/colors';

const MuiFab = {
  variants: [
    {
      props: { color: 'primary' },
      style: {
        '&:hover': {
          backgroundColor: colors.primary300,
        },
      },
    },
  ],
} as any;

export default MuiFab;

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// import { useSearchParams, useLocation } from 'react-router-dom';
// import useNavigateSearch from 'hooks/useNavigateSearchParams';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Typography from 'components/common/Typography';
import colors from 'constants/colors';
import Box from 'components/common/Box/Box';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import VerifiedIcon from '@mui/icons-material/Verified';
import Stack from '../Stack/Stack';
import { Icon } from '@mui/material';

type Props = {
  items: Array<String> | Array<Number>;
  title?: string;
  handleChange: (e: SelectChangeEvent<string>) => void;
  disabled?: boolean;
  value?: string;
  required?: boolean;
  error?: boolean;
  subtitle?: string;
  placeholder?: string;
  data: any;
};

function SelectMenu({
  items,
  title,
  subtitle,
  disabled = false,
  handleChange,
  value = '',
  required,
  error,
  placeholder,
  data,
}: Props) {
  const onChange = (event: SelectChangeEvent) => {
    handleChange(event);
  };
  const { t } = useTranslation('common');

  return (
    <Box>
      {title ? (
        <Typography
          pb={subtitle ? 0 : 1}
          variant="body1"
          color={error ? 'error.main' : 'neutral.contrastText'}
        >
          {`${title}${required ? ' *' : ''}`}
        </Typography>
      ) : null}
      {subtitle ? (
        <Typography
          pb={1}
          variant={'caption2'}
          display="block"
          color={error ? 'error.main' : 'neutral.contrastText'}
        >
          {`${subtitle}${required ? ' *' : ''}`}
        </Typography>
      ) : null}
      <Select
        required={required}
        disabled={disabled}
        variant="filled"
        fullWidth
        disableUnderline
        displayEmpty
        color="primary"
        value={value}
        label={title}
        onChange={onChange}
        renderValue={(selected) => {
          return selected ? (
            selected
          ) : (
            <span style={{ opacity: 0.4 }}>{placeholder}</span>
          );
        }}
        sx={{
          borderRadius: '3px',
          backgroundColor: colors.neutral100,
          '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
          },
          '& .MuiSelect-select': {
            paddingTop: '16.5px',
            paddingBottom: '16.5px',
          },
        }}
      >
        {items.length === 0 ? (
          <MenuItem disabled value="">
            <em>{t('None')}</em>
          </MenuItem>
        ) : null}
        {items.map((item, key) => {
          return (
            <MenuItem value={`${item}`} key={key}>
              <Stack spacing={1} direction={'row'} width={'100%'}>
                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {' '}
                  {item}
                </Typography>
                {data &&
                data.find((entry: any) => entry.name === item)
                  ?.matched_company ? (
                  <VerifiedIcon color="primary" />
                ) : (
                  <Icon />
                )}
                {data &&
                data.find((entry: any) => entry.name === item)
                  ?.reporting_company ? (
                  <FactCheckIcon color="secondary" />
                ) : (
                  <Icon />
                )}
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
}
export default observer(SelectMenu);

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button';
import Typography from 'components/common/Typography';
import { ArrowBackIcon } from 'components/icons/mui_icons';
import { useNavigate } from 'react-router-dom';

function GoBackButton() {
  let navigate = useNavigate();
  const { t } = useTranslation('common');

  return (
    <Button
      variant="text"
      color="primary"
      size="small"
      onClick={() => {
        navigate(-1);
      }}
      sx={{ '&:hover': { backgroundColor: 'primary.contrastText' } }}
    >
      <ArrowBackIcon color={'primary'} fontSize="small" />

      <Typography
        variant="action_small"
        color="primary"
        ml={'5px'}
        sx={{ textTransform: 'uppercase' }}
      >
        {t('Back')}
      </Typography>
    </Button>
  );
}

export default observer(GoBackButton);

import { observer } from 'mobx-react-lite';
import ReactApexChart from 'react-apexcharts';

import Typography from 'components/common/Typography/Typography';
import Stack from 'components/common/Stack/Stack';
import Grid from 'components/common/Grid/Grid';

function Component({ title, data, labels, colors }) {
  const suplliersPieConf = {
    series: data,
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: labels,
      colors: colors,
      dataLabels: {
        enabled: true,
        position: 'left',
        style: {
          colors: [colors.neutral100],
          fontSize: '14px',
        },
        dropShadow: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <Grid>
      <Typography variant="h5">{title}</Typography>
      <Stack pt={4}>
        <ReactApexChart
          options={suplliersPieConf.options}
          series={suplliersPieConf.series}
          width={600}
          type="pie"
        />
      </Stack>
    </Grid>
  );
}

export default observer(Component);

import { decorate, observable, action, runInAction } from 'mobx';

import axios from 'axios';
import api from 'constants/api';
import * as schema from 'types/backendSchema';
import i18n from 'i18n';

import { phCapture } from 'utils/posthog';
import { getLanguagePreference } from 'utils/browser';
import AuthStore from 'state/AuthStore';
import SnackbarMessage from 'state/SnackbarMessageStore';
import FormStore from 'state/FormStore';
import {
  email,
  integerGreaterThanZero,
  required,
  combineFieldValidators,
} from 'state/FormStore/fieldValidators';
class SignUpStore {
  // OBSERVABLES................................................................
  hasSubmitted = false;
  isSubmitting = false;
  emailSent = false;
  hasVerifiedEmail = false;
  isVerifyingEmail = false;
  emailVerified = false;
  tokenInvalid = false;
  hasResentEmail = false;
  formPage = 0;
  signUpForm1 = new FormStore({
    defaults: {
      language: getLanguagePreference({ twoLetter: true }),
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      contact_number: '',
      job_title: '',
      company_name: '',
      country: '',
      website: '',
    },
    fieldValidators: {
      language: required,
      email: combineFieldValidators([required, email]),
      company_name: required,
      address: required,
      city: required,
      country: required,
    },
  });
  signUpForm2 = new FormStore({
    defaults: {
      sector: '',
      password: '',
      password_confirm: '',
      parent_company_name: '',
      has_parent_company: false,
      lei_code: '',
      employee_count: '',
      employee_count_year: new Date().getFullYear() - 1,
      currency: '',
      accepted_privacy_policy: true, //FIXME: remove this when we remove the from the backend
    },
    fieldValidators: {
      employee_count: combineFieldValidators([
        required,
        integerGreaterThanZero,
      ]),
      employee_count_year: required,
      password: required,
      sector: required,
      password_confirm: combineFieldValidators([
        required,
        (value, values): string => {
          return values && value !== values['password']
            ? i18n.t('Passwords must match')
            : '';
        },
      ]),
    },
  });
  errorResponse = {} as { [key: string]: any };
  turnover_amount = {} as { [key: string]: string };
  turnover_year = {} as { [key: string]: string };
  buyerId: string | undefined = undefined;

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................

  setFormPage = (pageNum: number) => {
    this.formPage = pageNum;
  };

  setBuyerID = (id: string) => {
    this.buyerId = id;
  };

  submitForm1 = async ({ auth }: { auth: AuthStore }) => {
    try {
      this.signUpForm1.checkFieldErrors();
      if (!this.signUpForm1.hasErrors) {
        await axios.post(
          `${api.url}/auth/signup/check_email/`,
          { email: this.signUpForm1.values.get('email') },
          auth.requestConfig
        );
        phCapture('signup form 1 submit');
        this.setFormPage(2);
      }
    } catch (err: any) {
      if (err && err.response && err.response.status === 400) {
        this.signUpForm1.setFieldError('email', err.response.data['400']);
      } else {
        console.error(err);
        window.Sentry?.captureException(err);
      }
    }
  };

  submitSignUp = async ({
    auth,
    snackbarMessage,
  }: {
    auth: AuthStore;
    snackbarMessage: SnackbarMessage;
  }) => {
    this.signUpForm2.checkFieldErrors();
    if (!this.signUpForm2.hasErrors) {
      this.isSubmitting = true;
      const data = {
        ...this.signUpForm1.deserialize(),
        ...this.signUpForm2.deserialize(),
      };
      const {
        email,
        job_title,
        accepted_terms,
        accepted_privacy_policy,
        first_name,
        last_name,
        password,
        password_confirm,
        language,
        title,
        company_name,
        address,
        address2,
        zip_code,
        city,
        region,
        contact_number,
        has_subsidiaries,
        parent_company_name,
        lei_code,
        employee_count,
        employee_count_year,
        sector,
        turnover_amount,
        turnover_year,
        country,
        currency,
        tier,
        website,
      } = data;
      const signupUser: schema.definitions['SignUpUser'] = {
        email,
        job_title,
        accepted_terms,
        accepted_privacy_policy,
        first_name,
        last_name,
        contact_number,
        password,
        password_confirm,
        language,
        title,
      };
      const signUpCompany: schema.definitions['SignUpCompany'] = {
        company_name,
        has_subsidiaries,
        parent_company_name,
        has_parent_company: parent_company_name.trim().length > 0,
        lei_code,
        employee_count_year,
        employee_count,
        turnover_amount,
        turnover_year,
        sector,
        currency,
        address,
        address2,
        zip_code,
        city,
        region,
        country,
        tier,
        website,
      };
      try {
        let signUpUrl = '';
        if (this.buyerId) {
          signUpUrl = `${api.url}/auth/signup/${this.buyerId}/`;
        } else {
          signUpUrl = `${api.url}/auth/signup/`;
        }
        await axios.post(
          signUpUrl,
          { user_details: signupUser, company_details: signUpCompany },
          auth.requestConfig
        );
        this.submitSignupSuccess();
      } catch (err: any) {
        this.submitSignupFailure(err, snackbarMessage);
      }
    }
  };

  submitSignupSuccess = () => {
    this.isSubmitting = false;
    this.hasSubmitted = true;
    this.setFormPage(3); // <-- go to succcess page
    phCapture('signup form 2 submit');
  };

  submitSignupFailure = (err: any, snackbarMessage: SnackbarMessage) => {
    this.isSubmitting = false;
    this.hasSubmitted = false;
    const errors = {
      ...err.response?.data?.user_details,
      ...err.response?.data?.company_details,
    };
    let needsToGoToForm1 = false;
    Object.keys(errors).forEach((field) => {
      if (this.signUpForm1.values.has(field)) {
        needsToGoToForm1 = true;
        this.signUpForm1.setFieldError(field, errors[field].join(' '));
      } else if (this.signUpForm2.values.has(field)) {
        this.signUpForm2.setFieldError(field, errors[field].join(' '));
      } else {
        console.error(err);
        window.Sentry?.captureException(err);
        snackbarMessage.addMessage({
          message: i18n.t('There was a problem'),
        });
      }
    });
    if (needsToGoToForm1) {
      this.setFormPage(1);
    }
  };

  toggleEmailSent = () => {
    this.emailSent = !this.emailSent;
  };

  verifyEmail = async ({
    auth,
    uid,
    token,
  }: {
    auth: AuthStore;
    uid?: string;
    token?: string;
  }) => {
    this.isVerifyingEmail = true;
    try {
      await axios.get(
        `${api.url}/auth/email/verify/${uid}/${token}/`,
        auth.requestConfig
      );
      this.verifiyEmailSuccess();
      phCapture('email verified');
    } catch (err) {
      this.verifyEmailFailure(err);
    }
  };

  verifiyEmailSuccess = () => {
    this.emailVerified = true;
    this.hasVerifiedEmail = true;
    this.isVerifyingEmail = false;
  };

  verifyEmailFailure = (err: any) => {
    if (err?.response?.data?.token) {
      this.tokenInvalid = true;
    } else {
      window.Sentry?.captureException(err);
      console.error(err);
    }
    this.hasVerifiedEmail = true;
    this.isVerifyingEmail = false;
  };

  resendEmail = async ({
    auth,
    email,
  }: {
    auth: AuthStore;
    email?: string;
  }) => {
    try {
      await axios.post(
        `${api.url}/auth/email/verify/resend/`,
        { email: email || auth.userDetails.email },
        auth.requestConfig
      );
    } catch (err) {
      console.error(err);
      window.Sentry?.captureException(err);
    } finally {
      runInAction(() => {
        this.hasResentEmail = true;
      });
    }
  };
}

decorate(SignUpStore, {
  formPage: observable,
  errorResponse: observable,
  setFormPage: action,
  hasSubmitted: observable,
  isSubmitting: observable,
  isVerifyingEmail: observable,
  hasVerifiedEmail: observable,
  hasResentEmail: observable,
  turnover_amount: observable,
  turnover_year: observable,
  emailSent: observable,
  emailVerified: observable,
  tokenInvalid: observable,
  buyerId: observable,
  setBuyerID: action,
  submitForm1: action,
  submitSignUp: action,
  submitSignupSuccess: action,
  submitSignupFailure: action,
  toggleEmailSent: action,
  verifyEmail: action,
  verifiyEmailSuccess: action,
  verifyEmailFailure: action,
  resendEmail: action,
});

export default SignUpStore;

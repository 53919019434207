import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button';
import Box from 'components/common/Box';

import RFIDialog from '../../RFIDialog';

type Props = {
  id: string;
  companyName: string;
  userDetails: object;
};

function UserDashboardSuppliersAddSuppliers({
  id,
  companyName,
  userDetails,
}: Props) {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const setOpenRFI = useCallback(() => {
    setOpen(!open);
    return true;
  }, [open]);

  return (
    <Box>
      <Button variant={'contained'} onClick={setOpenRFI}>
        {t('Upload supplier list')}
      </Button>
      <RFIDialog
        open={open}
        closeDialog={setOpenRFI}
        identifier={id}
        companyName={companyName}
        userDetails={userDetails}
      />
    </Box>
  );
}

export default UserDashboardSuppliersAddSuppliers;

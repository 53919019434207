import colors from 'constants/colors';

const tccMuiButton = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      fontWeight: 'bold',
      letterSpacing: 1.5,
      fontSize: '12px',
    },
  },
  variants: [
    {
      props: { variant: 'contained', color: 'primary' },
      style: {
        '&:hover': {
          backgroundColor: colors.primary300,
        },
      },
    },
    {
      props: { variant: 'contained', color: 'secondary' },
      style: {
        '&:hover': {
          backgroundColor: colors.secondary400,
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'primary' },
      style: {
        '&:hover': {
          backgroundColor: colors.neutral200,
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'secondary' },
      style: {
        '&:hover': {
          backgroundColor: colors.neutral200,
        },
      },
    },
    {
      props: { variant: 'text' },
      style: {
        color: colors.neutral700,
        '&:hover': {
          backgroundColor: colors.neutral200,
        },
      },
    },
    {
      props: { variant: 'whiteBackground' },
      style: {
        color: colors.secondary500,
        backgroundColor: colors.white,
        '&:hover': {
          backgroundColor: colors.neutral200,
        },
      },
    },
    {
      props: { variant: 'formBackButton' },
      style: {
        color: colors.neutral500,
        backgroundColor: colors.neutral100,
        '&:hover': {
          backgroundColor: colors.neutral200,
        },
      },
    },
  ],
} as any;

export default tccMuiButton;

import { decorate, observable, action } from 'mobx';

import i18n from 'i18n';
import axios from 'axios';
import api from 'constants/api';
import {
  combineFieldValidators,
  required,
  email,
} from 'state/FormStore/fieldValidators';
import * as schema from 'types/backendSchema';

import FormStore from 'state/FormStore';
import AuthStore from 'state/AuthStore';
import SnackbarMessageStore from 'state/SnackbarMessageStore';

class ForgetPasswordStore {
  constructor() {
    this.form = new FormStore({
      defaults: {
        email: '',
      },
      fieldValidators: {
        email: combineFieldValidators([required, email]),
      },
    });
  }

  form: FormStore;
  // OBSERVABLES................................................................
  isSubmitting = false;
  emailSent = false;

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  onForgetPasswordSubmit = ({
    auth,
    snackbarMessage,
  }: {
    auth: AuthStore;
    snackbarMessage: SnackbarMessageStore;
  }) => {
    this.submit({ auth, snackbarMessage });
  };

  submit = async ({
    auth,
    snackbarMessage,
  }: {
    auth: AuthStore;
    snackbarMessage: SnackbarMessageStore;
  }) => {
    this.isSubmitting = true;
    const { email } = this.form.deserialize();

    try {
      const res = await axios.post(
        `${api.url}/auth/password/reset/`,
        { email } as schema.definitions['PasswordReset'],
        auth.requestConfig
      );
      if (res && res.data) {
        snackbarMessage.addMessage({
          message: i18n.t('common:::Forget password email sent.'),
        });
        this.forgetPasswordSuccess();
      } else {
        this.forgetPasswordFailure();
      }
    } catch (err) {
      console.error(err);
      window.Sentry?.captureException(err);
      this.forgetPasswordFailure(err);
    }
  };

  forgetPasswordSuccess = () => {
    this.isSubmitting = false;
    this.form.resetValues();
    this.toggleEmailSent();
  };

  forgetPasswordFailure = (err?: any) => {
    this.isSubmitting = false;
  };

  toggleEmailSent = () => {
    this.emailSent = !this.emailSent;
  };
}

decorate(ForgetPasswordStore, {
  isSubmitting: observable,
  emailSent: observable,
  onForgetPasswordSubmit: action,
  submit: action,
  forgetPasswordSuccess: action,
  forgetPasswordFailure: action,
  toggleEmailSent: action,
});

export default ForgetPasswordStore;

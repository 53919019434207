import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import Card from 'components/common/Card';

import Button from 'components/common/Button';
import CardMedia from 'components/common/CardMedia';
import { MailOutlineIcon } from 'components/icons/mui_icons';
import RFIDialog from './RFIDialog';

import { dashboardImages } from 'media/user_dashboard';
import colors from 'constants/colors';
import Box from 'components/common/Box';

type Props = {
  companyName?: string;
  id?: string;
  userDetails: object;
};

function UserDashboardContact({ companyName, id, userDetails }: Props) {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const setOpenRFI = useCallback(() => {
    setOpen(!open);
    return true;
  }, [open]);

  return (
    <Card>
      <Stack spacing={2}>
        <Typography variant="h5">{t('Want to know more?')}</Typography>
        <Typography variant="body1" color={'inherit'} mt={2}>
          {t(
            `If you have questions or would like to give us feedback, please contact us and we'll get back to you as soon as possible.`
          )}
        </Typography>
        <Box />
        <Box>
          <Button
            size="large"
            variant={'contained'}
            startIcon={<MailOutlineIcon />}
            onClick={setOpenRFI}
          >
            {t('Contact us')}
          </Button>
        </Box>
      </Stack>
      <RFIDialog
        open={open}
        closeDialog={setOpenRFI}
        identifier={id}
        companyName={companyName}
        userDetails={userDetails}
      />
    </Card>
  );
}

export default observer(UserDashboardContact);

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useNavigateSearch from 'hooks/useNavigateSearchParams';
import { useSearchParams, useLocation } from 'react-router-dom';

import Box from 'components/common/Box';
import Button from 'components/common/Button';
import FilterChip from './FilterChip';
import FilterAutocomplete from './FilterAutocomplete';
import { sortArrayOfStringsAlphabetically } from 'utils/tools';

type tagsTableProps = [
  {
    key: string;
    value: string;
  }
];

type tagsProps = {
  filterId: string;
  tags: Array<string>;
  tagsTable: tagsTableProps;
};

const FilterSectionSearch = ({ filterId, tags, tagsTable }: tagsProps) => {
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  const [selectedTagsKeys, setSelectedTagsKeys] = React.useState<string[]>([]);

  const productTags = searchParams.get(filterId) || '';
  const selectedTags = tagsTable
    .filter((item) => selectedTagsKeys.includes(item.key))
    .map((item) => item.value);

  const tagsList = tagsTable
    .filter((item) => item.key !== '')
    .map((tag) => tag.value);

  useEffect(() => {
    if (
      productTags !== '' &&
      productTags !== 'undefined' &&
      productTags !== undefined
    ) {
      const productTagsArray = productTags.split(',');
      setSelectedTagsKeys(
        productTagsArray.map((productTag: string) =>
          productTag.replace('+', ' ')
        )
      );
    } else {
      setSelectedTagsKeys([]);
    }
  }, [filterId, productTags]);

  const goToRoute = (keysArray: string[]) => {
    navigateSearch({
      pathname: location.pathname,
      params: {
        page: 1,
        [filterId]: keysArray.length > 0 ? keysArray.join(',') : keysArray,
      },
      replace: false,
    });
  };

  const handleCompanyFilterChange = (options: Array<string>) => {
    const selectedTableItems = tagsTable.filter((item) =>
      options.includes(item.value)
    );
    const selectedItemsKeys = selectedTableItems.map((item) => item.key);
    goToRoute(selectedItemsKeys);
    setSelectedTagsKeys(selectedItemsKeys);
  };

  const onDelete = (selectedTag: string) => () => {
    const selectedTableItem = tagsTable.filter(
      (item) => item.key === selectedTag
    )[0];
    const restKeys = selectedTagsKeys.filter(
      (item) => item !== selectedTableItem.key
    );
    goToRoute(restKeys);
    setSelectedTagsKeys(restKeys);
  };

  const handleClearAll = () => {
    setSelectedTagsKeys([]);
    navigateSearch({
      pathname: location.pathname,
      params: { page: 1, [filterId]: [] },
      replace: false,
    });
  };

  return (
    <>
      {tags && Object.values(tags) !== undefined ? (
        <>
          <FilterAutocomplete
            options={sortArrayOfStringsAlphabetically(tagsList)}
            onChange={handleCompanyFilterChange}
            value={selectedTags}
            textField={t('Search')}
          />
          <Box
            mt={3}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {selectedTagsKeys.map((selectedTagKey) => {
              const label = `${
                tagsTable.find((item) => item.key === selectedTagKey)?.value
              }`;
              return (
                <FilterChip
                  key={selectedTagKey}
                  selectedTagKey={selectedTagKey}
                  label={label}
                  onDelete={onDelete(selectedTagKey)}
                />
              );
            })}
          </Box>
          {selectedTagsKeys.length > 0 ? (
            <Button onClick={() => handleClearAll()}>{t('Clear All')}</Button>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default observer(FilterSectionSearch);

import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

// appens filter to query if the filter not already exists

const useMultiSelectFilterNavigation = () => {
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  return (queryParam: string, filter: string) => {
    const selectedFilter = filter;
    let newFilters: string[] = [];
    if (searchParams.has(queryParam) && searchParams.get(queryParam) !== null) {
      let queryParamsValues = searchParams.get(queryParam);
      if (queryParamsValues !== null)
        newFilters = queryParamsValues?.split(',');
    }

    if (newFilters.includes(selectedFilter)) {
      const index = newFilters.findIndex(
        (element: string) => element === selectedFilter
      );
      newFilters.splice(index, 1);
    } else {
      newFilters.push(selectedFilter);
    }
    navigateSearch({
      pathname: location.pathname,
      params: {
        page: 1,
        [queryParam]: newFilters.length > 0 ? newFilters.join(',') : newFilters,
      },
      replace: false,
    });
  };
};

export default useMultiSelectFilterNavigation;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import colors from 'constants/colors';

import Grid from 'components/common/Grid';
import Button from 'components/common/Button';

let debounceId: ReturnType<typeof setTimeout>;
const DEBOUNCE_INTERVAL = 300; // ms

const Search = () => {
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();
  const [searchText, setSearchText] = React.useState(
    searchParams.get('search') || ''
  );

  const searchCompanies = (string = '') => {
    setSearchText(string);
    clearTimeout(debounceId);
    debounceId = setTimeout(
      () =>
        navigateSearch({
          pathname: location.pathname,
          params: {
            page: 1,
            search: string === '' ? [] : string,
          },
          replace: false,
        }),
      DEBOUNCE_INTERVAL
    );
  };

  return (
    <Grid item mb={'22px'}>
      <TextField
        fullWidth
        placeholder={t('Search companies')}
        variant="standard"
        size="small"
        value={searchText}
        onChange={(e) => searchCompanies(e.target.value)}
        InputProps={{
          disableUnderline: true,
          style: {
            paddingLeft: 10,
            borderRadius: '8px',
            backgroundColor: colors.neutral100,
          },
          endAdornment: (
            <Button
              color="primary"
              variant="contained"
              size="medium"
              startIcon={!!searchText ? <CloseIcon /> : <SearchIcon />}
              onClick={!!searchText ? () => searchCompanies('') : () => null}
              sx={{ borderRadius: '0px 8px 8px 0px' }}
            >
              {t('Search')}
            </Button>
          ),
        }}
      />
    </Grid>
  );
};

export default observer(Search);

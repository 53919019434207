import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';

import { useTranslation } from 'react-i18next';
import EmptyTextField from 'components/common/EmptyTextField';
import { getLanguagePreference } from 'utils/browser';
import SourceChannelChip from '../../SourceChannelChip';

export default function EmissionsDataTable({ data }: { data: any }) {
  const { t } = useTranslation('common');

  const rows = data.map((datapoints: any) => {
    const publishDate = new Date(datapoints.publish_date);
    const publishDateYear = publishDate.getFullYear();
    return { id: Math.random(), ...datapoints, publish_date: publishDateYear };
  });

  const columns: GridColDef[] = [
    { field: 'scope', headerName: t('Scope'), flex: 0, sortable: false },
    {
      field: 'framework',
      headerName: t('Framework'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'ambition',
      headerName: t('Ambition'),
      flex: 0,
      sortable: false,
    },
    {
      field: 'target_year',
      headerName: t('Target Year'),
      sortable: false,
      flex: 0,
    },
    {
      field: 'target_type',
      headerName: t('Target Type'),
      sortable: false,
      flex: 0,
    },
    {
      field: 'base_year',
      headerName: t('Base Year'),
      sortable: false,
      flex: 0,
    },
    {
      field: 'reduction',
      headerName: t('Reduction'),
      sortable: false,
      flex: 0,
      renderCell: (params) => (
        <div>
          {params.row &&
            typeof params.row.reduction === 'number' &&
            `${params.row.reduction}%`}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: t('Status'),
      sortable: false,
      flex: 0,
    },
    {
      field: 'publish_year',
      headerName: t('Publish Year'),
      flex: 0,
      sortable: false,
    },
    {
      field: 'source',
      headerName: t('Source'),
      sortable: false,
      flex: 0,
      renderCell: (params) => {
        return <SourceChannelChip channel={params.row.source_channel} />;
      },
    },
  ];

  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('Data unavailable'),
  };

  return !data ? (
    <EmptyTextField text={t('Data unavailable') + ''} />
  ) : (
    <div style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        localeText={localeText}
        sx={{ width: '100%' }}
        rows={rows}
        columns={columns}
        hideFooter
      />
    </div>
  );
}

import React from 'react';
import DashboardTable from 'components/common/DashboardTable';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Pagination from '@mui/material/Pagination';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import colors from 'constants/colors';
import useUserCompany from 'hooks/useUserCompany';
import DashboardNumberCard from 'components/common/DashboardNumberCard';

const rowsPerPage = 5;

function Component({ countOnly }: { countOnly?: boolean }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const [page, setPage] = React.useState(1);
  const { userCompany } = useUserCompany();

  const getData = async (page: number) => {
    const res = await axios.get(
      `${api.url}/user/company/suppliers/dashboard/suppliers/?has_ccf_all_three_scopes=True&has_pcf=True&page=${page}`,
      auth.requestConfig
    );
    return res.data;
  };
  const { isLoading, data, isError, isFetching } = useQuery({
    queryKey: ['supplier-ccf-and-pcf', page],
    queryFn: () => getData(page),
    keepPreviousData: true,
  });

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : !userCompany.suppliers_count ? null : countOnly ? (
    <DashboardNumberCard
      textColor={colors.neutral600}
      totalNum={Math.round((data.count / userCompany.suppliers_count) * 100)}
      postFix="%"
    />
  ) : (
    <Stack spacing={2}>
      <DashboardTable
        isLoading={isFetching}
        suppliers={data.results}
        suppliersCount={data.count}
      />
      <Pagination
        color="primary"
        shape="rounded"
        count={Math.ceil(data.count / rowsPerPage)}
        page={page}
        onChange={(event, value) => {
          event.preventDefault();
          setPage(value);
        }}
        sx={{ marginLeft: '0px' }}
      />
    </Stack>
  );
}

export default observer(Component);

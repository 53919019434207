// import React from 'react'
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import colors from 'constants/colors';

import Stack from 'components/common/Stack';

import DashboardPieChart from 'components/common/DashboardPieChart';

function Component() {
  const { t } = useTranslation();
  const auth = useAuth();
  const { isLoading, data, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['supplier-screening-target-distribution', { type: 'any' }],
    queryFn: async () => {
      const res = await axios.get(
        `${
          api.url
        }/user/company/suppliers/dashboard/target_distributions/${'any'}/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  return isLoading ? (
    <Loading color="primary" />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <Stack spacing={2}>
      <DashboardPieChart
        legendBottom
        labels={data ? data.map((d: any) => d.value) : []}
        data={data ? data.map((d: any) => d.count) : []}
        colors={[
          colors.primary500,
          colors.secondary500,
          colors.tertiary500,
          colors.neutral500,
          colors.neutral200,
        ]}
      />
    </Stack>
  );
}

export default observer(Component);

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Box from 'components/common/Box';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';

import RetakeCrcCard from './RetakeCrcCard';

import colors from 'constants/colors';

import * as schema from 'types/backendSchema';
import useAuth from 'hooks/useAuth';
import useWizardStore from 'components/App/Routes/CrcWizard/Wizard/store/WizardStore';

type Props = {
  questions: schema.definitions['SurveyQuestion'][];
};

const CRCOverviewYourAnswers = ({ questions }: Props) => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const navigate = useNavigate();

  let uniqueGroupIdsSet = new Set<number>();
  questions.forEach((question) => uniqueGroupIdsSet.add(question.group_id));
  const uniqueGroupIds = Array.from(uniqueGroupIdsSet);

  const { crcActive, isCrcActive, createNewCrc, initWizard } = useWizardStore();

  useEffect(() => {
    isCrcActive({ auth });
  }, [auth, isCrcActive]);

  return (
    <Stack spacing={{ xs: 2, sm: 3 }} padding={3}>
      <Typography variant="h5" color="onSureface.100" alignSelf={'flex-start'}>
        {t('Your Answers')}
      </Typography>
      <Stack spacing={2}>
        {uniqueGroupIds.map((groupId, index) => {
          const questionsGroup = questions.filter(
            (question) => question.group_id === groupId
          );
          return (
            <Box
              key={index}
              sx={{
                backgroundColor: colors.neutral100,
                p: '30px',
                borderRadius: '6px',
                justifyContent: 'space-evenly',
                justifyItems: 'center',
              }}
            >
              {questionsGroup.map((question, index) => {
                if (!question.is_skipped) {
                  return (
                    <Box key={index} marginBottom={1}>
                      <Typography variant={'subtitle2'} key={index}>
                        {question.question_identifier}
                        {question.question_identifier?.includes('.')
                          ? ' '
                          : '. '}
                        {question.question_text}
                      </Typography>

                      {question.options
                        .filter((option) => option.selected === true)
                        .map((answers, index) => {
                          return (
                            <Typography
                              key={index}
                              variant={'body1'}
                              color={'text.secondary'}
                              sx={{ display: 'list-item', marginLeft: 3 }}
                            >
                              {answers.text}
                            </Typography>
                          );
                        })}
                    </Box>
                  );
                } else return null;
              })}
            </Box>
          );
        })}
      </Stack>
      <RetakeCrcCard
        title={
          crcActive
            ? t('You or another user of your company started the survey')
            : t('Have your responses changed?')
        }
        buttonText={
          crcActive
            ? t('Continue the Climate Readiness Check')
            : t('Retake the Climate Readiness Check')
        }
        onClick={async () => {
          if (!crcActive) {
            await createNewCrc({ auth });
          }
          initWizard({ auth: auth, id: 'latest_crc' });
          navigate('/crc-questions');
        }}
      />
    </Stack>
  );
};

export default observer(CRCOverviewYourAnswers);

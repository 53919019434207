// import React from 'react'
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';
import Typography from 'components/common/Typography';

import useAuth from 'hooks/useAuth';
import Grid from 'components/common/Grid';
import api from 'constants/api';
import Loading from 'components/common/Loading/Loading';
import BaseYearDataTable from './BaseYearDataTable';

export type BaseYearDataPoint = {
  reporting_year: number;
  source_channel: string;
  value: string;
};
const getPoints = (data: any): [BaseYearDataPoint[], string] => {
  if (!data) return [[], ''];
  let unit = '';
  const points = data.map(
    ({ variable, datapoints }: { variable: any; datapoints: any }) => {
      unit = variable.unit;
      const valueMap = datapoints.map((point: any) => {
        return {
          id: Math.random(),
          reporting_year: point.reporting_year,
          value: point.value,
          source_channel: point.source_channel,
        };
      });
      return valueMap;
    }
  );
  let formattedData = [] as BaseYearDataPoint[];
  points.forEach((datapoints: any) => {
    formattedData = formattedData.concat(datapoints);
  });
  return [formattedData, unit];
};

function Component({ slug }: { slug: string | undefined }) {
  const auth = useAuth();
  const { t } = useTranslation('common');
  const { isLoading, data, isError } = useQuery({
    queryKey: [`company-variables-base-year-${slug}`],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/variables/${slug}?starts_with=BASE_YEAR`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const [formattedData] = getPoints(data);
  return (
    <Grid container spacing={2}>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Alert severity="error">{t('There was a problem')}</Alert>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" color="onSurface.100">
              {t('Emissions Base Year')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BaseYearDataTable data={formattedData} />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default observer(Component);

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import SourceChannelChip from 'components/App/Routes/Company/CompanyDetailsPage/SourceChannelChip';
import { deDE } from '@mui/x-data-grid/locales';
import { useTranslation } from 'react-i18next';
import { getLanguagePreference } from 'utils/browser';
import useAdminCompanyDataPoints from 'hooks/useAdminCompanyDataPoints';

export default function EmissionsDataTable({ jobId }: { jobId?: string }) {
  const { t } = useTranslation('common');
  const { isLoading, data, page, setPage } = useAdminCompanyDataPoints({
    jobId,
  });
  const columns: GridColDef[] = [
    { field: 'reporting_year', headerName: t('Year'), flex: 0, sortable: true },
    { field: 'variable', headerName: t('Variable'), flex: 1, sortable: false },
    {
      field: 'value',
      headerName: t('Value'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'source',
      headerName: t('Source'),
      sortable: false,
      flex: 0,
      renderCell: (params) => {
        return <SourceChannelChip channel={params.row.source_channel} />;
      },
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    //noRowsLabel: t('Data unavailable'),
  };

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        loading={isLoading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'reporting_year', sort: 'desc' }],
          },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        autoHeight
        localeText={localeText}
        sx={{ width: '100%' }}
        rows={data?.results || []}
        columns={columns}
        pagination
        hideFooter={!data || data?.count <= 10}
        rowCount={data?.count || 0}
        paginationMode="server"
        pageSizeOptions={[10]}
        paginationModel={{ page: page - 1, pageSize: 10 }}
        onPaginationModelChange={(model) => {
          setPage(model.page + 1);
        }}
      />
    </div>
  );
}

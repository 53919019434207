import React from 'react';

import Grid from 'components/common/Grid';
import SaveFavoriteButton from 'components/common/SaveFavoriteButton';

import CompanyRating from 'components/common/CompanyRating/CompanyRating';
import CompanyImpactCategories from './CompanyImpactCategories/CompanyImpactCategories';

type Props = {
  badge: string;
  label: string;
  companyImpactCategories: {};
};

function CompanyInfoBlock({ badge, label, companyImpactCategories }: Props) {
  return (
    <Grid mt={'28px'}>
      <Grid
        item
        container
        alignItems="center"
        justifyContent={'space-between'}
        wrap="wrap"
      >
        <Grid item xs={'auto'}>
          <CompanyRating badge={badge} label={label} />
        </Grid>
        <Grid item xs={'auto'} mr={'auto'} ml={'16px'}>
          <CompanyImpactCategories
            companyImpactCategories={companyImpactCategories}
          />
        </Grid>
        <Grid item xs={'auto'}>
          <SaveFavoriteButton />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CompanyInfoBlock;

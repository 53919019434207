import React from 'react';
import { styled } from '@mui/material/styles';

import Typography from 'components/common/Typography';
import Container from 'components/common/Container';
import Box from 'components/common/Box';
import Logo from 'components/icons/Logo';
import LanguageSwitch from 'components/App/Layout/LanguageSwitch';

const StyledContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(6),
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  width: '100%',
}));

const UnauthenticatedContainer = ({
  title,
  showLogo = true,
  subtitle,
  children,
  ...rest
}) => {
  return (
    <Container pageContainer component="main" disableGutters={false} {...rest}>
      <StyledContainer>
        {showLogo && <Logo sx={{ fontSize: '200px', lineHeight: 0 }} />}
        {title && (
          <Typography
            sx={{
              marginTop: (theme) => theme.spacing(-1),
              textAlign: 'left',
            }}
            color="primary"
            variant="h5"
            align="center"
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Container maxWidth="sm" disableGutters>
            <Typography
              sx={{
                marginTop: (theme) => title && theme.spacing(3),
                padding: '0 10%',
              }}
              variant="subtitle1"
              align="center"
            >
              {subtitle}
            </Typography>
          </Container>
        )}
        <StyledContent>{children}</StyledContent>
        <Box pt={2}>
          <LanguageSwitch />
        </Box>
      </StyledContainer>
    </Container>
  );
};

export default UnauthenticatedContainer;

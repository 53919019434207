import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom';
import { scrollToTop } from 'utils/browser';

type Props = {
  pathname: string;
  params: any;
  replace?: boolean;
  removeParams?: any[];
};

const useNavigateSearch = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  return ({ pathname, params, replace = true, removeParams }: Props) => {
    let newParams: { [key: string]: any } = {};
    if (replace) {
      newParams = { ...params };
    } else {
      newParams = {
        ...Object.fromEntries(searchParams),
        ...params,
      };
    }

    if (removeParams && removeParams.length > 0) {
      for (let param of removeParams) {
        delete newParams[param];
      }
    }

    navigate({ pathname, search: `?${createSearchParams(newParams)}` });
    scrollToTop();
  };
};

export default useNavigateSearch;

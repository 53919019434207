import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useCompanySectors() {
  const auth = useAuth();
  return useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 100000,
    staleTime: 100000,
    queryKey: ['company-sectors'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/sectors`,
        auth.requestConfig
      );
      return res.data;
    },
  });
}

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Switch,
} from '@mui/material';
import useCPASurvey from '../store/useCPASurvey';

interface CPASurveyQuestionEditProps {
  id: string;
  answers: string[];
  index: number;
  url?: string;
  values: any;
}

const CPASurveyQuestionEdit: React.FC<CPASurveyQuestionEditProps> = observer(
  ({ id, answers, index, url, values }) => {
    const { edits, saveEdit, saveNote, notes } = useCPASurvey();
    const { t } = useTranslation('common');
    const note = notes.get(id);
    const [useCheckboxes, setUseCheckboxes] = useState<boolean>(true);

    return (
      <Grid container spacing={2}>
        {index !== 0 && (
          <Grid item xs={12} sx={useCheckboxes ? { opacity: 0.5 } : {}}>
            <FormControlLabel
              control={
                <Switch
                  color="warning"
                  onChange={() => setUseCheckboxes(!useCheckboxes)}
                  checked={!useCheckboxes}
                />
              }
              label={t('Override checkboxes?')}
            />
          </Grid>
        )}
        {url ? (
          <Grid item xs={12}>
            <a href={url}>{values.file_name}</a>
            <Box>{t('file upload (work in progress)')}</Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {answers.map((value, i) => (
              <Box key={`${id}_${i}`} sx={{ mb: 2 }}>
                {index === 0 ? (
                  <TextField
                    fullWidth
                    label={value}
                    value={edits.get(`${id}_${i}`) || values[i] || ''}
                    onChange={(e) => saveEdit(`${id}_${i}`, e.target.value)}
                  />
                ) : answers.length === 1 || !useCheckboxes ? (
                  <TextField
                    fullWidth
                    label={value}
                    multiline
                    rows={5}
                    value={edits.get(`${id}_${i}`) || values[i] || ''}
                    onChange={(e) => saveEdit(`${id}_${i}`, e.target.value)}
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          edits.has(`${id}_${i}`)
                            ? !!edits.get(`${id}_${i}`)
                            : !!values[i] || false
                        }
                        onChange={(e) =>
                          saveEdit(`${id}_${i}`, e.target.checked ? value : '')
                        }
                        color="primary"
                      />
                    }
                    label={value}
                  />
                )}
              </Box>
            ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('Review Note')}
            multiline
            rows={5}
            value={note}
            onChange={(e) => saveNote(id, e.target.value)}
          />
        </Grid>
      </Grid>
    );
  }
);

export default CPASurveyQuestionEdit;

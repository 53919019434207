import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import Box from '@mui/material/Box';
import TextField from 'components/common/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from 'components/common/Typography';
import Button from 'components/common/Button';

import api from 'constants/api';
import colors from 'constants/colors';
import useAuth from 'hooks/useAuth';
import CircularProgress from '@mui/material/CircularProgress';

let debounceId: ReturnType<typeof setTimeout>;
let debounceId2: ReturnType<typeof setTimeout>;
const DEBOUNCE_INTERVAL = 300; // ms

interface ListBoxProps extends React.HTMLAttributes<HTMLUListElement> {
  loadMore?: any;
}

const ListBox = forwardRef(function ListBoxBase(
  props: ListBoxProps,
  ref: ForwardedRef<HTMLUListElement>
) {
  const { children, loadMore, ...rest } = props;

  const innerRef = useRef<HTMLUListElement>(null);

  useImperativeHandle<NullableUlElement, NullableUlElement>(
    ref,
    () => innerRef.current
  );

  return (
    <ul
      {...rest}
      ref={innerRef}
      //eslint-disable-next-line
      role="list-box"
    >
      {children}
      {loadMore ? (
        <Button fullWidth onClick={loadMore}>
          load more
        </Button>
      ) : null}
    </ul>
  );
});

type NullableUlElement = HTMLUListElement | null;

const Component = ({
  title,
  subtitle,
  onChange,
  required,
  value,
  error,
  placeholder,
}: {
  title?: string;
  subtitle?: string;
  onChange: (companyId: string) => void;
  required?: boolean;
  value?: string;
  error?: boolean;
  placeholder?: string;
}) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const auth = useAuth();
  const { isFetching, data, isError, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      enabled: open,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      queryKey: ['companies-admin', { searchText }],
      queryFn: async ({ pageParam = 1 }) => {
        const params = new URLSearchParams();
        params.set('page', pageParam);
        if (searchText) {
          params.set('search', searchText);
        }
        const res = await axios.get(
          `${api.url}/admin/companies/`,
          Object.assign(auth.requestConfig, { params })
        );
        return res.data;
      },
      getNextPageParam: (lastPage) => {
        if (!lastPage.next) return undefined;
        const url = new URL(lastPage.next);
        return url.searchParams.get('page') ?? undefined;
      },
    });

  const {
    isFetching: isFetchingValue,
    data: dataValue,
    isError: isErrorValue,
  } = useQuery({
    enabled: !!value,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 0,
    queryKey: ['companies-admin', { id: value }],
    queryFn: async () => {
      if (!value) {
        return [] as { [key: string]: any }[];
      }
      const params = new URLSearchParams();
      params.set('id', value);
      const res = await axios.get(
        `${api.url}/admin/companies/`,
        Object.assign(auth.requestConfig, { params })
      );
      return res.data.results as { [key: string]: any }[];
    },
  });

  let allPages: any[] = [];
  if (data && data.pages) {
    data.pages.forEach((page) => {
      page.results.forEach((result: any) => {
        allPages.push(result);
      });
    });
  }

  const options =
    dataValue && dataValue[0]?.name === searchText ? dataValue : allPages;

  const selectedValue =
    value && dataValue
      ? dataValue[0]
      : value && data?.pages
      ? data.pages.find((company) => company.id === value) || null
      : null;

  return (
    <Box display={'flex'} flexDirection={'column'}>
      {title ? (
        <Typography
          pb={subtitle ? 0 : 1}
          variant="body1"
          color={error ? 'error.main' : 'neutral.contrastText'}
        >
          {`${title}${required ? ' *' : ''}`}
        </Typography>
      ) : null}
      {subtitle ? (
        <Typography
          variant={'caption2'}
          color={error ? 'error.main' : 'neutral.contrastText'}
          pb={1}
        >
          {`${subtitle}${required && !title ? ' *' : ''}`}
        </Typography>
      ) : null}
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={isFetching || isFetchingValue}
        value={selectedValue}
        filterOptions={(x) => x}
        onChange={(e: any, option: any) => {
          clearTimeout(debounceId);
          debounceId = setTimeout(() => {
            const companyId = option?.id;
            onChange(companyId || '');
            setSearchText('');
          }, DEBOUNCE_INTERVAL);
        }}
        onInputChange={(e: any, inputValue, reason) => {
          clearTimeout(debounceId2);
          debounceId2 = setTimeout(() => {
            setSearchText(inputValue);
          }, DEBOUNCE_INTERVAL);
        }}
        ListboxComponent={ListBox}
        ListboxProps={{
          //@ts-ignore
          loadMore: hasNextPage ? fetchNextPage : null,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            placeholder={placeholder}
            sx={{
              backgroundColor: colors.neutral100,
              '& fieldset': { border: 'none' },
              disableUnderline: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {open && (isFetching || isFetchingValue) ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {isError || isErrorValue ? (
        <Alert sx={{ mt: 1 }} severity="error">
          {t('There was a problem')}
        </Alert>
      ) : null}
    </Box>
  );
};

export default Component;

import colors from 'constants/colors';
import { fontSize, fontWeight } from 'constants/typography';

const tccMuiChip = {
  defaultProps: {
    style: {
      borderRadius: '4px',
      height: '24px',
      justifyContent: 'space-between',
      textTransform: 'none',
      letterSpacing: '0.4px',
      backgroundColor: colors.neutral200,
      color: colors.neutral600,
      fontSize: fontSize.caption,
      fontWeight: fontWeight.caption,
    },
  },
} as any;

export default tccMuiChip;

import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Toolbar from 'components/common/Toolbar';
import IconButton from 'components/common/IconButton';
import Logo from 'components/icons/Logo';
import NotificationIcon from 'components/icons/Notification';

import LinksTabWeb from './LinksTabWeb';
import AvatarUserSettings from './AvatarUserSettings';
import BurgerLinksMobile from './BurgerLinksMobile';

import colors from 'constants/colors';

import LanguageSwitch from '../../LanguageSwitch';

const Component = () => {
  return (
    <Toolbar disableGutters={false}>
      <Box
        component={Link}
        to={'/'}
        sx={{
          display: { xs: 'none', md: 'flex' },
          cursor: 'pointer',
        }}
      >
        <Logo
          sx={{
            width: '90px',
            height: '60px',
            color: (theme: any) =>
              theme.palette.mode === 'dark'
                ? colors.white
                : colors.secondary400,
          }}
        />
      </Box>
      {/* Mobile */}
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <BurgerLinksMobile />
      </Box>
      {/* <Link to={'/'}> */}
      <Box
        component={Link}
        to={'/'}
        sx={{
          ml: '48px',
          flexGrow: 1,
          display: { xs: 'flex', md: 'none' },
          cursor: 'pointer',
        }}
      >
        <Logo sx={{ width: '90px', height: '60px' }} />
      </Box>
      {/* </Link> */}
      {/* Mobile-END */}
      <Box
        sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
        id={'TabsContainer'}
      >
        <LinksTabWeb />
      </Box>
      <Box sx={{ flexGrow: 0, mr: 1 }}>
        <LanguageSwitch />
      </Box>
      <Box sx={{ flexGrow: 0, mr: 1 }}>
        <IconButton
          sx={{
            color: (theme: any) =>
              theme.palette.mode === 'dark'
                ? colors.white
                : colors.secondary500,
          }}
        >
          <NotificationIcon />
        </IconButton>
      </Box>
      <AvatarUserSettings />
    </Toolbar>
  );
};
export default observer(Component);

import React from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import SelectMenu from 'components/common/SelectMenu';
import { useState } from 'react';
import Stack from 'components/common/Stack';
import Box from 'components/common/Box';
import ReactApexChart from 'react-apexcharts';

import scope3Breakdown from 'constants/scope3Breakdown';
import colors from 'constants/colors';

const availableYears = ['2022', '2021', '2020', '2019'];

function Component() {
  const { t } = useTranslation();
  const [year, setYear] = useState<string>(availableYears[0]);
  const auth = useAuth();
  const { isLoading, data, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['supplier-dashboard-scope-3-emissions', { year }],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/scope_3_emissions/${year}/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const companyNames = isLoading ? [] : data.map((c: any) => c.company_name);
  const companySlugs = isLoading ? [] : data.map((c: any) => c.company_slug);

  const config = !isLoading
    ? {
        series: scope3Breakdown.map((scope_variable_id: string) => {
          return {
            name: scope_variable_id,
            data: companySlugs.map(
              (slug: string) =>
                data
                  .find((c: any) => c.company_slug === slug)
                  ?.emissions.find(
                    (ce: any) => ce.variable_id === scope_variable_id
                  )?.value || 0
            ),
          };
        }),
        options: {
          colors: [
            colors.primary500,
            colors.secondary500,
            colors.tertiary500,
            colors.neutral500,
            colors.primary300,
            colors.secondary300,
            colors.neutral400,
            colors.warning100,
            colors.secondary300,
            colors.warning500,
            colors.neutral200,
            colors.warning500,
            '#FF9800',
            colors.neutral600,
            '#ABB0EA',
            '#E1FFE0',
            '#D16ABB',
          ],
          dataLabels: {
            enabled: false,
          },
          chart: {
            stacked: true,
            dataLabels: {
              show: false,
            },
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: true,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              distributed: false,
              horizontal: true,
              borderRadius: 10,
              dataLabels: {
                total: {
                  enabled: true,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900,
                  },
                },
              },
            },
          },
          xaxis: {
            labels: {
              show: false,
            },
            categories: companyNames,
            tickPlacement: 'on',
          },
          legend: {
            show: false,
          },
        },
      }
    : undefined;

  return isLoading ? (
    <Loading color="primary" />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <Stack spacing={2}>
      <Box maxWidth={150}>
        <SelectMenu
          items={availableYears}
          value={year}
          handleChange={async (e) => {
            setYear(e.target.value);
          }}
        />
      </Box>
      <ReactApexChart
        options={config?.options}
        series={config?.series}
        type="bar"
        height={350}
      />
    </Stack>
  );
}

export default observer(Component);

import React, { useState } from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPagination,
  GridRowParams,
} from '@mui/x-data-grid';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { deDE } from '@mui/x-data-grid/locales';
import Chip from 'components/common/Chip';
import Container from 'components/common/Container';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import UpdateIcon from '@mui/icons-material/Update';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import Stack from 'components/common/Stack';
import ViewIcon from '@mui/icons-material/ArticleOutlined';
import useAdminCompanyJobs from 'hooks/useAdminCompanyJobs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getLanguagePreference } from 'utils/browser';
import { getDurationBetweenDates, getLengthOfTime } from 'utils/date';
import Box from 'components/common/Box';
import colors from 'constants/colors';
import Card from 'components/common/Card';
import Button from 'components/common/Button';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import { getClimateScoreAsPercent } from 'utils/tools';
import Typography from 'components/common/Typography';
import { useAccessRequestCreate } from 'hooks/useAccessRequests';
import { SendOutlined } from '@mui/icons-material';
import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import Check from 'components/icons/Check';
import Close from '@mui/icons-material/Close';
import Tooltip from 'components/common/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Badge } from '@mui/material';
import useAuth from 'hooks/useAuth';
import axios from 'axios';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { fontSize } from 'constants/typography';

function CustomNoRowsOverlay() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent={'center'}
      height={'100%'}
      spacing={2}
    >
      <Box>
        {t(
          'Collections are groups of companies, suppliers, or other partners.'
        )}
      </Box>
      <Button
        startIcon={<ArrowDownwardIcon />}
        variant="contained"
        color="primary"
        onClick={() => setSearchParams({ example: 'true' })}
      >
        {t('Show Example Collection')}
      </Button>
    </Stack>
  );
}

interface ScoreProps {
  score?: number; // score is optional and ranges from 0 to 100
}

const ScoreComponent: React.FC<ScoreProps> = ({ score = 0 }) => {
  // Normalize score to fit within a scale of 0-10
  const normalizedScore = Math.round((score / 100) * 10);

  return (
    <Box display="flex" alignItems="center" height={'100%'}>
      {/* Display the numerical score */}
      <Typography variant="body1" sx={{ marginRight: 1, width: '24px' }}>
        {score}
      </Typography>

      {/* Display the score bar */}
      <Box display="flex">
        {[...Array(10)].map((_, index) => (
          <Box
            key={index}
            width={6}
            height={16}
            borderRadius={1}
            marginRight={'2px'}
            bgcolor={
              index < normalizedScore ? colors.secondary300 : colors.neutral200
            }
          />
        ))}
      </Box>
    </Box>
  );
};

function QualatativeDescripterChip({
  type,
}: {
  type?: 'VERY_GOOD' | 'GOOD' | 'FAIR' | 'POOR';
}) {
  const { t } = useTranslation();
  return type ? (
    <Chip
      label={
        type === 'GOOD' ? t('Good') : type === 'FAIR' ? t('Fair') : t('Poor')
      }
      style={{
        borderRadius: 4,
        fontSize: '12px',
        color: colors.neutral600,
        height: '24px',
        backgroundColor:
          type === 'GOOD'
            ? colors.primary100
            : type === 'FAIR'
            ? colors.warning100
            : colors.tertiary100,
      }}
    />
  ) : null;
}

function UserCompanyCollectionListDataGrid({
  rows = [],
  collectionId,
  hasCollections,
  loading,
}: {
  rows: any;
  collectionId?: string;
  hasCollections?: boolean;
  loading?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { userCompany } = useUserCompany();
  const [searchParams] = useSearchParams();
  const auth = useAuth();

  const [notificationSending, setNotificaitonSending] = useState(false);
  const [notificationSent, setNotificaitonSent] = useState(false);
  const sendNotification = async (
    targetCompanyName: string,
    targetCompanyId: string
  ) => {
    try {
      const formData = new FormData();
      formData.set('subject', '[Support] Info request: Public screening');
      formData.set(
        'body',
        `
              user name: ${auth.userDetails.first_name} ${auth.userDetails.last_name}
              user email: ${auth.userDetails.email}
              user company: ${userCompany.name}

              target company: ${targetCompanyName}
              target company id: ${targetCompanyId}
            `
      );
      formData.set('receiver_company', userCompany.name);
      setNotificaitonSending(true);
      const res = await axios.post(
        `${api.url}/emails/contact_tcc/`,
        formData,
        auth.requestConfig
      );
      if (res && res.data) {
        setNotificaitonSent(true);
      }
      setNotificaitonSending(false);
    } catch (err) {
      console.log(err);
      setNotificaitonSending(false);
    }
  };

  const showFakeData = searchParams.get('example') === 'true';
  const { mutate: createRequest, isLoading: isLoadingCreate } =
    useAccessRequestCreate();

  const { data, isLoading, refetch, isRefetching } =
    useUserCompanyCollectionOverview({
      collection_id: collectionId,
    });

  const rowCount = rows.length;

  const columns: GridColDef[] = [
    {
      field: 'company_name',
      headerName: t('Name'),
      flex: 0,
      minWidth: 190,
      sortable: true,
    },
    {
      field: 'reporting_company_name',
      headerName: t('Reporting company'),
      flex: 0,
      minWidth: 190,
      sortable: false,
    },
    {
      field: 'climate_score',
      headerName: t('ClimateChoice Score'),
      flex: 0,
      minWidth: 150,
      sortable: true,
      renderCell: (params) => (
        <Box display={'flex'} height={'100%'} alignItems={'center'}>
          <ScoreComponent
            score={getClimateScoreAsPercent(params.row.climate_score)}
          />
        </Box>
      ),
    },
    {
      field: 'maturity_level',
      headerName: t('Maturity level'),
      flex: 0,
      minWidth: 120,
      sortable: false,
      renderCell: (params) => (
        <Box display={'flex'} height={'100%'} alignItems={'center'}>
          {params.row.maturity_level_self_reported ? (
            <Tooltip
              title={
                <Stack spacing={2}>
                  <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <InfoOutlined
                      sx={{ transform: 'scale(1.2)', fontSize: '15px' }}
                    />
                    <Box>{t('Self reported data exists.')}</Box>
                  </Stack>
                  <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <Box>{t('Self reported maturity') + ':'}</Box>
                    <Box>
                      <QualatativeDescripterChip
                        type={params.row.maturity_level_self_reported}
                      />
                    </Box>
                  </Stack>
                </Stack>
              }
            >
              <Badge
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: colors.white,
                    padding: 0,
                    width: '15px',
                    minWidth: '15px',
                    height: '15px',
                    minHeight: '15px',
                  },
                }}
                badgeContent={
                  <InfoOutlined
                    sx={{
                      transform: 'scale(1.2)',
                      fontSize: '15px',
                      color: colors.neutral400,
                    }}
                  />
                }
              >
                <QualatativeDescripterChip
                  type={
                    params.row.maturity_level ||
                    params.row.maturity_level_self_reported
                  }
                />
              </Badge>
            </Tooltip>
          ) : (
            <QualatativeDescripterChip type={params.row.maturity_level} />
          )}
        </Box>
      ),
    },
    {
      field: 'public_data_available',
      headerName: t('Public data'),
      flex: 0,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          {params.row.public_data_available ? (
            <Check color="primary" />
          ) : !params.row.public_data_available &&
            params.row.public_data_screening_last_date ? (
            <Close color="error" />
          ) : params.row.public_data_screening_status === 'IN_PROGRESS' ? (
            <Box>
              <Tooltip
                title={
                  <Stack spacing={2}>
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                      <Box
                        sx={{
                          width: 10,
                          height: 10,
                          borderRadius: '50%',
                          backgroundColor: colors.warning500,
                        }}
                      />
                      <Box>{t('Screening in progress')}</Box>
                    </Stack>
                  </Stack>
                }
              >
                <Badge
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: colors.white,
                      padding: 0,
                      width: '15px',
                      minWidth: '15px',
                      height: '15px',
                      minHeight: '15px',
                    },
                  }}
                  badgeContent={
                    <InfoOutlined
                      sx={{
                        transform: 'scale(1.2)',
                        fontSize: '15px',
                        color: colors.neutral400,
                      }}
                    />
                  }
                >
                  <div style={{ height: '28px', display: 'flex' }}>
                    <Loading
                      disableShrink
                      size="24px"
                      sx={{ color: colors.neutral400 }}
                    />
                  </div>
                </Badge>
              </Tooltip>
            </Box>
          ) : (
            <Button
              variant="text"
              disabled={showFakeData || notificationSending || notificationSent}
              onClick={() => {
                sendNotification(
                  params.row.company_name,
                  params.row.company_uuid
                );
              }}
              sx={{
                whiteSpace: 'nowrap',
                alignSelf: 'center', // Ensures the button aligns in the center
              }}
              startIcon={notificationSent ? <Check /> : <AutoFixHighIcon />}
            >
              {notificationSent ? t('Requested') : t('Request')}
            </Button>
          )}
        </Box>
      ),
    },

    {
      field: 'public_data_screening_last_date',
      headerName: t('Last public update'),
      flex: 0,
      minWidth: 150,
      sortable: true,
      renderCell: (params) => {
        const longAgo = getDurationBetweenDates(
          params.row.public_data_screening_last_date,
          new Date().toUTCString()
        );
        return (
          <Box
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            sx={{
              color: longAgo ? undefined : colors.neutral500,
            }}
            title={longAgo}
          >
            {longAgo || t('Never')}
          </Box>
        );
      },
    },
    {
      field: 'private_data_status',
      headerName: t('Private data'),
      flex: 0,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Stack
          spacing={1}
          direction={'row'}
          alignItems={'center'}
          height={'100%'}
        >
          {params.row.private_data_status === 'ACCESS_CAN_BE_REQUESTED' ? (
            <>
              <Button
                startIcon={<SendOutlined />}
                disabled={
                  showFakeData || isLoadingCreate || isLoading || isRefetching
                }
                onClick={() => {
                  if (
                    userCompany?.id &&
                    params.row?.id &&
                    confirm(
                      t(
                        'Requesting access will notify a representitive at {{target_company}}. Do you wish to continue?',
                        { target_company: params.row.company_name }
                      )
                    )
                  ) {
                    createRequest({
                      data: {
                        data_receiver: userCompany?.id,
                        data_provider: params.row.id,
                      },
                      onSuccess: () => {
                        refetch();
                        snackbarMessage.addMessage({
                          message: t('Request sent'),
                        });
                      },
                    });
                  }
                }}
              >
                {t('Request')}
              </Button>
            </>
          ) : params.row.private_data_status === 'ACCESS_GRANTED' ? (
            <>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: colors.primary300,
                }}
              />
              <Typography variant="body1">{t('Granted')}</Typography>
            </>
          ) : params.row.private_data_status === 'ACCESS_REQUESTED' ? (
            <>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: colors.warning100,
                }}
              />
              <Typography variant="body1">{t('Requested')}</Typography>
            </>
          ) : null}
        </Stack>
      ),
    },
    {
      field: 'private_data_last_update',
      headerName: t('Last private update'),
      flex: 0,
      minWidth: 150,
      sortable: true,
      renderCell: (params) => {
        const longAgo = getDurationBetweenDates(
          params.row.private_data_last_update,
          new Date().toUTCString()
        );
        return (
          <Box
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            sx={{
              color: longAgo ? undefined : colors.neutral500,
            }}
            title={longAgo}
          >
            {longAgo || t('Never')}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 40,
      getActions: (params: GridRowParams) => [
        ...(!!params.row.reporting_company_slug
          ? [
              <GridActionsCellItem
                disabled={showFakeData}
                icon={<OpenInNewIcon />}
                onClick={() =>
                  navigate(`/company/${params.row.reporting_company_slug}`)
                }
                label={t('View reporting company profile')}
                showInMenu
              />,
            ]
          : []),
        <GridActionsCellItem
          disabled={showFakeData}
          icon={<OpenInNewIcon />}
          onClick={() => navigate(`/company/${params.row.company_slug}`)}
          label={t('View company profile')}
          showInMenu
        />,
      ],
    },
  ];
  const localeText = {
    ...(i18n.language === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('This collection is empty.'),
  };

  return (
    <Box minHeight={'400px'}>
      <DataGrid
        loading={loading}
        localeText={localeText}
        rows={rows}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'company_name', sort: 'asc' }],
          },
          pagination: {
            paginationModel: { pageSize: 100, page: 0 }, // Ensures the default page size is 100
          },
        }}
        rowSelection={false}
        paginationMode="client"
        pageSizeOptions={[100]}
        sx={{ minHeight: hasCollections ? undefined : 400 }}
        slots={{
          noRowsOverlay: hasCollections ? undefined : CustomNoRowsOverlay,
        }}
      />
    </Box>
  );
}

export default UserCompanyCollectionListDataGrid;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Grid from 'components/common/Grid';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import { EmailIcon } from 'components/icons/mui_icons/index';
import EmptyTextField from 'components/common/EmptyTextField';
import colors from 'constants/colors';

type Props = {
  email: string | undefined;
};

function Email({ email }: Props) {
  const { t } = useTranslation('common');

  return (
    <Stack direction={'row'} width={'220px'} marginTop={'20px'}>
      <EmailIcon sx={{ color: colors.neutral600 }} />
      <Grid item marginLeft={'20px'}>
        <Typography variant="caption" color={colors.neutral600}>
          {t('Email')}
        </Typography>
        {email ? (
          <Typography
            variant="body1"
            color="primary.main"
            marginTop={'8px'}
            width={'180px'}
            noWrap
            textOverflow="ellipsis"
          >
            {email}
          </Typography>
        ) : (
          <EmptyTextField />
        )}
      </Grid>
    </Stack>
  );
}

export default observer(Email);

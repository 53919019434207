import React from 'react';

import Grid from 'components/common/Grid';

import CompanyAvatar from './CompanyAvatar/CompanyAvatar';
import BackgroundImage from './BackgroundImage/BackgroundImage';
// import ImpactCategoriesTags from './comps/ImpactCategoriesTags';
import * as schema from 'types/backendSchema';

type Props = {
  companyData: schema.definitions['PublicCompany'];
  userDetails: {};
  isUserCompany?: boolean;
};

function CompanyHeader({ companyData, userDetails, isUserCompany }: Props) {
  return (
    <Grid item container>
      <Grid item xs={12}>
        <BackgroundImage banner={(companyData as any).banner} />
      </Grid>
      <Grid item md={1}></Grid>
      <Grid item marginTop={-11} marginLeft={2}>
        <CompanyAvatar
          isUserCompany={isUserCompany}
          companyName={(companyData as any).name}
          companySlug={(companyData as any).slug}
          userCompanySlug={(userDetails as any).company}
          logo={(companyData as any).logo}
          isAdmin={(userDetails as any).is_staff}
          published={(companyData as any).published}
        />
      </Grid>
      {/* <Grid item marginTop={'-235px'} marginLeft={'auto'}>
        <ImpactCategoriesTags />
      </Grid> */}
    </Grid>
  );
}

export default CompanyHeader;

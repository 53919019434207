import * as React from 'react';
import { SvgIcon } from '@mui/material';

function SvgEnergy(props: any) {
  return (
    <SvgIcon color={'inherit'} {...props} viewBox={`0 0 16 16`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8c0-2.182 1.818-4 4-4 1.87 0 3.47 1.331 3.89 3.084.11.465-.232.916-.705.916H8zm0 0c0 2.182-1.818 4-4 4C2.13 12 .53 10.67.109 8.917-.001 8.45.342 8 .814 8H8zm0 0C5.818 8 4 6.182 4 4 4 2.131 5.33.531 7.084.11 7.549 0 8 .341 8 .814V8zm0 0c2.182 0 4 1.818 4 4 0 1.87-1.33 3.47-3.084 3.891-.465.11-.916-.233-.916-.705V8z"
      />
    </SvgIcon>
  );
}

export default SvgEnergy;

export const getUniqueTargets = (targets: any) => {
  let unigue_targets = new Set();

  targets.forEach((t: any) => {
    if (t.framework === null && t.job !== null) {
      //means that it comes from public screening
      unigue_targets.add(`PS_${t.source_channel}`.toUpperCase());
    } else {
      unigue_targets.add(t.source_channel.toUpperCase());
    }
  });
  return Array.from(unigue_targets).join(', ');
};

import useAuth from 'hooks/useAuth';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import api from 'constants/api';

import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import SelectMenu from 'components/common/SelectMenu';

function Component({
  companyId,
  value: listId,
  onChange,
}: {
  companyId: string | undefined;
  value: string;
  onChange: (value: string) => void;
}) {
  const auth = useAuth();
  const { t } = useTranslation('common');
  const { isLoading, data, isError } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    queryKey: ['admin-screening-list', { companyId }],
    queryFn: async () => {
      if (companyId) {
        const res = await axios.get(
          `${api.url}/admin/screening_lists/principal/${companyId}/`,
          auth.requestConfig
        );
        return res.data;
      }
      return [];
    },
  });

  const items = data ? data?.map((list: any) => list.name) : [];
  const value = listId
    ? data?.find((item: any) => item.id === listId)?.name
    : '';

  const handleChange = (listName: string) => {
    const selectedListId = data.find((item: any) => item.name === listName)?.id;
    onChange(selectedListId);
  };

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <SelectMenu
      items={items}
      placeholder={t('Select Screening List')}
      handleChange={(e) => {
        handleChange(e.target.value);
      }}
      value={value}
    />
  );
}
export default Component;

import React from 'react';
import { observer } from 'mobx-react-lite';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Grid from 'components/common/Grid';
import Box from 'components/common/Box';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import Divider from 'components/common/Divider';
import EmptyContentPlaceholder from 'components/common/EmptyContentPlaceholder';
import Loading from 'components/common/Loading';
import CompanyInfoHeader from 'components/App/Routes/Company/CompanyDetailsPage/CompanyInfoHeader';

import CompanyHeader from './CompanyHeader';
import CompanyInfoBlock from './CompanyInfoBlock';
import CompanyDescription from './CompanyDescription';
import CompanyCRCScore from 'components/common/CompanyCRCScore';
import CompanySDGs from './CompanySDGs';
import CompanyCDPInformation from './CompanyCDPInformation';
import CompanyOrganizationsInitiatives from './CompanyOrganizationsInitiatives';
import CompanyCertifications from './CompanyCertifications';
import GoBackButton from 'components/common/GoBackButton';
import GoOnTopButton from 'components/common/GoOnTopButton';
import CompanyKeyFacts from 'components/common/CompanyKeyFacts';
import CompanyClimateTargetsData from './CompanyClimateTargetsData';
import CompanyEmissionsData from './CompanyEmissionsData';
import CompanyEmissionsIntensity from './CompanyEmissionsIntensity';
import CompanyBaseYear from './CompanyBaseYear';

import colors from 'constants/colors';
import * as schema from 'types/backendSchema';

import useCompany from 'hooks/useCompany';
import Button from 'components/common/Button';
import { useNavigate } from 'react-router-dom';

export type Props = {
  companyData: null | schema.definitions['PublicCompany'];
  isUserCompany?: boolean;
};

function CompanyDetailsPage({ companyData, isUserCompany }: Props) {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { removeCompany } = useCompany();
  React.useEffect(() => removeCompany, [removeCompany]);

  const cdpData = companyData?.cdp_data;

  return (
    <Container
      maxWidth="lg"
      sx={(theme) => ({
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(8),
      })}
    >
      {companyData === undefined ? (
        <div>
          <Loading color="primary" />
        </div>
      ) : companyData ? (
        <Stack spacing={3}>
          <Stack direction={'row'} spacing={2} width={'100%'}>
            <GoBackButton />
            <Box flex="1 1 auto" />
            {auth.hasAdminPermission ? (
              <Button
                variant="text"
                onClick={() => navigate(`/admin/company/${companyData.id}`)}
              >
                {t('Go to admin')}
              </Button>
            ) : null}
          </Stack>
          <Box>
            <CompanyHeader
              isUserCompany={isUserCompany}
              companyData={companyData}
              userDetails={auth.userDetails}
            />
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <Stack spacing={4} paddingLeft={{ sm: 8, md: 12 }} width={'100%'}>
              <CompanyInfoHeader
                badge={(companyData as any).survey?.badge}
                label={(companyData as any).survey?.label}
                companyImpactCategories={
                  (companyData as any).tcc_impact_categories
                }
              />
              <CompanyInfoBlock companyData={companyData} />

              <CompanyDescription
                description={(companyData as any).description}
              />

              <Divider />

              <CompanyCRCScore id={(companyData as any).id} />

              {(companyData as any).key_facts &&
              Object.keys((companyData as any).key_facts).length !== 0 &&
              !Object.values((companyData as any).survey).includes(null) ? (
                <Typography variant="h5" color={colors.neutral700}>
                  {t('Insights')}
                </Typography>
              ) : null}
              {Object.keys((companyData as any).key_facts).length > 0 ? (
                <Box>
                  <CompanyKeyFacts keyFacts={(companyData as any).key_facts} />
                </Box>
              ) : null}
              <Box>
                <CompanyEmissionsIntensity
                  emissionIntensities={
                    (companyData as any).emission_intensities
                  }
                />
              </Box>
              <Box>
                <CompanyEmissionsData slug={companyData.slug} />
              </Box>
              <Box>
                <CompanyBaseYear slug={companyData.slug} />
              </Box>
              <Box>
                <CompanyClimateTargetsData slug={companyData.slug} />
              </Box>
              <Box>
                <CompanyCDPInformation data={cdpData} />
              </Box>

              <Stack gap={4}>
                <Typography variant="h5" color="onSurface.100">
                  {t('Certifications and Ratings')}
                </Typography>
                <CompanyCertifications
                  data={(companyData as any).certifications}
                />
              </Stack>

              <Divider sx={{ mt: '12px' }} />

              <CompanyOrganizationsInitiatives
                data={(companyData as any).tcc_affiliations}
              />

              <Divider sx={{ mt: '12px' }} />

              <CompanySDGs companySdgs={(companyData as any).sdgs} />

              <Divider sx={{ mt: '12px' }} />
            </Stack>
          </Box>
          <GoOnTopButton />
        </Stack>
      ) : (
        <EmptyContentPlaceholder text={t('data is not available')} />
      )}
    </Container>
  );
}

export default observer(CompanyDetailsPage);

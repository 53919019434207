import React from 'react';
// import SvgIcon from '@mui/material/SvgIcon';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

// can swap later
const NotificationIcon = (props: any) => (
  // <SvgIcon {...props}>
  //
  // </SvgIcon>
  <NotificationsNoneOutlinedIcon {...props} />
);

export default NotificationIcon;

import React from 'react';

import Typography from 'components/common/Typography';

type Props = {
  color: string;
  score: number;
};

function Percent({ color, score }: Props) {
  return (
    <Typography variant="h5" color={color} width={'100%'} align={'center'}>
      {score}
      {'%'}
    </Typography>
  );
}

export default Percent;

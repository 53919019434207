import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import DemoMockedData from '../../DemoMockedData/store/DemoMockedData';

import Grid from 'components/common/Grid/Grid';
import Card from 'components/common/Card';
import Typography from 'components/common/Typography/Typography';

import UserDashboardSuppliersPieChart from '../../UserDashboardSuppliersPieChart';
import UserDashboardSuppliersColumn from '../../UserDashboardSuppliersColumn';

import colors from 'constants/colors';

function Component() {
  const { t } = useTranslation('common');

  const {
    suppliersWithScopeEmissionsData,
    suppliersWithoutScopeEmissionsData,
    targetCoverage,
    addCategoryEmissionsKilotonne,
    scopePerYearCount,
    emissionsCountPerCountry,
  } = DemoMockedData();

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Typography variant="h8" color={colors.neutral600}>
          {t('Scope 2')}
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Card>
          <UserDashboardSuppliersPieChart
            title={t('Scope 2 - Corporate Carbon Footprint Emissions')}
            labels={[t('Yes'), t('No')]}
            colors={[colors.secondary300, colors.tertiary500]}
            data={[
              suppliersWithScopeEmissionsData('scope2'),
              suppliersWithoutScopeEmissionsData('scope2'),
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={8}>
        <Card>
          <UserDashboardSuppliersPieChart
            title={t('Scope 2 Target Coverage')}
            labels={['Keine', '1.5˚C', '2°C', 'Unsure']}
            colors={[
              colors.secondary300,
              colors.tertiary500,
              colors.primary300,
              colors.warning100,
              colors.secondary100,
            ]}
            data={[
              targetCoverage('scope2').Keine,
              targetCoverage('scope2')[`1.5°C `],
              targetCoverage('scope2')[`2°C `],
              targetCoverage('scope2').Unsure,
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Reported Scope 2 Category Emissions')}
            titleY={t('GHG Emissions(metrics kilotonne CO2)')}
            formater={''}
            groups={[{ title: 'Scope 2 Category', cols: 3 }]}
            color={colors.primary400}
            data={[
              {
                x: '1:Indirekte Emissionen aus gekauftem Strom',
                y: addCategoryEmissionsKilotonne(
                  'Scope2_1_Purchased_Electricity'
                ),
              },
              {
                x: '2:Indirekte Emissionen aus Fernwärme/-kälte',
                y: addCategoryEmissionsKilotonne(
                  'Scope2_2_District_Heating_and_Cooling'
                ),
              },
              {
                x: '3:Indirekte Emissionen aus gekauftem Dampf',
                y: addCategoryEmissionsKilotonne('Scope2_3_Purchased_Steam'),
              },
            ]}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Reporting Years for Scope 2')}
            titleY={t('Companies reporting for Scope 2')}
            formater={''}
            groups={[{ title: t('Year'), cols: 2 }]}
            color={colors.warning500}
            data={scopePerYearCount('scope2_reporting_year')}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersColumn
            title={t('Scope 2 Emissions per Country')}
            titleY={t('GHG Emissions(metrics kilotonne CO2)')}
            formater={''}
            groups={[{ title: t('Country'), cols: 4 }]}
            color={colors.secondary300}
            data={emissionsCountPerCountry('scope2')}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

export default observer(Component);

import { decorate, observable, action } from 'mobx';
import axios from 'axios';
import api from 'constants/api';
import i18n from 'i18n';
import { dateReadable } from 'utils/date';

import { required, email, string20 } from 'state/FormStore/fieldValidators';

import * as schema from 'types/backendSchema';
import * as Sentry from '@sentry/react';

import SnackbarMessageStore from 'state/SnackbarMessageStore';
import AuthStore from 'state/AuthStore';
import FormStore from 'state/FormStore';
import { phCapture } from 'utils/posthog';

class UserCompanyStore {
  // OBSERVABLES................................................................
  isLoadingUserCompany = false;
  hasLoadedUserCompany = false;
  isPatchingUserCompany = false;
  userCompany = {} as schema.definitions['UserCompany'];
  userCompanyForm = new FormStore({
    defaults: {
      sector: '',
    },
    fieldValidators: {
      name: required,
      email: email,
      lei_code: string20,
    },
  });

  isLoadingUserCompanyUsers = false;
  hasLoadedUserCompanyUsers = false;
  userCompanyUsers = [] as schema.definitions['UserDetails'][];
  userCompanyUsersCount = 0;
  userCompanyUsersPage = '1';

  isLoadingUserCompanyCRC = false;
  hasLoadedUserCompanyCRC = false;
  userCompanyCRC = {} as schema.definitions['UserCRCSurvey'];

  isLoadingUserCompanyCRCAssets = false;
  hasLoadedUserCompanyCRCAssets = false;
  userCompanyCRCAssets = {} as schema.definitions['UserCompanyCRCAssets'];

  isLoadingUserCompanyCPALink = false;
  hasLoadedUserCompanyCPALink = false;
  userCompanyCPALink: {
    url?: string;
    validity_period?: number;
  } = {};

  isCPAexpired = false;
  expirationDateReadable = '';
  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  loadUserCompany = async ({ auth }: { auth: AuthStore }) => {
    this.isLoadingUserCompany = true;
    let defaultUrl = `${api.url}/user/company/`;
    try {
      const res = await axios.get(defaultUrl, auth.requestConfig);
      this.loadUserCompanySuccess(res.data);
    } catch (err) {
      this.loadUserCompanyFailure(err);
    }
  };

  loadUserCompanySuccess = (
    data: schema.operations['user_company_read']['responses'][200]['schema']
  ) => {
    this.userCompany = data;
    if (!data.id) {
      const UserNoCompanyError = new Error('User does not have a company');
      window.Sentry?.captureException(UserNoCompanyError);
    }
    this.userCompanyForm.resetValues(data);
    this.isLoadingUserCompany = false;
    this.hasLoadedUserCompany = true;
  };

  loadUserCompanyFailure = (err: any) => {
    console.log(err);
    this.isLoadingUserCompany = false;
    this.hasLoadedUserCompany = true;
  };

  saveCompany = ({
    auth,
    snackbarMessage,
  }: {
    auth: AuthStore;
    snackbarMessage?: SnackbarMessageStore;
  }) => {
    this.userCompanyForm.checkFieldErrors();
    if (
      this.userCompanyForm.hasDirtyValues &&
      !this.userCompanyForm.hasErrors
    ) {
      const data = this.userCompanyForm.deserializeDirty();
      const formData = new FormData();
      for (let key of Object.keys(data)) {
        formData.set(key, data[key]);
      }
      this.patchUserCompany({ data: formData, auth, snackbarMessage });
    }
  };

  patchUserCompany = async ({
    data,
    auth,
    snackbarMessage,
    onSuccess,
  }: {
    // data: schema.definitions["UserDetails"];
    data: any;
    auth: AuthStore;
    snackbarMessage?: SnackbarMessageStore;
    onSuccess?: () => void;
  }) => {
    this.isPatchingUserCompany = true;
    let defaultUrl = `${api.url}/user/company/`;
    try {
      const res = await axios.patch(defaultUrl, data, auth.requestConfig);
      if (onSuccess) {
        onSuccess();
      }
      this.patchUserCompanySuccess(res.data);

      if (snackbarMessage) {
        snackbarMessage.addMessage({
          message: i18n.t('Update success.'),
        });
      }
    } catch (err) {
      this.patchUserCompanyFailure(err);
      if (snackbarMessage) {
        snackbarMessage.addMessage({
          message: i18n.t('There was a problem'),
        });
      }
    }
  };
  patchUserCompanySuccess = (data: any) => {
    this.userCompany = data;
    this.userCompanyForm.resetValues(data);
    this.isPatchingUserCompany = false;
  };
  patchUserCompanyFailure = (err: any) => {
    this.isPatchingUserCompany = false;
    if (err && err.response && err.response.data) {
      for (const [key, value] of Object.entries(err.response.data)) {
        const valueArray: any = value;
        this.userCompanyForm.setFieldError(key, valueArray[0]);
      }
    }
  };

  loadUserCompanyUsers = async ({
    auth,
    page,
  }: {
    auth: AuthStore;
    page: string;
  }) => {
    this.isLoadingUserCompanyUsers = true;
    this.userCompanyUsersPage = page;
    let params = new URLSearchParams();
    params.append('page', page);
    let defaultUrl = `${api.url}/user/company/users/`;
    try {
      const res = await axios.get(
        defaultUrl,
        Object.assign(auth.requestConfig, { params })
      );
      this.loadUserCompanyUsersSuccess(res.data);
    } catch (err) {
      this.loadUserCompanyUsersFailure(err);
    }
  };

  loadUserCompanyUsersSuccess = (
    data: schema.operations['user_company_users_list']['responses'][200]['schema']
  ) => {
    this.userCompanyUsers = data.results;
    this.userCompanyUsersCount = data.count;
    this.isLoadingUserCompanyUsers = false;
    this.hasLoadedUserCompanyUsers = true;
  };

  loadUserCompanyUsersFailure = (err: any) => {
    console.log(err);
    this.isLoadingUserCompanyUsers = false;
    this.hasLoadedUserCompanyUsers = true;
  };

  loadUserCompanyCRC = async ({ auth }: { auth: AuthStore }) => {
    this.isLoadingUserCompanyCRC = true;
    let defaultUrl = `${api.url}/user/company/crc/`;
    try {
      const res = await axios.get(defaultUrl, auth.requestConfig);
      this.loadUserCompanyCRCSuccess(res.data);
    } catch (err) {
      this.loadUserCompanyCRCFailure(err);
    }
  };

  loadUserCompanyCRCSuccess = (
    data: schema.operations['user_company_crc_read']['responses'][200]['schema']
  ) => {
    this.userCompanyCRC = data;
    this.isCPAexpired = this.setIsCPAexpired(data.expiration_date);
    if (data.expiration_date) {
      this.expirationDateReadable = dateReadable(
        new Date(data.expiration_date).toISOString()
      );
    } else {
      this.expirationDateReadable = 'Invalid Date';
    }
    phCapture('update company cpa score', {
      $set: { cpa_score: data.score_total_crc, cpa_status: data.status },
    });
    this.isLoadingUserCompanyCRC = false;
    this.hasLoadedUserCompanyCRC = true;
  };

  loadUserCompanyCRCFailure = (err: any) => {
    console.log(err);
    this.isLoadingUserCompanyCRC = false;
    this.hasLoadedUserCompanyCRC = true;
  };

  loadUserCompanyCRCAssets = async ({ auth }: { auth: AuthStore }) => {
    this.isLoadingUserCompanyCRCAssets = true;
    let defaultUrl = `${api.url}/user/company/crc/assets/`;
    try {
      const res = await axios.get(defaultUrl, auth.requestConfig);
      this.loadUserCompanyCRCAssetsSuccess(res.data);
    } catch (err) {
      this.loadUserCompanyCRCAssetsFailure(err);
    }
  };

  loadUserCompanyCRCAssetsSuccess = (
    data: schema.operations['user_company_crc_assets']['responses'][200]['schema']
  ) => {
    this.userCompanyCRCAssets = data;
    this.isLoadingUserCompanyCRCAssets = false;
    this.hasLoadedUserCompanyCRCAssets = true;
  };

  loadUserCompanyCRCAssetsFailure = (err: any) => {
    console.log(err);
    this.isLoadingUserCompanyCRCAssets = false;
    this.hasLoadedUserCompanyCRCAssets = true;
  };

  loadUserCompanyCPALink = async ({ auth }: { auth: AuthStore }) => {
    this.isLoadingUserCompanyCPALink = true;
    let defaultUrl = `${api.url}/user/company/cpa/link/`;
    try {
      const res = await axios.get(defaultUrl, auth.requestConfig);
      this.loadUserCompanyCPALinkSuccess(res.data);
    } catch (err) {
      this.loadUserCompanyCPALinkFailure(err);
    }
  };

  loadUserCompanyCPALinkSuccess = (data: any) => {
    this.userCompanyCPALink = data;
    this.isLoadingUserCompanyCPALink = false;
    this.hasLoadedUserCompanyCPALink = true;
  };

  loadUserCompanyCPALinkFailure = (err: any) => {
    console.log(err);
    this.isLoadingUserCompanyCPALink = false;
    this.hasLoadedUserCompanyCPALink = true;
  };

  removeUserCompany = () => {
    this.hasLoadedUserCompany = false;
    this.hasLoadedUserCompanyUsers = false;
    this.userCompany = {} as schema.definitions['UserCompany'];
    this.userCompanyUsers = [];
    this.userCompanyCRC = {} as schema.definitions['UserCRCSurvey'];
    this.userCompanyCRCAssets =
      {} as schema.definitions['UserCompanyCRCAssets'];
  };

  setIsCPAexpired = (epxirationDate: string | undefined) => {
    const todaysDate = new Date().toISOString();
    return epxirationDate ? epxirationDate < todaysDate : false;
  };
}

decorate(UserCompanyStore, {
  isLoadingUserCompany: observable,
  hasLoadedUserCompany: observable,
  isPatchingUserCompany: observable,
  userCompany: observable,
  userCompanyForm: observable,
  isLoadingUserCompanyUsers: observable,
  hasLoadedUserCompanyUsers: observable,
  userCompanyUsers: observable,
  userCompanyUsersCount: observable,
  userCompanyUsersPage: observable,
  isLoadingUserCompanyCRC: observable,
  hasLoadedUserCompanyCRC: observable,
  userCompanyCRC: observable,
  isLoadingUserCompanyCRCAssets: observable,
  hasLoadedUserCompanyCRCAssets: observable,
  userCompanyCRCAssets: observable,
  isLoadingUserCompanyCPALink: observable,
  hasLoadedUserCompanyCPALink: observable,
  userCompanyCPALink: observable,
  isCPAexpired: observable,
  expirationDateReadable: observable,
  setIsCPAexpired: action,
  loadUserCompany: action,
  loadUserCompanySuccess: action,
  loadUserCompanyFailure: action,
  saveCompany: action,
  patchUserCompany: action,
  patchUserCompanySuccess: action,
  patchUserCompanyFailure: action,
  loadUserCompanyUsers: action,
  loadUserCompanyUsersSuccess: action,
  loadUserCompanyUsersFailure: action,
  loadUserCompanyCRC: action,
  loadUserCompanyCRCSuccess: action,
  loadUserCompanyCRCFailure: action,
  loadUserCompanyCRCAssets: action,
  loadUserCompanyCRCAssetsSuccess: action,
  loadUserCompanyCRCAssetsFailure: action,
  loadUserCompanyCPALink: action,
  loadUserCompanyCPALinkSuccess: action,
  loadUserCompanyCPALinkFailure: action,
  removeUserCompany: action,
});

export default UserCompanyStore;

import React from 'react';
import { styled } from '@mui/material/styles';
import { DrawerProps } from '@mui/material/Drawer';

function DrawerHeader(props: DrawerProps) {
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  return <DrawerHeader {...props} />;
}

export default DrawerHeader;

import React, { useEffect, useState } from 'react';
import { getLanguagePreference } from 'utils/browser';
import countriesI18n from 'i18n-iso-countries';

import Box from '@mui/material/Box';
import TextField from 'components/common/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from 'components/common/Typography';

type Props = {
  onChange: (obj: any) => void;
  title?: string | null;
  subTitle?: string | null;
  value: string;
  required?: boolean;
  placeholder?: string;
};

interface CountryType {
  code: string;
  label: string;
}

export default function CountrySelect({
  onChange,
  title,
  subTitle,
  value,
  required,
  placeholder,
}: Props) {
  const [countries, setCountries] = useState<CountryType[]>([]);

  useEffect(() => {
    const countriesI18nLocalized = countriesI18n.getNames(
      getLanguagePreference({ twoLetter: true, supportedLang: true })
    );
    let countriesCodeLabel: CountryType[] = [];
    Object.keys(countriesI18nLocalized).forEach((langCode) => {
      countriesCodeLabel.push({
        code: langCode,
        label: countriesI18nLocalized[langCode],
      });
    });
    setCountries(countriesCodeLabel);
  }, []);

  return (
    <Box>
      {title ? (
        <Typography
          pb={subTitle ? 0 : 1}
          variant="body1"
          color={'neutral.contrastText'}
        >
          {`${title}${required ? ' *' : ''}`}
        </Typography>
      ) : null}
      {subTitle ? (
        <Typography
          variant={'caption2'}
          color={'neutral.contrastText'}
          display="block"
          pb={1}
        >
          {subTitle}
        </Typography>
      ) : null}
      <Autocomplete
        id="country-select"
        sx={{
          mb: '10px',
          backgroundColor: 'neutral.main',
          borderRadius: '3px',
        }}
        options={countries.map((country) => country.label)}
        autoHighlight
        onChange={(e: any, value: string | null) => {
          const matchedCountryLabelToCode = countries.find(
            (country) => country.label === value
          )?.code;
          onChange(matchedCountryLabelToCode);
        }}
        value={
          countries.find((country) => country.code === value)?.label || null
        }
        renderOption={(props, value) => {
          const option = countries.find((country) => country.label === value);
          return (
            option && (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )
          );
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              '& fieldset': {
                border: 'none',
              },
            }}
            required={required}
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </Box>
  );
}

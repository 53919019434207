import React from 'react';
import { observer } from 'mobx-react-lite';

import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';

import SelectScreeningListEntryMenu from './SelectScreeningListEntryMenu';
import useScreeningListEntries from '../../../hooks/useScreeningListEntries';

function Component({
  title,
  subtitle,
  listId,
  value: screeningListEntryId,
  onChange,
  error,
  required,
}: {
  title?: string;
  subtitle?: string;
  listId: string;
  required?: boolean;
  value?: string;
  error?: boolean;
  onChange: (value: string) => void;
}) {
  const { t } = useTranslation('common');

  const { isLoading, data, isError } = useScreeningListEntries(listId);

  const items = data
    ? data
        ?.sort((listA: any, listB: any) => {
          if (listA.name < listB.name) {
            return -1;
          }
          if (listA.name > listB.name) {
            return 1;
          }
          return 0;
        })
        .map((list: any) => list.name)
    : [];
  const value = screeningListEntryId
    ? data?.find((item: any) => item.id === screeningListEntryId)?.name
    : '';

  const handleChange = (listName: string) => {
    const selectedListId = data.find((item: any) => item.name === listName)?.id;
    onChange(selectedListId);
  };

  return !listId ? null : isLoading ? (
    <Loading />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <Box display={'flex'} flexDirection={'column'}>
      {title ? (
        <Typography
          pb={subtitle ? 0 : 1}
          variant="body1"
          color={error ? 'error.main' : 'neutral.contrastText'}
        >
          {`${title}${required ? ' *' : ''}`}
        </Typography>
      ) : null}
      {subtitle ? (
        <Typography
          variant={'caption2'}
          color={error ? 'error.main' : 'neutral.contrastText'}
          pb={1}
        >
          {`${subtitle}${required && !title ? ' *' : ''}`}
        </Typography>
      ) : null}
      <SelectScreeningListEntryMenu
        items={items}
        data={data}
        placeholder={t('Select Screening Entry')}
        handleChange={(e) => {
          handleChange(e.target.value);
        }}
        value={value}
      />
    </Box>
  );
}
export default observer(Component);

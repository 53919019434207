import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Chip from 'components/common/Chip';

// import colors from 'constants/colors';

type Props = {
  params: any;
};

function StatusChip({ params }: Props) {
  const { t } = useTranslation('common');
  const [chipColor, setChipColor] = useState({
    color: '',
    backgroundColor: '',
  });
  const [chipLabel, setChipLabel] = useState(t('not yet rated'));

  useEffect(() => {
    if (params.formattedValue.expires_soon) {
      setChipColor({ color: '#FFAB00', backgroundColor: '#FFF3CC' });
      setChipLabel(t('expires soon'));
    } else {
      switch (params.formattedValue.status) {
        case 'NEW':
          setChipColor({ color: '#113192', backgroundColor: '#E8EBF6' });
          setChipLabel(t('In progress'));
          break;
        case 'IN_REVIEW':
          setChipColor({ color: '#113192', backgroundColor: '#E8EBF6' });
          setChipLabel(t('In progress'));
          break;
        case 'FINALIZED':
          setChipColor({ color: '#60BCAA', backgroundColor: '#EAF9F6' });
          setChipLabel(t('Approved'));
          break;
        default:
          setChipColor({ color: '#4E6883', backgroundColor: '#E6EBEF' });
          setChipLabel(t('not yet rated'));
      }
    }
  }, [
    params,
    params.formattedValue.expires_soon,
    params.formattedValue.status,
    t,
  ]);

  return (
    <Chip
      variant="filled"
      sx={{
        height: '30px',
        borderRadius: '40px !important',
        color: `${chipColor.color} !important`,
        backgroundColor: `${chipColor.backgroundColor} !important`,
      }}
      label={chipLabel}
    />
  );
}

export default StatusChip;

import React from 'react';

import Link from 'components/common/Link';

type Props = {
  icon: React.ReactNode;
  link: string | undefined;
};

function SocialMediaIcon({ icon, link }: Props) {
  return (
    <>
      {link ? (
        <Link href={link} target="_blank" rel="noopener">
          {icon}
        </Link>
      ) : null}
    </>
  );
}

export default SocialMediaIcon;

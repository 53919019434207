import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import Grid from 'components/common/Grid';
import CardMedia from 'components/common/CardMedia';
import Link from 'components/common/Link';
import Typography from 'components/common/Typography';

import colors from 'constants/colors';

type Props = {
  queryValue: string;
  iconSrc: string;
};

const CompanyOrganizationsInitiativesCard = ({
  queryValue = '',
  iconSrc = '',
}: Props) => {
  const navigateSearch = useNavigateSearch();
  const { t } = useTranslation('common');

  const handleCompanyFilterChange = (value: string) => {
    navigateSearch({
      pathname: `/companies/search`,
      params: { page: 1, tcc_affiliations: value },
      replace: true,
    });
  };

  if (queryValue && typeof queryValue === 'string') {
    return (
      <Grid item>
        <Grid item mr={'24px'}>
          <Link
            component="button"
            variant="body2"
            onClick={() => handleCompanyFilterChange(queryValue)}
          >
            <CardMedia
              component="img"
              image={iconSrc}
              height={'80px'}
            ></CardMedia>
            {queryValue === 'CDP ACCREDITED SOLUTION PROVIDER' ? (
              <Typography
                variant="body1"
                width="140px"
                color={colors.neutral400}
              >
                {t('Accredited Solutions Provider')}
              </Typography>
            ) : null}
          </Link>
        </Grid>
      </Grid>
    );
  } else return null;
};

export default observer(CompanyOrganizationsInitiativesCard);

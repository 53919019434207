import { decorate, observable, action, computed, runInAction } from 'mobx';
import axios from 'axios';
import * as schema from 'types/backendSchema';
import api from 'constants/api';
import i18n from 'i18n';
import * as Sentry from '@sentry/react';

import FormStore from 'state/FormStore';
import SnackbarMessageStore from 'state/SnackbarMessageStore';
import {
  combineFieldValidators,
  required,
  string255,
} from 'state/FormStore/fieldValidators';
import { getCookie, isLocalhost } from 'utils/browser';
import { phReset, phIdentify } from 'utils/posthog';

class AuthStore {
  // OBSERVABLES................................................................
  hasCheckedAuth = false;
  token = '';
  useToken = isLocalhost;
  userId: string | undefined = '';
  userFirstName: string | undefined = '';
  isLoadingUserData = false;
  isPatchingUserData = false;
  hasLoadedUserData = false;
  acceptedPrivacyPolicy: boolean | undefined = false;
  acceptedTerms: boolean | undefined = false;
  verifiedEmail: boolean | undefined = false;
  userDataForm = new FormStore({
    defaults: {
      email: '',
      first_name: '',
      last_name: '',
      job_title: '',
    },
    fieldValidators: {
      first_name: combineFieldValidators([required, string255]),
      last_name: combineFieldValidators([required, string255]),
      job_title: string255,
    },
  });
  favorites = [] as string[];
  userDetails = {} as schema.definitions['UserDetails'];

  // COMPUTEDS..................................................................
  get isAuthenticated() {
    return !!this.userId;
  }

  get hasAdminPermission() {
    return this.userDataForm.values.get('is_staff');
  }

  get hasDashboardPermission() {
    return (
      this.isAuthenticated &&
      this.verifiedEmail &&
      this.userDetails.has_completed_crc &&
      this.acceptedPrivacyPolicy &&
      this.acceptedTerms
    );
  }

  get hasCompanyPermission() {
    return this.hasDashboardPermission;
  }

  get hasUserPermission() {
    return this.hasDashboardPermission;
  }

  get requestConfig() {
    return {
      crossDomain: true,
      withCredentials: true,
      headers: Object.assign(
        {
          'X-CSRFToken': getCookie('csrftoken'),
        },
        !!this.token
          ? {
              Authorization: `token ${this.token}`,
            }
          : {}
      ),
    } as any;
  }

  // ACTIONS....................................................................
  setToken = async (token: string) => {
    this.token = token;
  };

  removeAuthenticated = () => {
    this.userId = '';
    this.token = '';
    phReset();
  };

  checkAuth = async () => {
    try {
      // here we can grab the response and check for the 204, but we need this error code in the response
      await axios.get(`${api.url}/auth/login/`, this.requestConfig);
      await this.loadUserData();
      this.checkAuthSuccess();
    } catch (err) {
      this.checkAuthFailure(err);
    }
  };

  checkAuthSuccess = () => {
    this.hasCheckedAuth = true;
  };

  checkAuthFailure = (err: any) => {
    this.hasCheckedAuth = true;
    console.log(err);
  };

  loadUserData = async () => {
    this.isLoadingUserData = true;
    let defaultUrl = `${api.url}/user/`;
    try {
      const res = await axios.get(defaultUrl, this.requestConfig);
      Sentry.setUser({ email: res.data.email });
      this.loadUserDataSuccess(res.data);
    } catch (err) {
      this.loadUserDataFailure(err);
    }
  };

  loadUserDataSuccess = (data: schema.definitions['UserDetails']) => {
    //FIX-QA
    if (data.favorite_companies) {
      this.setFavorites(data.favorite_companies.slice());
      delete data.favorite_companies;
    }
    this.userDataForm.resetValues(data);
    this.userId = data.id;
    this.userFirstName = data.first_name;
    this.isLoadingUserData = false;
    this.hasLoadedUserData = true;
    this.acceptedPrivacyPolicy = data.accepted_privacy_policy;
    this.acceptedTerms = data.accepted_terms;
    this.verifiedEmail = data.is_email_verified;
    runInAction(() => (this.userDetails = data));
    phIdentify(data.id, {
      email: data.email,
      company: data.company,
    });
  };

  loadUserDataFailure = (err: any) => {
    console.log(err);
    this.isLoadingUserData = false;
    this.hasLoadedUserData = true;
  };

  onUserSubmit = ({
    snackbarMessage,
  }: {
    snackbarMessage: SnackbarMessageStore;
  }) => {
    this.userDataForm.checkFieldErrors();
    if (!this.userDataForm.hasErrors) {
      this.patchUserData({
        data: this.userDataForm.deserialize(),
        snackbarMessage,
      });
    }
  };

  setUserTerms = async (userTerms: boolean) => {
    let defaultUrl = `${api.url}/user/`;
    try {
      const { data }: { data: schema.definitions['UserDetails'] } =
        await axios.patch(
          defaultUrl,
          { accepted_terms: userTerms },
          this.requestConfig
        );

      runInAction(() => (this.acceptedTerms = data.accepted_terms));
    } catch (err) {
      console.log(err);
    }
  };
  setUserPrivacyPolicy = async (setUserPrivacyPolicy: boolean) => {
    let defaultUrl = `${api.url}/user/`;
    try {
      const { data }: { data: schema.definitions['UserDetails'] } =
        await axios.patch(
          defaultUrl,
          { accepted_privacy_policy: setUserPrivacyPolicy },
          this.requestConfig
        );
      runInAction(
        () => (this.acceptedPrivacyPolicy = data.accepted_privacy_policy)
      );
    } catch (err) {
      console.log(err);
    }
  };

  patchUserData = async ({
    data,
    snackbarMessage,
  }: {
    data: schema.definitions['UserDetails'];
    snackbarMessage: SnackbarMessageStore;
  }) => {
    this.isPatchingUserData = true;
    let defaultUrl = `${api.url}/user/`;
    try {
      const res = await axios.patch(defaultUrl, data, this.requestConfig);
      this.patchUserDataSuccess(res.data);
      if (snackbarMessage) {
        snackbarMessage.addMessage({
          message: i18n.t('Update success.'),
        });
      }
    } catch (err) {
      this.patchUserDataFailure(err);
      if (snackbarMessage) {
        snackbarMessage.addMessage({
          message: i18n.t('There was a problem'),
        });
      }
    }
  };

  patchUserDataSuccess = (data: schema.definitions['UserDetails']) => {
    if (data.favorite_companies) {
      this.setFavorites(data.favorite_companies.slice());
      delete data.favorite_companies;
    }
    this.userDataForm.resetValues(data);
    this.isPatchingUserData = false;
  };

  patchUserDataFailure = (err: any) => {
    console.log(err);
    this.isPatchingUserData = false;
  };

  setFavorites = (favorites = [] as string[]) => {
    this.favorites = favorites;
  };

  toggleFavoriteCompany = (id: string | undefined) => {
    if (id && !this.isPatchingUserData) {
      let favorites = this.favorites.slice() as string[];
      if (favorites.includes(id)) {
        const index = favorites.indexOf(id);
        favorites.splice(index, 1);
      } else {
        favorites.push(id);
      }
      this.setFavorites(favorites);
      this.patchUserData({
        data: {
          favorite_companies: this.favorites.slice(),
        },
      } as any);
    }
  };
}

decorate(AuthStore, {
  hasCheckedAuth: observable,
  token: observable,
  isLoadingUserData: observable,
  isPatchingUserData: observable,
  hasLoadedUserData: observable,
  userId: observable,
  userFirstName: observable,
  favorites: observable.ref,
  acceptedPrivacyPolicy: observable,
  acceptedTerms: observable,
  userDetails: observable,
  verifiedEmail: observable,
  isAuthenticated: computed,
  hasAdminPermission: computed,
  hasDashboardPermission: computed,
  hasCompanyPermission: computed,
  hasUserPermission: computed,
  requestConfig: computed,
  setToken: action,
  removeAuthenticated: action,
  checkAuth: action,
  checkAuthSuccess: action,
  checkAuthFailure: action,
  loadUserData: action,
  loadUserDataSuccess: action,
  loadUserDataFailure: action,
  patchUserData: action,
  patchUserDataSuccess: action,
  patchUserDataFailure: action,
  setFavorites: action,
  toggleFavoriteCompany: action,
});

export default AuthStore;

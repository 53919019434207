import React, { lazy } from 'react';
import { observer } from 'mobx-react-lite';

import useAuth from 'hooks/useAuth';

const Posthog = lazy(() => import('./Posthog'));

function Component() {
  const auth = useAuth();
  return auth.userDetails.id && auth.isAuthenticated && auth.acceptedTerms ? (
    <Posthog userDetails={auth.userDetails} />
  ) : (
    <React.Fragment />
  );
}

export default observer(Component);

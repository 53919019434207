import profile from './profile.png';
import crc_white from './crc_white.png';
import crc from './crc.png';
import suppliers from './suppliers.png';
import contact from './contact.png';
import primary_nace_code_icon from './primary_nace_code_icon.png';
import find_us from './find_us.png';
import slack from './slack.png';
import lyt from './lyt.png';

export const dashboardImages: any = {
  profile: profile,
  crc: crc,
  crc_white: crc_white,
  suppliers: suppliers,
  contact: contact,
  primary_nace_code_icon: primary_nace_code_icon,
  find_us: find_us,
  slack: slack,
  lyt: lyt,
};

// import React from 'react'
import { observer } from 'mobx-react-lite';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CountrySelect from 'components/common/CountrySelect';

// import useAuth from 'hooks/useAuth'
import useNavigateSearch from 'hooks/useNavigateSearchParams';

function Component() {
  // const auth = useAuth()
  // const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();
  const { t } = useTranslation('common');
  return (
    <CountrySelect
      value={searchParams.get('country') || ''}
      placeholder={t('Filter by country')}
      onChange={(country: string) => {
        console.log(country);
        navigateSearch({
          pathname: location.pathname,
          params: {
            page: 1,
            ...(country && { country }),
          },
          removeParams: country ? [] : ['country'],
          replace: false,
        });
      }}
      title={''}
    />
  );
}

export default observer(Component);

import React from 'react';
import MuiDialogActions, {
  DialogActionsProps,
} from '@mui/material/DialogActions';

function DialogActions(props: DialogActionsProps) {
  return <MuiDialogActions {...props} />;
}

export default DialogActions;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Box, TextField } from '@mui/material';

import useCPASurvey from '../store/useCPASurvey';

interface CPASurveyQuestionDataProps {
  id: string;
  flag?: string;
  values: string[];
  answers?: string[];
  cpasurveyId?: string;
  index?: number;
  url?: string;
}

const CPASurveyQuestionData: React.FC<CPASurveyQuestionDataProps> = ({
  id,
  values,
  answers,
  url,
}) => {
  const { notes } = useCPASurvey();
  const { t } = useTranslation('common');
  const note = notes.get(id);

  return (
    <Container>
      <Grid container spacing={2}>
        {answers && answers.length > 0 && (
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column">
              {answers.map((value, i) => (
                <Box key={i} sx={{ opacity: 0.5 }}>
                  {value || ' '}
                </Box>
              ))}
            </Box>
          </Grid>
        )}
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column">
            {url ? (
              <a href={url}>{values.join(', ')}</a>
            ) : (
              <>
                {values.map((value, i) => (
                  <Box key={i} sx={{ fontWeight: 'bold', minHeight: '20px' }}>
                    <span>{value}</span>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Grid>
        {note && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={5}
              label={t('Review Notes')}
              name="note"
              value={note}
              disabled
              variant="outlined"
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CPASurveyQuestionData;

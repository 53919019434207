import { observer } from 'mobx-react-lite';
import AppBar from 'components/common/AppBar';
import Toolbar from './Toolbar';
import Container from 'components/common/Container';

import colors from 'constants/colors';

function ResponsiveAppBar() {
  // notes: drawer z-index is 1200, so we want to put this above
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? colors.neutral800 : 'white',
        zIndex: 1201,
      }}
      elevation={0}
    >
      <Container disableGutters>
        <Toolbar />
      </Container>
    </AppBar>
  );
}
export default observer(ResponsiveAppBar);

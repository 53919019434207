import React from 'react';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

interface ExtendedCheckboxProps extends CheckboxProps {
  label?: string;
  error?: boolean;
  helperText?: string;
}

const Checkbox = ({
  checked,
  onChange,
  label = '',
  error = false,
  helperText = '',
  ...rest
}: ExtendedCheckboxProps) => {
  return (
    <FormControl error={error}>
      <FormControlLabel
        control={
          <MuiCheckbox
            checked={checked}
            onChange={onChange}
            color={'primary'}
            {...rest}
          />
        }
        label={label}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Checkbox;

import React from 'react';
import Chart from 'react-apexcharts';

const RadialBar = ({
  sizeW = 270,
  sizeH = 270,
  radialOffsetY = -26,
  radialOffsetX = -36,
  lineSize = '65%',
  score = 94,
  fontSize = '34px',
  offsetY = 8,
  color = '#6AD1BC',
  scoreColor = '#113192',
  noTrack = false,
  outOf100 = false,
  hideValue = false,
}) => {
  const config = {
    options: {
      chart: {
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          track: {
            background: color,
            opacity: noTrack ? 0 : 0.2,
          },
          offsetY: radialOffsetY,
          offsetX: radialOffsetX,
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 0,
            size: lineSize,
            background: 'transparent',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: !hideValue,
              fontFamily: 'Lato',
              color: scoreColor,
              fontWeight: 900,
              offsetY: offsetY,
              fontSize: fontSize,
              formatter: function (val) {
                return outOf100 ? parseInt(val) + ' / 100' : val + '%';
              },
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
      colors: [color],
    },
    series: [score],
  };

  return (
    <Chart
      options={config.options}
      series={config.series}
      type="radialBar"
      width={sizeW}
      height={sizeH}
    />
  );
};

export default RadialBar;

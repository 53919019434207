import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

// const useStyles = makeStyles((theme) => ({
//
// }));

const Loading = ({ color = 'inherit', ...rest }) => {
  // const classes = useStyles();
  return <CircularProgress color={color} {...rest} />;
};

export default Loading;

import { decorate, observable, action } from 'mobx';
import axios from 'axios';
import api from 'constants/api';
import AuthStore from '../AuthStore';

import * as schema from 'types/backendSchema';

class CompaniesStore {
  // OBSERVABLES................................................................
  count = 0;
  isLoadingCompanies = false;
  hasLoadedCompanies = false;
  companies: schema.operations['companies_list']['responses'][200]['schema']['results'] =
    [];

  isUsingCompaniesFilter = false;

  impactCategories = [];
  isLoadingImpactCategories = false;
  hasLoadedImpactCategories = false;
  companyFilterCategories = {} as { [key: string]: any };
  isLoadingCompanyFilterCategories = false;
  hasLoadedCompanyFilterCategories = false;

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................

  loadCompanies = async ({
    auth,
    page,
    search,
    ids,
    countFavorites,
    impactCategories,
    naceCodes,
    productTags,
    eUTaxonomyCode,
    impactTag,
    affiliationsProfileFilter,
    sgdProfileFilter,
    certificationsProfileFilter,
    partnerFilters,
    companySortingFilter,
    targets,
    cdpData,
    emasData,
    sectors,
    country,
  }: {
    auth: AuthStore;
    page: string;
    search: string;
    ids: string[];
    countFavorites: boolean;
    impactCategories: string;
    naceCodes: string;
    productTags: string;
    eUTaxonomyCode: string;
    impactTag: string;
    affiliationsProfileFilter: string;
    sgdProfileFilter: string;
    certificationsProfileFilter: string;
    partnerFilters: string | undefined;
    companySortingFilter: string;
    targets?: string;
    cdpData?: string;
    emasData?: string;
    sectors?: string;
    country?: string;
  }) => {
    this.isLoadingCompanies = true;
    const isUsingCompaniesFilter = !!(
      search ||
      impactCategories ||
      naceCodes ||
      productTags ||
      eUTaxonomyCode ||
      impactTag ||
      affiliationsProfileFilter ||
      sgdProfileFilter ||
      certificationsProfileFilter ||
      partnerFilters ||
      country ||
      targets ||
      cdpData ||
      emasData ||
      sectors
    );

    let params = new URLSearchParams();
    params.append('page', page);
    params.append('search', search);

    for (let id of ids) {
      params.append('id', id);
    }

    if (emasData) {
      params.append('emas_data', emasData);
    }

    if (cdpData) {
      params.append('cdp_data', cdpData);
    }

    if (country) {
      params.append('country', country);
    }

    if (targets) {
      const targetArray = targets.split(',');
      for (let target of targetArray) {
        params.append('target_framework', target);
      }
    }

    if (sectors) {
      const sectorsArray = sectors.split(',');
      for (let sector of sectorsArray) {
        params.append('sectors', sector);
      }
    }

    if (impactCategories !== '') {
      const impactCategoriesArray = impactCategories.split(',');
      for (let impactCategory of impactCategoriesArray) {
        params.append('tcc_impact_categories', impactCategory);
      }
    }
    // company's filter
    if (productTags !== '') {
      const productTagsArray = productTags.split(',');
      for (let productTag of productTagsArray) {
        params.append('tcc_product_tags', productTag);
      }
    }
    if (naceCodes !== '') {
      const naceCodesArray = naceCodes.split(',');
      for (let naceCode of naceCodesArray) {
        params.append('tcc_nace_codes', naceCode);
      }
    }
    params.append('tcc_eu_taxonomy_codes', eUTaxonomyCode);
    params.append('tcc_impact_tags', impactTag);
    // for the next endpoints we need a quard before append
    // backend does not accept empty string ''
    if (affiliationsProfileFilter) {
      params.append('tcc_affiliations', affiliationsProfileFilter);
    }

    if (sgdProfileFilter) {
      params.append('sdgs', sgdProfileFilter);
    }
    if (certificationsProfileFilter) {
      params.append('certifications', certificationsProfileFilter);
    }

    if (
      partnerFilters !== 'undefined' &&
      partnerFilters !== null &&
      partnerFilters !== undefined
    ) {
      const partnerFiltersArray = partnerFilters.split(',');
      for (let partnerFilter of partnerFiltersArray) {
        params.append('partner', partnerFilter);
      }
    }
    // company's sorting filter - !!!Default Filter -profile_search_ranking!!!
    params.append(
      'ordering',
      companySortingFilter === ''
        ? '-profile_search_ranking'
        : companySortingFilter
    );

    let defaultUrl = `${api.url}/companies/`;
    try {
      const res = await axios.get(
        defaultUrl,
        Object.assign(auth.requestConfig, { params })
      );
      this.loadCompaniesSuccess(
        res.data,
        auth,
        countFavorites,
        isUsingCompaniesFilter
      );
    } catch (err) {
      this.loadCompaniesFailure(err);
    }
  };

  loadCompaniesSuccess = (
    data: schema.operations['companies_list']['responses'][200]['schema'],
    auth: AuthStore,
    countFavorites: boolean,
    isUsingCompaniesFilter: boolean
  ) => {
    // we need specificly to check auth.favorites.length otherwise when the favorites are empty
    // the count is the total number of the companies
    // if we change the count to auth.favorites.length and search on favorites page
    // the 'results found' doesn't reflect the real number
    //FIX-QA
    if (countFavorites && auth.favorites.length === 0) {
      this.count = 0;
    } else {
      this.count = data.count;
    }
    this.companies = data.results;
    this.isLoadingCompanies = false;
    this.hasLoadedCompanies = true;
    this.isUsingCompaniesFilter = isUsingCompaniesFilter;
  };

  loadCompaniesFailure = (err: any) => {
    console.log(err);
    this.isLoadingCompanies = false;
    this.hasLoadedCompanies = true;
  };

  setHasLoadedCompanies = (bool = false) => {
    this.hasLoadedCompanies = bool;
  };

  loadImpactCategories = async ({ auth }: { auth: AuthStore }) => {
    this.isLoadingImpactCategories = true;
    let defaultUrl = `${api.url}/companies/impact_categories`;
    try {
      const res = await axios.get(defaultUrl, auth.requestConfig);
      this.loadImpactCategoriesSuccess(res.data);
    } catch (err) {
      this.loadImpactCategoriesFailure(err);
    }
  };

  loadImpactCategoriesSuccess = (data: any) => {
    this.isLoadingImpactCategories = false;
    this.impactCategories = data;
    this.hasLoadedImpactCategories = true;
  };

  loadImpactCategoriesFailure = (err: any) => {
    console.log(err);
    this.isLoadingImpactCategories = false;
    this.hasLoadedImpactCategories = true;
  };

  loadCompanyFilterCategories = async ({ auth }: { auth: AuthStore }) => {
    this.isLoadingCompanyFilterCategories = true;
    try {
      let resData = {};

      let sectors = axios.get(
        `${api.url}/companies/sectors`,
        auth.requestConfig
      );
      let product_tags = axios.get(
        `${api.url}/companies/product_tags`,
        auth.requestConfig
      );
      let eu_taxonomy_codes = axios.get(
        `${api.url}/companies/eu_taxonomy_codes`,
        auth.requestConfig
      );
      let impact_tags = axios.get(
        `${api.url}/companies/impact_tags`,
        auth.requestConfig
      );
      let affiliations = axios.get(
        `${api.url}/companies/affiliations`,
        auth.requestConfig
      );
      let sdgs = axios.get(`${api.url}/companies/sdgs`, auth.requestConfig);

      let certifications = axios.get(
        `${api.url}/companies/certifications`,
        auth.requestConfig
      );

      const results = await Promise.all([
        sectors,
        product_tags,
        eu_taxonomy_codes,
        impact_tags,
        affiliations,
        sdgs,
        certifications,
      ]);
      if (results) {
        resData = {
          sectors: results[0].data,
          tcc_product_tags: results[1].data,
          tcc_eu_taxonomy_codes: results[2].data,
          tcc_impact_tags: results[3].data,
          tcc_affiliations: results[4].data,
          sdgs: results[5].data,
          certifications: results[6].data,
        };
      }
      this.loadCompanyFilterCategoriesSuccess(resData);
    } catch (err) {
      this.loadCompanyFilterCategoriesFailure(err);
    }
  };
  loadCompanyFilterCategoriesSuccess = (data: any) => {
    this.isLoadingCompanyFilterCategories = false;
    this.companyFilterCategories = data;
    this.hasLoadedCompanyFilterCategories = true;
  };

  loadCompanyFilterCategoriesFailure = (err: any) => {
    console.log(err);
    this.isLoadingCompanyFilterCategories = false;
    this.hasLoadedCompanyFilterCategories = true;
  };
}

decorate(CompaniesStore, {
  isLoadingCompanies: observable,
  hasLoadedCompanies: observable,
  companies: observable,
  isUsingCompaniesFilter: observable,
  count: observable,
  impactCategories: observable,
  isLoadingImpactCategories: observable,
  hasLoadedImpactCategories: observable,
  companyFilterCategories: observable,
  isLoadingCompanyFilterCategories: observable,
  hasLoadedCompanyFilterCategories: observable,
  loadCompanies: action,
  loadCompaniesSuccess: action,
  loadCompaniesFailure: action,
  setHasLoadedCompanies: action,
  loadImpactCategories: action,
  loadImpactCategoriesSuccess: action,
  loadImpactCategoriesFailure: action,
  loadCompanyFilterCategories: action,
  loadCompanyFilterCategoriesSuccess: action,
  loadCompanyFilterCategoriesFailure: action,
});

export default CompaniesStore;

import { decorate, observable, action, computed, toJS, configure } from 'mobx';

import { FieldValidator } from './fieldValidators';

configure({ enforceActions: 'observed' });

class FormStore {
  constructor({
    name = '',
    defaults = {},
    fieldValidators = {},
  }: {
    name?: string;
    defaults?: { [key: string]: any };
    fieldValidators?: { [key: string]: FieldValidator };
  }) {
    this.name = name;
    this.defaults = Object.assign({}, defaults);
    this.fieldValidators = Object.assign({}, fieldValidators);

    this.values.replace(this.defaults);
  }

  defaults;
  fieldValidators;

  // OBSERVABLES................................................................
  name = '';
  values = observable.map({});
  dirtyValues = observable.map({});
  errors = observable.map({});

  // COMPUTEDS..................................................................
  get hasErrors() {
    return this.errors.size > 0;
  }

  get errorFields() {
    return Object.keys(this.errors.toJSON());
  }

  get hasDirtyValues() {
    return this.dirtyValues.size > 0;
  }

  // ACTIONS....................................................................
  init = async (formData = {}) => {
    let values = formData;
    this.resetValues(values);
  };

  setValue = (key = '', value: any): void => {
    this.values.set(key, value);
    this.dirtyValues.set(key, value);
    if (this.errors.has(key)) {
      this.checkFieldError(key);
    }
  };

  clear = (): void => {
    this.values.clear();
    this.dirtyValues.clear();
    this.errors.clear();
  };

  clearErrors = (): void => {
    this.errors.clear();
  };

  resetValues = (values = {}): void => {
    this.values.replace(Object.assign({}, this.defaults, values));
    this.dirtyValues.clear();
    this.errors.clear();
  };

  checkFieldErrors = (): void => {
    const validatorFieldNames = Object.keys(this.fieldValidators);
    for (let validatorFieldName of validatorFieldNames) {
      this.checkFieldError(validatorFieldName);
    }
  };

  checkFieldError = (field: string): void => {
    const validateField = this.fieldValidators[field];
    const error = validateField
      ? validateField(this.values.get(field), this.deserialize())
      : false;
    if (error) {
      this.errors.set(field, error);
    } else {
      this.errors.delete(field);
    }
  };

  checkMultipleFieldErrors = (fields = []): void => {
    for (let field of fields) {
      this.checkFieldError(field);
    }
  };

  setFieldError = (field?: string, error?: any): void => {
    if (field) {
      this.errors.set(field, error);
    }
  };

  removeFieldError = (field?: string): void => {
    this.errors.delete(field);
  };

  deserialize = (): { [key: string]: any } => {
    return toJS(Object.fromEntries(this.values));
  };

  deserializeDirty = (): { [key: string]: any } => {
    return toJS(Object.fromEntries(this.dirtyValues));
  };
}

decorate(FormStore, {
  name: observable,
  values: observable,
  dirtyValues: observable,
  errors: observable,
  hasErrors: computed,
  hasDirtyValues: computed,
  init: action,
  setValue: action,
  clear: action,
  clearErrors: action,
  resetValues: action,
  checkFieldErrors: action,
  checkFieldError: action,
  setFieldError: action,
  removeFieldError: action,
  deserialize: action,
  deserializeDirty: action,
});

export default FormStore;

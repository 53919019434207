import React, { useState, useEffect } from 'react';

import Button from 'components/common/Button';
import { AddIcon, RemoveIcon } from 'components/icons/mui_icons/index';
import colors from 'constants/colors';

const moreInfoStyle = {
  fontSize: '10px',
  color: 'secondary.main',
  fontWeight: 700,
  letterSpacing: '1.5px',
  borderStyle: 'none',
  padding: '4px',
  '&:hover': {
    borderStyle: 'none',
    backgroundColor: colors.neutral200,
  },
} as const;

type Props = {
  show: Boolean;
  handleChange: () => void;
  collapse: Boolean;
};

const icons = {
  add: <AddIcon color={'primary'} />,
  remove: <RemoveIcon color={'primary'} />,
};

function TagButton({ show, handleChange, collapse }: Props) {
  const [icon, setIcon] = useState<React.ReactElement>();
  const [text, setText] = useState<String>();

  useEffect(() => {
    if (collapse) {
      setIcon(icons.remove);
      setText('COLLAPSE');
    } else {
      setIcon(icons.add);
      setText('EXPAND');
    }
  }, [collapse]);

  return (
    <>
      {show ? (
        <Button
          variant="outlined"
          sx={moreInfoStyle}
          onClick={handleChange}
          startIcon={icon}
        >
          {text}
        </Button>
      ) : null}
    </>
  );
}

export default TagButton;

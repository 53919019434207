import React from 'react';
import { observer } from 'mobx-react-lite';

import CardMedia from 'components/common/CardMedia';

import { imageSrcMap } from 'media/backgroundImageEmptyState/index';

const backGroundImageStyle = {
  minWidth: '240px',
  height: '240px',
};

type Props = {
  banner: string;
};

function BackgroundImage({ banner }: Props) {
  return (
    <>
      {banner ? (
        <CardMedia
          component="img"
          image={banner}
          sx={backGroundImageStyle}
        ></CardMedia>
      ) : (
        <CardMedia
          component="img"
          image={imageSrcMap.backgroundImage}
          sx={backGroundImageStyle}
        ></CardMedia>
      )}
    </>
  );
}

export default observer(BackgroundImage);

import React from 'react';

import Container from 'components/common/Container';

import ConsolidateCompanyDialog from './ConsolidateCompanyDialog';
import ScreeningList from './ScreeningList';

const Component = () => {
  // const { t } = useTranslation('common');

  return (
    <Container maxWidth="xl" pageContainer>
      <ScreeningList />
      <ConsolidateCompanyDialog />
    </Container>
  );
};

export default Component;

// import React from 'react'
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import Container from 'components/common/Container';
// import { useNavigate } from 'react-router-dom'
// import { useTranslation } from 'react-i18next';

// import useAuth from 'hooks/useAuth'

function Component() {
  // const auth = useAuth()
  // const navigate = useNavigate()
  // const { t } = useTranslation('common');
  return (
    <Container pageContainer maxWidth="lg" sx={{ pt: 3 }}>
      <Outlet />
    </Container>
  );
}

export default observer(Component);

import React from 'react';
import { observer } from 'mobx-react-lite';

import Typography from 'components/common/Typography';
import colors from 'constants/colors';

type Props = {
  companyName: string;
};

function CompanyName({ companyName }: Props) {
  return (
    <Typography variant="h3" color={colors.neutral800}>
      {companyName}
    </Typography>
  );
}

export default observer(CompanyName);

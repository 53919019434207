import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Fab from '@mui/material/Fab';

import Button from 'components/common/Button';
import useUserCompany from 'hooks/useUserCompany';
import TccInputTextField from 'components/common/TccInputTextField';
import Form from 'components/common/Form';
import Stack from 'components/common/Stack';
import SectorSelect from 'components/common/SectorSelect';
import CountrySelect from 'components/common/CountrySelect';

import useAuth from 'hooks/useAuth';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import CompanyAvatar from 'components/App/Routes/Company/CompanyDetailsPage/CompanyHeader/CompanyAvatar/CompanyAvatar';

import { UploadIcon } from 'components/icons/mui_icons';

import colors from 'constants/colors';
import { getMediaUrlSrc } from 'utils/browser';
import Typography from '@mui/material/Typography';

const UserCompanyEditForm = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const snackbarMessage = useSnackbarMessage();
  const { userCompanyForm, saveCompany, isPatchingUserCompany } =
    useUserCompany();

  return (
    <Form onSubmit={() => saveCompany({ auth, snackbarMessage })}>
      <Fab
        sx={{
          margin: 0,
          top: 'auto',
          right: { xs: '16px', sm: '32px' },
          bottom: { xs: '16px', sm: '32px' },
          left: 'auto',
          position: 'fixed',
        }}
        variant="extended"
        type="submit"
        disabled={!userCompanyForm.hasDirtyValues || userCompanyForm.hasErrors}
        color="primary"
      >
        {t('Save Company')}
      </Fab>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            direction={{ xs: 'column-reverse', md: 'row' }}
          >
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <TccInputTextField
                  title={t(`Company Name`)}
                  isDirty={userCompanyForm.dirtyValues.has('name')}
                  error={userCompanyForm.errors.has('name')}
                  required
                  onChange={(e: any) => {
                    userCompanyForm.setValue('name', e.target.value);
                  }}
                  value={userCompanyForm.values.get('name') || ''}
                />
                <TccInputTextField
                  title={t(`Address Line 1`)}
                  isDirty={userCompanyForm.dirtyValues.has('address')}
                  error={userCompanyForm.errors.has('address')}
                  onChange={(e: any) => {
                    userCompanyForm.setValue('address', e.target.value);
                  }}
                  value={userCompanyForm.values.get('address') || ''}
                />
                <TccInputTextField
                  title={t(`Address Line 2`)}
                  isDirty={userCompanyForm.dirtyValues.has('address2')}
                  error={userCompanyForm.errors.has('address2')}
                  onChange={(e: any) => {
                    userCompanyForm.setValue('address2', e.target.value);
                  }}
                  value={userCompanyForm.values.get('address2') || ''}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack
                spacing={2}
                alignItems={{ xs: 'left', md: 'center' }}
                mb={'10px'}
              >
                <Stack spacing={2} alignItems="left">
                  <Typography
                    variant={'body1'}
                    color={
                      userCompanyForm.errors.has('logo')
                        ? 'error.main'
                        : 'neutral.contrastText'
                    }
                  >
                    {t('Company Logo')}
                  </Typography>
                  <CompanyAvatar
                    hideChip
                    companyName={userCompanyForm.values.get('name')}
                    companySlug={''}
                    userCompanySlug={''}
                    logo={getMediaUrlSrc(userCompanyForm.values.get('logo'))}
                    isAdmin={false}
                    published={userCompanyForm.values.get('published')}
                  />
                </Stack>
                <label htmlFor="logo">
                  <input
                    hidden
                    type="file"
                    accept="image/*"
                    id="logo"
                    onChange={(e: any) => {
                      userCompanyForm.setValue('logo', e.target.files[0]);
                      const maxSize = 3140000; // 3MiB
                      let size = e.target.files[0]?.size || 0;
                      if (size > maxSize) {
                        userCompanyForm.setFieldError('logo', true);
                      } else {
                        userCompanyForm.removeFieldError('logo');
                      }
                    }}
                    style={{
                      color: colors.neutral200,
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="inherit"
                    component="span"
                    disabled={isPatchingUserCompany}
                    startIcon={<UploadIcon />}
                  >
                    {t('Choose file')}
                  </Button>
                </label>
                {userCompanyForm.errors.has('logo') ? (
                  <Alert severity="error">
                    {t('Maximum file size is {{size}}', { size: '3MB' })}
                  </Alert>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={12} md={6}>
              <TccInputTextField
                title={t(`Zip Code`)}
                isDirty={userCompanyForm.dirtyValues.has('zip_code')}
                error={userCompanyForm.errors.has('zip_code')}
                onChange={(e: any) => {
                  userCompanyForm.setValue('zip_code', e.target.value);
                }}
                value={userCompanyForm.values.get('zip_code') || ''}
              />
            </Grid>
            <Grid item xs={6} sm={12} md={6}>
              <TccInputTextField
                title={t(`City`)}
                isDirty={userCompanyForm.dirtyValues.has('city')}
                error={userCompanyForm.errors.has('city')}
                onChange={(e: any) => {
                  userCompanyForm.setValue('city', e.target.value);
                }}
                value={userCompanyForm.values.get('city') || ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TccInputTextField
                title={t(`Region`)}
                isDirty={userCompanyForm.dirtyValues.has('region')}
                error={userCompanyForm.errors.has('region')}
                onChange={(e: any) => {
                  userCompanyForm.setValue('region', e.target.value);
                }}
                value={userCompanyForm.values.get('region') || ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CountrySelect
                title={t('Country')}
                onChange={(country) => {
                  userCompanyForm.setValue('country', country);
                }}
                value={userCompanyForm.values.get('country')}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={t(`Email`)}
                isDirty={userCompanyForm.dirtyValues.has('email')}
                error={userCompanyForm.errors.has('email')}
                helpText={userCompanyForm.errors.get('email')}
                onChange={(e: any) => {
                  userCompanyForm.setValue('email', e.target.value);
                  userCompanyForm.removeFieldError('email');
                }}
                value={userCompanyForm.values.get('email') || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={t(`Website`)}
                isDirty={userCompanyForm.dirtyValues.has('website')}
                error={userCompanyForm.errors.has('website')}
                helpText={userCompanyForm.errors.get('website')}
                onChange={(e: any) => {
                  userCompanyForm.setValue('website', e.target.value);
                  userCompanyForm.removeFieldError('website');
                }}
                value={userCompanyForm.values.get('website') || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={t(`Company LEI code`)}
                subTitle={t(
                  'Legal Entity Identifier (LEI) code for your company'
                )}
                isDirty={userCompanyForm.dirtyValues.has('lei_code')}
                error={userCompanyForm.errors.has('lei_code')}
                helpText={userCompanyForm.errors.get('lei_code')}
                onChange={(e: any) => {
                  userCompanyForm.setValue('lei_code', e.target.value);
                }}
                value={userCompanyForm.values.get('lei_code') || ''}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <SectorSelect
                required
                title={t('Sector')}
                value={userCompanyForm.values.get('sector')}
                onChange={(sector) =>
                  userCompanyForm.setValue('sector', sector)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={t(`Short Description`)}
                isDirty={userCompanyForm.dirtyValues.has('short_description')}
                error={userCompanyForm.errors.has('short_description')}
                multiline
                rows={3}
                onChange={(e: any) => {
                  userCompanyForm.setValue('short_description', e.target.value);
                }}
                value={userCompanyForm.values.get('short_description') || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={t(`Description`)}
                isDirty={userCompanyForm.dirtyValues.has('description')}
                error={userCompanyForm.errors.has('description')}
                multiline
                rows={6}
                onChange={(e: any) => {
                  userCompanyForm.setValue('description', e.target.value);
                }}
                value={userCompanyForm.values.get('description') || ''}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default observer(UserCompanyEditForm);

import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useAccessRequestList() {
  const auth = useAuth();
  return useQuery({
    // enabled: !!companyId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    // refetchInterval: 5000,
    // cacheTime: 0,
    staleTime: 5000,
    queryKey: ['user-access-requests'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/confidentiality/data-access-requests/`,
        auth.requestConfig
      );
      return res.data.results as { [key: string]: string }[];
    },
  });
}

export function useAccessRequestCreate() {
  const auth = useAuth();
  return useMutation({
    mutationFn: async ({
      data,
      onSuccess,
    }: {
      data: { data_receiver: string; data_provider: string };
      onSuccess?: () => void;
    }) => {
      const res = await axios.post(
        `${api.url}/confidentiality/data-access-requests/`,
        data,
        auth.requestConfig
      );
      if (onSuccess) {
        onSuccess();
      }
      return res.data;
    },
  });
}

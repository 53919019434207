import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export function useAdminJobPatch(jobId?: string) {
  const auth = useAuth();
  return useMutation({
    mutationFn: async ({ company }: { company: string }) => {
      const formData = new FormData();
      if (!company || !jobId) return;

      formData.set('company', company);

      const res = await axios.patch(
        `${api.url}/etl/jobs/${jobId}/`,
        formData,
        auth.requestConfig
      );

      return res.data;
    },
  });
}

export function useAdminJobDelete(jobId?: string) {
  const auth = useAuth();
  return useMutation({
    mutationFn: async () => {
      if (!jobId) return;

      const keepGoing = confirm(
        'Are you sure you want to delete this job? (non-reversible)'
      );
      if (!keepGoing) return;

      const res = await axios.delete(
        `${api.url}/etl/jobs/${jobId}/`,
        auth.requestConfig
      );

      return res.data;
    },
  });
}

export default function useAdminJob(jobId?: string) {
  const auth = useAuth();
  return useQuery({
    enabled: !!jobId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
    cacheTime: 0,
    staleTime: 0,
    queryKey: ['admin-job', { jobId }],
    queryFn: async () => {
      if (jobId) {
        const res = await axios.get(
          `${api.url}/etl/jobs/${jobId}/`,
          auth.requestConfig
        );
        return res.data;
        // return [
        //   {
        //     id: 123,
        //     type: 'CDP',
        //     status: 'DONE',
        //     errors: null,
        //     company: companyId,
        //   },
        // ];
      }
      return [];
    },
  });
}

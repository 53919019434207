import React from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import Toolbar from 'components/common/Toolbar';

import AppBar from './AppBar';
import UnauthAppBar from './UnauthAppBar';

import useAuth from 'hooks/useAuth';

function Component() {
  const auth = useAuth();
  return (
    <React.Fragment>
      {auth.isAuthenticated ? (
        <>
          <AppBar />
          <Toolbar />
        </>
      ) : (
        <UnauthAppBar />
      )}
      <Outlet />
    </React.Fragment>
  );
}

export default observer(Component);

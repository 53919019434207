import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Typography from 'components/common/Typography';
import colors from 'constants/colors';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function CompaniesSortingMenu() {
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  const handleChange = (event: SelectChangeEvent) => {
    navigateSearch({
      pathname: location.pathname,
      params: {
        page: 1,
        ordering: event.target.value,
      },
      replace: false,
    });
  };

  return (
    <Select
      variant="filled"
      fullWidth
      disableUnderline
      color="primary"
      value={searchParams.get('ordering') || '-profile_search_ranking'}
      onChange={handleChange}
      IconComponent={() => (
        <KeyboardArrowDownIcon
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            display: 'inline-block',
            color: colors.neutral500,
            marginTop: '8px',
            marginRight: '6px',
            pointerEvents: 'none',
          }}
        />
      )}
      sx={{
        paddingBottom: '14px',
        borderRadius: '8px',
        height: '40px',
        backgroundColor: colors.neutral100,
        '& .MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <MenuItem
        key={'-profile_search_ranking'}
        value={'-profile_search_ranking'}
      >
        <Typography variant="body1"> {t('Profile Rank')}</Typography>
      </MenuItem>
      <MenuItem key={'name'} value={'name'}>
        <Typography variant="body1"> {t('A-Z')}</Typography>
      </MenuItem>
      <MenuItem key={'-name'} value={'-name'}>
        <Typography variant="body1"> {t('Z-A')}</Typography>
      </MenuItem>
    </Select>
  );
}
export default observer(CompaniesSortingMenu);

import { Link, useParams } from 'react-router-dom';
import Stack from 'components/common/Stack';
import { useTranslation } from 'react-i18next';
import useAdminCompany from 'hooks/useAdminCompany';
import Box from '@mui/material/Box';

function CompanyCannedSearches() {
  const { companyId } = useParams();
  const { data: company } = useAdminCompany(companyId);
  const { t } = useTranslation();

  const current_year = new Date().getFullYear();
  const ownershipLinks = [
    `who owns ${company?.name}`,
    `parent company of ${company?.name}`,
    `${company?.name} wikipedia`,
  ];

  const reportLinks = [
    `${company?.name} sustainability report`,
    `${company?.name} annual report`,
    `${company?.name} supplier code of conduct`,
    `${company?.name} code of conduct`,
  ];

  const cdpLinks = [`${company?.name} CDP questionnaire`];

  const gleifLink = `https://search.gleif.org/#/search/simpleSearch=${encodeURIComponent(
    company?.name || ''
  )}`;

  return company ? (
    <Stack direction="row" spacing={2} maxWidth={'100%'}>
      <Box>
        <Stack spacing={1}>
          <Link to={gleifLink} target="_blank" rel="noopener noreferrer">
            {'[gleif search]'} "{company?.name}"
          </Link>
        </Stack>
      </Box>
      <Box>
        <Stack spacing={1}>
          {ownershipLinks.map((search, index) => (
            <Link
              key={index}
              to={`https://www.google.com/search?q=${encodeURIComponent(
                search
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {'[google search]'} "{search}"
            </Link>
          ))}
        </Stack>
      </Box>
      <Box>
        <Stack spacing={1}>
          {reportLinks.map((search, index) => (
            <Link
              key={index}
              to={`https://www.google.com/search?q=${encodeURIComponent(
                search
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {'[google search]'} "{search}"
            </Link>
          ))}
        </Stack>
      </Box>
      <Box>
        <Stack spacing={1}>
          {cdpLinks.map((search, index) => (
            <Link
              key={index}
              to={`https://www.google.com/search?q=${encodeURIComponent(
                search
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {'[google search]'} "{search}"
            </Link>
          ))}
        </Stack>
      </Box>
    </Stack>
  ) : null;
}

export default CompanyCannedSearches;

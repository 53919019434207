// import React from 'react'
import { observer } from 'mobx-react-lite';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import api from 'constants/api';
import Loading from 'components/common/Loading';
import { Alert } from '@mui/material';
import Stack from 'components/common/Stack';

import ReactApexChart from 'react-apexcharts';
import CDPScores from 'constants/CDPScores';
import colors from 'constants/colors';

function Component() {
  const { t } = useTranslation();
  const auth = useAuth();
  const { isLoading, data, isError } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['latest-cdp-score-distribution'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/suppliers/dashboard/cdp_scores/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const config = {
    series: [
      {
        name: 'score',
        data: isLoading
          ? []
          : data.map((c: any) => {
              const letterScore = c.cdp_score?.score;
              return {
                x: c.company_name,
                y: CDPScores.findIndex((score) => score === letterScore),
              };
            }),
      },
    ],
    options: {
      colors: [
        colors.tertiary800,
        colors.tertiary500,
        colors.neutral800,
        colors.neutral600,
        colors.neutral400,
        colors.secondary300,
        colors.secondary500,
        colors.primary300,
        colors.primary500,
      ].reverse(),
      tooltip: {
        enabledOnSeries: [],
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          colors: {
            ranges: [
              { from: 0, to: 0, color: colors.tertiary800 },
              { from: 1, to: 1, color: colors.tertiary500 },
              { from: 2, to: 2, color: colors.neutral800 },
              { from: 3, to: 3, color: colors.neutral600 },
              { from: 4, to: 4, color: colors.neutral400 },
              { from: 5, to: 5, color: colors.secondary300 },
              { from: 6, to: 6, color: colors.secondary500 },
              { from: 7, to: 7, color: colors.primary300 },
              { from: 8, to: 8, color: colors.primary500 },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: isLoading
          ? []
          : data.map((company: any) => company.company_name),
        max: 10,
        labels: {
          minHeight: 18,
          formatter: function (value: any, index: number) {
            return CDPScores[index];
          },
        },
      },
    },
  };

  return isLoading ? (
    <Loading color="primary" />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <Stack spacing={2}>
      <ReactApexChart
        options={config?.options}
        series={config?.series}
        type="bar"
        height={400}
      />
    </Stack>
  );
}

export default observer(Component);

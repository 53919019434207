import React from 'react';
import { observer } from 'mobx-react-lite';
import FlagIcon from '@mui/icons-material/Flag';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'; // Assuming you have a Grid component in your components
import CPASurveyQuestionSwitch from './CPASurveyQuestionSwitch';
import axios from 'axios';
import useCPASurvey from '../store/useCPASurvey';
import { useQuery } from '@tanstack/react-query';
import useAuth from 'hooks/useAuth';
import Loading from 'components/common/Loading';
import api from 'constants/api';

interface CPASurveyQuestionProps {
  id: string;
  title: string;
  mediaid?: string;
  values: string[];
  answers: string[];
  cpasurveyId: string;
  index: number;
  onFlagClick: () => void;
  final: boolean;
}

const CPASurveyQuestion: React.FC<CPASurveyQuestionProps> = ({
  id,
  title,
  mediaid,
  values,
  answers,
  cpasurveyId,
  index,
  onFlagClick,
  final,
}) => {
  const auth = useAuth();
  const [open, setOpen] = React.useState(index === 0);
  const { flags } = useCPASurvey();

  const { isFetching, data } = useQuery({
    enabled: !!mediaid,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 10000,
    staleTime: 10000,
    queryKey: [`cpa-media-${mediaid}`],
    queryFn: async () => {
      if (!mediaid) return {};
      const res = await axios.get(
        `${api.url}/admin/crc_surveys/media/${mediaid}/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const url = mediaid && data ? data.url : '';
  const flag = flags.get(id);
  const displayText =
    mediaid && data
      ? data.file_name
      : values.filter((v) => v !== '').join(', ');

  return isFetching ? (
    <Loading />
  ) : (
    <Container
      sx={{
        border: `${flag && !final ? '1px solid' : '1px solid'} ${
          flag && !final ? 'error.main' : 'divider'
        }`,
        borderRadius: 2,
        padding: 2,
        mb: 1,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{title}</Box>
            {!final && (
              <Box sx={{ color: flag ? 'error.main' : 'inherit' }}>
                <IconButton size="small" onClick={onFlagClick}>
                  <FlagIcon color={flag ? 'error' : 'inherit'} />
                </IconButton>
              </Box>
            )}
            <Box>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? (
                  <ExpandLessIcon color="inherit" />
                ) : (
                  <ExpandMoreIcon color="inherit" />
                )}
              </IconButton>
            </Box>
          </Box>
        </Grid>

        {!open && !!(url || displayText) && (
          <Grid item xs={12}>
            <Box display="flex" fontWeight="bold">
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  {displayText}
                </a>
              ) : (
                <span>{displayText}</span>
              )}
            </Box>
          </Grid>
        )}

        {open && (
          <Grid item xs={12}>
            <CPASurveyQuestionSwitch
              id={id}
              values={values}
              answers={answers}
              cpasurveyId={cpasurveyId}
              final={final}
              index={index}
              url={url}
              flag={flag}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default observer(CPASurveyQuestion);

// import React from 'react'
import Container, { ContainerProps } from '@mui/material/Container';

interface ExtendedContainerProps extends ContainerProps {
  pageContainer?: boolean;
}

function Component({
  pageContainer,
  maxWidth = 'xl',
  sx,
  ...props
}: ExtendedContainerProps) {
  // const auth = useAuth()
  // const navigate = useNavigate()
  return (
    <Container
      maxWidth={maxWidth}
      sx={
        pageContainer
          ? {
              pt: { xs: 2, sm: 3 },
              pb: { xs: 6, sm: 10 },
              pr: { xs: 2, sm: 3, md: 5 },
              pl: { xs: 2, sm: 3, md: 5 },
              ...sx,
            }
          : sx
      }
      {...props}
    />
  );
}

export default Component;

import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';

import Tooltip from 'components/common/Tooltip/Tooltip';

import colors from 'constants/colors';

export default function SourceChannelChip({ channel }: { channel: string }) {
  const { t } = useTranslation('common');
  const explainers: { [k: string]: string } = {
    CDP: t(
      'Data extracted directly from Carbon Disclosure Project disclosures'
    ),
    public: t(
      'Information available to the general public through open sources such as sustainability reports, financial disclosures, and online content'
    ),
    SBTi: t('Sourced directly from the Science Based Targets initiative'),
    primary: t('Primary data originates directly from the company itself'),
    wikipedia: t('Sourced via Wikipedia.com'),
  };

  return (
    <Tooltip
      title={explainers[channel]}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            color: colors.neutral600,
            fontSize: '14px',
            padding: '22px',
            boxShadow:
              '0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.1)',
            borderRadius: '22px',
            bgcolor: 'white',
            '& .MuiTooltip-arrow': {
              color: 'white',
            },
            marginBottom: '20px',
          },
        },
      }}
    >
      <Chip
        label={channel}
        color={'secondary'}
        sx={{
          cursor: 'default',
        }}
      />
    </Tooltip>
  );
}

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import useCompanies from 'hooks/useCompanies';

import CompaniesListData from '../CompanyListData/CompaniesListData';

const Component = () => {
  const { companies } = useCompanies();

  const rows = toJS(companies.slice());

  return <CompaniesListData rows={rows} />;
};

export default observer(Component);

import { decorate, observable, action, runInAction } from 'mobx';
import {
  combineFieldValidators,
  required,
  email,
} from 'state/FormStore/fieldValidators';
import axios from 'axios';
import api from 'constants/api';

import {
  setLocalStorage,
  removeLocalStorage,
  getLocalStorage,
} from 'utils/browser';
import * as schema from 'types/backendSchema';

import FormStore from 'state/FormStore';
import AuthStore from 'state/AuthStore';
import SnackbarMessageStore from 'state/SnackbarMessageStore';

class LoginStore {
  constructor() {
    this.form = new FormStore({
      defaults: {
        email: '',
        password: '',
        remember: !!getLocalStorage('tcc-token'),
        userToken: getLocalStorage('tcc-token') || '',
      },
      fieldValidators: {
        email: combineFieldValidators([required, email]),
      },
    });
  }

  form: FormStore;
  // OBSERVABLES................................................................
  isLoggingIn = false;
  isLoggingFailure = false;
  isInvalid = false;
  user_email = '';
  error_message = '';

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  login = async ({
    auth,
    snackbarMessage,
  }: {
    auth: AuthStore;
    snackbarMessage: SnackbarMessageStore;
  }) => {
    this.isLoggingIn = true;

    const { email, password, userToken, remember } = this.form.deserialize(); // password..

    try {
      if (!auth.useToken) {
        await axios.post(
          `${api.url}/auth/login/`,
          { email, password } as schema.definitions['Login'],
          auth.requestConfig
        );
      } else {
        auth.setToken(userToken);

        if (remember && userToken) {
          setLocalStorage('tcc-token', userToken);
        } else {
          removeLocalStorage('tcc-token');
        }
      }

      await auth.loadUserData();

      if (auth.userId) {
        this.loginSuccess();
      } else {
        this.loginFailure();
      }
    } catch (err: any) {
      this.loginFailure(err);
      runInAction(() => {
        this.isLoggingFailure = true;
      });
      runInAction(() => {
        this.user_email = email;
      });
      runInAction(() => {
        this.error_message = err?.response?.data?.detail;
      });
    }
  };

  loginSuccess = () => {
    this.isLoggingIn = false;
    this.isInvalid = false;
    this.form.resetValues();
  };

  loginFailure = (err?: any) => {
    this.isLoggingIn = false;
    this.isInvalid = true;
    console.log('login failure', err);
  };
}

decorate(LoginStore, {
  isLoggingIn: observable,
  isInvalid: observable,
  user_email: observable,
  error_message: observable,
  isLoggingFailure: observable,
  login: action,
  loginSuccess: action,
  loginFailure: action,
});

export default LoginStore;

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Logo = (props: any) => {
  return (
    <SvgIcon
      {...props}
      viewBox={'0 0 201 201'}
      fontSize={'inherit'}
      color="inherit"
      fill="none"
      style={{ lineHeight: 0 }}
    >
      <path
        d="M24.2836 119.778C13.4546 77.9251 38.6041 35.2181 80.4568 24.3891C121.334 13.8125 163.025 37.5564 175.036 77.6623C181.408 74.3911 188.204 71.7207 195.368 69.7424C179.374 19.4938 126.68 -10.0513 75.0051 3.31918C21.5159 17.1591 -10.6262 71.7402 3.21371 125.229C16.6469 177.147 68.4617 208.953 120.406 198.122C118.608 190.908 117.594 183.678 117.311 176.524C76.2252 185.828 34.9091 160.844 24.2836 119.778Z"
        fill="inherit"
      />
      <path
        d="M140.429 167.26C139.951 175.004 140.555 182.933 142.355 190.851C180.047 173.325 203.089 133.919 199.822 92.4317C192.044 94.7539 184.842 98.1253 178.331 102.349C177.602 128.966 163.231 153.555 140.429 167.26Z"
        fill="inherit"
      />
    </SvgIcon>
  );
};

export default Logo;

import { useTranslation } from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';
import Typography from 'components/common/Typography';

import Avatar from 'components/common/Avatar';

import Stack from 'components/common/Stack/Stack';

import useUserCompany from 'hooks/useUserCompany';

import { getCountryName } from 'utils/getCountryName';
import Chip from 'components/common/Chip';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';
import RadialBar from 'components/common/RadialBar/RadialBar';
import { getClimateScoreAsPercent } from 'utils/tools';
import { useEffect, useState } from 'react';
import colors from 'constants/colors';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import RFIDialog from 'components/common/UserDashboardAssesment/RFIDialog';
import { dateReadable } from 'utils/date';
import SelectMenu from 'components/common/SelectMenu';

const Component = ({
  scorecard,
}: {
  scorecard?: { overall: number; categories: { score: number }[] };
}) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});
  const auth = useAuth();

  // const showCPAUpsellBanner =
  //   isCPAexpired === true || userCompanyCRC.status === undefined;
  const showCPAUpsellBanner = true;

  const { userCompany, userCompanyCRC, isCPAexpired, expirationDateReadable } =
    useUserCompany();

  const { isLoading, data, isError } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['user-company-customers'],
    retry: false,
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/customers/`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const customers = data && data.results ? data.results : [];

  useEffect(() => {
    if (!(selectedCustomer as any).id && customers.length > 0) {
      setSelectedCustomer(customers[0]);
    }
  }, [selectedCustomer, setSelectedCustomer, customers]);

  const score = scorecard?.overall
    ? getClimateScoreAsPercent(scorecard.overall)
    : 0;

  const medianScore = t('Median ClimateChoice Score:');
  const customerAverage =
    selectedCustomer.id &&
    scorecard?.overall === selectedCustomer.monitored_suppliers_median
      ? t('Your score is comparable to other suppliers of {{customer}}', {
          customer: `${
            selectedCustomer.name
          } (${medianScore} ${getClimateScoreAsPercent(
            selectedCustomer.monitored_suppliers_median
          )})`,
        })
      : scorecard?.overall &&
        scorecard?.overall > selectedCustomer.monitored_suppliers_median
      ? t('Your score is higher than other suppliers of {{customer}}', {
          customer: `${
            selectedCustomer.name
          } (${medianScore} ${getClimateScoreAsPercent(
            selectedCustomer.monitored_suppliers_median
          )})`,
        })
      : t('Your score is lower than other suppliers of {{customer}}', {
          customer: `${
            selectedCustomer.name
          } (${medianScore} ${getClimateScoreAsPercent(
            selectedCustomer.monitored_suppliers_median
          )})`,
        });

  return customers.length > 0 ? (
    <Card>
      <Stack spacing={2}>
        <Typography variant="h5">{t('How do you compare?')}</Typography>

        <Typography variant="body1">{customerAverage}</Typography>
        <Stack height={250} alignItems={'center'}>
          {!selectedCustomer?.id ? null : (
            <Box position={'absolute'} width={270} height={270}>
              <Box position={'absolute'} top={0} left={0}>
                <RadialBar
                  hideValue
                  score={score}
                  outOf100
                  scoreColor={colors.primary500}
                  fontSize="24px"
                  offsetY={30}
                  radialOffsetX={-2}
                  radialOffsetY={-5}
                />
              </Box>
              <Box position={'absolute'} left={'50%'} top={85} ml={-3.5}>
                <Typography variant="body1" color={colors.neutral600}>
                  {t('Median')}
                </Typography>
              </Box>
              <Box position={'absolute'} top={0} left={0}>
                <RadialBar
                  noTrack
                  fontSize="28px"
                  offsetY={12}
                  outOf100
                  color={colors.neutral600}
                  scoreColor={colors.neutral600}
                  score={getClimateScoreAsPercent(
                    selectedCustomer.monitored_suppliers_median
                  )}
                  radialOffsetX={-2}
                  radialOffsetY={-5}
                />
              </Box>
              {scorecard?.overall &&
              scorecard?.overall <
                selectedCustomer.monitored_suppliers_median ? (
                <Box position={'absolute'} top={0} left={0}>
                  <RadialBar
                    outOf100
                    hideValue
                    score={score}
                    color={colors.tertiary500}
                    scoreColor={colors.tertiary500}
                    fontSize="24px"
                    offsetY={30}
                    radialOffsetX={-2}
                    radialOffsetY={-5}
                  />
                </Box>
              ) : null}
            </Box>
          )}
        </Stack>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant="body1">{t('Compare for:')}</Typography>
          <SelectMenu
            items={customers.map((item: any) => item.name)}
            handleChange={(e: any) => {
              setSelectedCustomer(
                customers.find(
                  (customer: any) => customer.name === e.target.value
                ) || {}
              );
            }}
            value={(selectedCustomer as any).name}
          />
        </Stack>
      </Stack>
      <RFIDialog
        open={open}
        closeDialog={() => {
          setOpen(false);
          return true;
        }}
        identifier={userCompany.id}
        companyName={userCompany.name}
        userDetails={auth.userDetails}
      />
    </Card>
  ) : null;
};

export default Component;

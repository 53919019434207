import { useTranslation } from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';

import colors from 'constants/colors';
import Typography from 'components/common/Typography';
import Box from 'components/common/Box';

import Avatar from 'components/common/Avatar';

import Stack from 'components/common/Stack/Stack';

import useUserCompany from 'hooks/useUserCompany';

import { getCountryName } from 'utils/getCountryName';
import Chip from 'components/common/Chip';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';
import Card from 'components/common/Card';
import ScoreRadialSmall from './ScoreRadialSmall';
import ClimateImpactModel from 'components/icons/crc/crc_icons/ClimateImpactModel';

const Component = ({ score = 0 }) => {
  const { t } = useTranslation('common');

  const auth = useAuth();
  const { userCompany } = useUserCompany();

  return (
    <Card>
      <Stack spacing={2} onClick={() => false}>
        <ScoreRadialSmall
          title={t('Emissions Performance')}
          info={t(
            'The Emissions Performance category reflects the draw-down of emissions over time.'
          )}
          // breakdown={[
          //   {
          //     question: 'Are your Scope 1 & 2 emissions decreasing?',
          //     score: 1,
          //   },
          //   {
          //     question: 'Are your Scope 3 emissions decreasing?',
          //     score: 0,
          //   },
          //   {
          //     question: 'Have your total emissions decreased?',
          //     score: 0.5,
          //   },
          // ]}
          breakdown={userCompany?.score_breakdown
            ?.filter((s: any) => {
              return s.category === 'Emissions (Performance)';
            })
            .map((s: any) => {
              return {
                question: s.question_text,
                score: s.score,
              };
            })}
          score={score}
          icon={<ClimateImpactModel color={colors.secondary500} />}
        />
      </Stack>
    </Card>
  );
};

export default Component;

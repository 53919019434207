// import React from 'react'
import { observer } from 'mobx-react-lite';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/common/Checkbox';

// import useAuth from 'hooks/useAuth'
import useNavigateSearch from 'hooks/useNavigateSearchParams';

function Component() {
  // const auth = useAuth()
  // const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();
  const { t } = useTranslation('common');

  const targets = searchParams.get('target_framework')?.split(',') || [];

  const toggleTargets = (targetsToToggle: string[]) => {
    let newTargets = targets.slice();
    targetsToToggle.forEach((target: string) => {
      const targetIndex = newTargets.indexOf(target);
      if (targetIndex === -1) {
        newTargets.push(target);
      } else {
        newTargets.splice(targetIndex, 1);
      }
    });
    navigateSearch({
      pathname: location.pathname,
      params: {
        page: 1,
        ...(newTargets.length > 0
          ? {
              target_framework: newTargets.join(','),
            }
          : {}),
      },
      replace: false,
      removeParams: newTargets.length === 0 ? ['target_framework'] : [],
    });
  };

  return (
    <>
      <Checkbox
        checked={
          searchParams
            .get('target_framework')
            ?.split(',')
            .includes('Science-Based Targets')
            ? true
            : false
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          toggleTargets(['Science-Based Targets']);
        }}
        label={t('SBTi Target Data') + ''}
      />
      <Checkbox
        checked={
          searchParams.get('target_framework')?.split(',').includes('')
            ? true
            : false
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          toggleTargets(['', 'n/a']);
        }}
        label={t('Non-SBTi Target Data') + ''}
      />
    </>
  );
}

export default observer(Component);

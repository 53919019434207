import React, { useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { dateReadable } from 'utils/date';
import * as schema from 'types/backendSchema';

// import SelectCard from './SelectCard';
// import CustomTableFooter from './CustomTableFooter/CustomTableFooter';
import ColHeader from './UserDashboardCustomersTableComps/ColHeader';
import CompanyTitleCell from './UserDashboardCustomersTableComps/CompanyTitleCell';
import StatusChip from './UserDashboardCustomersTableComps/StatusChip';
import Typography from 'components/common/Typography';

import { styled } from '@mui/system';
import colors from 'constants/colors';
import Box from 'components/common/Box';

import { getClimateScoreAsPercent } from 'utils/tools';

const StyledDataGrid = styled(DataGrid)(`
.MuiDataGrid-row:nth-of-type(odd){
    background: white;
  }
  .MuiDataGrid-row:hover{
    background: ${colors.primary100};
  }
.MuiDataGrid-columnHeaders{
  background: ${colors.neutral200};
}
.MuiDataGrid-columnHeaderTitle{
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.MuiDataGrid-rowSeparator{
  display: 'none',
},
`);

type Props = {
  customers: schema.operations['user_company_customers_list']['responses'][200]['schema']['results'];
  customersCount: number | null;
  rowsPerPage: number;
};

export default function UserDashboardCustomersTable({
  customers,
  customersCount,
  rowsPerPage,
}: Props) {
  const { t } = useTranslation('common');
  const [height, setHeight] = useState(340);

  useLayoutEffect(() => {
    setHeight(customers.length * 52 + 125);
  }, [customers.length]);

  const columns: GridColDef[] = [
    {
      field: 'customerName',
      headerName: `${t('Company')}`,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <CompanyTitleCell params={params} />,
    },
    {
      field: 'dateAdded',
      headerName: `${t('Date Added')}`,
      width: 140,
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return (
          <ColHeader
            title={params.colDef.headerName}
            text={t('The date the company was added as a customer.')}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: `${t('CPA Status')}`,
      type: 'number',
      width: 110,
      // editable: true,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true,
      renderCell: (params) => <StatusChip params={params} />,
      renderHeader: (params) => {
        return (
          <ColHeader
            title={params.colDef.headerName}
            text={t(
              'Reflects where each company is in the assessment process.'
            )}
          />
        );
      },
    },
    {
      field: 'median_supplier_climate_score',
      headerName: `${t('Median Climate Score')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      align: 'right',
      disableColumnMenu: true,
      width: 200,
      renderHeader: (params) => {
        return (
          <ColHeader
            title={params.colDef.headerName}
            text={t(
              'The median Climate Score of all monitored suppliers for a given customer.'
            )}
          />
        );
      },
    },
  ];

  const rows = customers.map((customer, index) => {
    return {
      id: index,
      logo: customer.logo,
      customerName: customer.name,
      customerSlug: customer.slug,
      hasExportableCRC: customer.has_exportable_crc,
      dateAdded: dateReadable(`${customer.customer_since}`),
      status: {
        status: customer.survey?.status,
        expires_soon: customer.survey?.expires_soon,
      },
      median_supplier_climate_score: customer.monitored_suppliers_median
        ? getClimateScoreAsPercent(Number(customer.monitored_suppliers_median))
        : null,
      visibility: customer.published,
    };
  });

  return (
    <Box>
      {customersCount !== null && customersCount !== 0 ? (
        <div style={{ height: `${height}px` }}>
          <StyledDataGrid
            sx={{
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            rows={rows}
            columns={columns}
            pageSizeOptions={[rowsPerPage]}
            isRowSelectable={(params) => {
              return params.row.hasExportableCRC;
            }}
            disableRowSelectionOnClick
            style={{ borderStyle: 'none' }}
          />
        </div>
      ) : (
        <Typography variant="subtitle1" color={colors.neutral600}>
          {t('0 results found')}
        </Typography>
      )}
    </Box>
  );
}

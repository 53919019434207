import { decorate, observable, action } from 'mobx';

import i18n from 'i18n';
import axios from 'axios';
import api from 'constants/api';
import { required } from 'state/FormStore/fieldValidators';

import FormStore from 'state/FormStore';

const defaultText = `Sehr geehrte Damen und Herren,

wir sind an eine Zusammenarbeit mit Ihnen interessiert. Wir würden uns freuen, wenn Sie uns die angehängten Dokumente ausfüllen und zeitnah zukommen lassen könnten.

Mit freundlichen Grüßen,
`;

class ROIStore {
  constructor() {
    this.form = new FormStore({
      defaults: {
        subject: '',
        body: defaultText,
        attachments: [],
      },
      fieldValidators: {
        subject: required,
        body: required,
      },
    });
  }

  // OBSERVABLES................................................................
  hasInitialized = false;
  isSubmitting = false;
  submitted = false;

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  init = async ({ auth, identifier }) => {
    if (!this.form.values.has('receiver_company')) {
      this.form.setValue('receiver_company', identifier);
    }
    try {
      // await auth.loadUserData();
      this.initSuccess(auth);
    } catch (err) {
      this.initFailure(err);
    }
  };

  initSuccess = (auth) => {
    this.form.setValue(
      'body',
      this.form.values.get('body') +
        `${auth.userDataForm.values.get(
          'first_name'
        )} ${auth.userDataForm.values.get('last_name')}`
    );
    this.hasInitialized = true;
  };

  initFailure = (err) => {
    console.log(err);
    this.hasInitialized = true;
  };

  reset = () => {
    this.form.resetValues();
    this.hasInitialized = false;
  };

  onSubmit = ({ auth, snackbarMessage, onClose }) => {
    this.submit({ auth, snackbarMessage, onClose });
  };

  submit = async ({ auth, snackbarMessage, onClose = () => false }) => {
    this.isSubmitting = true;
    const { subject, body, receiver_company, attachments } =
      this.form.deserialize();

    try {
      const formData = new FormData();
      formData.set('subject', subject);
      formData.set('body', body);
      formData.set('receiver_company', receiver_company);
      for (let attachment of attachments) {
        formData.append('attachments', attachment);
      }
      const res = await axios.post(
        `${api.url}/emails/contact_tcc/`,
        formData,
        auth.requestConfig
      );
      if (res && res.data) {
        snackbarMessage.addMessage({
          message: i18n.t('common:::Request sent.'),
        });
        onClose();
        this.submitSuccess();
      } else {
        this.submitFailure(res, snackbarMessage);
      }
    } catch (err) {
      this.submitFailure(err, snackbarMessage);
    }
  };

  submitSuccess = () => {
    this.isSubmitting = false;
    this.submitted = true;
    this.form.resetValues();
  };

  submitFailure = (err, snackbarMessage) => {
    this.isSubmitting = false;
    console.log(err);
    if (err.response && err.response.data) {
      const message = i18n.t('There was a problem');
      snackbarMessage.addMessage({
        message,
      });
    }
  };
}

decorate(ROIStore, {
  hasInitialized: observable,
  isSubmitting: observable,
  submitted: observable,
  init: action,
  reset: action,
  initSuccess: action,
  initFailure: action,
  onSubmit: action,
  submit: action,
  submitSuccess: action,
  submitFailure: action,
});

export default ROIStore;

import React from 'react';
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';
import { useTranslation } from 'react-i18next';
import EmptyTextField from 'components/common/EmptyTextField';
import { getLanguagePreference } from 'utils/browser';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import Link from 'components/common/Link';
import Button from 'components/common/Button';
import Tooltip from 'components/common/Tooltip';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';
import { dateTimeReadable } from 'utils/date';
import { TextField } from '@mui/material';
import useAssignConfirmationDialog from 'hooks/useAssignConfirmationDialog';
import Box from 'components/common/Box';
import Dialog from 'components/common/Dialog';
import CompanyLLMOutputsDoubleCheck from './CompanyLLMOutputsDoubleCheck';
import Chip from 'components/common/Chip';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from 'components/common/IconButton';

export default function CompanyLLMOutputsTable({
  data,
  refetchLLMOutputs,
}: {
  data: any;
  refetchLLMOutputs: () => void;
}) {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const [rows, setRows] = React.useState(data);
  const { confirm, DialogComponent } = useAssignConfirmationDialog();
  const [chunk, setChunk] = React.useState('');
  const [editingRowId, setEditingRowId] = React.useState<string | null>(null);
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && event.shiftKey) {
      // Prevent the default behavior of the DataGrid exiting edit mode
      event.stopPropagation();
    }
  };

  React.useEffect(() => {
    if (data) {
      const updatedData = data.map((element: any) => ({
        ...element,
        output: element.output.replace(/(\[.*?\])\s*/g, '$1\n'),
      }));
      setRows(updatedData);
    }
  }, [data]);

  const handleCloseSnackbar = () => setSnackbar(null);

  const updateRow = async (
    id: string,
    data: any,
    successMessage: string,
    errorMessage: string
  ) => {
    try {
      const res = await axios.patch(
        `${api.url}/etl/llm-outputs/${id}/`,
        data,
        auth.requestConfig
      );
      const updatedRow = { ...res.data };
      setRows(rows.map((row: any) => (row.id === id ? updatedRow : row)));
      setSnackbar({ children: successMessage, severity: 'success' });
      return updatedRow;
    } catch (error) {
      console.error(errorMessage, error);
      const er = (error as any).response.data.errors || 'Error';
      setSnackbar({ children: er, severity: 'error' });
    }
  };

  const processRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    const confirmed = await confirm(
      'Confirm Save',
      ' Do you want to save your changes?(response cannot be an empty string)'
    );
    if (confirmed) {
      const { id, output } = newRow;
      if (!id) {
        console.error('Row missing ID:', newRow);
        return;
      }
      return updateRow(
        id,
        { output },
        'Row updated successfully',
        'Error updating row:'
      );
    } else {
      const { id, output } = oldRow;
      if (!id) {
        console.error('Row missing ID:', oldRow);
        return;
      }
      return updateRow(
        id,
        { output },
        'Row data remained the same',
        'Error updating row:'
      );
    }
  };

  const handleVerifyClick = async (newRow: GridRowModel) => {
    const { id } = newRow;
    if (!id) {
      console.error('Verify missing ID:', newRow);
      return;
    }
    return updateRow(
      id,
      { is_checked: true },
      'Data been ingested',
      'Error updating verify:'
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'prompt_key',
      headerName: t('Prompt key'),
      width: 200,
      sortable: true,
      renderCell: (params) => (
        <Typography variant="body1" component="div" sx={{ cursor: 'help' }}>
          <Tooltip title={params.row.prompt_text}>
            <div>{params.row.prompt_key}</div>
          </Tooltip>
          {params.row.sources[0].page_number === null &&
          (params.row.sources[0].s3asset.file_name.includes('jpeg') ||
            params.row.sources[0].s3asset.file_name.includes('png')) ? (
            <Chip label="double checked" />
          ) : null}
        </Typography>
      ),
    },
    {
      field: 'output',
      headerName: t('LLM output'),
      flex: 4,
      sortable: false,
      editable: true,
      renderEditCell: (params) => (
        <TextField
          variant="standard"
          onChange={(e) =>
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: e.target.value,
            })
          }
          value={params.value}
          multiline
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            style: {
              minWidth: '400px',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            },
          }}
          onKeyDown={handleKeyDown}
        />
      ),
    },
    {
      field: 'sources',
      headerName: t('Sources'),
      flex: 3,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack spacing={1}>
            {params.row.sources.map((source: any, index: number) => {
              return (
                <Box key={index}>
                  <Stack spacing={1} direction="row" alignItems="center">
                    {source.s3asset.file_name.includes('jpeg') ||
                    params.row.sources[0].s3asset.file_name.includes(
                      'png'
                    ) ? null : (
                      <IconButton
                        size="smart"
                        onClick={() => setChunk(source.text_chunk)}
                      >
                        <ManageSearchIcon fontSize="small" />
                      </IconButton>
                    )}
                    {source.page_number ? (
                      <Typography variant={'caption2'} noWrap>
                        pg:{source.page_number}
                      </Typography>
                    ) : null}

                    <Link
                      href={source.s3asset.file_url}
                      target="_blank"
                      rel="noopener"
                      underline="none"
                      sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        overflowWrap: 'anywhere',
                      }}
                    >
                      {source.s3asset.file_name.split('/')[2]}
                    </Link>
                  </Stack>

                  <CompanyLLMOutputsDoubleCheck
                    llmSourceId={source.id}
                    pg={source.page_number}
                    refetchLLMOutputs={refetchLLMOutputs}
                  />
                </Box>
              );
            })}
          </Stack>
        );
      },
    },
    {
      field: 'is_checked',
      headerName: t('Ingestion'),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.is_checked ? (
          <Typography variant={'caption2'}>{`user: ${
            params.row.checked_by
          }, timestamp: ${dateTimeReadable(
            params.row.checked_on
          )}`}</Typography>
        ) : params.row.sources[0].s3asset.file_name.includes('jpeg') ||
          params.row.sources[0].s3asset.file_name.includes('png') ? null : (
          <Button
            disabled={editingRowId === params.id}
            variant="contained"
            onClick={() => handleVerifyClick(params.row)}
          >
            {t('ingest')}
          </Button>
        );
      },
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('Data unavailable'),
  };

  return !data ? (
    <EmptyTextField text={t('Data unavailable') + ''} />
  ) : (
    <div style={{ width: '100%' }}>
      <Button
        variant="contained"
        onClick={() => refetchLLMOutputs()}
        sx={{ marginBottom: 2 }}
      >
        Reload
      </Button>
      <DataGrid
        hideFooterPagination
        getRowHeight={() => 'auto'}
        autoHeight
        localeText={localeText}
        sx={{
          '& .MuiDataGrid-overlayWrapper': {
            minHeight: '103px',
          },
          '& .MuiDataGrid-cell': {
            alignItems: 'flex-start',
            pt: 1,
            pb: 1,
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          },
          width: '100%',
        }}
        rows={rows}
        columns={columns}
        hideFooter={rows.length <= 10}
        onCellEditStart={(params) => {
          setEditingRowId(params.id as string);
        }}
        onCellEditStop={() => setEditingRowId(null)}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => console.log('err', error)}
        isCellEditable={(params) => params.row.is_checked === false}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={10000} //10sec
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      {DialogComponent}
      {chunk ? (
        <Dialog open onClose={() => setChunk('')}>
          <Box p={3}>{chunk}</Box>
        </Dialog>
      ) : null}
    </div>
  );
}

import React from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import TccInputTextField from 'components/common/TccInputTextField/TccInputTextField';
import { CloseIcon } from 'components/icons/mui_icons';
import IconButton from '@mui/material/IconButton';

let debounceId: ReturnType<typeof setTimeout>;
const DEBOUNCE_INTERVAL = 300; // ms

const CommonTextSearchFilter = ({
  title,
  searchParam,
}: {
  title: string;
  searchParam: string;
}) => {
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  const [pText, setPText] = React.useState(searchParams.get(searchParam) || '');

  React.useEffect(() => {
    const param = searchParams.get(searchParam);
    if (param) {
      setPText(param);
    }
  }, [setPText, searchParams, searchParam]);

  const filter = (p: string) => {
    setPText(p);
    clearTimeout(debounceId);
    debounceId = setTimeout(
      () =>
        navigateSearch({
          pathname: location.pathname,
          params: p ? { [searchParam]: p } : {},
          removeParams: p ? [] : [searchParam],
          replace: false,
        }),
      DEBOUNCE_INTERVAL
    );
  };

  return (
    <TccInputTextField
      value={pText}
      title={title}
      onChange={(e) => filter(e.target.value)}
      endAdornment={
        pText ? (
          <IconButton size="small" onClick={() => filter('')}>
            <CloseIcon />
          </IconButton>
        ) : null
      }
    />
  );
};

export default CommonTextSearchFilter;

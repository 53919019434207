import React from 'react';
import { observer } from 'mobx-react-lite';
import Button from 'components/common/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import useSnackbarMessage from 'hooks/useSnackbarMessage';

const SnackbarMessages = () => {
  const snackbarMessage = useSnackbarMessage();
  const { activeMessage, removeActiveMessage, runActiveMessageAction } =
    snackbarMessage;

  const onClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      // do nothing
      return;
    } else {
      removeActiveMessage();
    }
  };

  return (
    <Snackbar
      key={activeMessage.key}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={activeMessage.visible}
      autoHideDuration={7000}
      onClose={onClose}
      TransitionProps={{ onExited: removeActiveMessage }}
      message={activeMessage.message}
      action={
        <React.Fragment>
          {activeMessage.action && (
            <Button
              color="secondary"
              size="small"
              onClick={runActiveMessageAction}
            >
              {activeMessage.actionMessage}
            </Button>
          )}
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={removeActiveMessage}
          >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default observer(SnackbarMessages);

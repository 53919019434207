// import { decorate, observable, action, runInAction } from 'mobx';
// import api from 'constants/api';
// import axios from 'axios';

import mockedSuppliersData from '../dashboard_graphs_for_demo_mocked_data.json';
// import { mockedSuppliersDataTypes } from '../demo_mocked_data_types';

class DemoMockedData {
  // OBSERVABLES................................................................
  data = mockedSuppliersData;

  // COMPUTEDS..................................................................
  // ACTIONS....................................................................

  suppliersCounts = this.data.length;
  companiesWithData = mockedSuppliersData.filter((el) => {
    return el.scope1 || el.scope2 || el.scope3;
  });

  countries = this.companiesWithData.map((el) => el.country);

  uniqueCountries = new Set(this.countries);

  suppliersWithScopeEmissionsData = (scope: string) => {
    return this.data.filter((element: any) => element[`${scope}`] !== '')
      .length;
  };

  suppliersWithoutScopeEmissionsData = (scope: string) => {
    return this.suppliersCounts - this.suppliersWithScopeEmissionsData(scope);
  };

  targetCoverage = (scope: any) => {
    const scope_ambition = `${scope}_ambition`;
    let suppliersWithScopeOneTargetCoverage: any = [];
    this.data.filter((element: any) =>
      suppliersWithScopeOneTargetCoverage.push(element[scope_ambition])
    );

    const uniqueResultsCountObj: any = {};

    for (const element of suppliersWithScopeOneTargetCoverage) {
      if (uniqueResultsCountObj[element]) {
        uniqueResultsCountObj[element] += 1;
      } else {
        uniqueResultsCountObj[element] = 1;
      }
    }
    uniqueResultsCountObj['Unsure'] = uniqueResultsCountObj[''];
    delete uniqueResultsCountObj[''];

    return uniqueResultsCountObj;
  };

  addCategoryEmissionsKilotonne = (category: any) => {
    let countEmissions = 0;
    this.data.forEach((element: any) => {
      countEmissions += Number(element[category]);
    });
    return Number(countEmissions / 1000).toFixed(2);
  };

  scopePerYearCount = (scope: any) => {
    const companiesYear = this.data.map((el: any) => el[scope]);

    const uniqueYears = new Set(companiesYear.sort());

    if (uniqueYears.has('')) {
      uniqueYears.delete('');
    }
    const uniqueCount = {} as any;

    for (const element of companiesYear) {
      if (uniqueCount[element]) {
        uniqueCount[element] += 1;
      } else {
        uniqueCount[element] = 1;
      }
    }
    let yearReportArray = [] as any;
    uniqueYears.forEach((uniYear) => {
      yearReportArray.push({ x: uniYear, y: uniqueCount[`${uniYear}`] });
    });

    return yearReportArray;
  };

  emissionsCountPerCountry = (scope: any) => {
    if (this.uniqueCountries.has('')) {
      this.uniqueCountries.delete('');
    }
    let totalCountriesArrScope: any = [];
    this.uniqueCountries.forEach((uniCountry) => {
      let countEmissions = 0;
      this.data.forEach((element: any) => {
        if (element.country === uniCountry) {
          countEmissions += Number(element[`${scope}`]);
        }
      });
      totalCountriesArrScope.push({
        x: uniCountry,
        y: Number(countEmissions / 1000).toFixed(2),
      });
    });
    return totalCountriesArrScope;
  };

  carbonIntensityPerCompany = (scope: any) => {
    let companiesScope1_2: any = [];
    this.data.forEach((company: any) => {
      companiesScope1_2.push({
        x: company.company_name,
        y: Number(company[scope]).toFixed(2),
      });
    });
    function compareScope(a: any, b: any) {
      return b.y - a.y;
    }
    return companiesScope1_2.sort(compareScope);
  };

  scope3TargetStatus = () => {
    const scope_ambition = `scope3_target_status`;
    let suppliersWithScopeOneTargetCoverage: any = [];
    this.data.filter((element: any) =>
      suppliersWithScopeOneTargetCoverage.push(element[scope_ambition])
    );

    const uniqueResultsCountObj: any = {};

    for (const element of suppliersWithScopeOneTargetCoverage) {
      if (uniqueResultsCountObj[element]) {
        uniqueResultsCountObj[element] += 1;
      } else {
        uniqueResultsCountObj[element] = 1;
      }
    }
    uniqueResultsCountObj['Unsure'] = uniqueResultsCountObj[''];
    delete uniqueResultsCountObj[''];

    return uniqueResultsCountObj;
  };
}

// decorate(DemoMockedData, {
//   hasLoadedData: observable,
// });

export default DemoMockedData;

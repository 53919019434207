import { useTranslation } from 'react-i18next';
import { GridToolbarContainer } from '@mui/x-data-grid';
import Button from 'components/common/Button';
import JoinFullIcon from '@mui/icons-material/JoinFull';

import useStore from 'components/App/Routes/Admin/Screening/store/useStore';
import { observer } from 'mobx-react-lite';

const CompanyShortlistToolbar = () => {
  const { t } = useTranslation('common');
  const { setConsolidateCompanyId, shortlistTCCCompany, reportingCompanyMode } =
    useStore();

  return (
    <GridToolbarContainer>
      <Button
        variant="contained"
        color={'primary'}
        startIcon={<JoinFullIcon />}
        onClick={() => {
          setConsolidateCompanyId(shortlistTCCCompany?.id || 'NEW');
        }}
      >
        {`${t('Consolidate')} (${
          reportingCompanyMode
            ? t('Identify Reporting Company')
            : t('Identify Company')
        })`}
      </Button>
    </GridToolbarContainer>
  );
};

export default observer(CompanyShortlistToolbar);

import React from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';
import { scrollToTop } from 'utils/browser';

import Pagination from '@mui/material/Pagination';
import Box from 'components/common/Box';

type CustomPaginationProps = {
  suppliersCount: number | null;
  clearSelection: () => void;
  rowsPerPage: number;
};

function CustomPagination({
  suppliersCount,
  clearSelection,
  rowsPerPage,
}: CustomPaginationProps) {
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  const onPageChange = React.useCallback(
    (e: any, page: number) => {
      navigateSearch({
        pathname: location.pathname,
        params: {
          page: page,
        },
        replace: false,
      });
      scrollToTop();
    },
    [location.pathname, navigateSearch]
  );
  return (
    <Box>
      {suppliersCount ? (
        <Pagination
          color="primary"
          shape="rounded"
          count={
            suppliersCount < rowsPerPage
              ? 1
              : suppliersCount % rowsPerPage
              ? Math.floor(suppliersCount / rowsPerPage) + 1
              : Math.floor(suppliersCount / rowsPerPage)
          }
          page={parseInt(searchParams.get('page') as string) || 1}
          onChange={(event, value) => {
            event.preventDefault();
            clearSelection();
            onPageChange(event, value);
          }}
          sx={{ marginLeft: '0px' }}
        />
      ) : null}
    </Box>
  );
}

export default CustomPagination;

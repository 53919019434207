import { Alert, DialogContent, IconButton, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from 'components/common/Dialog/Dialog';
import DialogTitle from 'components/common/DialogTitle';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from 'components/common/Typography';
import { useQueryClient } from '@tanstack/react-query';
import Loading from 'components/common/Loading';
import CompanyTargetsTable from './CompanyTargetsTable';
import CompanyDataPointsTable from './CompanyDataPointsTable';
import { useEffect, useState } from 'react';
import Tabs from 'components/common/Tabs';
import Tab from 'components/common/Tab';
import Box from 'components/common/Box';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import useAdminJob, {
  useAdminJobDelete,
  useAdminJobPatch,
} from 'hooks/useAdminJob';
import Stack from 'components/common/Stack';
import Container from 'components/common/Container';
import colors from 'constants/colors';
import useAdminCompanyLLMOutputs from 'hooks/useAdminCompanyLLMOutputs';
import useAdminCompanyS3Assets from 'hooks/useAdminCompanyS3Assets';
import CompanyS3AssetsTable from './CompanyS3AssetsTable';
import CompanyLLMOutputsTable from './CompanyLLMOutputsTable';
import CompanySelect from 'components/common/CompanySelect';
import Button from 'components/common/Button';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { setPageTitle } from 'utils/tools';
import useAdminCompany from 'hooks/useAdminCompany';
import Card from 'components/common/Card';

const NoTransition = React.forwardRef(function NoTransition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <>{props.children}</>;
});

const Component = () => {
  const { t } = useTranslation('common');
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('TARGETS');
  const queryClient = useQueryClient();
  const [companyAssigned, setCompanyAssigned] = useState('');

  const { data: job, isLoading: isLoadingJob, refetch } = useAdminJob(jobId);
  const { data: company } = useAdminCompany(job?.company);

  const { mutate, isLoading, isError, error } = useAdminJobPatch(jobId);
  const {
    mutate: deleteJob,
    isLoading: isloadingDelete,
    isError: isErrorDelete,
    error: errorDelete,
    isSuccess,
  } = useAdminJobDelete(jobId);
  useEffect(() => {
    if (job && job.company) {
      setCompanyAssigned(job.company);
    }
  }, [job, setCompanyAssigned]);
  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess, navigate]);

  const { data: s3Assets, isLoading: isLoadingS3Assets } =
    useAdminCompanyS3Assets({
      jobId: jobId,
    });

  const {
    data: LLMOutputs,
    isLoading: isLoadingLLMOutputs,
    refetch: refetchLLMOutputs,
  } = useAdminCompanyLLMOutputs({
    jobId: jobId,
  });

  useEffect(() => {
    if (company?.name) {
      setPageTitle(company.name);
    }
    return () => setPageTitle();
  }, [company]);

  return jobId ? (
    <Dialog
      fullScreen
      open={!!jobId}
      onClose={() => false}
      TransitionComponent={NoTransition}
    >
      <DialogTitle variant="h4">
        <Toolbar disableGutters>
          <Container maxWidth="xl" sx={{ display: 'flex' }}>
            {t('Job')}
            <div style={{ flex: 1 }} />
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate(-1)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Container>
        </Toolbar>
      </DialogTitle>
      <DialogContent sx={{ minHeight: 400 }}>
        {isLoadingJob || isLoadingS3Assets || isLoadingLLMOutputs ? (
          <Loading />
        ) : (
          <Container maxWidth="xl">
            {job.company ? (
              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="text"
                    onClick={() => navigate(`/admin/company/${job.company}`)}
                  >
                    {t('View company')}
                  </Button>
                  <Button
                    startIcon={<DeleteOutlined />}
                    variant="contained"
                    color="error"
                    disabled={isloadingDelete}
                    onClick={() => {
                      deleteJob();
                    }}
                  >
                    {t('Remove job')}
                  </Button>
                </Stack>
                {isErrorDelete ? (
                  <Alert severity="error">
                    {(errorDelete as any)?.response?.data?.detail}
                  </Alert>
                ) : null}
              </Stack>
            ) : null}

            <Stack
              pt={3}
              pb={3}
              direction={'row'}
              spacing={2}
              alignItems={'center'}
            >
              <Box width={'300px'}>
                <CompanySelect
                  title={t('Company')}
                  value={companyAssigned}
                  onChange={(id) => setCompanyAssigned(id)}
                />
              </Box>
              {companyAssigned !== job?.company ? (
                <Stack spacing={2}>
                  <Stack spacing={2} direction={'row'} pt={3.5}>
                    <Box>
                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={() => {
                          mutate(
                            { company: companyAssigned },
                            {
                              onSuccess: () => {
                                queryClient.invalidateQueries([
                                  'admin-job',
                                  jobId,
                                ]); // Ensure the query is invalidated
                                refetch(); // Manually trigger refetch
                              },
                            }
                          );
                        }}
                      >
                        {t('Update company')}
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant="outlined"
                        onClick={() => setCompanyAssigned(job?.companyId)}
                      >
                        {t('Cancel')}
                      </Button>
                    </Box>
                  </Stack>
                  {isError ? (
                    <Alert severity="error">
                      {(error as any)?.response?.data?.detail}
                    </Alert>
                  ) : null}
                </Stack>
              ) : null}
            </Stack>

            <Stack spacing={3} direction={'row'} pb={3}>
              <Stack spacing={1.3} textAlign={'right'}>
                <Typography variant="caption">type</Typography>
                <Typography variant="caption">id</Typography>
                <Typography variant="caption">created</Typography>
                <Typography variant="caption">status</Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body1">{job.type}</Typography>
                <Typography variant="body1">{job.id}</Typography>
                <Typography variant="body1">{job.created}</Typography>
                <Typography variant="body1">{job.status}</Typography>
              </Stack>
            </Stack>

            {job.errors && job.errors.length > 0 ? (
              <Card
                borderColor={colors.tertiary100}
                backgroundColor={colors.white}
                sx={{ mb: 5 }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: '24px', color: colors.tertiary500 }}
                >
                  {'Errors'}
                </Typography>
                <Box pb={3} overflow={'scroll'}>
                  {job.errors.map((error: any, i: number) => (
                    <pre key={i}>
                      <code>{JSON.stringify(error, null, 2)}</code>
                    </pre>
                  ))}
                </Box>
              </Card>
            ) : null}

            <Typography
              variant="body1"
              sx={{ fontSize: '24px', color: colors.neutral400 }}
            >
              {t('Input')}
            </Typography>
            <Box pb={3} overflow={'scroll'}>
              {job.inputs && job.inputs.length > 0 ? (
                job.inputs.map((input: any, i: number) => (
                  <pre key={i}>
                    <code>{JSON.stringify(input.message, null, 2)}</code>
                  </pre>
                ))
              ) : (
                <Typography variant="body1" color="text.secondary">
                  {t('None.')}
                </Typography>
              )}
            </Box>

            <Typography
              variant="body1"
              sx={{ fontSize: '24px', color: colors.neutral400 }}
            >
              {t('Output')}
            </Typography>
            {job.results && job.results.length > 0
              ? job.results.map((result: any, i: number) => (
                  <pre key={i}>
                    <code>{JSON.stringify(result.message, null, 2)}</code>
                  </pre>
                ))
              : null}
            <Tabs
              sx={{ pb: 2 }}
              value={activeTab}
              onChange={(_, value) => setActiveTab(value)}
            >
              <Tab label={t('Targets')} value="TARGETS" />
              <Tab label={t('Data Points')} value="DATA_POINTS" />
              <Tab label={t('Assets')} value="S3ASSETS" />
              <Tab label={t('LLM Outputs')} value="LLMOUTPUTS" />
            </Tabs>
            {activeTab === 'TARGETS' ? (
              <CompanyTargetsTable jobId={jobId} />
            ) : null}
            {activeTab === 'DATA_POINTS' ? (
              <CompanyDataPointsTable jobId={jobId} />
            ) : null}
            {activeTab === 'S3ASSETS' ? (
              <CompanyS3AssetsTable data={s3Assets?.results || []} />
            ) : null}
            {activeTab === 'LLMOUTPUTS' ? (
              <CompanyLLMOutputsTable
                data={LLMOutputs || []}
                refetchLLMOutputs={refetchLLMOutputs}
              />
            ) : null}
            <Box pb={5} />
          </Container>
        )}
      </DialogContent>
    </Dialog>
  ) : null;
};

export default Component;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const EmptyContentPlaceholder = ({ text, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: 'transparent',
        padding: (theme) => theme.spacing(2),
        border: (theme) => `1px dashed ${theme.palette.divider}`,
      }}
      {...rest}
    >
      <Typography sx={{ textAlign: 'center' }} variant="body2">
        {text || t('Empty')}
      </Typography>
    </Paper>
  );
};

export default EmptyContentPlaceholder;

import { observer } from 'mobx-react-lite';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import tccPalette from './tccPalette';
import tccPaletteDark from './tccPaletteDark';
import tccTypography from './tccTypography';
import tccMuiButton from './tccMuiButton';
import tccMuiFab from './tccMuiFab';
import tccMuiDrawer from './tccMuiDrawer';
import tccMuiChip from './tccMuiChip';

import usePreferences from 'hooks/usePreferences';

const getTheme = ({ darkMode }: { darkMode: boolean }) =>
  createTheme({
    shape: {
      borderRadius: 8,
    },
    typography: { ...tccTypography },
    palette: { ...(darkMode ? tccPaletteDark : tccPalette) },
    components: {
      MuiFab: { ...tccMuiFab },
      MuiButton: { ...tccMuiButton },
      MuiDrawer: { ...tccMuiDrawer },
      MuiChip: { ...tccMuiChip },
    },
  });

const TccThemeProvider = ({ children }: any) => {
  const preferences = usePreferences();
  const theme = getTheme({ darkMode: preferences.darkMode });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default observer(TccThemeProvider);

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    hover: PaletteColor;
    neutral: {
      800: PaletteColor;
      700: PaletteColor;
      600: PaletteColor;
      500: PaletteColor;
      400: PaletteColor;
      200: PaletteColor;
      100: PaletteColor;
    };
    onSurface: {
      100: PaletteColor;
    };
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    caption2: React.CSSProperties;
    action_small: React.CSSProperties;
    action_medium: React.CSSProperties;
  }
  interface ButtonVariants {
    neutral: React.CSSProperties['color'];
    onSurface: React.CSSProperties['color'];
  }

  interface TypographyVariantsOptions {
    caption2?: React.CSSProperties;
    action_small?: React.CSSProperties;
    action_medium?: React.CSSProperties;
  }
  interface ButtonVariantsOptions {
    neutral?: React.CSSProperties['color'];
    onSurface?: React.CSSProperties['color'];
  }
}
declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    caption2: true;
    action_small: true;
    action_medium: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    whiteBackground: true;
    formBackButton: true;
  }
  interface ButtonPropsColorOverrides {
    neutral: true;
    onSurface: true;
  }
}

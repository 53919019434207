import React from 'react';
// import SvgIcon from '@mui/material/SvgIcon';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOn';

// can swap later
const Hamburger = (props: any) => (
  // <SvgIcon {...props}>
  //
  // </SvgIcon>
  <LocationOnOutlinedIcon {...props} />
);

export default Hamburger;

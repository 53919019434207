import React, { useState, useEffect } from 'react';
import useNavigateSearch from 'hooks/useNavigateSearchParams';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { getLanguagePreference } from 'utils/browser';

import Grid from 'components/common/Grid';
import Box from 'components/common/Box';
import Link from 'components/common/Link';
import Typography from 'components/common/Typography';

import * as svgsEnglish from 'components/icons/sdg/sdgCompsEnglish/index';
import SDGsEmptyState from './SDGsEmptyState/SDGsEmptyState';
import * as svgsGerman from 'components/icons/sdg/sdgCompsGerman/index';

const dataDefault = {
  property1: '',
  property2: '',
};

const sdgStyle = {
  width: 80,
  marginRight: 10,
  marginBottom: 10,
  pointerEvents: 'none',
};

const isDataValid = (filterObj: any) => {
  if (
    filterObj &&
    filterObj !== undefined &&
    Object.entries(filterObj).length !== 0 &&
    !Object.values(filterObj).includes(undefined) &&
    !Object.values(filterObj).includes('')
  ) {
    return true;
  } else {
    return false;
  }
};

const convertObjKeysToArray = (dataObject: any) => {
  return Object.keys(dataObject).map((item) => item.toLowerCase());
};

const CompanySdgs = ({ companySdgs = dataDefault }) => {
  const { t } = useTranslation('common');
  const navigateSearch = useNavigateSearch();
  const browserLang = getLanguagePreference({ twoLetter: true });
  const [iconLang, setIconLang] = useState(svgsEnglish);

  useEffect(() => {
    if (browserLang === 'en') {
      setIconLang(svgsEnglish);
    } else if (browserLang === 'de') {
      setIconLang(svgsGerman);
    }
  }, [browserLang]);

  const companySdgsKeysArray = convertObjKeysToArray(companySdgs);

  const handleCompanySdgsChange = (e: any) => {
    navigateSearch({
      pathname: `/companies/search`,
      params: { page: 1, sdgs: e.target.value },
    });
  };

  const sortSdgsIcons = React.useCallback((module: any) => {
    let sdgsMap = new Map();
    const moduleEntries = Object.entries(module);

    for (let i = 0; i < moduleEntries.length; i++) {
      const pairedArray = moduleEntries[i];
      const sdgIconNum = parseInt(moduleEntries[i][0].split('g')[1]);
      sdgsMap.set(pairedArray, sdgIconNum);
    }

    return Array.from(sdgsMap.entries()).sort((a, b) => a[1] - b[1]);
  }, []);

  const sortedSdgs = sortSdgsIcons(iconLang);

  return (
    <Grid item xs>
      <Typography variant="h5" color="onSurface.100">
        {t('Sustainable Development Goals (SDGs)')}
      </Typography>
      {isDataValid(companySdgs) ? (
        <Grid item container mt={'30px'}>
          {sortedSdgs.map((arrayObj, key) => {
            const sdg = arrayObj[0][0].toLowerCase();
            const Component = arrayObj[0][1]; // is a custome svg comp not mui
            return (
              <div key={key}>
                {sdg === 'sdglogo' ? (
                  <Component style={sdgStyle} />
                ) : (
                  <Box
                    key={key}
                    sx={{
                      '&:hover': {
                        filter: 'contrast(76%)',
                      },
                    }}
                  >
                    <Link
                      key={key}
                      component="button"
                      variant="body2"
                      value={arrayObj[0][0].toUpperCase()}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleCompanySdgsChange(e);
                      }}
                    >
                      {companySdgsKeysArray.includes(sdg) ? (
                        <Component style={sdgStyle} sdgEnabled={true} />
                      ) : (
                        <Component style={sdgStyle} sdgEnabled={false} />
                      )}
                    </Link>
                  </Box>
                )}
              </div>
            );
          })}
        </Grid>
      ) : (
        <SDGsEmptyState />
      )}
    </Grid>
  );
};

export default observer(CompanySdgs);

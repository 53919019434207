import AppBar, { AppBarProps } from '@mui/material/AppBar';

// interface ExtendedAppBarProps extends AppBarProps {
//   other?: boolean;
// }

function Component(props: AppBarProps) {
  return <AppBar elevation={0} sx={{ backgroundColor: 'white' }} {...props} />;
}

export default Component;

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import useWizardStore from 'components/App/Routes/CrcWizard/Wizard/store/WizardStore';

import Grid from 'components/common/Grid';
import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import CardMedia from 'components/common/CardMedia';
import Button from 'components/common/Button/Button';
import { imageSrcMap } from 'media/crc_start_icon/index';
import Container from 'components/common/Container';

import LinearStepper from 'components/common/LinearStepper';

const CrcStartPage = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const { createNewCrc, setShowCrcQuestions, crcActive, initWizard } =
    useWizardStore();

  return (
    <Container maxWidth="md" pageContainer>
      <LinearStepper
        steps={[t('Registration Sign up'), t('Climate Readiness Check')]}
        activeStep={1}
        maxWidth={400}
      />

      <Grid container justifyContent="center" alignItems="center" padding={4}>
        <Typography variant={'h4'} color={'black'}>
          {t(`You’re almost there`)}
        </Typography>
        <Box maxWidth={'sm'} mt={2} justifyContent={'center'}>
          <Typography
            variant={'body1'}
            color={'neutral.contrastText'}
            align={'center'}
            mb={4}
          >
            {t(
              'The Climate Readiness Check is a short set of questions that enable your organization to understand its climate maturity with respect to internationally recognised standards and objectives. Using your answers, we create a report that can provide you with an analysis of your strengths and opportunities for improvement, along with actionable recommendations for next steps.'
            )}
          </Typography>
          <Button
            fullWidth
            sx={{ height: '40px' }}
            variant="contained"
            type="button"
            size="medium"
            onClick={async () => {
              if (!crcActive) {
                await createNewCrc({ auth });
                initWizard({ auth: auth, id: 'latest_crc' });
              }
              setShowCrcQuestions();
            }}
          >
            {t('Start Climate Readiness Check')}
          </Button>

          <CardMedia
            image={imageSrcMap.crcStartIcon}
            component="img"
            sx={{
              // marginTop: '20px',
              objectFit: 'contain',
              height: '250px',
              width: '250px',
              m: 'auto',
              mt: 6,
              mb: 4,
            }}
          />
        </Box>
      </Grid>
    </Container>
  );
};

export default observer(CrcStartPage);

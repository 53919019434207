import { DialogContent, IconButton, Toolbar } from '@mui/material';
// import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
// import axios from 'axios';
// import api from 'constants/api';
// import useAuth from 'hooks/useAuth';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
// import { scrollToTop } from 'utils/browser';

import useStore from 'components/App/Routes/Admin/Search/store/useStore';
import { observer } from 'mobx-react-lite';
import Dialog from 'components/common/Dialog/Dialog';
import DialogTitle from 'components/common/DialogTitle';
import EditCompanyDetails from 'components/App/Routes/Admin/Company/EditCompanyDetails';

const Component = () => {
  const { t } = useTranslation('common');
  const snackbarMessage = useSnackbarMessage();
  // const auth = useAuth();
  const { editCompanyId, setEditCompanyId } = useStore();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={!!editCompanyId}
      onClose={() => setEditCompanyId('')}
    >
      <DialogTitle variant="h4">
        <Toolbar disableGutters>
          {t('Edit Company')}
          <div style={{ flex: 1 }} />
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setEditCompanyId('')}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent>
        <EditCompanyDetails
          id={editCompanyId}
          onSuccess={() => {
            setEditCompanyId('');
            snackbarMessage.addMessage({
              message: t('Success'),
            });
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default observer(Component);

import { decorate, action, observable } from 'mobx';

class Store {
  // OBSERVABLES................................................................
  source = '';
  target = '';

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  setSource = (sourceId: string) => {
    this.source = sourceId;
  };

  setTarget = (targetId: string) => {
    this.target = targetId;
  };
}

decorate(Store, {
  source: observable,
  target: observable,
  setSource: action,
  setTarget: action,
});

export default Store;

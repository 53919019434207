import React from 'react';
import { observer } from 'mobx-react-lite';

import Box from 'components/common/Box';

import CustomPagination from '../CustomPagination';

type CustomFooterProps = {
  slug: string | undefined;
  suppliersCount: number | null;
  onClick: () => void;
  rowsPerPage: number;
};

function CustomTableFooter({
  slug,
  suppliersCount,
  onClick,
  rowsPerPage,
}: CustomFooterProps) {
  return (
    <Box width={'100%'}>
      <CustomPagination
        suppliersCount={suppliersCount}
        clearSelection={onClick}
        rowsPerPage={rowsPerPage}
      />
    </Box>
  );
}

export default observer(CustomTableFooter);

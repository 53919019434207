import { observer } from 'mobx-react-lite';
import ReactApexChart from 'react-apexcharts';

import Stack from 'components/common/Stack/Stack';
import Grid from 'components/common/Grid/Grid';

function Component({ title, titleY, data, formater, groups, color }) {
  const config = {
    series: [
      {
        name: title,
        data: data,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        width: 2,
      },
      title: {
        text: title,
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        type: 'category',
        // categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          trim: true,
          minHeight: '500px',
        },
      },
      yaxis: {
        title: {
          text: titleY,
        },
        // labels: {
        //   formatter: function (y) {
        //     return y + formater;
        //   },
        // },
      },
    },
  };

  return (
    <Grid>
      <Stack>
        {/* <Stack direction={'row'} gap={16}>
          <Typography variant="h5">{title}</Typography>
        </Stack> */}
        <ReactApexChart
          options={config.options}
          series={config.series}
          width={'1000px'}
          type="line"
          // height={550}
        />
      </Stack>
    </Grid>
  );
}

export default observer(Component);

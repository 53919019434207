import { useTranslation } from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';

import colors from 'constants/colors';
import Typography from 'components/common/Typography';
import Box from 'components/common/Box';

import Avatar from 'components/common/Avatar';

import Stack from 'components/common/Stack/Stack';

import useUserCompany from 'hooks/useUserCompany';

import { getCountryName } from 'utils/getCountryName';
import Chip from 'components/common/Chip';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';
import Card from 'components/common/Card';
import ScoreRadialSmall from './ScoreRadialSmall';
import ClimateImpactModel from 'components/icons/crc/crc_icons/ClimateImpactModel';
import Tooltip from 'components/common/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const ModuleItem = ({ tooltip = '', title = '' }) => {
  return (
    <Box>
      <Box display={'flex'} alignItems={'flex-end'} overflow={'hidden'}>
        <Typography
          component={'span'}
          variant={'subtitle1'}
          sx={{
            color: colors.neutral400,
            width: '100%',
            lineHeight: 1.2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pr: tooltip ? 0 : 0.5,
          }}
        >
          {title}
        </Typography>

        {tooltip ? (
          <Tooltip
            title={tooltip}
            placement="right"
            arrow
            slotProps={{
              arrow: {
                sx: {
                  mt: 1,
                },
              },
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  color: colors.neutral600,
                  fontSize: '14px',
                  padding: '22px',
                  boxShadow:
                    '0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.1)',
                  borderRadius: '22px',
                  bgcolor: 'white',
                  '& .MuiTooltip-arrow': {
                    color: 'white',
                  },
                  marginBottom: '20px',
                },
              },
            }}
          >
            <InfoOutlined
              sx={{
                color: colors.neutral400,
                mb: 0.75,
                ml: 0.25,
              }}
              fontSize="inherit"
            />
          </Tooltip>
        ) : null}
      </Box>
    </Box>
  );
};

const Component = ({ score = 0 }) => {
  const { t } = useTranslation('common');

  const auth = useAuth();
  const { userCompany } = useUserCompany();

  return (
    <Card>
      <Stack spacing={2} onClick={() => false}>
        <Typography variant="h5">{t('What should you do next?')}</Typography>
        <Typography variant="body1">
          {t(
            "Understand what's next for your company in your climate journey and identify areas for improvement."
          )}
        </Typography>
        <Box />
        <Stack spacing={0.5}>
          <ModuleItem
            title={t('Emissions Calculation')}
            tooltip={t(
              'The Emissions Calculation category reflects the availability and quality of climate-related emissions data at a company and product-level.'
            )}
          />
          <ModuleItem
            title={t('Strategy & Control')}
            tooltip={t(
              'The Strategy & Control category reflects the strategic alignment and management oversight needed to steer a company’s Climate transition successfully.'
            )}
          />
          <ModuleItem
            title={t('Climate Targets')}
            tooltip={t(
              'The Climate Targets category reflects the presence and quality of specific climate targets, both long- and near-term. '
            )}
          />
          <ModuleItem
            title={t('Decarbonization Measures')}
            tooltip={t(
              'The Decarbonization Measures category reflects the operational actions in past, present and future timeframes that have an impact on emissions.'
            )}
          />
          <ModuleItem
            title={t('Emissions Performance')}
            tooltip={t(
              'The Emissions Performance category reflects the draw-down of emissions over time.'
            )}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default Component;

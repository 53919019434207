const MuiDrawer = {
  styleOverrides: {
    paper: {
      width: '280px',
      left: 'auto',
      right: 0,
      borderBottomLeftRadius: '8px',
    },
  },
} as any;

export default MuiDrawer;

import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';

import Logo from 'components/icons/Logo';
import AppBar from 'components/common/AppBar';
import Toolbar from 'components/common/Toolbar';
import Grid from 'components/common/Grid';
import Container from 'components/common/Container';

import useSignUp from 'hooks/useSignUp';
import Stack from 'components/common/Stack';
import LanguageSwitch from '../LanguageSwitch';

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  return (
    <AppBar position="sticky" elevation={0} sx={{ backgroundColor: 'white' }}>
      <Container disableGutters>
        <Toolbar disableGutters={false}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              onClick={() => {
                navigate('/');
              }}
              sx={{
                cursor: 'pointer',
              }}
            >
              <Logo sx={{ width: '90px', height: '60px' }} />
            </Box>

            <Box>
              <Stack direction={'row'} spacing={2}>
                <LanguageSwitch />
                <Button
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  {t('Login')}
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default observer(ResponsiveAppBar);

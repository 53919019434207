import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';

import { iconSrcMap } from 'media/organizationsInitiatives_logos/index';
import EmptyTextField from 'components/common/EmptyTextField';

import OrganizationsInitiativesCard from './OrganizationsInitiativesCard/OrganizationsInitiativesCard';

const isDataValid = (filterObj: any) => {
  if (
    filterObj &&
    filterObj !== undefined &&
    Object.entries(filterObj).length !== 0 &&
    !Object.values(filterObj).includes(undefined) &&
    !Object.values(filterObj).includes('')
  ) {
    return true;
  } else {
    return false;
  }
};

const CompanyOrganizationsInitiatives = ({ data }: any) => {
  const { t } = useTranslation('common');

  return (
    <Grid item>
      <Typography variant="h5" color="onSurface.100" mt="0px">
        {t('Organizations and Initiatives')}
      </Typography>
      {isDataValid(data) ? (
        <Grid container item mt={'30px'}>
          {Object.keys(data).map((arrayObj, key) => {
            const queryValue = arrayObj;
            const iconSrc = iconSrcMap[queryValue];

            return (
              <OrganizationsInitiativesCard
                key={key}
                queryValue={queryValue}
                iconSrc={iconSrc}
              />
            );
          })}
        </Grid>
      ) : (
        <EmptyTextField />
      )}
    </Grid>
  );
};

export default CompanyOrganizationsInitiatives;

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';
import Stack from 'components/common/Stack';

import RadialBar from 'components/common/RadialBar/RadialBar';
import colors from 'constants/colors';

type Props = {
  climateActionsScore?: number;
};

function ClimateActionScore({ climateActionsScore = 0 }: Props) {
  const { t } = useTranslation('common');

  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      {climateActionsScore || climateActionsScore === 0 ? (
        <RadialBar
          score={climateActionsScore}
          radialOffsetY={-5}
          offsetY={12}
          radialOffsetX={-2}
        />
      ) : null}

      <Typography
        component={'div'}
        variant="body1"
        color="onSurface.100"
        width={'100%'}
      >
        {t(
          'The Decarbonization Measures category reflects the operational actions in past, present and future timeframes that have an impact on emissions.'
        )}
      </Typography>
    </Stack>
  );
}

export default observer(ClimateActionScore);

export const findAnsweredQuestionIndex = (
  questionsArray: any,
  answeredQuestionId: string
) => {
  return questionsArray.findIndex(
    (question: any) => question.question_identifier === answeredQuestionId
  );
};

export const findAnsweredQuestionOptionIndex = (
  answeredQuestion: any,
  value: any
) => {
  return answeredQuestion.options.findIndex(
    (option: any) => option.text === value
  );
};

export const isQuestionAlreadyAnswered = (answeredQuestion: any) => {
  let isAnswered = false;
  answeredQuestion.options.forEach((option: any) => {
    if (option.selected === true) {
      isAnswered = true;
    }
  });
  return isAnswered;
};

export const spliceSelectedOptionIndexFromArray = (
  array: any,
  answeredQuestionIndex: any
) => {
  const index = array.indexOf(answeredQuestionIndex);
  array.splice(index, 1);
  return array;
};

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Typography from 'components/common/Typography';
import Divider from 'components/common/Divider';
import Grid from 'components/common/Grid';
import Stack from 'components/common/Stack';

import RatingLabel from './RatingLabel/RatingLabel';

import colors from 'constants/colors';

type Props = {
  badge?: string;
  label?: string;
  small?: boolean;
};

function CompanyRating({ badge, label, small }: Props) {
  const { t } = useTranslation('common');
  const [badgeName, setBabgeName] = useState('');

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (badge && label) {
      setBabgeName(label);
    }
  }, [badge, label]);

  return (
    <Grid item>
      {badge ? (
        <Stack
          direction={isXs ? 'column' : 'row'}
          spacing={{ xs: 1, sm: 0 }}
          alignItems={'center'}
          // m={small ? '14px 0px 14px 0px' : '0px'}
        >
          <RatingLabel
            iconSrc={badge} // link form api
            width={small ? '80px' : '52px'}
            marginRight="6px"
          />
          <Typography
            variant={small ? 'body2' : 'overline'}
            color={small ? colors.neutral600 : colors.secondary500}
            sx={{
              textAlign: 'center',
              padding: small ? '8px' : '4px',
              backgroundColor: '#F9F9F9',
              borderRadius: '40px',
              zIndex: '2',
              fontWeight: small ? 900 : 700,
              textTransform: 'uppercase',
              letterSpacing: '1px',
              pr: 2,
              pl: 2,
            }}
          >
            {t('Climate Choice')} {badgeName} {t('Rating')}
          </Typography>
          {!small ? (
            <Grid
              item
              sx={{ marginLeft: '-256px', zIndex: '1', width: '250px' }}
            >
              <Divider />
            </Grid>
          ) : null}
        </Stack>
      ) : null}
    </Grid>
  );
}

export default observer(CompanyRating);

import { decorate, observable, action, computed, runInAction } from 'mobx';

import axios from 'axios';
import api from 'constants/api';
// import * as schema from 'types/backendSchema';

import { phCapture } from 'utils/posthog';

import AuthStore from 'state/AuthStore';
import {
  findAnsweredQuestionIndex,
  isQuestionAlreadyAnswered,
  findAnsweredQuestionOptionIndex,
  spliceSelectedOptionIndexFromArray,
} from './utils';
// import {
//   email,
//   required,
//   combineFieldValidators,
// } from 'state/FormStore/fieldValidators';

class WizardStore {
  // OBSERVABLES................................................................
  id = '';
  auth: AuthStore | null = null;
  isInitializing = false;
  hasInitialized = false;
  isSubmitting = false;
  questionCount = 0;
  groupCount = 0;
  currentGroupIndex = 0;
  nextGroup = '';
  previousGroup = '';
  questions = observable([]);
  currentQuestionIndex = 0;
  currentGroupFromGet = {};
  isCrcComplete = false;
  questionAlreadyAnswered = false;
  crcActive = false;
  showCrcQuestions = false;

  // COMPUTEDS..................................................................
  get groupProgress() {
    return this.groupCount > 0
      ? (this.currentGroupIndex + 1) / this.groupCount
      : 0;
  }

  // ACTIONS....................................................................
  initWizard = async ({ auth, id }: { auth: AuthStore; id: string }) => {
    runInAction(() => {
      this.id = id;
      this.auth = auth;
      this.isInitializing = true;
      this.isCrcActive({ auth });
    });
    try {
      const res = await axios.get(
        `${api.url}/surveys/survey/${id}`,
        auth.requestConfig
      );

      runInAction(() => {
        this.updateWizard(res.data);
        this.currentGroupFromGet = res.data;
      });
      runInAction(() => {
        this.isInitializing = false;
        this.hasInitialized = true;
      });
    } catch (err: any) {
      runInAction(() => {
        this.isInitializing = false;
        this.hasInitialized = true;
      });
    }
  };

  finalizeCrcSurvey = async ({ auth, id }: { auth: AuthStore; id: string }) => {
    try {
      const res = await axios.post(
        `${api.url}/surveys/survey/${id}/submit/`,
        {},
        auth.requestConfig
      );
      phCapture('crc survey submit');
      runInAction(() => {
        this.isCrcComplete = !!(res as any).data['200'];
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  updateWizard = (data: any) => {
    // PLEASE CHANGE THIS "data: any" to the correct schema for the new survey stuff
    const questions = data?.questions || [];

    this.questionCount = data?.question_count;
    this.groupCount = data?.group_count;
    this.currentGroupIndex = data?.current_group_index;
    this.questions = questions;
    this.currentQuestionIndex = questions.length > 0 ? questions.length - 1 : 0;
    this.nextGroup = data?.next_group;
    this.previousGroup = data?.previous_group;
    this.isCrcComplete = data?.has_reached_crc_end;
    data.questions.forEach((question: any) => {
      this.questionAlreadyAnswered = isQuestionAlreadyAnswered(question);
    });
  };

  submitCurrentQuestionSingleChoice = async (
    { auth, id }: { auth: AuthStore; id: string },
    questionId: string,
    value: string
  ) => {
    //we create a new obj in order to ammend as need
    const updatedResponse = this.currentGroupFromGet as any;
    //from the questions array get the answered index
    const answeredQuestionIndex = findAnsweredQuestionIndex(
      updatedResponse.questions,
      questionId
    );
    //we check if the question is already answered in order to make a post or patch request
    runInAction(() => {
      this.questionAlreadyAnswered = isQuestionAlreadyAnswered(
        updatedResponse.questions[answeredQuestionIndex]
      );
    });
    //
    //---signle choice questions they have 3 options(Yes,No,Unsure) we check which one is selected
    // we need to set the answered one to true and the rest to false
    const answeredQuestionOptionIndex = findAnsweredQuestionOptionIndex(
      updatedResponse.questions[answeredQuestionIndex],
      value
    );

    // set the selected option to true
    updatedResponse.questions[answeredQuestionIndex].options[
      answeredQuestionOptionIndex
    ].selected = true;

    //create an array with unselected options
    const unselectedOptionsArray = spliceSelectedOptionIndexFromArray(
      Array.from(
        updatedResponse.questions[answeredQuestionIndex].options.keys()
      ),
      answeredQuestionOptionIndex
    );
    // set unselected options to false
    unselectedOptionsArray.forEach((element: any) => {
      updatedResponse.questions[answeredQuestionIndex].options[
        element
      ].selected = false;
    });
    //end single choice--//

    if (!this.questionAlreadyAnswered) {
      // single choice post request
      try {
        const data = updatedResponse;
        const resPost = await axios.post(
          `${api.url}/surveys/survey/${id}/`,
          data,
          auth.requestConfig
        );
        runInAction(() => {
          this.currentGroupFromGet = resPost.data;
          this.updateWizard(resPost.data);
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      // single choice patch
      // we need to send the Obj with only the changed answer in the questions array
      const selectedQuestionObj =
        updatedResponse.questions[answeredQuestionIndex];

      const setAnsweredQuestionForPatch = () => {
        updatedResponse.questions = [selectedQuestionObj];
      };
      setAnsweredQuestionForPatch();

      try {
        const data = updatedResponse;
        const resPost = await axios.patch(
          `${api.url}/surveys/survey/${id}/`,
          data,
          auth.requestConfig
        );
        runInAction(() => {
          this.currentGroupFromGet = resPost.data;
          this.updateWizard(resPost.data);
        });
      } catch (err) {
        console.log(err);
      }
    }
    runInAction(() => {
      this.nextGroup = (this.currentGroupFromGet as any).next_group;
    });
  };

  submitCurrentQuestionMultipleChoice = async (
    { auth, id }: { auth: AuthStore; id: string },
    questionId: string,
    value: string,
    checked: boolean
  ) => {
    //we create a new obj in order to ammend as need
    const updatedResponse = this.currentGroupFromGet as any;

    //from the questions array get the answered index
    const answeredQuestionIndex = findAnsweredQuestionIndex(
      updatedResponse.questions,
      questionId
    );

    //we check if the question is already answered in order to make a post or patch request
    runInAction(() => {
      this.questionAlreadyAnswered = isQuestionAlreadyAnswered(
        updatedResponse.questions[answeredQuestionIndex]
      );
    });
    //

    const answeredQuestionOptionIndex = findAnsweredQuestionOptionIndex(
      updatedResponse.questions[answeredQuestionIndex],
      value
    );
    // multiple choice selected option is true or false
    updatedResponse.questions[answeredQuestionIndex].options[
      answeredQuestionOptionIndex
    ].selected = checked;

    if (!this.questionAlreadyAnswered) {
      try {
        const data = updatedResponse;
        const resPost = await axios.post(
          `${api.url}/surveys/survey/${id}/`,
          data,
          auth.requestConfig
        );
        runInAction(() => {
          this.currentGroupFromGet = resPost.data;
          this.updateWizard(resPost.data);
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      // multiple choice patch
      // we need to send the Obj with only the changed answer in the questions array
      const selectedQuestionObj =
        updatedResponse.questions[answeredQuestionIndex];

      const setAnsweredQuestionForPatch = () => {
        updatedResponse.questions = [selectedQuestionObj];
      };
      setAnsweredQuestionForPatch();

      try {
        const data = updatedResponse;
        const resPost = await axios.patch(
          `${api.url}/surveys/survey/${id}/`,
          data,
          auth.requestConfig
        );
        runInAction(() => {
          this.currentGroupFromGet = resPost.data;
          this.updateWizard(resPost.data);
        });
      } catch (err) {
        console.log(err);
      }
    }
    runInAction(() => {
      this.nextGroup = (this.currentGroupFromGet as any).next_group;
    });
  };

  getNextGroup = async () => {
    this.isSubmitting = true;
    if (this.auth && this.nextGroup) {
      try {
        const res = await axios.get(this.nextGroup, this.auth.requestConfig);
        runInAction(() => {
          this.currentGroupFromGet = res.data;
          this.updateWizard(res.data);
        });
      } catch (err: any) {
        console.log(err);
        runInAction(() => {
          this.isSubmitting = false;
        });
      }
    }
  };

  getPreviousGroup = async () => {
    this.isSubmitting = true;
    if (this.auth && this.previousGroup) {
      try {
        const res = await axios.get(
          this.previousGroup,
          this.auth.requestConfig
        );
        runInAction(() => {
          this.currentGroupFromGet = res.data;
          this.updateWizard(res.data);
        });
        runInAction(() => {
          this.isSubmitting = false;
        });
      } catch (err: any) {
        console.log(err);
        runInAction(() => {
          this.isSubmitting = false;
        });
      }
    }
  };

  clearInit = () => {
    this.hasInitialized = false;
  };

  setShowCrcQuestions = () => {
    phCapture('crc survey load');
    runInAction(() => {
      this.showCrcQuestions = true;
    });
  };

  createNewCrc = async ({ auth }: { auth: AuthStore }) => {
    try {
      await axios.post(
        `${api.url}/surveys/survey/latest_crc/new/`,
        {},
        auth.requestConfig
      );
      runInAction(() => {
        this.crcActive = true;
      });
    } catch (err) {
      console.log(err);
    }
  };

  isCrcActive = async ({ auth }: { auth: AuthStore }) => {
    try {
      const res = await axios.get(
        `${api.url}/surveys/survey/latest_crc/new/`,
        auth.requestConfig
      );
      if (res.data.result === null) {
        runInAction(() => {
          this.crcActive = true;
        });
      } else if (res.data.detail === 'This company has no ongoing survey.')
        runInAction(() => {
          this.crcActive = false;
        });
    } catch (err: any) {
      console.log(err);
    }
  };
}

decorate(WizardStore, {
  id: observable,
  auth: observable,
  isSubmitting: observable,
  isInitializing: observable,
  hasInitialized: observable,
  currentGroupFromGet: observable,
  questionCount: observable,
  groupCount: observable,
  currentGroupIndex: observable,
  nextGroup: observable,
  previousGroup: observable,
  questions: observable,
  questionAlreadyAnswered: observable,
  crcActive: observable,
  showCrcQuestions: observable,
  groupProgress: computed,
  initWizard: action,
  getNextGroup: action,
  getPreviousGroup: action,
  updateWizard: action,
  clearInit: action,
  submitCurrentQuestionSingleChoice: action,
  submitCurrentQuestionMultipleChoice: action,
  isCrcActive: action,
});

export default WizardStore;

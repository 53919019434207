import React, { useEffect } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

type Props = {
  steps: Array<string>;
  activeStep: number;
  maxWidth?:number;
};

export default function HorizontalLinearStepper({ steps, activeStep,maxWidth }: Props) {
  const [activeStepNum, setActiveStepNum] = React.useState(0);

  useEffect(() => {
    setActiveStepNum(activeStep);
  }, [activeStep]);

  return (
      <Stepper activeStep={activeStepNum} sx={{ width: '100%', maxWidth: maxWidth, flexGrow: 1,margin:'auto' }}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
  );
}

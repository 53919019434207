// import React from 'react'
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import api from 'constants/api';

import Container from 'components/common/Container';

// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import AppLoading from 'components/common/AppLoading/AppLoading';
import Stack from 'components/common/Stack/Stack';
import { useState } from 'react';
import TccInputTextField from 'components/common/TccInputTextField/TccInputTextField';
import Button from 'components/common/Button/Button';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

function Component() {
  // comment
  const auth = useAuth();
  // const navigate = useNavigate()
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const { t } = useTranslation('common');
  const snackbarMessage = useSnackbarMessage();

  const { isLoading, isError, refetch } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['admin-login'],
    retry: false,
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/admin/companies/`, // <-- picking an admin route to try, this could be an admin/login or something like we do for normal app auth
        auth.requestConfig
      );
      return res.data as { [key: string]: string };
    },
  });

  const { mutate } = useMutation({
    onSuccess: (res) => {
      snackbarMessage.addMessage({
        message: res.data?.details || t('Success'),
      });
      refetch();
    },
    onError: (err) => {
      setCodeError(true);
    },
    mutationFn: async () => {
      setCodeError(false);
      const data = new FormData();
      data.set('code', code);
      const res = await axios.post(
        `${api.url}/auth/2fa/`,
        data,
        auth.requestConfig
      );
      return res;
    },
  });

  return isError ? (
    <Container pageContainer maxWidth="xs" sx={{ pt: 3 }}>
      <Stack spacing={2}>
        <TccInputTextField
          title={'2FA code'}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <Button onClick={() => mutate()}>{t('Submit')}</Button>
        {codeError ? (
          <Alert severity="error">{t('2FA code invalid.')}</Alert>
        ) : null}
      </Stack>
    </Container>
  ) : isLoading ? (
    <AppLoading />
  ) : (
    <Outlet />
  );
}

export default observer(Component);

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

import useRFIDialog from './store/useRFIDialog';

import Stack from 'components/common/Stack';
import Loading from 'components/common/Loading';
import Grid from 'components/common/Grid';
import Button from 'components/common/Button/Button';
import Typography from 'components/common/Typography';
import BootstrapDialogTitle from 'components/common/BootstrapDialogTitle';
import DialogActions from 'components/common/DialogActions';
import Box from 'components/common/Box';
import Switch from '@mui/material/Switch';
import TccInputTextField from 'components/common/TccInputTextField';

import { MailOutlineIcon } from 'components/icons/mui_icons';

const RFIForm = ({
  onClose = () => false,
  identifier,
  companyName = '',
  userDetails,
}) => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const snackbarMessage = useSnackbarMessage();
  const { onSubmit, isSubmitting, form, hasInitialized, init } = useRFIDialog();
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    form.setValue(
      'emailSelect',
      `<strong>${t('I prefer to be contacted by telephone')}:</strong> ${
        event.target.checked
      }`
    );
    setChecked(event.target.checked);
  };

  React.useEffect(() => {
    if (!hasInitialized) {
      init({ auth, identifier });
    }
    form.setValue(
      'subject',
      `${t('New Assessment request from')} ${companyName}`
    );
  });

  const customBodyText = `<strong>${t(
    'Email sent by'
  )}:</strong> ${auth.userDataForm.values.get(
    'first_name'
  )} ${auth.userDataForm.values.get('last_name')}<br>
  <strong>${t('User Email')}:</strong> ${userDetails.email}<br>
  ${form.values.get('emailSelect')}<br>
   ${
     checked
       ? `${form.values.get('phone')}<br>
   ${form.values.get('date_time')}`
       : `${form.values.get('company_contact_email')}<br>`
   }<br>
   ${form.values.get('message')}<br>
   `;

  const setDefaultValue = (prop, text) => {
    if (form.values.get(prop) === undefined) {
      form.setValue(prop, text);
    }
  };
  return !hasInitialized ? (
    <Box sx={{ display: 'flex', width: '50px', height: '50px' }}>
      <Loading color="primary" />
    </Box>
  ) : (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        form.setValue('body', customBodyText);
        onSubmit({ auth, snackbarMessage, onClose });
      }}
      onKeyPress={(e) => {
        if (e.shiftKey && e.key === 'Enter') {
          return;
        } else if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <Box padding={2}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          <Grid>
            <Typography variant={'h4'} color={'black'} mb={'10px'}>
              {t(`Request New Assessment`)}
            </Typography>
            <Typography variant={'body1'} color={'black'} mb={'10px'}>
              {t(
                `Please complete the details below and one of our analysts will be in touch to help you with your request.`
              )}
            </Typography>
          </Grid>
        </BootstrapDialogTitle>
        <Grid padding={2}>
          <Stack spacing={2}>
            <Stack direction={'row'} alignItems={'center'} pb={'20px'}>
              <Typography variant={'body1'} color={'neutral.contrastText'}>
                {t(`I prefer to be contacted by telephone`)}
              </Typography>
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                value={false}
                onSubmit={setDefaultValue(
                  'emailSelect',
                  `<strong>${t(
                    'I want to be contacted by telephone'
                  )}: False</strong><br> `
                )}
              />
            </Stack>
            {checked ? (
              <>
                <TccInputTextField
                  title={t(`Contact phone number`)}
                  subTitle={t(
                    `Please give your preferred contact telephone number and one of our analysts will call you to help with your request.`
                  )}
                  type="number"
                  error={form.errors.has('phone')}
                  onChange={(e) =>
                    form.setValue(
                      'phone',
                      `<strong>${t('Phone')}:</strong><br> ${e.target.value}`
                    )
                  }
                  onSubmit={setDefaultValue(
                    'phone',
                    `<strong>${t('Phone')}:</strong><br> `
                  )}
                />
                <TccInputTextField
                  title={t(`Preferred time and day to call`)}
                  type="text"
                  error={form.errors.has('date_time')}
                  onChange={(e) =>
                    form.setValue(
                      'date_time',
                      `<strong>${t(
                        'Preferred time and day to call'
                      )}:</strong><br> ${e.target.value}`
                    )
                  }
                  onSubmit={setDefaultValue(
                    'date_time',
                    `<strong>${t(
                      'Preferred time and day to call'
                    )}:</strong><br> `
                  )}
                />
              </>
            ) : null}
            {!checked ? (
              <TccInputTextField
                title={t(`Contact email`)}
                type="email"
                placeholder={'name@example.com'}
                defaultValue={userDetails.email}
                error={form.errors.has('company_contact_email')}
                onChange={(e) =>
                  form.setValue(
                    'company_contact_email',
                    `<strong>${t('Contact email:')}</strong><br> ${
                      e.target.value
                    }`
                  )
                }
                onSubmit={setDefaultValue(
                  'company_contact_email',
                  `<strong>${t('Contact email:')}</strong><br> ${
                    userDetails.email
                  }`
                )}
              />
            ) : null}
            <TccInputTextField
              title={t(`Your message (optional)`)}
              type="text"
              multiline
              rows={5}
              characterLimit={400}
              placeholder={t('press Shift+Enter to change line')}
              error={form.errors.has('message')}
              onChange={(e) =>
                form.setValue(
                  'message',
                  `<strong>${t('Message')}:</strong><br> ${e.target.value}`
                )
              }
              onSubmit={setDefaultValue(
                'message',
                `<strong>${t('Message')}:</strong><br> `
              )}
            />
            <Box />
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting || form.hasErrors}
              startIcon={<MailOutlineIcon />}
            >
              {t('send email')}
            </Button>
          </Stack>
        </Grid>
      </Box>
    </form>
  );
};

export default observer(RFIForm);

import { useTranslation } from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';

import colors from 'constants/colors';
import Typography from 'components/common/Typography';
import Box from 'components/common/Box';

import Avatar from 'components/common/Avatar';

import Stack from 'components/common/Stack/Stack';

import useUserCompany from 'hooks/useUserCompany';

import { getCountryName } from 'utils/getCountryName';
import Chip from 'components/common/Chip';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';
import Card from 'components/common/Card';

const Component = () => {
  const { t } = useTranslation('common');

  const auth = useAuth();
  const { userCompany } = useUserCompany();

  const { data } = useQuery({
    enabled: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: 3600000, // 1hr
    cacheTime: 3600000, // 1hr
    queryKey: ['companies-sectors'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/sectors`,
        auth.requestConfig
      );
      return res.data as { [key: string]: string };
    },
  });

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        onClick={() => false}
      >
        <Avatar
          sx={{
            width: '45px',
            height: '45px',
            color: colors.white,
            backgroundColor: colors.neutral200,
          }}
          src={userCompany.logo}
          alt={userCompany.name}
        >
          <BusinessIcon fontSize="small" />
        </Avatar>
        <Stack spacing={0}>
          <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
            <Typography
              color={colors.neutral600}
              fontSize="24px"
              lineHeight={1.3}
              fontWeight={'normal'}
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {userCompany.name}
            </Typography>
            {/* <img
                      alt="rating"
                      width={'24px'}
                      height={'24px'}
                      src={scorecard2URL}
                    /> */}
            <Box pl={2}>
              <Chip
                label={
                  data && userCompany.sector ? data[userCompany.sector] : ''
                }
              />
            </Box>
          </Stack>
          <Typography variant="body1" sx={{ color: colors.neutral400 }}>
            {getCountryName(userCompany.country)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default Component;

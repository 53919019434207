import { decorate, action, observable } from 'mobx';

import { required, email, string20 } from 'state/FormStore/fieldValidators';

import FormStore from 'state/FormStore';

class Store {
  // OBSERVABLES................................................................
  editCompanyId = '';
  filtersOpen = false;
  invalid = false;
  selectedCompany: any = {};
  selectedIds: any[] = [];
  selectedGLEIFCompany: any = {};
  selectedGLEIFIds: any[] = [];

  editCompanyForm: FormStore = new FormStore({
    defaults: {},
    fieldValidators: {
      name: required,
      email: email,
      lei_code: string20,
    },
  });

  // COMPUTEDS..................................................................

  // ACTIONS....................................................................
  setEditCompanyId = (id: string) => {
    this.editCompanyId = id;
  };

  setFiltersOpen = (bool: boolean) => {
    this.filtersOpen = bool;
  };

  setInvalid = (bool: boolean) => {
    this.invalid = bool;
  };

  setSelectedCompany = (company: any) => {
    this.selectedCompany = company;
  };

  setSelectedGLEIFCompany = (company: any) => {
    this.selectedGLEIFCompany = company;
  };

  setSelectedIds = (ids: any[]) => {
    this.selectedIds = ids;
  };

  setSelectedGLEIFIds = (ids: any[]) => {
    this.selectedGLEIFIds = ids;
  };

  getCompanyForm = (defaults = {}) => {
    return new FormStore({
      defaults: defaults,
      fieldValidators: {
        name: required,
        email: email,
        lei_code: string20,
      },
    });
  };
}

decorate(Store, {
  editCompanyId: observable,
  filtersOpen: observable,
  invalid: observable,
  selectedCompany: observable,
  selectedGLEIFCompany: observable,
  selectedIds: observable,
  selectedGLEIFIds: observable,
  setEditCompanyId: action,
  setFiltersOpen: action,
  setInvalid: action,
  setSelectedCompany: action,
  setSelectedGLEIFCompany: action,
  setSelectedIds: action,
  setSelectedGLEIFIds: action,
  getCompanyForm: action,
});

export default Store;

import React from 'react';

import Container from 'components/common/Container';

import EditCompanyDialog from '../Company/EditCompanyDialog';
import SearchInput from './SearchInput';
import SearchCompanyList from './SearchCompanyList';
// import SearchWikipediaCompanyList from './SearchWikipediaCompanyList';
// import SearchGLEIFCompanyList from './SearchGLEIFCompanyList';
// import SearchGoogleCompanyList from './SearchGoogleCompanyList';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import Switch from 'components/common/Switch';
// import { useSearchParams } from 'react-router-dom';

const Component = ({ isNested = false }: { isNested?: boolean }) => {
  // const { t } = useTranslation('common');
  // const [useGleif, setUseGleif] = React.useState(false);
  // const [useWikipedia, setUseWikipedia] = React.useState(false);
  // const [useGoogle, setUseGoogle] = React.useState(false);

  // let [searchParams] = useSearchParams();

  // const search = searchParams.get('search');
  // const country = searchParams.get('country');
  // const lei_code = searchParams.get('lei_code');

  // const disableGlief = !search && !country && !lei_code;
  // const disableWikipedia = !search;
  // const disableGoogle = !search;

  // React.useEffect(() => {
  //   if (disableGlief) {
  //     setUseGleif(false);
  //   }
  //   if (disableWikipedia) {
  //     setUseWikipedia(false);
  //   }
  //   if (disableGoogle) {
  //     setUseGoogle(false);
  //   }
  // }, [
  //   setUseGoogle,
  //   setUseWikipedia,
  //   setUseGleif,
  //   disableGlief,
  //   disableWikipedia,
  //   disableGoogle,
  // ]);
  return (
    <Container
      maxWidth="lg"
      pageContainer={!isNested}
      disableGutters={isNested}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SearchInput />
            </Grid>
            <Grid item xs={12}>
              <SearchCompanyList />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <EditCompanyDialog />
    </Container>
  );
};

export default Component;

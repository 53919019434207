export function addHttpsOnUrl(url: string | null) {
  if (url === null || url.trim() === '') {
    return '';
  }

  let amendedUrl = url.trim();
  const hasHttp =
    amendedUrl.startsWith('http://') || amendedUrl.startsWith('https://');
  const hasWww = amendedUrl.includes('www.');

  if (!hasHttp) {
    amendedUrl = 'https://' + amendedUrl;
  }

  // Check if the URL is a root domain or subdomain
  const isSubdomain = amendedUrl.split('.').length > 2; // Considers something like subdomain.example.com as a subdomain

  if (!hasWww && !isSubdomain) {
    const protocolEndIndex = amendedUrl.indexOf('//') + 2;
    amendedUrl =
      amendedUrl.slice(0, protocolEndIndex) +
      'www.' +
      amendedUrl.slice(protocolEndIndex);
  }

  return amendedUrl;
}

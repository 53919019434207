import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import Typography from 'components/common/Typography';
import Card from 'components/common/Card';

import AlertTooltip from 'components/common/AlertTooltip';
import CardMedia from 'components/common/CardMedia';
import { dashboardImages } from 'media/user_dashboard';
import Button from 'components/common/Button';
import TextWithLinks from 'components/common/TextWithLinks';

import { phCapture } from 'utils/posthog';

import RFIDialog from './RFIDialog';

type Props = {
  id?: string;
  companyName?: string;
  userDetails: object;
  rowDirection?: boolean;
};

function UserDashboardMonSuppliersES({ id, companyName, userDetails }: Props) {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const setOpenRFI = useCallback(() => {
    setOpen(!open);
    return true;
  }, [open]);

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12} md sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" color={'inherit'}>
                {t('Upload Supplier List')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextWithLinks
                color={'inherit'}
                initialText={t(
                  'Please use button below to attach a file with suppliers (including company name and country) and click "Upload Supplier List" at the bottom. Supported file formats include csv, tsv, xlsx, xls or txt. We will then process your file and the companies will be added to your {{link_start}}supplier list{{link_end}}. Our team may reach out to you if there are any questions.'
                )}
                links={['/dashboard/my-suppliers/list']}
              ></TextWithLinks>
            </Grid>
            <Grid item xs={12}>
              <AlertTooltip
                text={t('Learn more about Supplier Analytics.')}
                tooltipText={t(
                  'Supplier monitoring provides you with Climate Readiness Ratings and detailed benchmarking for up to your entire supply chain.'
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant={'contained'}
                onClick={() => {
                  setOpenRFI();
                  phCapture('upload suppliers click');
                }}
              >
                {t('Upload suppliers list')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <CardMedia
            image={dashboardImages.suppliers}
            component="img"
            sx={{
              objectFit: 'contain',
              padding: 2,
              maxWidth: '200px',
            }}
          />
        </Grid>
      </Grid>

      <RFIDialog
        open={open}
        closeDialog={setOpenRFI}
        identifier={id}
        companyName={companyName}
        userDetails={userDetails}
      />
    </Card>
  );
}

export default observer(UserDashboardMonSuppliersES);

import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';
import Collapse from '@mui/material/Collapse';

import SeeMoreTagsButton from './SeeMoreTagsButton/SeeMoreTagsButton';
import TagTitle from './TagTitle/TagTitle';
import TagsButtons from './TagsButton/TagsButtons';
import colors from 'constants/colors';
// import * as schema from 'types/backendSchema';

type Props = {
  title: string;
  filterTag: string;
  companyData: any;
  column?: boolean;
};

function TagBlock({ title, filterTag, companyData, column }: Props) {
  const navigateSearch = useNavigateSearch();
  const { t } = useTranslation('common');
  const tagsContainer = useRef(null);
  const [collapse, setCollapse] = React.useState(false);
  const [showButton, setShowButton] = React.useState(false);
  const [tags, setTags] = useState({});

  useEffect(() => {
    if (companyData) {
      setTags((companyData as any)[filterTag]);
    }
  }, [companyData, filterTag]);

  useLayoutEffect(() => {
    if (tagsContainer.current) {
      const tagsContainerElement = (tagsContainer.current as any).children[0];
      setShowButton(tagsContainerElement.clientHeight > 34);
    }
  }, [tags]);

  const handleChange = () => {
    setCollapse((prev) => !prev);
  };

  const handleCompanyFilterChange = (
    e: React.MouseEvent<HTMLElement>,
    tags: object
  ) => {
    if (tags && Object.keys(tags).length !== 0) {
      navigateSearch({
        pathname: `/companies/search`,
        params: {
          [filterTag]: [(e.currentTarget as HTMLInputElement).value],
        },
        replace: true,
      });
    }
  };

  return (
    <Grid container spacing={2} flexDirection={column ? 'column' : 'row'}>
      <Grid
        item
        xs="auto"
        sm={column ? 12 : 6}
        md={column ? 12 : 4}
        lg={column ? 12 : 3}
      >
        <TagTitle title={title} />
      </Grid>
      <Grid
        item
        xs="auto"
        sm={column ? 12 : 6}
        md={column ? 12 : 8}
        lg={column ? 12 : 9}
      >
        <Grid container>
          <Grid item xs={12}>
            <Collapse in={collapse} collapsedSize={'32px'} ref={tagsContainer}>
              {Object.keys(tags).length !== 0 ? (
                <TagsButtons
                  tags={tags}
                  handleCompanyFilterChange={handleCompanyFilterChange}
                />
              ) : (
                <Typography variant="caption" color={colors.neutral400}>
                  {t('not available')}
                </Typography>
              )}
            </Collapse>
          </Grid>
          <Grid item>
            <SeeMoreTagsButton
              show={showButton}
              handleChange={handleChange}
              collapse={collapse}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default observer(TagBlock);

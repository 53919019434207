import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export function useEmailROI() {
  const auth = useAuth();
  return useMutation({
    mutationFn: async ({
      subject,
      body,
      receiver_company,
      attachments = [],
    }: {
      subject: string;
      body: string;
      receiver_company: string;
      attachments?: any[];
    }) => {
      const formData = new FormData();
      formData.set('subject', subject);
      formData.set('body', body);
      formData.set('receiver_company', receiver_company);
      for (let attachment of attachments) {
        formData.append('attachments', attachment);
      }
      const res = await axios.post(
        `${api.url}/emails/contact_tcc/`,
        formData,
        auth.requestConfig
      );

      return res.data;
    },
  });
}

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import Chip from 'components/common/Chip';
import Box from 'components/common/Box';

import ClearAllButton from 'components/common/ClearAllButton';

function FiltersChip() {
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  const chipFilterCategories = [
    { key: 'partner', label: t('Climate Performance Assessment') },
    { key: 'tcc_impact_categories', label: t('Impact Category') },
    { key: 'tcc_product_tags', label: t('Product & Services') },
    { key: 'tcc_impact_tags', label: t('Climate Focus') },
    { key: 'tcc_nace_codes', label: t('NACE Codes') },
    { key: 'sectors', label: t('Sectors') },
    { key: 'tcc_eu_taxonomy_codes', label: t('EU Taxonomy Codes') },
    { key: 'tcc_affiliations', label: t('Organizations and Initiatives') },
    { key: 'certifications', label: t('Certifications') },
    { key: 'sdgs', label: t('Sustainable Development Goals (SDGs)') },
    { key: 'target', label: t('Target Data') },
    { key: 'cdp_data', label: t('CDP data available') },
    { key: 'country', label: t('Country') },
  ];

  const handleDelete = (keyData: string) => {
    const newParams = Object.fromEntries(searchParams);
    delete newParams[keyData];

    navigateSearch({
      pathname: location.pathname,
      params: {
        ...newParams,
      },
      replace: true,
    });
  };

  let params = chipFilterCategories.filter((item) =>
    searchParams.has(item.key)
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
        mb: 3,
        alignItems: 'center',
      }}
    >
      {params.map((data) => {
        return (
          <Chip
            size={'small'}
            key={data.key}
            label={data.label}
            onDelete={() => handleDelete(data.key)}
          />
        );
      })}
      <Box>
        <ClearAllButton />
      </Box>
    </Box>
  );
}

export default observer(FiltersChip);

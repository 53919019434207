import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import Loading from 'components/common/Loading/Loading';
import Switch from 'components/common/Switch';
import useUserCompanyCustomers from 'hooks/useUserCompanyCustomers';
import {
  useCompanyDataAccessPermissionsGive,
  useCompanyDataAccessPermissionsRemove,
} from 'hooks/useCompanyDataAccessPermissions';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import Stack from 'components/common/Stack';
import { useEffect } from 'react';
import { Chip, Typography } from '@mui/material';
import useAccessRequestList from 'hooks/useAccessRequests';
import useUserCompany from 'hooks/useUserCompany';

function CustomNoRowsOverlay() {
  const { t } = useTranslation();
  return (
    <Box p={4} textAlign={'center'}>
      {t('No customers.')}
    </Box>
  );
}

const ConfidentialDataAccessTable = () => {
  const { t } = useTranslation('common');
  const snackbarMessage = useSnackbarMessage();

  const { userCompany } = useUserCompany();
  const {
    data: requests,
    refetch: refetchRequests,
    isLoading: isLoadingRequests,
  } = useAccessRequestList();

  const { isLoading, data, isError, refetch, isRefetching } =
    useUserCompanyCustomers();
  const {
    isError: isErrorGive,
    isLoading: isLoadingGive,
    mutate: givePermission,
  } = useCompanyDataAccessPermissionsGive();
  const {
    isError: isErrorRemove,
    isLoading: isLoadingRemove,
    mutate: removePermission,
  } = useCompanyDataAccessPermissionsRemove();

  useEffect(() => {
    if (isErrorRemove || isErrorGive) {
      snackbarMessage.addMessage({ message: t('Something went wrong.') });
    }
  }, [snackbarMessage, isErrorGive, isErrorRemove]);

  const buyers = data || [];
  const rows = buyers.map((buyer: any) => ({
    name: buyer.name,
    id: buyer.id,
    slug: buyer.slug,
    has_received_access: buyer.has_received_access,
  }));

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Company Name'),
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Stack spacing={2} direction={'row'}>
          <Typography variant="body1">{params.row.name}</Typography>
          {!userCompany?.default_customer_data_access_permissions &&
          !params.row.has_received_access &&
          requests?.find(
            (r: any) =>
              r?.data_provider === userCompany?.id &&
              r?.data_receiver === params.row?.id
          ) ? (
            <Chip label={t('New Request')} />
          ) : null}
        </Stack>
      ),
    },
    {
      field: 'access',
      headerName: t('Access'),
      sortable: false,
      renderCell: (params) => (
        <Stack spacing={1}>
          <Switch
            disabled={
              userCompany?.default_customer_data_access_permissions ||
              isRefetching
            }
            label={''}
            checked={
              userCompany?.default_customer_data_access_permissions
                ? true
                : params?.row.has_received_access
                ? true
                : false
            }
            onChange={
              isLoadingGive || isLoadingRemove
                ? () => false
                : params?.row.has_received_access
                ? () =>
                    removePermission({
                      id: params?.row.id,
                      onSuccess: () => refetch(),
                    })
                : () =>
                    givePermission({
                      id: params?.row.id,
                      onSuccess: () => refetch(),
                    })
            }
          />
        </Stack>
      ),
    },
  ];

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Alert severity="error">{t('There was a problem')}</Alert>
  ) : (
    <Box sx={{ height: '100%', minHeight: '240px', width: '100%' }}>
      <DataGrid
        sx={{
          '& .MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
        rows={rows}
        autoHeight
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        pageSizeOptions={[5]}
        disableColumnMenu
        hideFooter
        disableRowSelectionOnClick
      />
    </Box>
  );
};
export default observer(ConfidentialDataAccessTable);

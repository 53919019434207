import { useEffect } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSearchParams } from 'react-router-dom';

import useUserCompany from 'hooks/useUserCompany';
import useAuth from 'hooks/useAuth';

import UserCompanyMyUsersTable from './UserCompanyMyUsersTable';

const UserCompanyMyUsers = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const {
    loadUserCompanyUsers,
    hasLoadedUserCompanyUsers,
    isLoadingUserCompanyUsers,
    userCompanyUsers,
    userCompanyUsersCount,
    userCompanyUsersPage,
  } = useUserCompany();

  let [searchParams] = useSearchParams();
  let page = searchParams.get('page') || '1';

  useEffect(() => {
    if (
      (userCompanyUsersPage !== page || !hasLoadedUserCompanyUsers) &&
      !isLoadingUserCompanyUsers
    ) {
      loadUserCompanyUsers({ auth, page });
    }
  }, [
    loadUserCompanyUsers,
    auth,
    hasLoadedUserCompanyUsers,
    isLoadingUserCompanyUsers,
    page,
    userCompanyUsersPage,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h5">{t('Company Users')}</Typography>
        <Typography
          variant={'caption2'}
          color={'neutral.contrastText'}
          mb={'10px'}
        >
          {t(
            'Below is a list of all users currently connected to your company. If you wish to add, edit or remove any users, please contact us at support@theclimatechoice.com for now.'
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}></Grid>
      <Grid item xs={12}>
        <UserCompanyMyUsersTable
          users={toJS(userCompanyUsers.slice())}
          usersCount={userCompanyUsersCount}
        />
      </Grid>
    </Grid>
  );
};

export default observer(UserCompanyMyUsers);

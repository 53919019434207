import React from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import { useTranslation } from 'react-i18next';

import CountrySelect from 'components/common/CountrySelect/CountrySelect';

const CountrySearchFilter = () => {
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  const [country, setCountry] = React.useState(
    searchParams.get('country') || ''
  );

  React.useEffect(() => {
    const param = searchParams.get('country');
    if (param) {
      setCountry(param);
    }
  }, [setCountry, searchParams]);

  const filterCountry = (country: string) => {
    setCountry(country);
    navigateSearch({
      pathname: location.pathname,
      params: country ? { country } : {},
      removeParams: country ? [] : ['country'],
      replace: false,
    });
  };

  return (
    <CountrySelect
      value={country}
      title={t('Country')}
      onChange={(country) => filterCountry(country)}
    />
  );
};

export default CountrySearchFilter;

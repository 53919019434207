import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useAuth from 'hooks/useAuth';
import useCompanies from 'hooks/useCompanies';
import FilterItem from './FilterItem';
import ImpactCategoryFilter from './FilterStack/ImpactCategories/ImpactCategoryFilter';
// import MoreFilters from './MoreFilters';
import TccPartnerMenu from './FilterStack/TccPartnerFilter/TccPartnerFilter';
import FilterSectionSearchTags from './FilterStack/FilterTags/FilterSectionSearchTags';
import CDP from './FilterStack/CDP';
import EMAS from './FilterStack/EMAS';
import Country from './FilterStack/Country';

import colors from 'constants/colors';
import Box from '@mui/material/Box';
import Targets from './FilterStack/Targets';

const Filters = () => {
  const auth = useAuth();
  const { t } = useTranslation('common');

  const {
    loadCompanyFilterCategories,
    isLoadingCompanyFilterCategories,
    hasLoadedCompanyFilterCategories,
    companyFilterCategories,
  } = useCompanies();

  React.useEffect(() => {
    if (
      !hasLoadedCompanyFilterCategories &&
      !isLoadingCompanyFilterCategories
    ) {
      loadCompanyFilterCategories({ auth });
    }
  }, [
    auth,
    hasLoadedCompanyFilterCategories,
    isLoadingCompanyFilterCategories,
    loadCompanyFilterCategories,
    companyFilterCategories,
  ]);

  return (
    <Box
      sx={{
        backgroundColor: colors.neutral100,
        padding: (theme) => theme.spacing(1.5),
        borderRadius: 1,
      }}
    >
      <FilterItem title={t('Climate Performance Assessment')}>
        <TccPartnerMenu />
      </FilterItem>
      <FilterItem title={t('Carbon Disclosure Project')} divider>
        <CDP />
      </FilterItem>
      <FilterItem title={t('Targets')} divider>
        <Targets />
      </FilterItem>
      <FilterItem title={t('Eco-Management and Audit Scheme')} divider>
        <EMAS />
      </FilterItem>
      <FilterItem title={t('Country')} divider>
        <Country />
      </FilterItem>
      <FilterItem title={t('Impact Category')} divider>
        <ImpactCategoryFilter />
      </FilterItem>
      <FilterItem title={t('Product & Services')}>
        <FilterSectionSearchTags
          filterId={'tcc_product_tags'}
          tags={companyFilterCategories.tcc_product_tags}
        />
      </FilterItem>
      <FilterItem title={t('Climate Focus')}>
        <FilterSectionSearchTags
          filterId={'tcc_impact_tags'}
          tags={companyFilterCategories.tcc_impact_tags}
        />
      </FilterItem>
      <FilterItem title={t('Sectors')}>
        <FilterSectionSearchTags
          filterId={'sectors'}
          tags={companyFilterCategories.sectors}
        />
      </FilterItem>
    </Box>
  );
};

export default observer(Filters);

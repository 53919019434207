import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

import TccCheckbox from 'components/common/TccCheckbox';
import Typography from 'components/common/Typography';
import Stack from 'components/common/Stack';
import Button from 'components/common/Button/Button';
import DialogActions from 'components/common/DialogActions';
import TextWithLinks from 'components/common/TextWithLinks';

import Box from 'components/common/Box';

function TermsConditions() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  let auth = useAuth();
  const [buttonDisable, setButtonDisable] = useState(true);
  const [termsPolicyValues, setTermsPolicyValues] = useState({
    terms: 'False',
    policy: 'True',
  });

  const setUserTerms = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setTermsPolicyValues({ ...termsPolicyValues, terms: 'True' });
      } else {
        setTermsPolicyValues({ ...termsPolicyValues, terms: 'False' });
      }
    },
    [termsPolicyValues]
  );

  useEffect(() => {
    if (
      termsPolicyValues.policy === 'True' &&
      termsPolicyValues.terms === 'True'
    ) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [termsPolicyValues.policy, termsPolicyValues.terms]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 8,
      }}
    >
      <Stack gap={2}>
        <Stack>
          <Typography variant={'h4'} color={'black'} mb={'10px'}>
            {t(`Terms and Conditions`)}
          </Typography>
          <TextWithLinks
            blankPage
            initialText={t(
              `The use of our platform is governed by our {{link_start}}Terms and Conditions{{link_end}}.`
            )}
            links={[
              t(
                'https://theclimatechoice.com/en/general-terms-and-conditions/'
              ),
            ]}
          />
          <TextWithLinks
            blankPage
            initialText={t(
              `Details on how your data is processed in relation to the use of our platform can be found in our {{link_start}}Privacy Policy{{link_end}}.`
            )}
            links={[t('https://theclimatechoice.com/en/privacy-policy/')]}
          />
        </Stack>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            auth.setUserTerms(termsPolicyValues.terms === 'True');
            auth.setUserPrivacyPolicy(true);
            navigate('/');
          }}
        >
          <Stack gap={2}>
            <Stack>
              <Stack direction="row" alignItems="center">
                <TccCheckbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserTerms(e)
                  }
                  sx={{ pl: '0px' }}
                />
                <Typography variant="body1" color={'inherit'}>
                  {t('I have read and consent to the Terms and Conditions')}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent={'space-between'}
            //
          >
            <DialogActions sx={{ width: '50%' }}>
              <Button
                fullWidth
                sx={{ height: '40px' }}
                variant="contained"
                size="large"
                type="submit"
                disabled={buttonDisable}
              >
                {t('I agree')}
              </Button>
            </DialogActions>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}

export default observer(TermsConditions);

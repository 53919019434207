import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';
import { scrollToTop } from 'utils/browser';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import Pagination from '@mui/material/Pagination';
import Box from 'components/common/Box';

import useCompanies from 'hooks/useCompanies';

import CompaniesListDataItem from './CompaniesListDataItem';
import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';

import CompaniesSortingMenu from './CompaniesSortingMenu';

import colors from 'constants/colors';

const CompaniesListData = ({ rows = [] }: { rows: any[] }) => {
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();
  const { count, isLoadingCompanies, hasLoadedCompanies } = useCompanies();

  const onPageChange = React.useCallback(
    (e: any, page: number) => {
      navigateSearch({
        pathname: location.pathname,
        params: {
          page: page,
        },
        replace: false,
      });
      scrollToTop();
    },
    [location.pathname, navigateSearch]
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ paddingBottom: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <Typography
              variant="subtitle1"
              color={colors.neutral600}
              sx={(theme: any) => ({ padding: theme.spacing(3, 0, 2, 0) })}
            >
              {t('{{count}} result found', { count })}
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography variant="subtitle1" color={colors.neutral500}>
              {t('Sort by')}
            </Typography>
            <CompaniesSortingMenu />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: 'relative' }}>
        {isLoadingCompanies && (
          <LinearProgress
            sx={{
              position: 'absolute',
              width: '100%',
              top: '-4px',
            }}
            color="primary"
          />
        )}
        {rows.map((row, index) => (
          <CompaniesListDataItem key={index} row={row} />
        ))}
      </Box>
      {!isLoadingCompanies && hasLoadedCompanies && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <Pagination
            onChange={onPageChange}
            color="primary"
            count={Math.ceil(count / 10)}
            siblingCount={2}
            boundaryCount={2}
            shape="rounded"
            defaultPage={1}
            page={parseInt(searchParams.get('page') || '1')}
          />
        </Box>
      )}
    </Box>
  );
};

export default observer(CompaniesListData);

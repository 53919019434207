export const isValidEmail = (email = '') => {
  if (typeof email !== 'string') {
    return false;
  } else {
    const re =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(email);
  }
};

export const isValidPhone = (phone = '') => {
  if (typeof phone !== 'string') {
    return false;
  } else {
    const re = /^\+?\d{1,}$/i;
    return re.test(phone);
  }
};

export const isValidUrl = (url = '') => {
  if (typeof url !== 'string') {
    return false;
  } else {
    const re =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    return re.test(url);
  }
};

export const isValidNumber = (number = '') => {
  if (typeof number === 'number') {
    return true;
  } else if (typeof number === 'string') {
    const re = /^[0-9,-]+$/gi;
    return re.test(number);
  } else {
    return false;
  }
};

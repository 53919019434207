import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import useCompany from 'hooks/useCompany';

import Box from 'components/common/Box';
import Grid from 'components/common/Grid';
import Card from 'components/common/Card';

import TotalRadar from './TotalRadar/TotalRadar';
import TitleBlock from './TitleBlock/TitleBlock';
import colors from 'constants/colors';
import { getClimateScoreCategoryScores } from 'utils/tools';

function CompanyDimensionBenchmark({
  scorecard,
}: {
  scorecard?: { overall: number; categories: { score: number }[] };
}) {
  const { t } = useTranslation('common');

  const {
    emissionsCalculation,
    strategyAndControl,
    targets,
    decarbonizationMeasures,
    emissionsPerformance,
  } = getClimateScoreCategoryScores(scorecard);

  return (
    <Card>
      <Grid container justifyContent="center">
        <Grid item xs={'auto'} pt={12}>
          <Box position={'relative'}>
            <>
              <TitleBlock
                title={t('Emissions Calculation')}
                bottom="350px"
                right="180px"
                showCompaniesP
                showCompanyP
                score={emissionsCalculation}
              />
              <TitleBlock
                title={t('Climate Targets')}
                bottom="210px"
                right="5px"
                showCompaniesP
                showCompanyP
                score={targets}
              />
              <TitleBlock
                title={t('Strategy & Control')}
                bottom="30px"
                right="5px"
                showCompaniesP
                showCompanyP
                score={strategyAndControl}
              />
              <TitleBlock
                title={t('Decarbonization Measures')}
                bottom="30px"
                right="355px"
                showCompaniesP
                showCompanyP
                score={decarbonizationMeasures}
              />
              <TitleBlock
                title={t('Emissions Performance')}
                bottom="210px"
                right="384px"
                showCompaniesP
                showCompanyP
                score={emissionsPerformance}
              />
            </>

            <TotalRadar
              emissionsCalculation={emissionsCalculation}
              strategyAndControl={strategyAndControl}
              targets={targets}
              decarbonizationMeasures={decarbonizationMeasures}
              emissionsPerformance={emissionsPerformance}
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

export default observer(CompanyDimensionBenchmark);

import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FiberNewIcon from '@mui/icons-material/FiberNew';

import { getLocalFormattedDate } from 'utils/date';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';

import CheckIcon from 'components/icons/Check';

const CPASurveysData = ({ rows, loading, page, count }) => {
  let navigate = useNavigate();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();

  const columns = [
    { field: 'id', hide: true, filterable: false, flex: 0 },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 0.1,
      minWidth: 50,
      filterable: true,
      sortable: false,
      renderCell: (params) =>
        params.row.status === 'NEW' ? (
          <FiberNewIcon />
        ) : params.row.status === 'IN_REVIEW' ? (
          <AutorenewIcon />
        ) : (
          <CheckIcon />
        ),
    },
    {
      field: 'version',
      headerName: t('Version'),
      flex: 0.1,
      minWidth: 50,
      filterable: false,
      sortable: false,
    },
    {
      field: 'survey',
      headerName: t('Date'),
      flex: 0,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row &&
            params.row.submitted &&
            getLocalFormattedDate(params.row.submitted)}
        </div>
      ),
    },
    {
      field: 'personal_identifier',
      filterable: false,
      headerName: t('Name'),
      flex: 1,
    },
    {
      field: 'score',
      headerName: t('Score'),
      flex: 0.1,
      minWidth: 55,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <div style={{ fontWeight: 'bold' }}>
          {params.row &&
            typeof params.row.score_total_crc === 'number' &&
            `${params.row.score_total_crc}%`}
        </div>
      ),
    },
    {
      field: 'score-climate-impact',
      headerName: 'Strategy',
      flex: 0.1,
      minWidth: 55,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row &&
            typeof params.row.score_climate_impact === 'number' &&
            `${params.row.score_climate_impact}%`}
        </div>
      ),
    },
    {
      field: 'score-climate-leadership',
      headerName: 'Governance',
      flex: 0.1,
      minWidth: 55,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row &&
            typeof params.row.score_climate_leadership === 'number' &&
            `${params.row.score_climate_leadership}%`}
        </div>
      ),
    },
    {
      field: 'score-climate-transparency',
      headerName: 'Transparency',
      flex: 0.1,
      minWidth: 55,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row &&
            typeof params.row.score_climate_transparency === 'number' &&
            `${params.row.score_climate_transparency}%`}
        </div>
      ),
    },
    {
      field: 'score-climate-performance',
      headerName: 'Metrics & Targets',
      flex: 0.1,
      minWidth: 55,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row &&
            typeof params.row.score_climate_performance === 'number' &&
            `${params.row.score_climate_performance}%`}
        </div>
      ),
    },
    {
      field: 'score-climate-actions',
      headerName: 'Decarbonisation Measures',
      flex: 0.1,
      minWidth: 55,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row &&
            typeof params.row.score_climate_actions === 'number' &&
            `${params.row.score_climate_actions}%`}
        </div>
      ),
    },
    {
      field: 'note',
      filterable: false,
      headerName: t('Note'),
      flex: 1,
      renderCell: (params) => <div>{params.row && params.row.note}</div>,
    },
  ];

  const onPageChange = React.useCallback(
    (page) => {
      navigateSearch({
        pathname: location.pathname,
        params: {
          page: page + 1,
        },
        replace: false,
      });
    },
    [location.pathname, navigateSearch]
  );

  const onRowClick = React.useCallback(
    (row) => {
      navigate(`/cpa-surveys/${row.row.id}`);
    },
    [navigate]
  );

  return (
    <DataGrid
      autoHeight
      rows={rows}
      rowCount={count}
      loading={loading}
      paginationModel={{
        page: parseInt(searchParams.get('page')) - 1 || 0,
        pageSize: 10,
      }}
      pageSizeOptions={[10]}
      paginationMode="server"
      onPaginationModelChange={(newPageSize) => {
        onPageChange(newPageSize.page);
      }}
      onRowClick={onRowClick}
      columns={columns}
    />
  );
};

export default CPASurveysData;

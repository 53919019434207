// import React from 'react'
import axios from 'axios';
import { observer } from 'mobx-react-lite';
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import useAuth from 'hooks/useAuth';
import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';
import api from 'constants/api';
import Loading from 'components/common/Loading/Loading';

import DataPointTable from './DataPointTable';
import colors from 'constants/colors';

function Component({
  variable,
  title,
  unit,
}: {
  variable: string;
  title?: string;
  unit?: string;
}) {
  const auth = useAuth();
  const { t } = useTranslation('common');
  const { isLoading, data, isError } = useQuery({
    queryKey: [`user-company-data-point-${variable}`],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/user/company/data_points/`,
        Object.assign({}, auth.requestConfig, { params: { variable } })
      );
      return res.data.results as any[];
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          sx={{ fontSize: '16px', color: colors.neutral500 }}
          pb={1}
        >
          {title}
        </Typography>
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <Alert severity="error">{t('There was a problem')}</Alert>
        ) : (
          <DataPointTable data={data} variable={variable} unit={unit} />
        )}
      </Grid>
      <Grid item xs={12} />
    </Grid>
  );
}

export default observer(Component);

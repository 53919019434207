import SpaIcon from '@mui/icons-material/Spa';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import BuildIcon from '@mui/icons-material/Build';
import Energy from './Energy';

export const impactCategoriesIcons = {
  BUILDINGS: ApartmentIcon,
  DIGITAL: AlternateEmailIcon,
  ENERGY: Energy,
  ENVIRONMENT: SpaIcon,
  FINANCE: AccountBalanceWalletIcon,
  FOOD: FastfoodIcon,
  INDUSTRY: BuildIcon,
  SOCIETY: AccessibilityNewIcon,
  TRANSPORT: DirectionsBusIcon,
};

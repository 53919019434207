import React, { useState, useRef, useEffect } from 'react';

import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import Tooltip from 'components/common/Tooltip';

type Props = {
  text: string;
};

export default function TccTooltipText({ text }: Props) {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    setIsOverflow((textElementRef.current as any).clientWidth >= 180);
  }, []);

  return (
    <Stack>
      <Tooltip title={text ? text : ''} disableHoverListener={!isOverflowed}>
        <div ref={textElementRef}>
          <Typography
            variant={'body1'}
            color={'neutral.contrastText'}
            style={{
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {text}
          </Typography>
        </div>
      </Tooltip>
    </Stack>
  );
}

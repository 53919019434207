const scopes3Breakdown = [
  'SCOPE_3_01',
  'SCOPE_3_02',
  'SCOPE_3_03',
  'SCOPE_3_04',
  'SCOPE_3_05',
  'SCOPE_3_06',
  'SCOPE_3_07',
  'SCOPE_3_08',
  'SCOPE_3_09',
  'SCOPE_3_10',
  'SCOPE_3_11',
  'SCOPE_3_12',
  'SCOPE_3_13',
  'SCOPE_3_14',
  'SCOPE_3_15',
  'SCOPE_3_16',
  'SCOPE_3_17',
];

export default scopes3Breakdown;

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export function useCompanyDataAccessPermissionsGive() {
  const auth = useAuth();
  return useMutation({
    mutationFn: async ({
      id,
      onSuccess,
    }: {
      id: string;
      onSuccess?: () => void;
    }) => {
      const res = await axios.post(
        `${api.url}/companies/data_access_permissions/${id}`,
        {},
        auth.requestConfig
      );
      if (onSuccess) {
        onSuccess();
      }
      return res.data;
    },
  });
}

export function useCompanyDataAccessPermissionsRemove() {
  const auth = useAuth();
  return useMutation({
    mutationFn: async ({
      id,
      onSuccess,
    }: {
      id: string;
      onSuccess?: () => void;
    }) => {
      const res = await axios.delete(
        `${api.url}/companies/data_access_permissions/${id}`,
        auth.requestConfig
      );
      if (onSuccess) {
        onSuccess();
      }
      return res.data;
    },
  });
}

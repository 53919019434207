import React, { useEffect, useCallback, createRef } from 'react';

import Fab from 'components/common/Fab';
import { ArrowBackIcon } from 'components/icons/mui_icons';

function GoOnTopButton() {
  const inputEl = createRef<HTMLButtonElement>();

  const scrollTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const checkScrollTop = useCallback(() => {
    if (inputEl.current && window.pageYOffset > 300) {
      (inputEl.current as any).style.display = 'block';
    } else if (inputEl.current && window.pageYOffset <= 300) {
      (inputEl.current as any).style.display = 'none';
    }
  }, [inputEl]);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  return (
    <Fab
      ref={inputEl}
      color="primary"
      size={'large'}
      sx={{
        display: 'none',
        position: 'fixed',
        right: '16px',
        top: '80%',
        marginRight: '10px',
        zIndex: 1,
      }}
      onClick={scrollTop}
    >
      <ArrowBackIcon
        sx={{ transform: 'rotate(90deg)', color: 'white', mt: '8px' }}
      />
    </Fab>
  );
}

export default GoOnTopButton;

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useAdminCompanyJobs({
  companyId,
  page,
  status,
  type,
  refetchInterval = false,
}: {
  companyId?: string;
  page?: number;
  status?: string;
  type?: string;
  refetchInterval?: false | number;
}) {
  const auth = useAuth();
  return useQuery({
    enabled: !!companyId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: refetchInterval,
    cacheTime: 5000,
    staleTime: 5000,
    queryKey: [
      'company-data-services',
      {
        companyId,
        page,
        status,
        type,
      },
    ],
    queryFn: async () => {
      if (companyId) {
        const params = {
          company: companyId,
          type: type,
          status: status,
          page: page?.toString(),
        };
        const res = await axios.get(
          `${api.url}/etl/jobs/`,
          Object.assign(auth.requestConfig, { params })
        );
        return res.data;
      }
      return [];
    },
  });
}

import React from 'react';
import { observer } from 'mobx-react-lite';

import Typography from 'components/common/Typography';
import colors from 'constants/colors';

type Props = {
  shortDescription: string | undefined;
};

function CompanyShortDescription({ shortDescription }: Props) {
  return (
    <>
      {shortDescription ? (
        <Typography
          variant="subtitle1"
          fontSize={'16px'}
          color={colors.neutral700}
          mt={'22px'}
        >
          {shortDescription}
        </Typography>
      ) : null}
    </>
  );
}

export default observer(CompanyShortDescription);

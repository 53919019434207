import { TypographyOptions } from '@mui/material/styles/createTypography';
import { fontSize, fontWeight, letterSpacing } from 'constants/typography';

const tccTypography = {
  fontFamily: ['Lato', 'sans-serif'].join(','),
  h3: {
    fontSize: fontSize.h3,
    fontWeight: fontWeight.h3,
  },
  h4: {
    fontSize: fontSize.h4,
    fontWeight: fontWeight.h4,
  },
  h5: {
    fontSize: fontSize.h5,
    fontWeight: fontWeight.h5,
  },
  h6: {
    fontSize: fontSize.h6,
    fontWeight: fontWeight.h6,
  },
  h7: {
    fontSize: fontSize.h7,
    fontWeight: fontWeight.h6,
  },
  h8: {
    fontSize: fontSize.h8,
    fontWeight: fontWeight.h6,
  },
  body1: {
    fontSize: fontSize.body1,
  },
  subtitle1: {
    fontSize: fontSize.subTitle1,
    fontWeight: fontWeight.subTitle1,
  },
  subtitle2: {
    fontSize: fontSize.subTitle2,
    fontWeight: fontWeight.subTitle2,
  },
  caption: {
    fontSize: fontSize.caption,
    fontWeight: fontWeight.caption,
  },
  overline: {
    fontSize: fontSize.overline,
    fontWeight: fontWeight.overline,
    letterSpacing: letterSpacing.overline,
  },
  //custom styles
  caption2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  action_small: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  action_medium: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '1px',
  },
  crc_card_header: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '26px',
    lineHeight: '30px',
  },
} as TypographyOptions;

export default tccTypography;

const colors = {
  primary500: '#6AD1BC',
  primary400: '#78D5C2',
  primary300: '#86DAC9',
  primary100: '#EAF9F6',
  secondary500: '#113192',
  secondary400: '#28459C',
  secondary300: '#3E59A7',
  secondary100: '#E8EBF6',
  onSurface200: '#585757',
  onSurface100: '#1C1C1C',
  neutral800: '#131D29',
  neutral700: '#2D3F53',
  neutral600: '#4E6883',
  neutral500: '#6F89A4',
  neutral400: '#98A8B9',
  neutral200: '#E6EBEF',
  neutral100: '#F9F9F9',
  warning100: '#FFF3CC',
  warning500: '#FFAB00',
  tertiary100: '#ffccd8',
  tertiary500: '#EB6E80',
  tertiary600: '#E15555',
  tertiary800: '#964652',
  white: '#FFFFFF',
  error: '#E15555',
  //
  brandYellow: '#FBFB62',
  gold: {
    start: '#e0cd60',
    middle: '#c6ae3c',
    end: '#bba43f',
    text: '#825e01',
  },
  silver: {
    start: '#bcbcbc',
    middle: '#b5b5b5',
    end: '#8c8c8c',
    text: '#555555',
  },
  bronze: {
    start: '#e29f81',
    middle: '#d38360',
    end: '#9f5a3f',
    text: '#81552c',
  },
};

export default colors;

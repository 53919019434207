import React, { useState } from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPagination,
  GridRowParams,
} from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';
import Chip from 'components/common/Chip';
import Container from 'components/common/Container';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Stack from 'components/common/Stack';
import ViewIcon from '@mui/icons-material/ArticleOutlined';
import useAdminCompanyJobs from 'hooks/useAdminCompanyJobs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getLanguagePreference } from 'utils/browser';
import { getDurationBetweenDates, getLengthOfTime } from 'utils/date';
import Box from 'components/common/Box';
import colors from 'constants/colors';
import Card from 'components/common/Card';
import Button from 'components/common/Button';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import { getClimateScoreAsPercent } from 'utils/tools';
import Typography from 'components/common/Typography';
import { useAccessRequestCreate } from 'hooks/useAccessRequests';
import { SendOutlined } from '@mui/icons-material';
import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import Check from 'components/icons/Check';
import CollectionListDataGrid from './CollectionListDataGrid';

const fakeData = [
  {
    id: Math.random(),
    company_name: 'Acme Corp',
    company_slug: 'test-company-214',
    reporting_company_name: null,
    reporting_company_slug: null,
    maturity_level_self_reported: null,
    maturity_level: 'FAIR',
    climate_score: 6.1,
    public_data_available: true,
    public_data_screening_status: 'COMPLETED',
    public_data_screening_last_date: new Date(
      new Date().setDate(new Date().getDate() - 28)
    ),
    private_data_status: null,
  },
  {
    id: Math.random(),
    company_name: 'Concrete Co.',
    company_slug: 'test-company-214',
    reporting_company_name: 'Concrete Group',
    reporting_company_slug: 'concrete-group',
    maturity_level_self_reported: 'FAIR',
    maturity_level: 'POOR',
    climate_score: 3.9,
    public_data_screening_status: 'IN_PROGRESS',
    public_data_screening_last_date: null,
    private_data_status: 'ACCESS_GRANTED',
    private_data_last_update: new Date(
      new Date().setDate(new Date().getDate() - 5)
    ),
  },
  {
    id: Math.random(),
    company_name: 'RWS Web Services',
    company_slug: 'test-company-214',
    reporting_company_name: 'Rainforest.com Inc',
    reporting_company_slug: 'rainforest-group',
    maturity_level_self_reported: 'GOOD',
    maturity_level: 'FAIR',
    climate_score: 10.4,
    public_data_available: true,
    public_data_screening_status: 'COMPLETED',
    public_data_screening_last_date: new Date(
      new Date().setDate(new Date().getDate() - 15)
    ),
    private_data_status: 'ACCESS_CAN_BE_REQUESTED',
    private_data_last_update: new Date(
      new Date().setDate(new Date().getDate() - 105)
    ),
  },
  {
    id: Math.random(),
    company_name: 'Wisozk Ltd',
    company_slug: 'test-company-214',
    reporting_company_name: null,
    reporting_company_slug: null,
    maturity_level_self_reported: null,
    maturity_level: 'POOR',
    climate_score: 0,
    public_data_screening_status: null,
    public_data_screening_last_date: null,
    private_data_status: null,
    private_data_last_update: null,
  },
];

function UserCompanyCollectionList({
  collectionId,
  hasCollections,
}: {
  collectionId?: string;
  hasCollections?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { userCompany } = useUserCompany();
  const [searchParams] = useSearchParams();

  const showFakeData = searchParams.get('example') === 'true';

  const { data, isLoading, refetch, isRefetching } =
    useUserCompanyCollectionOverview({
      collection_id: collectionId,
    });

  const dedupeByName = (data: any[]): any[] => {
    const seenNames = new Set<string>();
    return data.filter((item) => {
      if (seenNames.has(item.company_name)) {
        return false;
      }
      seenNames.add(item.company_name);
      return true;
    });
  };

  const rows = showFakeData
    ? fakeData
    : data
    ? dedupeByName(data).map((d: any) => ({
        id: d.company_uuid,
        company_name: d.company_name,
        company_slug: d.company_slug,
        reporting_company_name: d.reporting_company_name,
        reporting_company_slug: d.reporting_company_slug,
        climate_score:
          d.reporting_company_climate_score_overall ||
          d.company_climate_score_overall,
        maturity_level:
          (d.reporting_company_public_data_screening_last_date &&
            d.reporting_company_maturity_level) ||
          (d.company_public_data_screening_last_date &&
            d.company_maturity_level),
        maturity_level_self_reported:
          d.reporting_company_maturity_level_crc ||
          d.company_maturity_level_crc,
        public_data_available: !!(
          d.reporting_company_public_data_screening_last_date ||
          d.company_public_data_screening_last_date
        ),
        public_data_screening_status:
          d.reporting_company_public_data_screening_status ||
          d.company_public_data_screening_status,
        public_data_screening_last_date:
          d.reporting_company_public_data_screening_last_date ||
          d.company_public_data_screening_last_date,
        private_data_status:
          d.reporting_company_private_data_status ||
          d.company_private_data_status,
        private_data_last_update:
          d.reporting_company_private_data_updation_last_date ||
          d.company_private_data_updation_last_date,
      }))
    : [];

  return (
    <CollectionListDataGrid
      collectionId={collectionId}
      rows={rows}
      loading={hasCollections && !showFakeData && isLoading}
    />
  );
}

export default UserCompanyCollectionList;

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Stack from 'components/common/Stack';

import useSignUp from 'hooks/useSignUp';

const Component = ({ confidential = false }: { confidential?: boolean }) => {
  const { t } = useTranslation('common');
  const { formPage } = useSignUp();

  return (
    <Stack
      direction={'row'}
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Box>
        <Typography variant={'h4'} color={'black'} mb={1}>
          {t(`Register for The Climate Choice`)}
        </Typography>
        <Typography display="block" variant={'subtitle'} color={'black'}>
          {t(`Fields marked with an asterisk (*) are mandatory.`)}
        </Typography>
        {confidential ? (
          <Typography display="block" variant={'subtitle'} color={'black'}>
            {t(
              `The information submitted on this page is considered confidential.`
            )}
          </Typography>
        ) : null}
      </Box>
      <Typography
        flex={0}
        whiteSpace={'nowrap'}
        variant="caption"
        color={'neutral.contrastText'}
      >
        {t(`Page {{pageNumber}} of {{totalNumberOfPages}}`, {
          pageNumber: formPage,
          totalNumberOfPages: 2,
        })}
      </Typography>
    </Stack>
  );
};

export default observer(Component);

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import AppLoading from 'components/common/AppLoading';
import Container from 'components/common/Container';
import Alert from '@mui/material/Alert';

import UserDashboardDrawer from 'components/App/Layout/AppDrawer';

import useAuth from 'hooks/useAuth';
import useUserCompany from 'hooks/useUserCompany';

function DashboardLayout() {
  const auth = useAuth();
  const [loaded, setLoaded] = React.useState(false);
  const {
    loadUserCompany,
    loadUserCompanyCRC,
    loadUserCompanyCRCAssets,
    loadUserCompanyCPALink,
    userCompany,
  } = useUserCompany();

  React.useEffect(() => {
    async function initializeDashboard() {
      try {
        // we need to wait for this async call to get userDetails
        await loadUserCompany({ auth });
        await loadUserCompanyCRC({ auth });
        await loadUserCompanyCRCAssets({ auth });
        await loadUserCompanyCPALink({ auth });
      } catch (err) {
        console.log(err);
      }
      setLoaded(true);
    }
    if (!loaded) {
      initializeDashboard();
    }
  }, [
    loaded,
    auth,
    loadUserCompany,
    loadUserCompanyCRC,
    loadUserCompanyCRCAssets,
    loadUserCompanyCPALink,
  ]);
  return !loaded ? (
    <AppLoading />
  ) : userCompany && userCompany.id ? (
    <Container disableGutters sx={{ display: 'flex', overflow: 'hidden' }}>
      <UserDashboardDrawer />
      <Outlet />
    </Container>
  ) : (
    <Container pageContainer>
      <Alert severity="error">user has no company assigned</Alert>
    </Container>
  );
}

export default observer(DashboardLayout);

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';

export default function useAdminCompanyTargets({
  companyId,
  jobId,
  initialPage = 1,
}: {
  companyId?: string;
  jobId?: string;
  initialPage?: number;
}) {
  const [page, setPage] = useState(initialPage);
  const auth = useAuth();

  const queryResult = useQuery({
    enabled: !!companyId || !!jobId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    queryKey: ['company-targets', { companyId, jobId, page }],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (companyId) {
        params.set('company', companyId);
      }
      if (jobId) {
        params.set('job', jobId);
      }
      if (page) {
        params.set('page', page + '');
      }
      const res = await axios.get(
        `${api.url}/admin/company/targets/`,
        Object.assign(auth.requestConfig, { params })
      );
      return res.data;
    },
  });

  return {
    ...queryResult,
    page,
    setPage,
  };
}

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import DemoMockedData from '../DemoMockedData/store/DemoMockedData';

import Grid from 'components/common/Grid/Grid';
import Card from 'components/common/Card';
import Typography from 'components/common/Typography/Typography';

import UserDashboardSuppliersLineChart from '../UserDashboardSuppliersLineChart';

import colors from 'constants/colors';

function Component() {
  const { t } = useTranslation('common');

  const { carbonIntensityPerCompany } = DemoMockedData();

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Typography variant="h7" color="onSureface.100">
          {t('Carbon Intensity')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersLineChart
            title={t(
              'Carbon Intensity: Reported Scope 1+2 emissions per million Euro (revenue)'
            )}
            titleY={t('Metric tons CO2 / million Euro')}
            formater={''}
            groups={[]} //[{ title: 'Scope', cols: 3 }]
            color={colors.neutral400}
            data={carbonIntensityPerCompany('scope1_2_per_mill_eur')}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <UserDashboardSuppliersLineChart
            title={t(
              'Carbon Intensity: Reported Scope 1+2 emissions per employee'
            )}
            titleY={t('Metric tons CO2 / employee')}
            formater={''}
            groups={[]} //[{ title: 'Scope', cols: 3 }]
            color={colors.neutral400}
            data={carbonIntensityPerCompany('scope1_2_per_employee')}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

export default observer(Component);

import { observer } from 'mobx-react-lite';
import ReactApexChart from 'react-apexcharts';

import constantColors from 'constants/colors';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

type PieChartProps = {
  labels: string[];
  data: number[];
  dataColors?: string[];
  colors?: string[];
  width?: string;
  legendBottom?: boolean;
};

function Component({
  labels,
  data,
  dataColors,
  colors,
  width,
  legendBottom,
}: PieChartProps) {
  const suplliersPieConf = {
    series: data,
    options: {
      labels: labels,
      colors: colors,
      dataLabels: {
        enabled: true,
        position: 'left',
        style: {
          colors: dataColors ? dataColors : ['white'],
          fontSize: '14px',
        },
        dropShadow: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -15,
          },
        },
      },
      responsive: [
        {
          breakpoint: legendBottom ? 3000 : 480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  const { t } = useTranslation();

  const allZeros = (arr: number[]): boolean => {
    return arr.every((value) => value === 0);
  };

  return allZeros(data) ? (
    <Typography variant="subtitle1" color={constantColors.neutral600}>
      {t('0 results found')}
    </Typography>
  ) : (
    <ReactApexChart
      options={suplliersPieConf.options}
      series={suplliersPieConf.series}
      width={width ? width : '100%'}
      type="pie"
    />
  );
}

export default observer(Component);

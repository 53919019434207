import React from 'react';
import { observer } from 'mobx-react-lite';

import Avatar from 'components/common/Avatar';
import colors from 'constants/colors';

function Logo({ logoSrc, title }: { logoSrc?: string; title?: string }) {
  function stringAvatar(name = '') {
    if (name.includes(' ')) {
      return {
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    } else return { children: name.slice(0, 2) };
  }
  return (
    <Avatar
      src={logoSrc}
      alt=""
      {...stringAvatar(title)}
      sx={{
        width: 140,
        height: 140,
        fontSize: '48px',
        fontWeight: 900,
        color: 'neutral.contrastText',
        backgroundColor: colors.neutral100,
        border: '0.1px solid onSurface.100',
        '& .MuiAvatar-img': {
          objectFit: 'contain',
        },
      }}
    />
  );
}

export default observer(Logo);

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import useAuth from 'hooks/useAuth';
import useCompanies from 'hooks/useCompanies';

import Search from './Search';
import Filters from './Filters';
import FiltersChip from './CompaniesFilterIdentifier/FiltersChip';

import Container from '@mui/material/Container';
import Grid from 'components/common/Grid';
import { Outlet, useSearchParams, useLocation } from 'react-router-dom';

import GoOnTopButton from 'components/common/GoOnTopButton';

const CompaniesListContainer = () => {
  const auth = useAuth();
  const location = useLocation();
  let [searchParams] = useSearchParams();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const { loadCompanies } = useCompanies();

  const search = searchParams.get('search') || '';
  const page = searchParams.get('page') || '1';
  const naceCodes = searchParams.get('tcc_nace_codes') || '';
  const productTags = searchParams.get('tcc_product_tags') || '';
  const eUTaxonomyCode = searchParams.get('tcc_eu_taxonomy_codes') || '';
  const impactTag = searchParams.get('tcc_impact_tags') || '';
  const impactCategories = searchParams.get('tcc_impact_categories') || '';
  const affiliationsProfileFilter = searchParams.get('tcc_affiliations') || '';
  const sgdProfileFilter = searchParams.get('sdgs') || '';
  const certificationsProfileFilter = searchParams.get('certifications') || '';
  const partnerFilters = searchParams.get('partner') || undefined;
  const companySortingFilter = searchParams.get('ordering') || '';
  const targets = searchParams.get('target_framework') || undefined;
  const cdpData = searchParams.get('cdp_data') || undefined;
  const emasData = searchParams.get('emas_data') || undefined;
  const sectors = searchParams.get('sectors') || undefined;
  const country = searchParams.get('country') || undefined;

  useEffect(() => {
    loadCompanies({
      auth,
      search,
      page,
      impactCategories: impactCategories,
      ids:
        location.pathname === '/companies/watchlist'
          ? auth.favorites.slice()
          : [],
      naceCodes,
      countFavorites:
        location.pathname === '/companies/watchlist' ? true : false,
      productTags,
      eUTaxonomyCode,
      impactTag,
      affiliationsProfileFilter,
      sgdProfileFilter,
      certificationsProfileFilter,
      partnerFilters,
      companySortingFilter,
      targets,
      cdpData,
      emasData,
      sectors,
      country,
    });
  }, [
    auth,
    auth.favorites,
    loadCompanies,
    search,
    page,
    naceCodes,
    productTags,
    eUTaxonomyCode,
    impactTag,
    impactCategories,
    affiliationsProfileFilter,
    sgdProfileFilter,
    certificationsProfileFilter,
    partnerFilters,
    companySortingFilter,
    targets,
    cdpData,
    emasData,
    location.pathname,
    sectors,
    country,
  ]);
  return (
    <Container
      maxWidth="lg"
      sx={(theme) => ({
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6),
      })}
    >
      <GoOnTopButton />
      <Grid
        container
        spacing={{ xs: 2, sm: 3 }}
        direction={isXs ? { xs: 'column-reverse' } : undefined}
      >
        <Grid item xs={12} sm={4} md={3}>
          <Filters />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <Search />
          <FiltersChip />
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
};

export default observer(CompaniesListContainer);

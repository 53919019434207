import React from 'react';
import { useTranslation } from 'react-i18next';
// import { getLanguagePreference } from 'utils/browser';

import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';

// import Link from 'components/common/Link';

import colors from 'constants/colors';
// import { dashboardImages } from 'media/user_dashboard';
// import SocialMediaIcon from 'components/App/Routes/Company/CompanyDetailsPage/CompanyInfoBlock/ContactCard/SocilaMedia/SocilaMediaIcon/SocialMediaIcon';
// import Card from 'components/common/Card/Card';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from 'utils/browser';
import Card from 'components/common/Card';
import Stack from 'components/common/Stack';
import Launch from '@mui/icons-material/Launch';

type CustomCardType = {
  title: string;
  subtitle?: any;
  link?: any;
};
const CustomCard = ({ title, subtitle, link }: CustomCardType) => {
  const isExternal = link.includes('https://');
  const navigate = useNavigate();
  return (
    <Grid item xs={12} sm={6}>
      <Card
        noBoxShadow
        sx={{ border: '1px solid', borderColor: colors.neutral200, p: 0 }}
      >
        <CardActionArea
          href={isExternal ? `${link}` : ''}
          target={isExternal ? '_blank' : undefined}
          rel={isExternal ? 'noopener' : undefined}
          onClick={
            isExternal
              ? () => false
              : () => {
                  navigate(link);
                  scrollToTop();
                }
          }
          sx={{
            height: '100%',

            padding: (theme) => theme.spacing(4, 3),
          }}
        >
          <Stack direction="row" spacing={2}>
            <Typography variant="h5" color={colors.neutral700} sx={{ flex: 1 }}>
              {title}
            </Typography>
            {isExternal ? (
              <Typography
                variant="h5"
                color={colors.neutral400}
                sx={{ flex: 0 }}
              >
                <Launch />
              </Typography>
            ) : null}
          </Stack>
          <Typography pt={1} variant="body1" color={colors.neutral600}>
            {subtitle}
          </Typography>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const CRCOverviewSlackCard = () => {
  const { t } = useTranslation('common');
  // const browserLang = getLanguagePreference({ twoLetter: true });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <Typography pl={4} variant="h5" color={colors.neutral700}>
          {t('Your Toolbox for Climate Action')}
        </Typography>
        <Typography pl={4} pt={1} variant="body1" color={colors.neutral600}>
          {t(
            'Free resources to support the next steps on your climate journey'
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={2}>
          <CustomCard
            title={t('The Climate Playbook')}
            subtitle={t('Get the free Climate Playbook')}
            link={t('https://theclimatechoice.com/en/climate-playbook-2023/')}
          />
          <CustomCard
            title={t('Carbon Accounting Guide for Suppliers')}
            subtitle={t('Including 8 free tools to get started')}
            link={t(
              'https://theclimatechoice.com/en/carbon-accounting-guide-free-tools-catalogue-for-suppliers/'
            )}
          />
          <CustomCard
            title={t('Explore the upcoming CHOICE Events')}
            subtitle={t('Free educational Webinars for Climate Transformation')}
            link={t('https://theclimatechoice.com/en/resources/choice-events/')}
          />
          <CustomCard
            title={t('The Climate Drive')}
            subtitle={t(
              'The Climate Drive (powered by WBCSD) offers an open first stop for businesses to confidently move from climate ambition to action.'
            )}
            link={'https://www.theclimatedrive.org/action-library'}
          />
          <CustomCard
            title={t('Unlock your Supply Chain Decarbonization Program ')}
            subtitle={t('Analyze supplier structure & collect auditable data')}
            link={'/dashboard/my-suppliers/screening'}
          />
          <CustomCard
            title={t('Subscribe to Climate News')}
            subtitle={t('Read about latest climate trends in our Newsletter')}
            link={t(
              'https://mailchi.mp/107ce1e49692/the-climate-choice-newsletter-anmeldung'
            )}
          />
          <CustomCard
            title={t('Read about latest trends in the CLIMATE Magazine')}
            link={t(
              'https://theclimatechoice.com/en/resources/climate-magazine/'
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CRCOverviewSlackCard;

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import useAuth from 'hooks/useAuth';
import { useSearchParams } from 'react-router-dom';
import useCPASurveys from './store/useCPASurveys';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import CPASortingMenu from './CPASortingMenu';
import Search from './Search';
import CPASurveysList from './CPASurveysList';

const CPASurveys = () => {
  const auth = useAuth();
  let [searchParams] = useSearchParams();
  const { loadCPASurveys } = useCPASurveys();
  const page = searchParams.get('page') || 1;
  const status = searchParams.get('status') || 'NEW';
  const companyName = searchParams.get('company_name') || '';
  useEffect(() => {
    loadCPASurveys({ auth, page, status, companyName });
  }, [loadCPASurveys, auth, page, status, companyName]);

  return (
    <Container maxWidth="lg" sx={{ pt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Stack direction="row" spacing={2} justifyContent={'space-between'}>
            <CPASortingMenu />
            <Search />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <CPASurveysList status={status} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default observer(CPASurveys);

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import UserCompanyEditForm from './UserCompanyEditForm';

const UserCompanyEdit = () => {
  const { t } = useTranslation('common');
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h5">{t('Company Information')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <UserCompanyEditForm />
      </Grid>
    </Grid>
  );
};

export default observer(UserCompanyEdit);

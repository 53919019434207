import MuiLinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';

// type Props = {};

export default function LinearProgress(props: LinearProgressProps) {
  // const [activeStepNum, setActiveStepNum] = React.useState(0);

  return <MuiLinearProgress {...props} />;
}

import React from 'react';
import { observer } from 'mobx-react-lite';

import RFIForm from './RFIForm';
import Dialog from 'components/common/Dialog';

import useRFIDialog from './store/useRFIDialog';

const RFIDialog = ({
  open = false,
  subject = '',
  closeDialog = () => false,
  identifier,
  companyName,
  userDetails,
}) => {
  const { reset } = useRFIDialog();
  const onClose = () => {
    closeDialog();
    setTimeout(() => reset(), 300);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <RFIForm
        onClose={onClose}
        subject={subject}
        identifier={identifier}
        companyName={companyName}
        userDetails={userDetails}
      />
    </Dialog>
  );
};

export default observer(RFIDialog);

import React from 'react';

import Typography from 'components/common/Typography';
import colors from 'constants/colors';

type Props = {
  title: string;
};

function TextTitle({ title }: Props) {
  return (
    <Typography
      variant="body2"
      color={colors.neutral600}
      maxWidth={'145px'}
      sx={{ wordWrap: 'break-word' }}
      align={'center'}
    >
      {title}
    </Typography>
  );
}

export default TextTitle;

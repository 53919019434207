import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import useUserCompany from 'hooks/useUserCompany';

// import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';

import Stack from 'components/common/Stack';
import api from 'constants/api';
import AppLoading from 'components/common/AppLoading';
import { getClimateScoreCategoryScores } from 'utils/tools';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';

import Box from 'components/common/Box';
import CollectionOverviewList from './CollectionList';
import SelectMenu from 'components/common/SelectMenu';
import useUserCompanyCollections from 'hooks/useUserCompanyCollections';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/common/Button';
import Check from 'components/icons/Check';
import Download from '@mui/icons-material/Download';
import Link from 'components/common/Link';

function DownloadCollectionData({
  collectionId,
  disabled,
}: {
  collectionId?: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation('common');
  return (
    // <Link
    //   href={
    //     `${api.url}/user/company/collection/${collectionId}/export_to_excel/`
    //   }
    //   rel="noopener"
    //   underline="none"
    //   target="_blank"
    // >
    <Button disabled={true} type="button" startIcon={<Download />}>
      {t('Download Data')}
    </Button>
    // </Link>
  );
}

function UserDashboardCollections() {
  const auth = useAuth();

  const { t } = useTranslation('common');
  const { userCompany } = useUserCompany();
  const navigate = useNavigate();

  const { isLoading, data, isError } = useUserCompanyCollections();
  const [notificationSending, setNotificaitonSending] = useState(false);
  const [notificationSent, setNotificaitonSent] = useState(false);
  const sendNotification = async () => {
    try {
      const formData = new FormData();
      formData.set('subject', '[Support] Info request: Collections');
      formData.set(
        'body',
        `
          user name: ${auth.userDetails.first_name} ${auth.userDetails.last_name}
          user email: ${auth.userDetails.email}
          user company: ${userCompany.name}
        `
      );
      formData.set('receiver_company', userCompany.name);
      setNotificaitonSending(true);
      const res = await axios.post(
        `${api.url}/emails/contact_tcc/`,
        formData,
        auth.requestConfig
      );
      if (res && res.data) {
        setNotificaitonSent(true);
      }
      setNotificaitonSending(false);
    } catch (err) {
      console.log(err);
      setNotificaitonSending(false);
    }
  };
  const { collectionId } = useParams();

  const collectionNames = data ? data.map((d: any) => d.name) : [];
  const selectedCollectionIndex = data?.findIndex(
    (d: any) => d.id === collectionId
  );
  const selectedCollection = !data
    ? undefined
    : selectedCollectionIndex > -1
    ? data[selectedCollectionIndex]
    : data[0];

  const [searchParams] = useSearchParams();
  const showExample = searchParams.get('example') === 'true';
  const hasCollections = collectionNames.length > 0;
  const exampleNames = [t('Example Collection')];

  return (
    <Grid container spacing={{ xs: 2, sm: 3 }}>
      <Grid item xs={12}>
        {isLoading ? (
          <AppLoading />
        ) : isError ? (
          <Alert severity="error">{t('Something went wrong')}</Alert>
        ) : (
          <Grid container spacing={{ xs: 2, sm: 3 }}>
            <Grid item xs={12}>
              <Typography variant={'h4'} sx={{ pb: 3 }}>
                {t('Collections')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} alignItems={'flex-end'}>
                <Box flex={1}>
                  <Box maxWidth={'400px'}>
                    <SelectMenu
                      items={showExample ? exampleNames : collectionNames}
                      handleChange={(e) => {
                        const id = data?.find(
                          (d: any) => d.name === e.target.value
                        )?.id;

                        if (id) {
                          navigate(`/dashboard/my-company/collections/${id}`);
                        }
                      }}
                      value={
                        showExample ? exampleNames[0] : selectedCollection?.name
                      }
                    />
                  </Box>
                </Box>
                {selectedCollection?.id && (
                  <Box>
                    <DownloadCollectionData
                      collectionId={selectedCollection?.id}
                      disabled={showExample}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <CollectionOverviewList
                collectionId={selectedCollection?.id}
                hasCollections={hasCollections}
              />
            </Grid>
            {showExample ? (
              <Grid item xs={12}>
                <Alert
                  severity="warning"
                  action={
                    <Button
                      color="warning"
                      variant="outlined"
                      size="small"
                      disabled={notificationSending || notificationSent}
                      onClick={() => {
                        sendNotification();
                      }}
                      startIcon={
                        notificationSent ? (
                          <Check />
                        ) : (
                          <ScheduleSendOutlinedIcon />
                        )
                      }
                    >
                      {notificationSent ? t('Requested') : t('Request upgrade')}
                    </Button>
                  }
                >
                  <Typography variant="body1" textAlign="left">
                    {t(
                      'You need to upgrade in order to access collections. Let us know if you are interested!'
                    )}
                  </Typography>
                </Alert>
              </Grid>
            ) : null}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default observer(UserDashboardCollections);
